import React, { useCallback, useEffect, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import Select from 'react-select';
import { CashIcon, CreditCardIcon, CurrencyDollarIcon, HashtagIcon, PencilAltIcon, UserIcon } from '@heroicons/react/outline';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
var CryptoJS = require("crypto-js");

// Create a constructor for functional component to get permissions
const useConstructor = (callBack = () => { }) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
}

//This has to be a functional component to use 'useParams()' from react-router-dom
const EditarPagosPage = (props) => {
    // Pago States
    // eslint-disable-next-line
    const [operador_id, setOperadorId] = useState('');
    const [operador, setOperador] = useState('');
    const [pagador_id, setPagadorId] = useState('');
    const [pagador, setPagador] = useState('');
    const [pagador_type, setPagadorType] = useState('');
    const [estudiante_id, setEstudianteId] = useState('');
    const [estudiante, setEstudiante] = useState('');
    const [referencia, setReferencia] = useState('');
    const [subtotal, setSubTotal] = useState(0.00);
    const [total, setTotal] = useState(0.00);
    const [forma_pago, setFormaPago] = useState('');
    const [dias, setDias] = useState([]);
    const [productos, setProductos] = useState([]);
    // eslint-disable-next-line
    const [tutores, setTutores] = useState([]);
    // eslint-disable-next-line
    const [estudiantes, setEstudiantes] = useState([]);
    const [filteredEstudiantes, setFilteredEstudiantes] = useState([]);
    const [cafeteria_productos, setCafeteriaProductos] = useState([]);
    // Request/Form states
    // eslint-disable-next-line
    const [area, setArea] = useState('cafeteria');
    const [week, setWeek] = useState(null);
    const [days, setDays] = useState([]);
    // eslint-disable-next-line
    const [statusResult, setStatusResult] = useState(0);
    const [loaded, setLoaded] = useState(false);
    // eslint-disable-next-line
    const [processingRequest, setProcessingRequest] = useState(false);
    // eslint-disable-next-line
    const [errors, setErrors] = useState([{ pagador_id: '', estudiante_id: '', dias: '', forma_pago: '', referencia: '', productos: '' }]);
    const [usersAccess, setUsersAccess] = useState([]);

    //Get the passed :id from parent and pass it to the component to render it
    const pago = useParams();

    /// Decript permissions
    useConstructor(() => {
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUsersAccess(_usersAccess);
    });

    const handleDays = useCallback((createdAt) => {
        const daysOfWeek = [{value: 1, label: 'Lunes'}, {value: 2, label: 'Martes'}, {value: 3, label: 'Miércoles'}, {value: 4, label: 'Jueves'}, {value: 5, label: 'Viernes'}];
        const dayOfWeek = createdAt.getDay();
        const startDate = new Date(createdAt);
        startDate.setDate(createdAt.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
        const endDate = new Date(createdAt);
        endDate.setDate(createdAt.getDate() + (5 - dayOfWeek));
        const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
        const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() + 1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
        setWeek({ startDate: formattedStartDate, endDate: formattedEndDate });
        setDays(daysOfWeek);
    }, []);

    // Get Pago by Id and set days for cafetería
    useEffect(() => {
        RestClient.GetRequest(AppUrl.PagoById + pago.id).then((result) => {
            setOperadorId(result.operador_id);
            setOperador(result.operador);
            setPagadorId(result.pagador_id);
            setPagador(result.pagador);
            setPagadorType(result.pagador_type);
            setEstudianteId(result.estudiante_id);
            setEstudiante(result.estudiante);
            setDias(result.dias);
            setFormaPago(result.forma_pago);
            setReferencia(result.referencia);
            setSubTotal(parseFloat(result.total) / result.dias.length);
            setTotal(parseFloat(result.total));
            setProductos(result.productos);
            handleDays(new Date(result.created_at))

            setLoaded(true);
        });
    }, [pago.id, handleDays]);
    // Get Collections 
    useEffect(() => {
        // Get Tutores
        RestClient.GetRequest(AppUrl.Tutores).then((result) => {
            setTutores([]);
            result.forEach(element => {
                setTutores(prevState => [...prevState, { value: element.id, label: element.nombre + ' ' + element.apellido, type: 'tutores', estudiantes: element.estudiantes }]);
            });
        });
        // Get Estudiantes
        RestClient.GetRequest(AppUrl.Estudiantes).then((result) => {
            setEstudiantes([]);
            setFilteredEstudiantes([]);
            result.forEach(element => {
                setEstudiantes(prevState => [...prevState, { value: element.id, label: element.nombre + ' ' + element.apellido, type: 'estudiantes' }]);
                setFilteredEstudiantes(prevState => [...prevState, { value: element.id, label: element.nombre + ' ' + element.apellido, type: 'estudiantes' }]);
            });
        });
        // Get Productos Cafetería
        RestClient.GetRequest(AppUrl.Cafeteria).then((result) => {
            setCafeteriaProductos([{ value: 'other', label: 'Varios', price: 0.00 }]);
            result.forEach(element => {
                setCafeteriaProductos(prevState => [...prevState, { value: element.id, label: element.producto, price: element.precio }]);
            });
        });
    }, []);
    
    function renderProductos(key) {
        var header = [];
        if (key === 'header') {
            for (let index = 0; index < productos.length; index++) {
                header.push(<tr key={index} className='bg-accent-2 flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-0'>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Producto</th>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Precio</th>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Cantidad</th>
                </tr>);
            }
            return header;
        }
        var body = [];
        if (key === 'body') {
            for (let index = 0; index < productos.length; index++) {
                body.push(<tr key={index} className='flex flex-col flex-no wrap md:table-row mb-2 md:mb-0'>
                    <td className='border p-1.5 max-h-[54px]'>
                        <Select placeholder='--Seleccionar Producto--'
                            options={[]}
                            isDisabled
                            value={cafeteria_productos.find(cp => cp.value === productos[index].producto_id) || null}
                            menuPortalTarget={document.body}
                            isLoading={cafeteria_productos.length === 0}
                            className='md:p-1'
                            styles={{
                                option: (base, state) => ({
                                    ...base,
                                    backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                }),
                            }}
                        />
                    </td>
                    <td className='border p-1.5 max-h-[54px]'>
                        <p className='text-center'>$ {productos[index].price ?? (cafeteria_productos.find(cp => cp.value === productos[index].producto_id)?.price || '0.00')}</p>
                    </td>
                    <td className='border p-1.5 min-h-[54px]'>
                        <div className="w-full flex justify-center items-center">
                            <div className='w-1/3 relative z-0 border-2 rounded-full border-gray-400 focus-within:border-accent-2 my-1 bg-slate-100'>
                                <input value={productos[index].cantidad || 1} disabled id="numCantidad" type="number" name="cantidad" min={1} step={1} pattern="[0-9]*" className="text-center block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                            </div>
                        </div>
                    </td>
                </tr>);
            }
            return body;
        }
    }

    function renderProductosMobile() {
        var _productos = [];
        for (let index = 0; index < productos.length; index++) {
            _productos.push(
                /* Row */
                <div key={index} className='w-full mb-2'>
                    {/* Producto */}
                    <div className='inline-flex w-full'>
                        <div className="w-1/2">
                            <div className='bg-accent-2 flex items-center rounded-tl-lg mb-2 h-full border-b-2'>
                                <h5 className="p-3 text-left text-white font-bold">Producto</h5>
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-center border-2 border-l-0 rounded-tr-lg bg-gray-100 border-slate-300 -mt-px">
                            <Select placeholder='--Seleccionar Producto--'
                                options={[]}
                                value={cafeteria_productos.find(cp => cp.value === productos[index].producto_id) || null}
                                menuPortalTarget={document.body}
                                className='inline-flex w-full h-full items-center justify-center'
                                isDisabled
                                isLoading={cafeteria_productos.length === 0}
                                styles={{
                                    option: (base, state) => ({
                                        ...base,
                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                    }),
                                    control: (base) => ({
                                        ...base,
                                        height: '90%',
                                        width: '90%',
                                    })
                                }}
                            />
                        </div>
                    </div>
                    {/* Precio */}
                    <div className='inline-flex w-full'>
                        <div className="w-1/2">
                            <div className='bg-accent-2 flex items-center mb-2 h-full border-b-2'>
                                <h5 className="p-3 text-left text-white font-bold">Precio</h5>
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-center border-2 border-l-0 border-t-0 bg-gray-100 border-slate-300">
                            <p className='text-center'>$ {productos[index].price ?? (cafeteria_productos.find(cp => cp.value === productos[index].producto_id)?.price || '0.00')}</p>
                        </div>
                    </div>
                    {/* Cantidad */}
                    <div className='inline-flex w-full'>
                        <div className="w-1/2">
                            <div className='bg-accent-2 flex items-center rounded-bl-lg  mb-2 h-full border-b-2'>
                                <h5 className="p-3 text-left text-white font-bold">Cantidad</h5>
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-center border-2 border-l-0 border-t-0 bg-gray-100 border-slate-300 rounded-br-lg mb-px">
                            <div className='w-1/2 relative z-0 border-2 rounded-full border-gray-400 focus-within:border-accent-2 my-1'>
                                <input value={productos[index].cantidad || 1} disabled id="numCantidad" type="number" name="cantidad" min={1} step={1} pattern="[0-9]*" className="text-center block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return _productos;
    }


    if (usersAccess.find(ua => ua.modulo_name === 'pagos' && ua.permiso_name === 'update') === undefined) {
        return <Navigate to='/access-denied' />
    }
    if (statusResult === 200) {
        return <Navigate to='/pagos' />
    }
    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60 ml-0' : 'sm:ml-20 ml-0'} duration-300 px-2 sm:pt-24 pt-20 pb-6 sm:px-4 md:px-8 bg-gray-100`}>
            <div className='sm:px-0 px-3'>
                {/* Page Title */}
                <div className='flex w-full'>
                    <h1 className='sm:text-xl text-lg font-medium'>Editar Pago</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                    {usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                    <p className='text-gray-400'>Finanzas</p>
                    <span className='text-xs'>/</span>
                    <Link to='/pagos' className='font-semibold hover:underline'>Pagos</Link>
                    <span className='text-xs'>/</span>
                    <p className='text-dark-purple font-semibold'>Editar Pago</p>
                </div>
            </div>
            {/* Main Section */}
            <div className='w-full flex justify-center mt-6'>
                <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                    <div className='sm:w-11/12 w-full sm:my-8'>
                        <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                            <div className='bg-gray-50'>
                                <div className='bg-white border'>
                                    <h1 className='text-accent-1 sm:text-2xl text-xl p-4'>Editar Pago</h1>
                                </div>
                                {!loaded ?
                                    <div className='flex w-full justify-center bg-white'>
                                        <Loading />
                                    </div> :
                                    <form /* onSubmit={this.onFormSubmit} */ className='mt-4'>
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Área</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-2' viewBox="0 0 512 512">
                                                                <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" />
                                                            </svg>
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select disabled id='selectArea' name="area" defaultValue={1} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione Área --</option>
                                                                <option value="1">Cafetería</option>
                                                            </select>
                                                            <label htmlFor="selectArea" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Área</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4 mt-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Pago</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex gap-4">
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                            <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <p id='pOperador' className='block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer'>{operador}</p>
                                                                <label htmlFor="pOperador" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Operación realizada por</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex gap-4">
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                            <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <Select placeholder='--Seleccionar Pagador--'
                                                                    options={[]}
                                                                    menuPortalTarget={document.body}
                                                                    className='p-1'
                                                                    isDisabled
                                                                    value={{ value: pagador_id, label: pagador }}
                                                                    styles={{
                                                                        option: (base, state) => ({
                                                                            ...base,
                                                                            backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                                                        }),
                                                                    }}
                                                                />
                                                                <label htmlFor="selectPagadores" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Pagó</label>
                                                            </div>
                                                        </div>
                                                        {usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === pagador_type) !== undefined &&
                                                            <Link to={`/${pagador_type}/editar/${pagador_id}`} title='Editar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                <PencilAltIcon className='h-5' />
                                                            </Link>}
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.pagador_id}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex gap-4">
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                            <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <Select placeholder='--Seleccionar Estudiante--'
                                                                    options={filteredEstudiantes}
                                                                    menuPortalTarget={document.body}
                                                                    className='p-1'
                                                                    value={{ value: estudiante_id, label: estudiante }}
                                                                    isDisabled
                                                                    styles={{
                                                                        option: (base, state) => ({
                                                                            ...base,
                                                                            backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                                                        }),
                                                                    }}
                                                                />
                                                                <label htmlFor="selectEstudiante" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Para Estudiante</label>
                                                            </div>
                                                        </div>
                                                        {usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'estudiantes') !== undefined &&
                                                            <Link to={`/estudiantes/editar/${estudiante_id}`} title='Editar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                <PencilAltIcon className='h-5' />
                                                            </Link>}
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.estudiante_id}</span>
                                                </div>
                                                <div hidden={area !== 'cafeteria'} className=''>
                                                    <fieldset className={`border ${errors.dias && 'border-red-500'} rounded bg-white p-3`}>
                                                    <legend className='px-1 text-sm text-gray-400 flex justify-between items-center gap-2'>
                                                        <span>Días pagados, Semana: {week.startDate} a {week.endDate}</span>
                                                    </legend>
                                                        <div className='grid lg:grid-cols-3 grid-cols-2'>
                                                            {days.map(day => (
                                                                <div key={day.value} className='px-3 inline-flex gap-3 col-span-1'>
                                                                    <input id={'checkDay' + day.value} disabled defaultChecked={dias.some(d => day.value === parseInt(d))} className='accent-accent-2 peer' type="checkbox" style={{ transform: 'scale(1.5)' }} />
                                                                    <label htmlFor={'checkDay' + day.value} className='text-base peer-disabled:text-gray-400 text-gray-800 text-light font-normal'>
                                                                        {day.label}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </fieldset>
                                                    <span className='text-sm text-red-500'>{errors.dias}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            {(forma_pago === 'efectivo' && <CashIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />) ||
                                                                (forma_pago === 'tarjeta' && <CreditCardIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />) ||
                                                                (forma_pago === 'deposito' && <CurrencyDollarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />)}
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select disabled id='selectFormaPago' name="forma_pago" value={forma_pago} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione Método --</option>
                                                                <option value="efectivo">Efectivo</option>
                                                                <option value="tarjeta">Tarjeta</option>
                                                                <option value="deposito">Depósito</option>
                                                            </select>
                                                            <label htmlFor="selectFormaPago" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Método de Pago</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.forma_pago}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <HashtagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input disabled value={referencia} id='textReferencia' type='text' name="referencia" maxLength={85} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                            <label htmlFor="textReferencia" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Referencia</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.referencia}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="space-y-4 md:space-y-0 w-full p-4 text-gray-800 items-center mb-4">
                                            <h2 className="text-accent-1 font-semibold">Información Productos</h2>
                                            <div className='md:px-5 md:container'>
                                                <table id='tblCondiciones' className='hidden md:inline-table w-full bg-white rounded-lg overflow-hidden md:shadow-md my-5'>
                                                    <thead className='text-white'>
                                                        {renderProductos('header')}
                                                    </thead>
                                                    <tbody className='flex-1 md:flex-none'>
                                                        {renderProductos('body')}
                                                    </tbody>
                                                    <tfoot className='flex-1 justify-end'>
                                                        <tr>
                                                            <th></th>
                                                            <th></th>
                                                            <th className='pr-3'>
                                                                <p className='text-end font-bold text-base'>${subtotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} x Día</p>
                                                                <p className='text-end font-bold text-base'>Total pagado x {dias.length} Día(s): ${total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                            </th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                                {/* Mobile */}
                                                <div className='md:hidden flex-col w-full'>
                                                    {renderProductosMobile()}
                                                    <p className='text-end font-bold text-lg'>${subtotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} x Día</p>
                                                    <p className='text-end font-bold text-lg'>Total pagado x {dias.length} Día(s): ${total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                </div>
                                                <span className='text-sm text-red-500'>{errors.productos}</span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='p-4 flex justify-center gap-4 bg-white'>
                                            {/* <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-4/5 sm:w-1/6 px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" hidden={processingRequest} className={`${!processingRequest && 'inline-flex'} disabled:bg-accent-2/70 gap-x-2 text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Guardar</button> */}
                                            <Link to='/pagos' hidden={processingRequest} className={`${!processingRequest && 'flex'} justify-center items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                        </div>
                                    </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditarPagosPage