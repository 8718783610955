import React from "react";
import styled from "styled-components";
import Loading from "./Loading";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { PlusIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import NoData from '../resources/images/EmptyBox.png';

var style = getComputedStyle(document.body);
export const colorAccent1 = style.getPropertyValue('--accent-1');
export const colorAccent2 = style.getPropertyValue('--accent-2');

export const customStyles = {
    header: {
        style: {
            backgroundColor: '#F3F4F6',
            fontSize: '22px',
            color: colorAccent1,
            minHeight: '56px',
            paddingLeft: '16px',
            paddingRight: '8px',
        },
    },
    subHeader: {
        style: {
            backgroundColor: '#F3F4F6',
            flexWrap: 'inherit',
        }
    },
    headRow: {
        style: {
            border: 'none',
        },
    },
    headCells: {
        style: {
            backgroundColor: colorAccent1,
            color: 'white',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    }
}

export const expandableStyles = {
    subHeader: {
        style: {
            backgroundColor: '#3d6bec'
        }
    },
    headRow: {
        style: {
            border: 'none',
        },
    },
    headCells: {
        style: {
            backgroundColor: '#123CB8',
            color: 'white',
            fontSize: '14px',
        },
    },
    rows: {
        style: {
            backgroundColor: '#3d6bec',
            color: 'white',
        },
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
            color: 'white',
            backgroundColor: '#123CB8',
        },
    },
    pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '32px',
        margin: 'px',
        cursor: 'pointer',
        transition: '0.4s',
        color: 'white',
        fill: 'white',
        backgroundColor: 'transparent',
        '&:disabled': {
            cursor: 'unset',
            color: 'white',
            fill: 'white',
        }
    },
};

const TextField = styled.input`
	height: 32px;
	width: 100%;
    @media (min-width: 640px) {
        width: 200px;
    }
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;
    :focus {
        outline: none;
        box-shadow: 0 0 0 2px ${colorAccent1};
    }
`;

const Button = styled.button`
    background-color: ${colorAccent1};
    border: none;
    color: white;
    padding: 8px 32px 8px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 3px;
    &:hover {
        cursor: pointer;
        background-color: ${colorAccent2};
    }
`;

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`;


export const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Buscar"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);

export const CustomLoader = () => (
    <Loading />
);

export const NoDataComponent = () => (
    <div className="py-5">
        <img src={NoData} className="md:w-64 md:h-64 w-48 h-48" alt="No data" />
        <p className="text-center font-medium text-gray-300">No hay registros para mostrar</p>
    </div>
);

export const AddButton = ({ route = '', onClick = () => { }, disabled = false, label = 'Agregar', tooltipContent = 'No cuentas con este permiso en este módulo', icon = null }) => {
    let _icon = icon || <PlusIcon className='h-5 w-5 text-white' />;

    if (disabled) {
        return (
            <>
                <ReactTooltip className='tooltip-default' anchorId='btnAddButton' content={tooltipContent} place="top" positionStrategy='fixed' />
                <button type='button' id={'btnAddButton'} className="p-2 bg-accent-2/60 text-gray-400 flex gap-1 items-center rounded-full shadow-lg cursor-default">
                    {_icon}
                    <div className='mx-1 flex items-center'>
                        <span className='font-semibold text-sm text-white'>{label}</span>
                    </div>
                </button>
            </>
        );
    }
    if (route) {
        return (
            <Link to={route}
                id='btnAddButton' className='p-2 bg-accent-1 flex gap-1 items-center rounded-full shadow-lg hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                {_icon}
                <div className='mx-1 flex items-center'>
                    <span className='font-semibold text-sm text-white'>{label}</span>
                </div>
            </Link>
        );
    }
    return (
        <button type='button' onClick={onClick}
            id='btnAddButton' className='p-2 bg-accent-1 flex gap-1 items-center rounded-full shadow-lg hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
            {_icon}
            <div className='mx-1 flex items-center'>
                <span className='font-semibold text-sm text-white'>{label}</span>
            </div>
        </button>
    );
}

export const EditButton = ({ itemId, route = '', onClick = () => { }, disabled = false, label = 'Editar' }) => {
    if (disabled) {
        return (
            <button type='button' disabled
                id={'btnEditButton' + itemId} className="disabled:text-gray-400 disabled:hover:bg-gray-200 flex w-full justify-between px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-accent-2 hover:text-white" role="menuitem">
                {label}
            </button>
        );
    }
    if (route) {
        return (
            <Link to={route}
                id={'btnEditButton' + itemId} className="flex w-full justify-between px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-accent-2 hover:text-white" role="menuitem">
                {label}
            </Link>
        );
    }
    return (
        <button type='button' disabled={disabled} onClick={() => onClick(itemId)}
            id={'btnEditButton' + itemId} className="disabled:text-gray-400 disabled:hover:bg-gray-200 flex w-full justify-between px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-accent-2 hover:text-white" role="menuitem">
            Editar
        </button>
    );

}

export const DeleteButton = ({ itemId, disabled = false, label = 'Eliminar', onClickDelete = () => { } }) => {
    return (
        <button type='button' onClick={() => onClickDelete(itemId)} disabled={disabled}
            id={'btnDeleteButton' + itemId} className="disabled:text-gray-400 disabled:hover:bg-gray-200 flex w-full justify-between px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-accent-2 hover:text-white" role="menuitem">
            {label}
        </button>
    );
}