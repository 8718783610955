import { XIcon } from '@heroicons/react/solid'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import ReactModal from 'react-modal'

const CustomModal = forwardRef(
    ({
        id = '',
        title = 'Alerta',
        body = 'Alerta',
        onConfirm = null,
        onCancel = null,
        onClose = null,
        onOpen = null,
        onRequestCanceled = null,
        shouldCloseOnOverlayClick = true,
        shouldCloseOnEsc = true,
        preventScroll = false,
        confirmLabel = 'Confirmar',
        cancelLabel = 'Cancelar',
        confirmDisabled = false,
        confirmStyle = 'primary' | 'danger' | 'success',
    }, ref) => {

        const [_title, setTitle] = useState(title);
        const [_body, setBody] = useState(body);
        const [_onConfirm, setOnConfirm] = useState(() => onConfirm);
        const [_onCancel, setOnCancel] = useState(() => onCancel);
        const [_onClose, setOnClose] = useState(() => onClose);
        const [_onOpen, setOnOpen] = useState(() => onOpen);
        const [_onRequestCanceled, setOnRequestCanceled] = useState(() => onRequestCanceled);
        const [_shouldCloseOnOverlayClick, setShouldCloseOnOverlayClick] = useState(shouldCloseOnOverlayClick);
        const [_shouldCloseOnEsc, setShouldCloseOnEsc] = useState(shouldCloseOnEsc);
        const [_preventScroll, setPreventScroll] = useState(preventScroll);
        const [_confirmLabel, setConfirmLabel] = useState(confirmLabel);
        const [_cancelLabel, setCancelLabel] = useState(cancelLabel);
        const [_confirmDisabled, setConfirmDisabled] = useState(confirmDisabled);
        const [_confirmStyle, setConfirmStyle] = useState(confirmStyle);

        const [isOpen, setIsOpen] = useState(false);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            if (!ref) setIsOpen(true);
        }, [ref]);
        // Prevent scroll when Modal is open if preventScroll is true
        useEffect(() => {
            let html = document.querySelector('html');
            if (_preventScroll && isOpen) {
                html.style.overflowY = 'hidden';
            }

            return () => {
                html.style.overflowY = 'unset';
            }
        }, [_preventScroll, isOpen]);


        const show = useCallback(() => {
            setIsOpen(true);
        }, []);

        const close = useCallback(() => {
            setIsOpen(false);
        }, []);

        const update = useCallback((modalProps) => {
            const {
                title,
                body,
                onConfirm,
                onCancel,
                onClose,
                onOpen,
                onRequestCanceled,
                shouldCloseOnOverlayClick,
                shouldCloseOnEsc,
                preventScroll,
                confirmLabel,
                cancelLabel,
                confirmDisabled,
                confirmStyle,
            } = modalProps

            setTitle((prevState) => title ?? prevState);
            setBody((prevState) => body ?? prevState);
            setOnConfirm((prevState) => onConfirm ?? prevState);
            setOnCancel((prevState) => onCancel ?? prevState);
            setOnClose((prevState) => onClose ?? prevState);
            setOnOpen((prevState) => onOpen ?? prevState);
            setOnRequestCanceled((prevState) => onRequestCanceled ?? prevState);
            setShouldCloseOnOverlayClick((prevState) => shouldCloseOnOverlayClick ?? prevState);
            setShouldCloseOnEsc((prevState) => shouldCloseOnEsc ?? prevState);
            setPreventScroll((prevState) => preventScroll ?? prevState);
            setConfirmLabel((prevState) => confirmLabel ?? prevState);
            setCancelLabel((prevState) => cancelLabel ?? prevState);
            setConfirmDisabled((prevState) => confirmDisabled ?? prevState);
            setConfirmStyle((prevState) => confirmStyle ?? prevState);
        }, []);

        function _onRequestClose(wasCanceled = true) {
            if (wasCanceled) {
                _onRequestCanceled?.();
            }
            setIsOpen(false);
        }

        function _onAfterOpen() {
            _onOpen?.(ref)
        }

        function _onAfterClose() {
            _onClose?.(id);
        }

        function __onConfirm() {
            if (typeof _onConfirm === 'function') {
                _onConfirm();
                _onRequestClose(false);
            } else if (typeof _onConfirm === 'object') {
                setLoading(true);
                const promise = _onConfirm.requestPromise;
                const requestComplete = _onConfirm.requestComplete;
                promise.then((response) => {
                    requestComplete?.(response);

                    setLoading(false);
                    _onRequestClose(false);
                });
            }
        }

        function __onCancel() {
            _onCancel?.();
            _onRequestClose();
        }

        useImperativeHandle(ref, () => ({
            id,
            show,
            close,
            update,
        }), [id, show, close, update]);


        return (
            <ReactModal
                closeTimeoutMS={800}
                isOpen={isOpen}
                contentLabel="Default Confirmation Modal"
                className='Modal'
                overlayClassName="Overlay"
                shouldCloseOnOverlayClick={_shouldCloseOnOverlayClick}
                shouldCloseOnEsc={_shouldCloseOnEsc}
                onRequestClose={_onRequestClose}
                onAfterOpen={_onAfterOpen}
                onAfterClose={_onAfterClose}
            >
                <div className="flex flex-col items-start">
                    <div className="flex items-center w-full border-b-2">
                        <div className="text-gray-900 font-medium text-xl">{_title}</div>
                        <button onClick={_onRequestClose} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                            <XIcon className='w-5 h-5' />
                        </button>
                    </div>
                    {typeof _body === 'string' ?
                        <div className="border-b-2 w-full py-4">
                            <p>{_body}</p>
                        </div> :
                        <div className="border-b-2 w-full py-4">{_body}</div>
                    }
                    <div className="flex justify-end w-full mt-4 gap-4">
                        <button hidden={!loading} type='button' disabled className='text-white bg-red-500 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </button>
                        <button onClick={__onConfirm} type='button' disabled={_confirmDisabled} hidden={loading}
                            className={`${_confirmStyle === 'success' ? 'disabled:bg-emerald-500/70 bg-emerald-500 hover:bg-emerald-600' : _confirmStyle === 'danger' ? 'disabled:bg-red-500/70 bg-red-500 hover:bg-red-600' : 'disabled:bg-accent-2/70 bg-accent-2 hover:bg-accent-1'} text-white font-bold py-2 px-4 rounded`}
                        >
                            {_confirmLabel}
                        </button>
                        <button onClick={__onCancel} type='button' hidden={loading} className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                            {_cancelLabel}
                        </button>
                    </div>
                </div>
            </ReactModal>
        )
    })

export default CustomModal;