import { ChevronDownIcon } from '@heroicons/react/solid';
import React, { Component } from 'react';
import { Collapse } from 'react-collapse';
import { Link, Navigate } from 'react-router-dom';
import InscripcionesTable from '../../components/inscripciones-colegiaturas/InscripcionesTable';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import ColegiaturaRegistrosTable from '../../components/inscripciones-colegiaturas/ColegiaturaRegistrosTable';
var CryptoJS = require("crypto-js");

class InscripcionesColegiaturasPage extends Component {

    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        var _moduloAccess = [];
        if (_usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'inscripciones') !== undefined) {
            _moduloAccess.push('inscripciones');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'colegiaturas') !== undefined) {
            _moduloAccess.push('colegiaturas');
        }
        var _inscripcionesPermissions = [];
        if (_usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'inscripciones') !== undefined) {
            _inscripcionesPermissions.push('create');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'inscripciones') !== undefined) {
            _inscripcionesPermissions.push('update');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'delete' && ua.modulo_name === 'inscripciones') !== undefined) {
            _inscripcionesPermissions.push('delete');
        }
        var _colegiaturasPermissions = [];
        if (_usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'colegiaturas') !== undefined) {
            _colegiaturasPermissions.push('create');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'colegiaturas') !== undefined) {
            _colegiaturasPermissions.push('update');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'delete' && ua.modulo_name === 'colegiaturas') !== undefined) {
            _colegiaturasPermissions.push('delete');
        }
        this.state = {
            usersAccess: _usersAccess,
            moduloAccess: _moduloAccess,
            inscripcionesPermissions: _inscripcionesPermissions,
            colegiaturasPermissions: _colegiaturasPermissions,
            dataInscripciones: null, dataColegiaturas: null, filter: _moduloAccess[0], collapsed: window.innerWidth > 640, hasUpdated: false
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.Inscripciones).then((result) => {
            this.setState({ dataInscripciones: result });
        });
        RestClient.GetRequest(AppUrl.ColegiaturaRegistros).then((result) => {
            this.setState({ dataColegiaturas: result });
        });
    }

    componentDidUpdate(_prevProps, prevState) {
        if (prevState.hasUpdated !== this.state.hasUpdated) {
            RestClient.GetRequest(AppUrl.Inscripciones).then((result) => {
                this.setState({ dataInscripciones: result });
            });
            RestClient.GetRequest(AppUrl.ColegiaturaRegistros).then((result) => {
                this.setState({ dataColegiaturas: result });
            });
        }
    }

    handleUpdate = () => {
        this.setState({ hasUpdated: !this.state.hasUpdated });
    }

    render() {
        if (!this.state.moduloAccess.includes('inscripciones') && !this.state.moduloAccess.includes('colegiaturas')) {
            return <Navigate to='/access-denied' />
        }
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60' : 'sm:ml-20 ml-8'} duration-300 p-12 sm:pt-24 pt-20 sm:pr-16 pr-0 bg-gray-100`}>
                {/* Page Title */}
                <div className='sm:px-0 px-3'>
                    <div className='flex w-full'>
                        <h1 className='sm:text-xl text-lg font-medium'>Inscripciones y Colegiaturas</h1>
                    </div>
                    {/* Breadcrum Nav */}
                    <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                        {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                            <>
                                <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                                <span className='text-xs'>/</span>
                            </>}
                        <p className='text-gray-400'>Finanzas</p>
                        <span className='text-xs'>/</span>
                        <Link to='/inscripciones-colegiaturas' className='text-dark-purple font-semibold hover:underline'>Inscripciones y Colegiaturas</Link>
                    </div>
                </div>
                {/* Data Table And Filter */}
                <div className='w-full flex justify-center sm:mt-6 mt-3'>
                    <div className='w-full flex-col justify-center bg-white rounded shadow-lg'>
                        <div hidden={this.state.moduloAccess.length < 2} className='mx-12 mt-8 mb-8 bg-gray-100 rounded shadow-lg'>
                            <fieldset className="flex flex-col lg:flex-row w-full lg:items-center sm:p-5 py-2.5 px-5 sm:space-y-3 lg:space-y-0">
                                <div onClick={() => window.innerWidth <= 640 && this.setState({ collapsed: !this.state.collapsed })} className="xl:w-2/5 md:w-1/4 w-full h-full sm:flex-none inline-flex items-center justify-between">
                                    <h1 className="sm:text-base text-lg font-semibold">Filtrar por:</h1>
                                    <ChevronDownIcon className={`sm:hidden w-7 h-7 text-slate-500 ${this.state.collapsed && 'rotate-180'} duration-300`} />
                                </div>
                                <Collapse isOpened={this.state.collapsed}>
                                    <div className='flex flex-col md:flex-row w-full justify-start md:gap-8 gap-3 sm:mt-0 mt-3'>
                                        <div className="flex items-center gap-2">
                                            <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioInscripiones" value="inscripciones" defaultChecked />
                                            <label className="font-semibold text-gray-600" htmlFor="radioInscripiones">
                                                Inscripciones
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioColegiaturas" value="colegiaturas" />
                                            <label className="font-semibold text-gray-600" htmlFor="radioColegiaturas">
                                                Colegiaturas
                                            </label>
                                        </div>
                                    </div>
                                </Collapse>
                            </fieldset>
                        </div>
                        <div className='sm:mx-12 mx-0 sm:my-8 my-2'>
                            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                {this.state.filter === 'inscripciones' ?
                                    <InscripcionesTable 
                                        data={this.state.dataInscripciones} 
                                        controls={this.state.inscripcionesPermissions}
                                        expandable={this.state.usersAccess.find(ua => ua.modulo_name === 'estudiantes' && ua.permiso_name === 'update') !== undefined}
                                        hasUpdated={this.handleUpdate} 
                                    /> :
                                    <ColegiaturaRegistrosTable 
                                        data={this.state.dataColegiaturas} 
                                        controls={this.state.colegiaturasPermissions}
                                        hasUpdated={this.handleUpdate} 
                                    />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InscripcionesColegiaturasPage;