import { DocumentTextIcon } from '@heroicons/react/outline';
import React, { useState, useEffect, useCallback } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import Select from 'react-select';
import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import EscalacionesComponent from '../../components/sla/EscalacionesComponent';
import { toast } from 'react-toastify';
var CryptoJS = require("crypto-js");

// Create a constructor for functional component to get permissions
const useConstructor = (callBack = () => { }) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return; 
    callBack();
    setHasBeenCalled(true);
}

//This has to be a functional component to use 'useParams()' from react-router-dom
const EditarSLAPage = (props) => {

    const [nombre, setNombre] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [condiciones, setCondiciones] = useState([]);
    const [apply, setApply] = useState(true);
    const [valorHeader, setValorHeader] = useState(false);
    const [tiempo_respuesta, setTiempoRespuesta] = useState({ dias: 0, horas: 0, minutos: 0 });
    const [tiempo_resolucion, setTiempoResolucion] = useState({ dias: 0, horas: 0, minutos: 0 });
    const [respEsc1, setRespEsc1] = useState(false);
    const [resolEsc1, setResolEsc1] = useState(false);
    const [resolEsc2, setResolEsc2] = useState(false);
    const [resolEsc3, setResolEsc3] = useState(false);
    const [resolEsc4, setResolEsc4] = useState(false);
    const [activo, setActivo] = useState(true);
    const [escalaciones, setEscalaciones] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [subcategorias, setSubcategorias] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [estatus, setEstatus] = useState([]);
    const [prioridades, setPrioridades] = useState([]);
    const [statusResult, setStatusResult] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [condicionesLoaded, setCondicionesLoaded] = useState(false);
    const [fetchedCondicionesCount, setFetchedCondicionesCount] = useState(-1);
    const [processingRequest, setProcessingRequest] = useState(false);
    const [errors, setErrors] = useState([{ 
        // SLA Errors
        nombre: '', descripcion: '', condiciones: '', respuesta_dias: '', respuesta_horas: '', respuesta_minutos: '', 
        resolucion_dias: '', resolucion_horas: '', resolucion_minutos: '', 
        // Escalacion Errors
        respuesta_esc1_dias: '', respuesta_esc1_horas: '', respuesta_esc1_minutos: '', respuesta_esc1_usuarios: '',
        resolucion_esc1_dias: '', resolucion_esc1_horas: '', resolucion_esc1_minutos: '', resolucion_esc1_usuarios: '',
        resolucion_esc2_dias: '', resolucion_esc2_horas: '', resolucion_esc2_minutos: '', resolucion_esc2_usuarios: '', 
        resolucion_esc3_dias: '', resolucion_esc3_horas: '', resolucion_esc3_minutos: '', resolucion_esc3_usuarios: '', 
        resolucion_esc4_dias: '', resolucion_esc4_horas: '', resolucion_esc4_minutos: '', resolucion_esc4_usuarios: ''}]);
    const [usersAccess, setUsersAccess] = useState([{}]);

    //Get the passed :id from parent and pass it to the component to render it
    const sla = useParams();

    /// Decript permissions
    useConstructor(() => {
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUsersAccess(_usersAccess);
    });

    const handleValorSelect = useCallback((condicion) => {
        switch (condicion) {
            case 'categorias':
                if (categorias.length === 0) {
                    RestClient.GetRequest(AppUrl.Categorias).then((result) => {
                        setCategorias([]);
                        result.forEach(element => {
                            setCategorias(prevState => ([...prevState, { value: element.id, label: element.nombre }]));
                        });
                        if (!condicionesLoaded) {
                            setFetchedCondicionesCount(prevState => prevState + 1);
                        }
                    });
                }
                break;
            case 'subcategorias':
                if (subcategorias.length === 0) {
                    RestClient.GetRequest(AppUrl.Subcategorias).then((result) => {
                        setSubcategorias([]);
                        result.forEach(element => {
                            setSubcategorias(prevState => ([...prevState, { value: element.id, label: element.nombre }]));
                        });
                        if (!condicionesLoaded) {
                            setFetchedCondicionesCount(prevState => prevState + 1);
                        }
                    });
                }
                break;
            case 'departamentos':
                if (departamentos.length === 0) {
                    RestClient.GetRequest(AppUrl.Departamentos).then((result) => {
                        setDepartamentos([]);
                        result.forEach(element => {
                            setDepartamentos(prevState => ([...prevState, { value: element.id, label: element.nombre }]));
                        });
                        if (!condicionesLoaded) {
                            setFetchedCondicionesCount(prevState => prevState + 1);
                        }
                    });
                }
                break;
            case 'estatus':
                if (estatus.length === 0) {
                    RestClient.GetRequest(AppUrl.Estatus).then((result) => {
                        var options = [];
                        var groupedOptions = [];
                        result.forEach((element, index) => {
                            var group = element.tipo;
                            if ((index + 1) !== result.length && group === result[index + 1].tipo) {
                                options.push({ value: element.id, label: element.nombre, color: element.color });
                            }
                            else {
                                options.push({ value: element.id, label: element.nombre, color: element.color });
                                groupedOptions.push({ label: 'Tipo: ' + group, options: options });
                                options = [];
                            }
                        });
                        setEstatus(groupedOptions);
                        if (!condicionesLoaded) {
                            setFetchedCondicionesCount(prevState => prevState + 1);
                        }
                    });
                }
                break;
            case 'prioridades':
                if (prioridades.length === 0) {
                    RestClient.GetRequest(AppUrl.Prioridades).then((result) => {
                        setPrioridades([])
                        result.forEach(element => {
                            setPrioridades(prevState => ([...prevState, { value: element.id, label: element.nombre }]));
                        });
                        if (!condicionesLoaded) {
                            setFetchedCondicionesCount(prevState => prevState + 1);
                        }
                    });
                }
                break;
            default:
                break;
        }
    }, [categorias.length, condicionesLoaded, departamentos.length, estatus.length, prioridades.length, subcategorias.length])

    // Get SLA by Id
    useEffect(() => {
        if (!loaded) {
            RestClient.GetRequest(AppUrl.SLAById + sla.id).then((result) => {
                setNombre(result.nombre);
                setDescripcion(result.descripcion === null ? '' : result.descripcion);
                if (result.condiciones.length > 0) {
                    result.condiciones.forEach(element => {
                        handleValorSelect(element.condicion);
                        setCondiciones(prevState => ([...prevState, { id: element.id, condicion: element.condicion, operador: element.operador, valor: element.valor }]));
                    });
                    setFetchedCondicionesCount(0);
                }
                else {
                    setCondiciones(prevState => ([...prevState, { condicion: '', operador: '', valor: '' }]));
                }
                setApply(result.condiciones.length > 0);
                setCondicionesLoaded(result.condiciones.length === 0);
                setValorHeader(result.condiciones.some(c => c.operador === 'equals' || c.operador === 'not equals'));
                setTiempoRespuesta({ dias: parseInt(result.tiempo_respuesta.dias), horas: parseInt(result.tiempo_respuesta.horas), minutos: parseInt(result.tiempo_respuesta.minutos) });
                setTiempoResolucion({ dias: parseInt(result.tiempo_resolucion.dias), horas: parseInt(result.tiempo_resolucion.horas), minutos: parseInt(result.tiempo_resolucion.minutos) });
                setRespEsc1(result.respuesta_esc1 === 1);
                setResolEsc1(result.resolucion_esc1 === 1);
                setResolEsc2(result.resolucion_esc2 === 1);
                setResolEsc3(result.resolucion_esc3 === 1);
                setResolEsc4(result.resolucion_esc4 === 1);
                setActivo(result.activo === 1);
                setEscalaciones(result.escalaciones);
                setLoaded(true);
            });
        }
    }, [sla.id, loaded, handleValorSelect]);

    useEffect(() => {
        if (fetchedCondicionesCount === condiciones.length && !condicionesLoaded) {
            const newConditions = condiciones.map((condicion) => {
                switch (condicion.condicion) {
                    case 'categorias':
                        return {...condicion, valor: categorias.filter(c => condicion.valor.some(v => parseInt(v) === c.value))};
                    case 'subcategorias':
                        return {...condicion, valor: subcategorias.filter(c => condicion.valor.some(v => parseInt(v) === c.value))};
                    case 'departamentos':
                        return {...condicion, valor: departamentos.filter(c => condicion.valor.some(v => parseInt(v) === c.value))};
                    case 'estatus':
                        return {...condicion, valor: estatus.map(e => e.options).flat().filter(c => condicion.valor.some(v => parseInt(v) === c.value))};
                    case 'prioridades':
                        return {...condicion, valor: prioridades.filter(c => condicion.valor.some(v => parseInt(v) === c.value))};
                    default:
                        return condicion;
                }
            });
            setCondiciones(newConditions);
            setCondicionesLoaded(true);
        }
        
    }, [fetchedCondicionesCount, condicionesLoaded, condiciones, departamentos, estatus, prioridades, subcategorias, categorias])
    

    function renderCondiciones(key) {
        var header = [];
        if (key === 'header') {
            for (let index = 0; index < condiciones.length; index++) {
                header.push(<tr key={index} className='bg-accent-2 flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-0'>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Condición</th>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Operador</th>
                    <th className={`border-b-2 md:border-none p-3.5 text-left md:max-h-[54px] h-full`} hidden={!valorHeader}>Valor</th>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Agregar/Remover</th>
                </tr>);
            }
            return header;
        }
        var body = [];
        if (key === 'body') {
            for (let index = 0; index < condiciones.length; index++) {
                body.push(<tr key={index} className='flex flex-col flex-no wrap md:table-row mb-2 md:mb-0'>
                    <td className='border p-1.5 max-h-[54px]'>
                        <select name="selectCondicion" onChange={(e) => handleCondiciones(index, 'condicion', e.target.value)} id="selectCondicion" value={condiciones[index].condicion} aria-label="Select Condition" className='w-full border p-2 bg-slate-100'>
                            <option value="" disabled>-- Selecciona Condición --</option>
                            <option value="categorias">Categoría</option>
                            <option value="subcategorias">Subcategoría</option>
                            <option value="departamentos">Departamento</option>
                            <option value="estatus">Estatus</option>
                            <option value="prioridades">Prioridad</option>
                        </select>
                    </td>
                    <td className='border p-1.5 max-h-[54px]'>
                        <select id="selectOperator" onChange={(e) => handleCondiciones(index, 'operador', e.target.value)} name="selectOperator" disabled={condiciones[index].condicion === ''} value={condiciones[index].operador} aria-label="Select Operator" className='disabled:bg-slate-200 w-full border p-2 bg-slate-100'>
                            <option value="" disabled>-- Selecciona Operador --</option>
                            <option value="equals">es</option>
                            <option value="not equals">no es</option>
                            <option value="empty">esta vacío</option>
                            <option value="not empty">no está vacío</option>
                        </select>
                    </td>
                    <td id={'tdValor' + index} className='border p-1.5 min-h-[54px] md:max-w-md' hidden={!valorHeader}>
                        {condiciones[index].operador !== '' && condiciones[index].operador !== 'empty' && condiciones[index].operador !== 'not empty' ?
                            <Select placeholder='--Seleccionar Valor(es)--'
                                isMulti
                                options={
                                    (condiciones[index].condicion === 'categorias' && categorias) ||
                                    (condiciones[index].condicion === 'subcategorias' && subcategorias) ||
                                    (condiciones[index].condicion === 'departamentos' && departamentos) ||
                                    (condiciones[index].condicion === 'estatus' && estatus) ||
                                    (condiciones[index].condicion === 'prioridades' && prioridades)
                                }
                                value={condiciones[index].valor}
                                menuPortalTarget={document.body}
                                className='md:p-1'
                                onChange={(valor) => handleCondiciones(index, 'valor', valor)}
                                isLoading={
                                    (condiciones[index].condicion === 'categorias' && categorias.length === 0) ||
                                    (condiciones[index].condicion === 'subcategorias' && subcategorias.length === 0) ||
                                    (condiciones[index].condicion === 'departamentos' && departamentos.length === 0) ||
                                    (condiciones[index].condicion === 'estatus' && estatus.length === 0) ||
                                    (condiciones[index].condicion === 'prioridades' && prioridades.length === 0)
                                }
                                styles={{
                                    option: (base, state) => ({
                                        ...base,
                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                    }),
                                }}
                            /> : <div className='p-2 text-transparent'>empty</div>}
                    </td>
                    <td className='border p-2 max-h-[54px]'>
                        <div className='flex w-full justify-center gap-4'>
                            <button type='button' onClick={() => setCondiciones(prevState => ([...prevState, { condicion: '', operador: '', valor: '' }]))} className='bg-accent-2 p-2 rounded hover:bg-blue-600'>
                                <PlusIcon className='text-white h-5' />
                            </button>
                            <button type='button' disabled={condiciones.length === 1} onClick={() => {setCondiciones(prevState => (prevState.filter((condicion, _index) => { return _index !== index }))); setValorHeader(condiciones.filter((condicion, _index) => { return _index !== index }).some(c => c.operador === 'equals' || c.operador === 'not equals'))}} className='disabled:opacity-70 disabled:hover:bg-accent-2 bg-accent-2 p-2 rounded hover:bg-blue-600'>
                                <MinusIcon className='text-white h-5' />
                            </button>
                        </div>
                    </td>
                </tr>);
            }
            return body;
        }
    }

    function handleCondiciones(index, type, value) {
        const newConditions = condiciones.map((condicion, _index) => {
            if (_index === index) {
                if (type === 'condicion') {
                    return { ...condicion, [type]: value, valor: '' };
                }
                return { ...condicion, [type]: value };
            }
            else return condicion;
        });
        setCondiciones(newConditions);
        setValorHeader(newConditions.some(c => c.operador === 'equals' || c.operador === 'not equals'));
        if (newConditions.some(c => c.operador === 'equals' || c.operador === 'not equals')) {
            handleValorSelect(newConditions[index].condicion);
        }
    }

    function renderSelect(value) {
        var values = [];
        for (let index = 0; index < value; index++) {
            values.push(<option key={index} value={index}>{index}</option>);
        }
        return values;
    }

    function updateEscalaciones(valueToUpdate, tipo, nivel, newValue) {
        let exists = false;
        const newEscalaciones = escalaciones.map((esc, _index) => {
            switch (valueToUpdate) {
                case 'tiempo':
                    if (esc.tipo === tipo && esc.nivel === nivel) {
                        exists = true;
                        return { ...esc, dias: newValue.dias, horas: newValue.horas, minutos: newValue.minutos };
                    }
                    return esc;
                case 'usuarios':
                    if (esc.tipo === tipo && esc.nivel === nivel) {
                        exists = true;
                        return { ...esc, usuarios: newValue };
                    }
                    return esc;
                case 'notificaciones_correo':
                    if (esc.tipo === tipo && esc.nivel === nivel) {
                        exists = true;
                        return { ...esc, notificaciones_correo: newValue };
                    }
                    return esc;
                default:
                    return esc;
            }
        });
        if (!exists) {
            newEscalaciones.push({tipo: tipo, nivel: nivel, dias: newValue?.dias || 0, horas: newValue?.horas || 0, minutos: newValue?.minutos || 0, 
                usuarios: valueToUpdate === 'usuarios' ? newValue : [], notificaciones_correo: valueToUpdate === 'notificaciones_correo' ? newValue : null})
        }
        setEscalaciones(newEscalaciones);
    }

    function onFormSubmit(e) {
        e.preventDefault();
        //Disable controls while request is processed and clear error messages
        setProcessingRequest(true);
        setErrors([{ 
            // SLA Errors
            nombre: '', descripcion: '', condiciones: '', respuesta_dias: '', respuesta_horas: '', respuesta_minutos: '', 
            resolucion_dias: '', resolucion_horas: '', resolucion_minutos: '', 
            // Escalacion Errors
            respuesta_esc1_dias: '', respuesta_esc1_horas: '', respuesta_esc1_minutos: '', respuesta_esc1_usuarios: '',
            resolucion_esc1_dias: '', resolucion_esc1_horas: '', resolucion_esc1_minutos: '', resolucion_esc1_usuarios: '',
            resolucion_esc2_dias: '', resolucion_esc2_horas: '', resolucion_esc2_minutos: '', resolucion_esc2_usuarios: '', 
            resolucion_esc3_dias: '', resolucion_esc3_horas: '', resolucion_esc3_minutos: '', resolucion_esc3_usuarios: '', 
            resolucion_esc4_dias: '', resolucion_esc4_horas: '', resolucion_esc4_minutos: '', resolucion_esc4_usuarios: ''}]
        );

        const data = new FormData();
        data.append('nombre', nombre);
        data.append('descripcion', descripcion);
        data.append('apply', apply);
        data.append('condiciones', JSON.stringify(condiciones));
        data.append('respuesta_dias', tiempo_respuesta.dias);
        data.append('respuesta_horas', tiempo_respuesta.horas);
        data.append('respuesta_minutos', tiempo_respuesta.minutos);
        data.append('resolucion_dias', tiempo_resolucion.dias);
        data.append('resolucion_horas', tiempo_resolucion.horas);
        data.append('resolucion_minutos', tiempo_resolucion.minutos);
        data.append('respuesta_esc1', respEsc1);
        data.append('resolucion_esc1', resolEsc1);
        data.append('resolucion_esc2', resolEsc2);
        data.append('resolucion_esc3', resolEsc3);
        data.append('resolucion_esc4', resolEsc4);
        data.append('activo', activo);
        if (respEsc1) {
            data.append('respuesta_esc1_dias', escalaciones.find(e => e.tipo === 'respuesta' && e.nivel === '1').dias);
            data.append('respuesta_esc1_horas', escalaciones.find(e => e.tipo === 'respuesta' && e.nivel === '1').horas);
            data.append('respuesta_esc1_minutos', escalaciones.find(e => e.tipo === 'respuesta' && e.nivel === '1').minutos);
            data.append('respuesta_esc1_usuarios', escalaciones.find(e => e.tipo === 'respuesta' && e.nivel === '1').usuarios);
            data.append('respuesta_esc1_ncs', escalaciones.find(e => e.tipo === 'respuesta' && e.nivel === '1').notificaciones_correo);
        }
        if (resolEsc1) {
            data.append('resolucion_esc1_dias', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '1').dias);
            data.append('resolucion_esc1_horas', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '1').horas);
            data.append('resolucion_esc1_minutos', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '1').minutos);
            data.append('resolucion_esc1_usuarios', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '1').usuarios);
            data.append('resolucion_esc1_ncs', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '1').notificaciones_correo);
        }
        if (resolEsc2) {
            data.append('resolucion_esc2_dias', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '2').dias);
            data.append('resolucion_esc2_horas', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '2').horas);
            data.append('resolucion_esc2_minutos', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '2').minutos);
            data.append('resolucion_esc2_usuarios', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '2').usuarios);
            data.append('resolucion_esc2_ncs', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '2').notificaciones_correo);
        }
        if (resolEsc3) {
            data.append('resolucion_esc3_dias', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '3').dias);
            data.append('resolucion_esc3_horas', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '3').horas);
            data.append('resolucion_esc3_minutos', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '3').minutos);
            data.append('resolucion_esc3_usuarios', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '3').usuarios);
            data.append('resolucion_esc3_ncs', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '3').notificaciones_correo);
        }
        if (resolEsc4) {
            data.append('resolucion_esc4_dias', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '4').dias);
            data.append('resolucion_esc4_horas', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '4').horas);
            data.append('resolucion_esc4_minutos', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '4').minutos);
            data.append('resolucion_esc4_usuarios', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '4').usuarios);
            data.append('resolucion_esc4_ncs', escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '4').notificaciones_correo);
        }

        RestClient.PostRequest(AppUrl.UpdateSLA + sla.id, data).then((result) => {
            console.log(result);
            setStatusResult(result.status);
            setProcessingRequest(false);
            setErrors(result.data);
            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });

    }

    if (usersAccess.find(ua => ua.modulo_name === 'SLA' && ua.permiso_name === 'update') === undefined) {
        return <Navigate to='/access-denied' />
    }
    if (statusResult === 200) {
        return <Navigate to='/slas' />
    }
    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'ml-80' : 'ml-20'} duration-300 p-12 pt-24 pr-16 bg-gray-100`}>
            {/* Page Title */}
            <div className='flex w-full'>
                <h1 className='text-xl font-medium'>Editar Acuerdo De Nivel De Servicio</h1>
            </div>
            {/* Breadcrum Nav */}
            <div className='flex w-full text-sm text-gray-500 mt-2 space-x-1'>
                {usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                    <>
                        <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                        <span className='text-xs'>/</span>
                    </>}
                <p className='text-gray-400'>Automatización</p>
                <span className='text-xs'>/</span>
                <Link to='/slas' className='font-semibold hover:underline'>SLAs</Link>
                <span className='text-xs'>/</span>
                <p className='text-dark-purple font-semibold'>Editar SLA</p>
            </div>
            {/* Main Section */}
            <div className='w-full flex justify-center mt-6'>
                <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                    <div className='w-11/12 mt-8 mb-8'>
                        <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                            <div className='bg-gray-50'>
                                <div className='bg-white border'>
                                    <h1 className='text-accent-1 text-2xl p-4'>Editar SLA</h1>
                                </div>
                                {!loaded ? <div className='flex w-full justify-center bg-white'>
                                        <Loading />
                                    </div> :
                                <form onSubmit={onFormSubmit} className='mt-4'>
                                    <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                        <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información SLA</h2>
                                        <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                            <div className=''>
                                                <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                    <div className="w-1/12 pt-2 flex justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-2' viewBox="0 0 640 512">
                                                            <path d="M506.1 127.1c-17.97-20.17-61.46-61.65-122.7-71.1c-22.5-3.354-45.39 3.606-63.41 18.21C302 60.47 279.1 53.42 256.5 56.86C176.8 69.17 126.7 136.2 124.6 139.1c-7.844 10.69-5.531 25.72 5.125 33.57c4.281 3.157 9.281 4.657 14.19 4.657c7.406 0 14.69-3.375 19.38-9.782c.4062-.5626 40.19-53.91 100.5-63.23c7.457-.9611 14.98 .67 21.56 4.483L227.2 168.2C214.8 180.5 207.1 196.1 207.1 214.5c0 17.5 6.812 33.94 19.16 46.29C239.5 273.2 255.9 279.1 273.4 279.1s33.94-6.813 46.31-19.19l11.35-11.35l124.2 100.9c2.312 1.875 2.656 5.251 .5 7.97l-27.69 35.75c-1.844 2.25-5.25 2.594-7.156 1.063l-22.22-18.69l-26.19 27.75c-2.344 2.875-5.344 3.563-6.906 3.719c-1.656 .1562-4.562 .125-6.812-1.719l-32.41-27.66L310.7 392.3l-2.812 2.938c-5.844 7.157-14.09 11.66-23.28 12.6c-9.469 .8126-18.25-1.75-24.5-6.782L170.3 319.8H96V128.3L0 128.3v255.6l64 .0404c11.74 0 21.57-6.706 27.14-16.14h60.64l77.06 69.66C243.7 449.6 261.9 456 280.8 456c2.875 0 5.781-.125 8.656-.4376c13.62-1.406 26.41-6.063 37.47-13.5l.9062 .8126c12.03 9.876 27.28 14.41 42.69 12.78c13.19-1.375 25.28-7.032 33.91-15.35c21.09 8.188 46.09 2.344 61.25-16.47l27.69-35.75c18.47-22.82 14.97-56.48-7.844-75.01l-120.3-97.76l8.381-8.382c9.375-9.376 9.375-24.57 0-33.94c-9.375-9.376-24.56-9.376-33.94 0L285.8 226.8C279.2 233.5 267.7 233.5 261.1 226.8c-3.312-3.282-5.125-7.657-5.125-12.31c0-4.688 1.812-9.064 5.281-12.53l85.91-87.64c7.812-7.845 18.53-11.75 28.94-10.03c59.75 9.22 100.2 62.73 100.6 63.29c3.088 4.155 7.264 6.946 11.84 8.376H544v175.1c0 17.67 14.33 32.05 31.1 32.05L640 384V128.1L506.1 127.1zM48 352c-8.75 0-16-7.245-16-15.99c0-8.876 7.25-15.99 16-15.99S64 327.2 64 336.1C64 344.8 56.75 352 48 352zM592 352c-8.75 0-16-7.245-16-15.99c0-8.876 7.25-15.99 16-15.99s16 7.117 16 15.99C608 344.8 600.8 352 592 352z" />
                                                        </svg>
                                                    </div>
                                                    <div className="relative z-0 w-full group">
                                                        <input onChange={(e) => setNombre(e.target.value)} id='txtNombre' defaultValue={nombre} type="text" name="nombre" maxLength={30} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                        <label htmlFor="txtNombre" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nombre</label>
                                                    </div>
                                                </div>
                                                <span className='text-sm text-red-500'>{errors.nombre}</span>
                                            </div>
                                            <div className=''>
                                                <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                    <div className="w-1/12 pt-2 flex justify-center">
                                                        <DocumentTextIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                    </div>
                                                    <div className="relative z-0 w-full group">
                                                        <textarea onChange={(e) => setDescripcion(e.target.value)} id='taDescripcion' defaultValue={descripcion} name="descripcion" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                        <label htmlFor="taDescripcion" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Descripción (Opcional)</label>
                                                    </div>
                                                </div>
                                                <span className='text-sm text-red-500'>{errors.descripcion}</span>
                                            </div>
                                            <div className=''>
                                                <h5 className='md:text-sm text-xs text-gray-400 text-light font-normal mb-1'>Estatus</h5>
                                                <div className="min-h-[1.5rem] mb-0.5 block pl-12">
                                                    <input onChange={() => setActivo(!activo)} id="activo" className="mt-[.135rem] rounded-[2.5rem] duration-300 ease-in-out after:rounded-full after:shadow-2xl after:duration-300 checked:after:translate-x-[1.3125rem] h-5 relative float-left -ml-12 w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-slate-800/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-accent-1 checked:bg-accent-2 checked:bg-none checked:bg-right" type="checkbox" defaultChecked={activo}/>
                                                    <label className="text-gray-900 mb-2 ml-1 font-normal cursor-pointer select-none text-sm" htmlFor="activo">{activo ? 'Activo' : 'Desactivado'}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="space-y-4 md:space-y-0 w-full p-4 text-gray-800 items-center mb-4">
                                        <h2 className="text-accent-1 font-semibold">Condiciones</h2>
                                        <fieldset className="md:inline-flex justify-around items-center w-full md:py-5 space-y-2">
                                            <span className="block text-left mb-4 md:mb-0">Cuando llegue una petición:</span>
                                            <div className="pl-4">
                                                <div className="flex items-center gap-2">
                                                    <input type="radio" onClick={() => setApply(true)} name="conditionRadios" id="rdApply" value="apply" defaultChecked={apply} />
                                                    <label htmlFor="rdApply">
                                                        Aplicar condiciones basado en el siguiente criterio
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="pl-4">
                                                <div className="flex items-center gap-2">
                                                    <input type="radio" onClick={() => setApply(false)} name="conditionRadios" id="rdDontApply" value="dontApply" defaultChecked={!apply} />
                                                    <label htmlFor="rdDontApply">
                                                        No aplicar condición
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>
                                        {/* Condicones Apply */}
                                        {!condicionesLoaded && apply ? 
                                        <div className='flex w-full justify-center gap-4'>
                                            <span className='text-base text-gray-500 italic'>Cargando Condiciones...</span>
                                            <svg className="animate-spin h-6 w-6 text-accent-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </div> :
                                        <div className='px-5 md:container' hidden={!apply}>
                                            <table id='tblCondiciones' className='hidden md:inline-table w-full bg-white rounded-lg overflow-hidden md:shadow-md my-5'>
                                                <thead className='text-white'>
                                                    {renderCondiciones('header')}
                                                </thead>
                                                <tbody className='flex-1 md:flex-none'>
                                                    {renderCondiciones('body')}
                                                </tbody>
                                            </table>
                                            {/* Mobile */}
                                            <div className='md:hidden flex-col w-full'>
                                                {/* {this.renderCondicionesMobile()} */}
                                            </div>
                                        </div>}
                                        
                                        {/* Condicones Not Apply */}
                                        <div className={`px-5 ${!apply ? 'flex' : 'hidden'} w-full`}>
                                            <div className='w-full bg-accent-2 rounded-md p-3 my-5'>
                                                <h5 className='italic text-center text-white font-bold'>El SLA se aplicará a todas las solicitudes entrantes</h5>
                                            </div>
                                        </div>
                                        <span className='text-sm text-red-500'>{errors.condiciones}</span>
                                        {/* Tiempos */}
                                        <div className='md:p-5 px-5 py-2'>
                                            <span>Cualquier petición que cumpla las condiciones arriba debera ser</span>
                                        </div>
                                        {/* Grid */}
                                        <div className='grid md:grid-cols-4 grid-cols-3 w-full md:p-5 px-5 py-2 gap-y-6'>
                                            <div className='md:col-span-1 col-span-3'>
                                                <div className='flex w-full md:justify-center items-center p-1'>
                                                    <span>Contestado en:</span>
                                                </div>
                                            </div>
                                            <div className='col-span-1'>
                                                <div className='inline-flex items-center w-full gap-4 group'>
                                                    <label htmlFor="respDias" className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Dias</label>
                                                    <input type="number" id='respDias' onChange={(e) => setTiempoRespuesta(prevState => ({
                                                        ...prevState, dias: e.target.value
                                                    }))} className='border-2 border-gray-400 rounded p-1 text-center w-20 focus:outline-none focus:ring-0 focus:border-accent-2' min={0} defaultValue={tiempo_respuesta.dias} required />  
                                                </div>
                                                <span className='text-sm text-red-500'>{errors.respuesta_dias}</span>
                                            </div>
                                            <div className='col-span-1'>
                                                <div className='inline-flex items-center w-full gap-4 group'>
                                                    <label htmlFor="respHoras" className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Horas</label>
                                                    <select name="horas" id="respHoras" onChange={(e) => setTiempoRespuesta(prevState => ({
                                                        ...prevState, horas: e.target.value
                                                    }))} defaultValue={tiempo_respuesta.horas} className='border-2 border-gray-400 rounded p-1 px-4 focus:outline-none focus:ring-0 focus:border-accent-2' required>
                                                        {renderSelect(24)}
                                                    </select>
                                                </div>
                                                <span className='text-sm text-red-500'>{errors.respuesta_horas}</span>
                                            </div>
                                            <div className='col-span-1'>
                                                <div className='inline-flex items-center w-full gap-4 group'>
                                                    <label htmlFor="respMinutos" className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Minutos</label>
                                                    <select name="minutos" id="respMinutos" onChange={(e) => setTiempoRespuesta(prevState => ({
                                                        ...prevState, minutos: e.target.value
                                                    }))} defaultValue={tiempo_respuesta.minutos} className='border-2 border-gray-400 rounded p-1 px-4 focus:outline-none focus:ring-0 focus:border-accent-2' required>
                                                        {renderSelect(60)}
                                                    </select>
                                                </div>
                                                <span className='text-sm text-red-500'>{errors.respuesta_minutos}</span>
                                            </div>
                                            <div className='md:col-span-1 col-span-3'>
                                                <div className='flex w-full md:justify-center items-center p-1'>
                                                    <span>Resuelto en:</span>
                                                </div>
                                            </div>
                                            <div className='col-span-1'>
                                                <div className='inline-flex items-center w-full gap-4 group'>
                                                    <label htmlFor="resolDias" className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Dias</label>
                                                    <input type="number" id='resolDias' onChange={(e) => setTiempoResolucion(prevState => ({
                                                        ...prevState, dias: e.target.value
                                                    }))} className='border-2 border-gray-400 rounded p-1 text-center w-20 focus:outline-none focus:ring-0 focus:border-accent-2' min={0} defaultValue={tiempo_resolucion.dias} required />
                                                </div>
                                                <span className='text-sm text-red-500'>{errors.resolucion_dias}</span>
                                            </div>
                                            <div className='col-span-1'>
                                                <div className='inline-flex items-center w-full gap-4 group'>
                                                    <label htmlFor="resolHoras" className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Horas</label>
                                                    <select name="horas" id="resolHoras" onChange={(e) => setTiempoResolucion(prevState => ({
                                                        ...prevState, horas: e.target.value
                                                    }))} defaultValue={tiempo_resolucion.horas} className='border-2 border-gray-400 rounded p-1 px-4 focus:outline-none focus:ring-0 focus:border-accent-2' required>
                                                        {renderSelect(24)}
                                                    </select>
                                                </div>
                                                <span className='text-sm text-red-500'>{errors.resolucion_horas}</span>
                                            </div>
                                            <div className='col-span-1'>
                                                <div className='inline-flex items-center w-full gap-4 group'>
                                                    <label htmlFor="resolMinutos" className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Minutos</label>
                                                    <select name="minutos" id="resolMinutos" onChange={(e) => setTiempoResolucion(prevState => ({
                                                        ...prevState, minutos: e.target.value
                                                    }))} defaultValue={tiempo_resolucion.minutos} className='border-2 border-gray-400 rounded p-1 px-4 focus:outline-none focus:ring-0 focus:border-accent-2' required>
                                                        {renderSelect(60)}
                                                    </select>
                                                </div>
                                                <span className='text-sm text-red-500'>{errors.resolucion_minutos}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="space-y-4 md:space-y-0 w-full p-4 text-gray-800 items-center">
                                        <h2 className="text-accent-1 font-semibold">Si ya pasó el tiempo de respuesta</h2>
                                        <div className='p-5 flex w-full gap-4'>
                                            <input type="checkbox" name="escalationCheck" id="resp_CheckEsc1" className='w-5' defaultChecked={respEsc1} onChange={() => setRespEsc1(!respEsc1)} />
                                            <label htmlFor="resp_CheckEsc1">Habilitar Escalación Nivel 1</label>
                                        </div>
                                        <div hidden={!respEsc1}>
                                            <EscalacionesComponent name={'respEsc1'} tiempo={{dias: escalaciones.find(e => e.tipo === 'respuesta' && e.nivel === '1')?.dias || 0, horas: escalaciones.find(e => e.tipo === 'respuesta' && e.nivel === '1')?.horas || 0, minutos: escalaciones.find(e => e.tipo === 'respuesta' && e.nivel === '1')?.minutos || 0}}
                                            usuarios={escalaciones.find(e => e.tipo === 'respuesta' && e.nivel === '1')?.usuarios} NCs={escalaciones.find(e => e.tipo === 'respuesta' && e.nivel === '1')?.notificaciones_correo}
                                            handleTiempo={(tiempo) => updateEscalaciones('tiempo','respuesta', '1', tiempo)}  handleUsers={(users) => updateEscalaciones('usuarios','respuesta', '1', users)} handleNCs={(ncs) => updateEscalaciones('notificaciones_correo','respuesta', '1', ncs)} 
                                            errors={{dias: errors.respuesta_esc1_dias, horas: errors.respuesta_esc1_horas, minutos: errors.respuesta_esc1_minutos, usuarios: errors.respuesta_esc1_usuarios}}  />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="space-y-4 md:space-y-0 w-full p-4 text-gray-800 items-center mb-4">
                                        <h2 className="text-accent-1 font-semibold">Si ya pasó el tiempo de resolución</h2>
                                        <div className='px-5 py-2.5 flex w-full gap-4'>
                                            <input type="checkbox" name="escalationCheck" id="res_CheckEsc1" className='w-5' checked={resolEsc1} onChange={(e) => {setResolEsc1(!resolEsc1); 
                                            !e.target.checked && setResolEsc2(false); !e.target.checked && setResolEsc3(false); !e.target.checked && setResolEsc4(false)}} />
                                            <label htmlFor="res_CheckEsc1">Habilitar Escalación Nivel 1</label>
                                        </div>
                                        <div hidden={!resolEsc1}>
                                            <EscalacionesComponent name={'resolEsc1'} tiempo={{dias: escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '1')?.dias || 0, horas: escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '1')?.horas || 0, minutos: escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '1')?.minutos || 0}}
                                            usuarios={escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '1')?.usuarios} NCs={escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '1')?.notificaciones_correo}
                                            handleTiempo={(tiempo) => updateEscalaciones('tiempo','resolucion', '1', tiempo)}  handleUsers={(users) => updateEscalaciones('usuarios','resolucion', '1', users)} handleNCs={(ncs) => updateEscalaciones('notificaciones_correo','resolucion', '1', ncs)}
                                            errors={{dias: errors.resolucion_esc1_dias, horas: errors.resolucion_esc1_horas, minutos: errors.resolucion_esc1_minutos, usuarios: errors.resolucion_esc1_usuarios}} />
                                        </div>
                                        <div className='px-5 py-2.5 flex w-full gap-4'>
                                            <input type="checkbox" name="escalationCheck" id="res_CheckEsc2" className='w-5' disabled={!resolEsc1} checked={resolEsc2} onChange={(e) => {setResolEsc2(!resolEsc2); 
                                            !e.target.checked && setResolEsc3(false); !e.target.checked && setResolEsc4(false)}} />
                                            <label htmlFor="res_CheckEsc2">Habilitar Escalación Nivel 2</label>
                                        </div>
                                        <div hidden={!resolEsc2}>
                                            <EscalacionesComponent name={'resolEsc2'} tiempo={{dias: escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '2')?.dias || 0, horas: escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '2')?.horas || 0, minutos: escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '2')?.minutos || 0}}
                                            usuarios={escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '2')?.usuarios} NCs={escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '2')?.notificaciones_correo}
                                            handleTiempo={(tiempo) => updateEscalaciones('tiempo','resolucion', '2', tiempo)}  handleUsers={(users) => updateEscalaciones('usuarios','resolucion', '2', users)} handleNCs={(ncs) => updateEscalaciones('notificaciones_correo','resolucion', '2', ncs)}
                                            errors={{dias: errors.resolucion_esc2_dias, horas: errors.resolucion_esc2_horas, minutos: errors.resolucion_esc2_minutos, usuarios: errors.resolucion_esc2_usuarios}} />
                                        </div>
                                        <div className='px-5 py-2.5 flex w-full gap-4'>
                                            <input type="checkbox" name="escalationCheck" id="res_CheckEsc3" className='w-5' disabled={!resolEsc2} checked={resolEsc3} onChange={(e) => {setResolEsc3(!resolEsc3); 
                                            !e.target.checked && setResolEsc4(false)}} />
                                            <label htmlFor="res_CheckEsc3">Habilitar Escalación Nivel 3</label>
                                        </div>
                                        <div hidden={!resolEsc3}>
                                            <EscalacionesComponent name={'resolEsc3'} tiempo={{dias: escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '3')?.dias || 0, horas: escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '3')?.horas || 0, minutos: escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '3')?.minutos || 0}}
                                            usuarios={escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '3')?.usuarios} NCs={escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '3')?.notificaciones_correo}
                                            handleTiempo={(tiempo) => updateEscalaciones('tiempo','resolucion', '3', tiempo)}  handleUsers={(users) => updateEscalaciones('usuarios','resolucion', '3', users)} handleNCs={(ncs) => updateEscalaciones('notificaciones_correo','resolucion', '3', ncs)}
                                            errors={{dias: errors.resolucion_esc3_dias, horas: errors.resolucion_esc3_horas, minutos: errors.resolucion_esc3_minutos, usuarios: errors.resolucion_esc3_usuarios}} />
                                        </div>
                                        <div className='px-5 py-2.5 flex w-full gap-4'>
                                            <input type="checkbox" name="escalationCheck" id="res_CheckEsc4" className='w-5' disabled={!resolEsc3} checked={resolEsc4} onChange={(e) => setResolEsc4(!resolEsc4)} />
                                            <label htmlFor="res_CheckEsc4">Habilitar Escalación Nivel 4</label>
                                        </div>
                                        <div hidden={!resolEsc4}>
                                            <EscalacionesComponent name={'resolEsc4'} tiempo={{dias: escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '4')?.dias || 0, horas: escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '4')?.horas || 0, minutos: escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '4')?.minutos || 0}}
                                            usuarios={escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '4')?.usuarios} NCs={escalaciones.find(e => e.tipo === 'resolucion' && e.nivel === '4')?.notificaciones_correo}
                                            handleTiempo={(tiempo) => updateEscalaciones('tiempo','resolucion', '4', tiempo)}  handleUsers={(users) => updateEscalaciones('usuarios','resolucion', '4', users)} handleNCs={(ncs) => updateEscalaciones('notificaciones_correo','resolucion', '4', ncs)}
                                            errors={{dias: errors.resolucion_esc4_dias, horas: errors.resolucion_esc4_horas, minutos: errors.resolucion_esc4_minutos, usuarios: errors.resolucion_esc4_usuarios}} />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='p-4 flex justify-center gap-4 bg-white'>
                                        <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </button>
                                        <button type="submit" hidden={processingRequest} disabled={!condicionesLoaded} className="disabled:bg-accent-2/70 text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                        <Link to='/slas' hidden={processingRequest} className={`${!processingRequest && 'flex'} items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                    </div>
                                </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditarSLAPage