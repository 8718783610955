import { AcademicCapIcon, BriefcaseIcon, CalendarIcon, DeviceMobileIcon, GlobeIcon, HashtagIcon, HeartIcon, HomeIcon, IdentificationIcon, LibraryIcon, LocationMarkerIcon, MailIcon, PhoneIcon, UserCircleIcon, UserGroupIcon, UserIcon, UsersIcon } from '@heroicons/react/outline';
import { PencilAltIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import Select from 'react-select';
var CryptoJS = require("crypto-js");

// Create a constructor for functional component to get permissions
const useConstructor = (callBack = () => { }) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
}

//This has to be a functional component to use 'useParams()' from react-router-dom
const EditarTutorPage = (props) => {

    // Domicilio states
    const [direccion, setDireccion] = useState('');
    const [codigo_postal, setCodigoPostal] = useState('');
    const [colonia, setColonia] = useState('');
    const [municipio, setMunicipio] = useState('');
    // Persona states
    const [persona_id, setPersonaId] = useState(null);
    const [usuario_id, setUsuarioId] = useState(null);
    const [email, setEmail] = useState(null);
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [fecha_nacimiento, setFechaNacimiento] = useState('');
    const [sexo, setSexo] = useState('nk');
    const [nacionalidad, setNacionalidad] = useState('');
    const [no_celular, setNoCelular] = useState('');
    const [curp, setCURP] = useState('');
    const [estado_civil, setEstadoCivil] = useState('');
    // Tutor states
    const [email_personal, setEmailPersonal] = useState('');
    const [parentesco_id, setParentescoId] = useState(0);
    const [parentescos, setParentescos] = useState([{}]);
    const [num_hijos, setNumHijos] = useState(0);
    const [ocupacion, setOcupacion] = useState('');
    const [tel_trabajo, setTelTrabajo] = useState('');
    const [escolaridad_id, setEscolaridadId] = useState(0);
    const [escolaridades, setEscolaridades] = useState([{}]);
    const [selected_estudiantes, setSelectedEstudiantes] = useState([]);
    const [estudiantes, setEstudiantes] = useState([]);
    // Request/Form states
    const [estudiantes_exists, setEstudiantesExists] = useState(true);
    const [statusResult, setStatusResult] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [processingRequest, setProcessingRequest] = useState(false);
    const [errors, setErrors] = useState([{
        // Domicilio errors
        direccion: '', codigo_postal: '', colonia: '', municipio: '',
        // Persona errors
        nombre: '', apellido: '', fecha_nacimiento: '', sexo: '', nacionalidad: '', no_celular: '', curp: '', estado_civil: '',
        // Tutor errors
        parentesco_id: '', email_personal: '', num_hijos: '', ocupacion: '', tel_trabajo: '', escolaridad_id: '', selected_estudiantes: ''
    }]);
    const [usersAccess, setUsersAccess] = useState([{}]);

    //Get the passed :id from parent and pass it to the component to render it
    const tutor = useParams();

    /// Decript permissions
    useConstructor(() => {
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUsersAccess(_usersAccess);
    });

    // Load Parentescos, Escolaridades and Estudiantes
    useEffect(() => {
        // Get Parentescos
        RestClient.GetRequest(AppUrl.Parentescos).then((result) => {
            setParentescos(result);
        });
        // Get Escolaridades
        RestClient.GetRequest(AppUrl.Escolaridades).then((result) => {
            setEscolaridades(result);
        });
        // Get Estudiantes
        RestClient.GetRequest(AppUrl.Estudiantes).then((result) => {
            var groupedOptions = [];
            result.forEach((estudiante) => {
                var nivel = estudiante.nivel;
                if (!groupedOptions.some(group => group.label === nivel)) {
                    groupedOptions.push({label: nivel, options: [{value: estudiante.id, label: estudiante.nombre + ' ' + estudiante.apellido}]});
                }
                else {
                    groupedOptions.find(group => group.label === nivel).options.push({value: estudiante.id, label: estudiante.nombre + ' ' + estudiante.apellido});
                }
            });
            setEstudiantes(groupedOptions);
        });
    }, []);

    // Get Tutor by Id
    useEffect(() => {
        RestClient.GetRequest(AppUrl.TutorById + tutor.id).then((result) => {
            console.log("🚀 ~ file: EditarTutorPage.jsx:105 ~ RestClient.GetRequest ~ result:", result)
            setPersonaId(result.persona_id);
            result.usuario_id !== null && setUsuarioId(result.usuario_id);
            result.email !== null && setEmail(result.email);
            setParentescoId(result.parentesco_id);
            setNombre(result.nombre);
            setApellido(result.apellido);
            setFechaNacimiento(result.fecha_nacimiento);
            setSexo(result.sexo);
            setNacionalidad(result.nacionalidad);
            setNoCelular(result.no_celular);
            setEmailPersonal(result.email_personal);
            setCURP(result.curp);
            setEstadoCivil(result.estado_civil);
            setDireccion(result.direccion);
            setCodigoPostal(result.codigo_postal);
            setColonia(result.colonia);
            setMunicipio(result.municipio);
            setNumHijos(result.num_hijos);
            setOcupacion(result.ocupacion);
            setTelTrabajo(result.tel_trabajo);
            setEscolaridadId(result.escolaridad_id);
            setSelectedEstudiantes(result.estudiantes.map(e => parseInt(e)));
            setEstudiantesExists(result.estudiantes[0] !== "");
            setLoaded(true);
        });
    }, [tutor.id]);

    function isNumericInput(event) {
        const key = event.keyCode;
        return ((key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
        );
    };

    function isModifierKey(event) {
        const key = event.keyCode;
        return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };

    function enforceFormat(event) {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if (isNumericInput(event) && !isModifierKey(event)) {
            event.preventDefault();
        }
    };

    function formatToPhone(event) {
        if (isModifierKey(event)) { return; }

        const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
        const areaCode = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const last = input.substring(6, 10);

        if (input.length > 6) { event.target.value = `(${areaCode}) ${middle}-${last}`; }
        else if (input.length > 3) { event.target.value = `(${areaCode}) ${middle}`; }
        else if (input.length > 0) { event.target.value = `(${areaCode}`; }
    };

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }

    function onFormSubmit(e) {
        e.preventDefault();
        //Disable controls while request is processed
        setProcessingRequest(true);
        setErrors([{
            // Domicilio errors
            direccion: '', codigo_postal: '', colonia: '', municipio: '',
            // Persona errors
            nombre: '', apellido: '', fecha_nacimiento: '', sexo: '', nacionalidad: '', no_celular: '', curp: '', estado_civil: '',
            // Tutor errors
            parentesco_id: '', email_personal: '', num_hijos: '', ocupacion: '', tel_trabajo: '', escolaridad_id: '', selected_estudiantes: ''
        }]);

        const data = new FormData();
        data.append('persona_id', persona_id);
        data.append('parentesco_id', parentesco_id);
        data.append('nombre', nombre);
        data.append('apellido', apellido);
        data.append('fecha_nacimiento', fecha_nacimiento);
        data.append('sexo', sexo);
        data.append('nacionalidad', nacionalidad);
        data.append('no_celular', no_celular);
        data.append('email_personal', email_personal);
        data.append('curp', curp);
        data.append('estado_civil', estado_civil);
        data.append('direccion', direccion);
        data.append('codigo_postal', codigo_postal);
        data.append('colonia', colonia);
        data.append('municipio', municipio);
        data.append('num_hijos', num_hijos);
        data.append('ocupacion', ocupacion === null ? '' : ocupacion);
        data.append('tel_trabajo', tel_trabajo === null ? '' : tel_trabajo);
        data.append('escolaridad_id', escolaridad_id);
        if (estudiantes_exists) {
            data.append('selected_estudiantes', selected_estudiantes);
        }

        RestClient.PostRequest(AppUrl.UpdateTutor + tutor.id, data).then((result) => {
            setStatusResult(result.status);
            setProcessingRequest(false);
            setErrors(result.data);

            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    if (usersAccess.find(ua => ua.modulo_name === 'tutores' && ua.permiso_name === 'update') === undefined) {
        return <Navigate to='/access-denied' />
    }
    if (statusResult === 200) {
        return <Navigate to='/tutores' />
    }
    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'ml-80' : 'ml-20'} duration-300 p-12 pt-24 pr-16 bg-gray-100`}>
            {/* Page Title */}
            <div className='flex w-full'>
                <h1 className='text-xl font-medium'>Editar Tutor</h1>
            </div>
            {/* Breadcrum Nav */}
            <div className='flex w-full text-sm text-gray-500 mt-2 space-x-1'>
                {usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                    <>
                        <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                        <span className='text-xs'>/</span>
                    </>}
                <p className='text-gray-400'>Personal</p>
                <span className='text-xs'>/</span>
                <Link to='/tutores' className='font-semibold hover:underline'>Tutores</Link>
                <span className='text-xs'>/</span>
                <p className='text-dark-purple font-semibold'>Editar Tutor</p>
            </div>
            {/* Main Section */}
            <div className='w-full flex justify-center mt-6'>
                <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                    <div className='w-11/12 mt-8 mb-8'>
                        <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                            <div className='bg-gray-50'>
                                <div className='bg-white border'>
                                    <h1 className='text-accent-1 text-2xl p-4'>Editar Tutor</h1>
                                </div>
                                {!loaded ?
                                    <div className='flex w-full justify-center bg-white'>
                                        <Loading />
                                    </div> :
                                    <form onSubmit={onFormSubmit} className='mt-4'>
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Tutor</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setNombre(e.target.value)} id='txtNombre' defaultValue={nombre} type="text" name="nombre" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtNombre" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nombre(s)</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.nombre}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setApellido(e.target.value)} id='txtApellido' defaultValue={apellido} type="text" name="apellido" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtApellido" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Apellido(s)</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.apellido}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setFechaNacimiento(e.target.value)} id='dateFechaNacimiento' defaultValue={fecha_nacimiento} type='date' name="fecha_nacimiento" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="dateFechaNacimiento" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Fecha de Nacimiento</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.fecha_nacimiento}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <UsersIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select onChange={(e) => setSexo(e.target.value)} id='selectSexo' name="sexo" defaultValue={sexo} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione un Sexo --</option>
                                                                <option value="nk">Desconocido</option>
                                                                <option value="m">Hombre</option>
                                                                <option value="f">Mujer</option>
                                                                <option value="na">No Aplicable</option>
                                                            </select>
                                                            <label htmlFor="selectSexo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Sexo</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.sexo}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <GlobeIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setNacionalidad(e.target.value)} id='textNacionalidad' defaultValue={nacionalidad} type='text' name="nacionalidad" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="textNacionalidad" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nacionalidad</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.nacionalidad}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <IdentificationIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setCURP(e.target.value)} id='textCURP' defaultValue={curp} type='text' name="curp" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="textCURP" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">CURP</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.curp}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <HeartIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select onChange={(e) => setEstadoCivil(e.target.value)} id='selectEstadoCivil' name="estado_civil" defaultValue={estado_civil} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione un Estado Civil --</option>
                                                                <option value="1">Casado(a)</option>
                                                                <option value="2">Viudo(a)</option>
                                                                <option value="3">Separado(a)</option>
                                                                <option value="4">Divorciado(a)</option>
                                                                <option value="5">Soltero(a)</option>
                                                            </select>
                                                            <label htmlFor="selectEstadoCivil" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Estado Civil</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.estado_civil}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <UserGroupIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setNumHijos(e.target.value)} id='numberNumHijos' defaultValue={num_hijos} type='number' name="num_hijos" min={0} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="numberNumHijos" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Número de Hijos</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.num_hijos}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <AcademicCapIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select onChange={(e) => setEscolaridadId(e.target.value)} id='selectEscolaridad' name="escolaridad_id" defaultValue={escolaridad_id} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione Nivel de Estudios --</option>
                                                                {escolaridades.map(item => {
                                                                    return <option key={item.id} value={item.id}>{item.nombre}</option>
                                                                })}
                                                            </select>
                                                            <label htmlFor="selectEscolaridad" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nivel Máximo de Estudios</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.escolaridad_id}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <UsersIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select onChange={(e) => setParentescoId(e.target.value)} id='selectParentesco' name="parentesco_id" defaultValue={parentesco_id} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione un Parentesco --</option>
                                                                {parentescos.map(item => {
                                                                    return <option key={item.id} value={item.id}>{item.nombre}</option>
                                                                })}
                                                            </select>
                                                            <label htmlFor="selectParentesco" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Parentesco con el almuno(a)</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.parentesco_id}</span>
                                                </div>

                                            </div>
                                        </div>
                                        <hr />
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Domicilio</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className='mt-4'>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <HomeIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setDireccion(e.target.value)} id='txtDireccion' defaultValue={direccion} type="text" name="direccion" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtDireccion" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Dirección</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.direccion}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <HashtagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setCodigoPostal(e.target.value)} id='txtCodigoPostal' defaultValue={codigo_postal} type="text" name="codigo_postal" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtCodigoPostal" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Código Postal</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.codigo_postal}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <LocationMarkerIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setColonia(e.target.value)} id='txtColonia' defaultValue={colonia} type="text" name="colonia" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtColonia" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Colonia</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.colonia}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <LibraryIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setMunicipio(e.target.value)} id='txtMunicipio' defaultValue={municipio} type="text" name="municipio" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtMunicipio" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Municipio</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.municipio}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Contacto</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className='mt-4'>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <DeviceMobileIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setNoCelular(formatPhoneNumber(e.target.value))} onKeyDown={enforceFormat} onKeyUp={formatToPhone} id='telNumCel' defaultValue={no_celular} type="tel" name="num_cel" maxLength={15} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="telNumCel" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Número Celular</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.no_celular}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <MailIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setEmailPersonal(e.target.value)} id='emailEmailPersonal' defaultValue={email_personal} type="email" name="email_personal" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="emailEmailPersonal" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Correo Personal</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.email_personal}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <BriefcaseIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setOcupacion(e.target.value)} id='txtOcupacion' defaultValue={ocupacion} type="text" name="ocupacion" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                            <label htmlFor="txtOcupacion" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Ocupación (Opcional)</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.ocupacion}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <PhoneIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setTelTrabajo(formatPhoneNumber(e.target.value))} onKeyDown={enforceFormat} onKeyUp={formatToPhone} id='telTelTrabajo' defaultValue={tel_trabajo} type='tel' name="tel_trabajo" maxLength={15} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                            <label htmlFor="telTelTrabajo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Teléfono Trabajo (Opcional)</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.tel_trabajo}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mt-4 mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Usuario</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    {usuario_id === null ?
                                                        <div className='w-full inline-flex items-center gap-4'>
                                                            <p className='italic'>Aún no cuenta con usuario</p>
                                                            {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'usuarios') !== undefined &&
                                                                <Link to={'/usuarios/crear-con-tutor/' + tutor.id} role='button' className='bg-accent-1 text-white p-2 rounded-lg font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    Crear Usuario
                                                                </Link>}
                                                        </div> :
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                                <div className="w-1/12 pt-2 flex justify-center mx-0.5">
                                                                    <UserCircleIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <input disabled id='txtUsuario' defaultValue={email} type="text" name="usuario_id" className="cursor-not-allowed block py-2.5 px-4 w-full text-sm text-gray-500 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                                    <label htmlFor="txtUsuario" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Usuario</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'usuarios') !== undefined &&
                                                                <Link to={'/usuarios/editar/' + usuario_id} title='Editar' className='p-3 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PencilAltIcon className='h-5' />
                                                                </Link>}
                                                        </div>}
                                                    <span className='text-sm text-red-500'>{errors.usuario_id}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Estudiantes</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className='mt-4 inline-flex space-x-3'>
                                                    <div className="min-h-[1.5rem] mb-0.5 block pl-2">
                                                        <input onChange={() => setEstudiantesExists(!estudiantes_exists)} defaultChecked={!estudiantes_exists} id='checkEstudiantes' className='accent-accent-2' type="checkbox" style={{ transform: 'scale(1.5)' }} />
                                                    </div>
                                                    <label className='md:text-sm text-xs text-gray-800 text-light font-normal mb-1' htmlFor='checkEstudiantes'>Aún no se registra el/los estudiante(s) para este tutor</label>
                                                </div>
                                                <div className='' hidden={!estudiantes_exists}>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <UsersIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <Select placeholder='--Seleccionar Estudiante(s)--'
                                                                isMulti
                                                                options={estudiantes}
                                                                defaultValue={estudiantes.map(e => e.options).flat().filter(e => selected_estudiantes.some(se => se === e.value))}
                                                                menuPortalTarget={document.body}
                                                                closeMenuOnSelect={false}
                                                                className='p-1'
                                                                onChange={(estudiantes) => setSelectedEstudiantes(estudiantes.map(t => t.value))}
                                                                isLoading={estudiantes.length === 0}
                                                                styles={{
                                                                    option: (base, state) => ({
                                                                        ...base,
                                                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                                                    }),
                                                                }}
                                                            />
                                                            <label htmlFor="selectEstudiantes" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Estudiante(s)</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.selected_estudiantes}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='p-4 flex justify-center gap-4 bg-white'>
                                            <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" hidden={processingRequest} className="text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                            <Link to='/tutores' hidden={processingRequest} className={`${!processingRequest && 'flex'} items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                        </div>
                                    </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditarTutorPage