import styles from '../../custom-css/ModalFormStyles.js';
import React, { useEffect } from 'react';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import { Node } from 'slate';
import ReactModal from 'react-modal';
import { InformationCircleIcon, XIcon } from '@heroicons/react/solid';
import { ExclamationCircleIcon, FlagIcon, UserIcon } from '@heroicons/react/outline';
import TextEditorInput from '../form-controls/TextEditorInput';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Select from 'react-select';
import Loading from '../Loading';
import PaginationComponent from '../form-controls/PaginationComponent';
import DetailsBody from '../details/DetailsBody';
import { DetailsFooter } from '../details/DetailsComponent';

const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const TareasModal = ({ isOpen, toggleModal, activeTab, handleActiveTab, setTareas, isPeticionOwner = false, currentTarea = null, userHasOtherTareas = false, canEditUser = false, processingRequest = false }) => {
    // Tarea State
    const [titulo, setTitulo] = React.useState('');
    const [descripcion, setDescripcion] = React.useState('');
    const [characterCount, setCharacterCount] = React.useState(0);
    const [tiempo, setTiempo] = React.useState({ dias: 0, horas: 0, minutos: 0 });
    const [usuario_id, setUsuarioId] = React.useState(0);
    const [estatus_id, setEstatusId] = React.useState(0);
    const [estatus_tipo, setEstatusTipo] = React.useState('');
    const [prioridad_id, setPrioridadId] = React.useState(0);
    const [porcentaje, setPorcentaje] = React.useState(0);
    // Collections
    const [usuarios, setUsuarios] = React.useState([]);
    const [estatus, setEstatus] = React.useState([]);
    const [prioridades, setPrioridades] = React.useState([]);
    const [historiales, setHistoriales] = React.useState([]);
    // Form States
    const [isValid, setIsValid] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [tabLoaded, setTabLoaded] = React.useState(true);
    const [confirmationModal, setConfirmationModal] = React.useState(false);
    const [reopenModal, setReopenModal] = React.useState(false);
    const [reopen_estatus, setReopenEstatus] = React.useState(0);
    const [prevUser, setPrevUser] = React.useState(null);

    // Get Users, Estatus, Prioridades
    useEffect(() => {
        // Get Usuarios Administrativos
        RestClient.GetRequest(AppUrl.Administrativos).then((result) => {
            var groupedOptions = [];
            result.filter(a => a.usuario_id !== null).forEach(element => {
                var depto = element.depto;
                if (!groupedOptions.some(o => o.label === depto)) {
                    groupedOptions.push({ label: depto, options: [{ value: parseInt(element.usuario_id), label: element.nombre + ' ' + element.apellido }] });
                }
                else {
                    groupedOptions.find(o => o.label === depto).options.push({ value: parseInt(element.usuario_id), label: element.nombre + ' ' + element.apellido });
                }
            });
            setUsuarios(groupedOptions);
        });
        // Get Estatus
        RestClient.GetRequest(AppUrl.Estatus).then((result) => {
            var options = [];
            var groupedOptions = [];
            result.forEach((element, index) => {
                var group = element.tipo;
                if ((index + 1) !== result.length && group === result[index + 1].tipo) {
                    options.push({ value: parseInt(element.id), label: element.nombre, color: element.color });
                }
                else {
                    options.push({ value: parseInt(element.id), label: element.nombre, color: element.color });
                    groupedOptions.push({ label: 'Tipo: ' + group, options: options });
                    options = [];
                }
            });
            setEstatus(groupedOptions);
        });
        // Get Prioridades
        RestClient.GetRequest(AppUrl.Prioridades).then((result) => {
            result.forEach(element => {
                setPrioridades(prevState => ([...prevState, { value: element.id, label: element.nombre, color: element.color }]));
            });
        });
    }, [])

    // Prevent scroll when Modal is open
    useEffect(() => {
        let html = document.querySelector('html');
        isOpen && (html.style.overflowY = 'hidden');
        !isOpen && (html.style.overflowY = 'unset');
    }, [isOpen]);

    // Validate Tarea fields
    useEffect(() => {
        if (titulo && characterCount && characterCount <= 500 && usuario_id && estatus_id && porcentaje >= 0 && porcentaje <= 100 && tiempo.dias >= 0 && tiempo.horas >= 0 && tiempo.minutos >= 0) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [titulo, characterCount, tiempo, usuario_id, estatus_id, porcentaje]);

    // if we have a current Tarea, set field values, else clear them
    useEffect(() => {
        if (currentTarea && activeTab === 'Editar') {
            setTitulo(currentTarea.titulo);
            handleDescripcionText(currentTarea.descripcion);
            setTiempo(currentTarea.tiempo || currentTarea.tiempo_id ? { dias: currentTarea.tiempo.dias, horas: currentTarea.tiempo.horas, minutos: currentTarea.tiempo.minutos } : { dias: 0, horas: 0, minutos: 0 });
            setUsuarioId(parseInt(currentTarea.usuario_id));
            setEstatusId(parseInt(currentTarea.estatus_id));
            setEstatusTipo(currentTarea.estatus_tipo);
            setPrioridadId(parseInt(currentTarea.prioridad_id));
            setPorcentaje(parseInt(currentTarea.porcentaje));
            const user = usuarios.map(u => u.options).flat().find(u => u.value === parseInt(currentTarea.usuario_id));
            setPrevUser({ id: user?.value, name: user?.label });
            toggleModal(true);
        } else if (activeTab === 'Agregar') {
            clearValues();
            toggleModal(true);
        }
    }, [currentTarea, activeTab, usuarios, toggleModal]);

    // Load tab only if we have new historial
    useEffect(() => {
        setTabLoaded(true);
    }, [historiales])

    function handleCloseModal() {
        toggleModal(false);
    }

    function handleConfirmationModal(flag) {
        setConfirmationModal(false);
        submitTarea(flag);
    }

    function handleCloseReopenModal() {
        setReopenModal(false);
    }

    function reopenTarea() {
        setReopenModal(false);
        submitTarea();
    }

    function handleDescripcionText(value) {
        let descripcionPlainText = '';
        let plainTextConcat = "";
        let plainTextArray = JSON.parse(value).map(n => Node.string(n));

        if (plainTextArray.length > 1) {
            plainTextArray.forEach(element => {
                plainTextConcat += element;
            });
            descripcionPlainText += plainTextConcat;
        } else {
            descripcionPlainText += plainTextArray[0];
        }
        setDescripcion(value);
        setCharacterCount(descripcionPlainText.length);
    }

    function renderSelect(value) {
        var values = [];
        for (let index = 0; index < value; index++) {
            values.push(<option key={index} value={index}>{index}</option>);
        }
        return values;
    }

    function clearValues() {
        setTitulo('')
        setDescripcion('');
        setCharacterCount(0);
        setTiempo({ dias: 0, horas: 0, minutos: 0 });
        setUsuarioId(0);
        setEstatusId(0);
        setPrioridadId(0);
        setPorcentaje(0);
    }

    function submitTarea(updatingUserFlag = '') {
        const persona = usuarios.map(u => u.options).flat().find(u => u.value === usuario_id);
        const estatusObj = estatus.map(e => e.options).flat().find(e => e.value === (estatus_tipo === 'completado' ? reopen_estatus : estatus_id));
        const prioridadObj = prioridades.find(p => p.value === prioridad_id);
        const newTarea = {
            titulo: titulo,
            descripcion: descripcion,
            tiempo: tiempo,
            usuario_id: usuario_id,
            nombre_completo: persona.label,
            estatus_id: estatus_tipo === 'completado' ? reopen_estatus : estatus_id,
            estatus_nombre: estatusObj.label,
            estatus_color: estatusObj.color,
            estatus_tipo: estatus_tipo,
            prioridad_id: prioridad_id,
            prioridad_nombre: prioridadObj?.label,
            prioridad_color: prioridadObj?.color,
            porcentaje: porcentaje,
            created_at: currentTarea?.created_at || 'Ahora',
            updated_at: currentTarea?.updated_at || 'Ahora'
        }
        if (currentTarea && activeTab === 'Editar') {
            newTarea.id = currentTarea.id;
            newTarea.tiempo_id = currentTarea.tiempo_id;
        } else {
            handleActiveTab('');
            clearValues();
        }
        setTareas(newTarea, updatingUserFlag);
    }

    function handleHistorialTab(_page = 1) {
        if (page !== _page || activeTab !== 'Historial') {
            handleActiveTab('Historial');
            setTabLoaded(false);
            setPage(_page);
            RestClient.GetRequest(AppUrl.HistorialesByTareaId + currentTarea.id + '/' + _page).then((result) => {
                setHistoriales(result);
            });
            RestClient.GetRequest(AppUrl.HistorialesCountByTareaId + currentTarea.id).then((result) => {
                setCount(result);
            });
        }
    }

    function handleAgregarGuardar() {
        if (prevUser && currentTarea?.created_at !== 'Ahora' && activeTab !== 'Agregar') {
            if (prevUser.id !== usuario_id && !userHasOtherTareas) {
                setConfirmationModal(true);
            } else {
                submitTarea('KEEP_USER');
            }
        } else {
            submitTarea();
        }
    }

    return (
        <>
        <ReactModal
            id='tareasModal'
            closeTimeoutMS={800}
            isOpen={isOpen}
            contentLabel="Default Confirmation Modal"
            style={window.innerWidth < 1025 ? styles.minMedia1024px : styles.maxMedia1024px}
            overlayClassName="Overlay"
            onRequestClose={handleCloseModal}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <div className="flex flex-col items-start h-full md">
                {/* Modal Head */}
                <div className="flex items-center w-full border-b-2">
                    <div className="bg-white">
                        <nav className="flex">
                            <button onClick={() => handleActiveTab('Agregar')} className={`${!isPeticionOwner && 'hidden'} py-4 px-6 block hover:text-accent-1 focus:outline-none ${activeTab === 'Agregar' ? 'text-accent-1 border-b-2 font-medium border-accent-2' : 'text-gray-600'}`}>
                                Agregar Tarea
                            </button><button onClick={() => handleActiveTab('Editar')} className={`${!currentTarea && 'hidden'} py-4 px-6 block hover:text-accent-1 focus:outline-none ${activeTab === 'Editar' ? 'text-accent-1 border-b-2 font-medium border-accent-2' : 'text-gray-600'}`}>
                                Editar Tarea
                            </button><button onClick={() => handleHistorialTab()} className={`${(!currentTarea || currentTarea.created_at === 'Ahora') && 'hidden'} py-4 px-6 block hover:text-accent-1 focus:outline-none ${activeTab === 'Historial' ? 'text-accent-1 border-b-2 font-medium border-accent-2' : 'text-gray-600'}`}>
                                Historial
                            </button>
                        </nav>
                    </div>
                    <button onClick={handleCloseModal} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                        <XIcon className='w-5 h-5' />
                    </button>
                </div>
                {/* Modal Body */}
                {!tabLoaded ? 
                <div className='flex w-full justify-center bg-white'>
                    <Loading />
                </div> :
                activeTab === 'Historial' ?
                <div className='p-4 md:flex-col space-y-4 md:space-y-0 w-full text-gray-500 items-start border-b-2 max-h-[40rem] overflow-y-auto'>
                    <PaginationComponent page={page} count={count} handleFunction={handleHistorialTab} />
                    <DetailsBody _data={historiales} activeTab={'historial'} canEditUser={canEditUser}>
                        <DetailsFooter page={page} count={count} />
                    </DetailsBody>
                </div> :
                <div className="border-b-2 w-full py-4 max-h-[70vh] overflow-y-auto">
                    <form className='mt-4 mx-8 space-y-5'>
                        <div className=''>
                            <div className={`w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group $/* {this.state.selectedNotificacion !== 'new' && 'bg-gray-100'} */`}>
                                <div className="w-1/12 pt-2 flex justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-2' viewBox="0 0 576 512">
                                        <path d="M184.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L39 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L39 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM256 96c0-17.7 14.3-32 32-32H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H288c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H288c-17.7 0-32-14.3-32-32zM192 416c0-17.7 14.3-32 32-32H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32zM80 464c-26.5 0-48-21.5-48-48s21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48z" />
                                    </svg>
                                </div>
                                <div className="relative z-0 w-full group">
                                    <input onChange={(e) => setTitulo(e.target.value)} disabled={estatus_tipo === 'completado' || !isPeticionOwner} value={titulo} id='txtTitulo' type="text" name="nombre" className="disabled:text-gray-500 disabled:cursor-not-allowed block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                    <label htmlFor="txtTitulo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Título</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{/* {this.state.errors?.descripcion} */}</span>
                        </div>
                        <div className=''>
                            <h5 className='mb-2 text-sm text-gray-400 peer-focus-within:text-accent-1'>Descripción</h5>
                            <div className='bg-white px-4 py-2 border-2 rounded border-gray-400 focus-within:border-accent-2 peer'>
                                <TextEditorInput defaultValue={descripcion} disabled={estatus_tipo === 'completado' || !isPeticionOwner} handleDescripcion={(descripcionValue) => handleDescripcionText(descripcionValue)} placeholder='Describe la tarea...' />
                            </div>
                            <p className={`${(estatus_tipo === 'completado' || !isPeticionOwner) ? 'hidden' : 'flex'} w-full justify-end text-xs ${characterCount > 500 && 'text-red-500 font-semibold'} text-gray-400`}>{characterCount}/500</p>
                            <span hidden={characterCount <= 500} className='text-sm text-red-500'>¡Estas sobre el límite de caracteres permitidos!</span>
                            <span className='text-sm text-red-500'>{/* {errors.descripcion} */}</span>
                        </div>
                        <div className=''>
                            <fieldset className='border-2 border-gray-400 rounded'>
                                <legend className='ml-3 px-1 text-sm text-gray-400 flex justify-between items-center gap-2'>
                                    <span>Tiempo Estimado</span>
                                    <ReactTooltip className='tooltip-default' anchorId='tooltipTiempoEstimado' content='Cuantos días, horas y minutos tiene el usuario asignado para completar la tarea. Si no se establece un tiempo, no tendrá vencimiento.' place="top" />
                                    <InformationCircleIcon id='tooltipTiempoEstimado' className='w-5 h-5 text-accent-2'/>
                                </legend>
                                <div className='grid grid-cols-2 md:grid-cols-3 w-full md:p-5 px-5 py-2 gap-y-6'>
                                    <div className='col-span-1'>
                                        <div className='inline-flex items-center w-full gap-4 group'>
                                            <label htmlFor="tarea_dias" className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Dias</label>
                                            <input type="number" id='tarea_dias' disabled={estatus_tipo === 'completado' || !isPeticionOwner} onChange={(e) => setTiempo(prevState => {
                                                let tiempo = Object.assign({}, prevState);
                                                tiempo.dias = parseInt(e.target.value);
                                                return tiempo;
                                            })} className='border-2 border-gray-400 rounded p-1 text-center w-20 focus:outline-none focus:ring-0 focus:border-accent-2' min={0} value={tiempo.dias} required />
                                        </div>
                                        <span className='text-sm text-red-500'>{/* {this.state.errors.respuesta_dias} */}</span>
                                    </div>
                                    <div className='col-span-1'>
                                        <div className='inline-flex items-center w-full gap-4 group'>
                                            <label htmlFor="tarea_horas" className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Horas</label>
                                            <select name="horas" id="tarea_horas" disabled={estatus_tipo === 'completado' || !isPeticionOwner} onChange={(e) => setTiempo(prevState => {
                                                let tiempo = Object.assign({}, prevState);
                                                tiempo.horas = parseInt(e.target.value);
                                                return tiempo;
                                            })} value={tiempo.horas} className='border-2 border-gray-400 rounded p-1 px-4 focus:outline-none focus:ring-0 focus:border-accent-2' required>
                                                {renderSelect(24)}
                                            </select>
                                        </div>
                                        <span className='text-sm text-red-500'>{/* {this.state.errors.respuesta_horas} */}</span>
                                    </div>
                                    <div className='col-span-1'>
                                        <div className='inline-flex items-center w-full gap-4 group'>
                                            <label htmlFor="tarea_minutos" className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Minutos</label>
                                            <select name="minutos" id="tarea_minutos" disabled={estatus_tipo === 'completado' || !isPeticionOwner} onChange={(e) => setTiempo(prevState => {
                                                let tiempo = Object.assign({}, prevState);
                                                tiempo.minutos = parseInt(e.target.value);
                                                return tiempo;
                                            })} value={tiempo.minutos} className='border-2 border-gray-400 rounded p-1 px-4 focus:outline-none focus:ring-0 focus:border-accent-2' required>
                                                {renderSelect(60)}
                                            </select>
                                        </div>
                                        <span className='text-sm text-red-500'>{/* {this.state.errors.resolucion_minutos} */}</span>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div className="md:inline-flex space-y-7 md:space-y-0 w-full py-4 text-gray-500 items-start mb-4 gap-10">
                            <div className="md:w-full space-y-7">
                                <div className=''>
                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                        <div className="w-1/12 items-center flex justify-center">
                                            <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                        </div>
                                        <div className="relative z-0 w-full group">
                                            <Select placeholder='--Seleccionar Usuario--'
                                                isDisabled={estatus_tipo === 'completado' || !isPeticionOwner}
                                                options={usuarios}
                                                menuPortalTarget={document.body}
                                                className='p-1'
                                                onChange={(usuario) => setUsuarioId(usuario.value)}
                                                value={usuario_id &&
                                                    usuarios.map(u => {
                                                        return u.options;
                                                    }).flat().find(u => u.value === usuario_id)}
                                                isLoading={usuarios.length === 0}
                                                styles={{
                                                    option: (base, state) => ({
                                                        ...base,
                                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                                    }),
                                                    menuPortal: base => ({
                                                        ...base, zIndex: 9999
                                                    }),
                                                }}
                                            />
                                            <label htmlFor="selectUsuarios" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Usuario Asignado</label>
                                        </div>
                                    </div>
                                    <span className='text-sm text-red-500'>{/* {errors.vence_en} */}</span>
                                </div>
                                <div className=''>
                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                        <div className="w-1/12 items-center flex justify-center">
                                            <FlagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                        </div>
                                        <div className="relative z-0 w-full group">
                                            <Select placeholder='--Seleccionar Estatus--'
                                                isDisabled={estatus_tipo === 'completado'}
                                                options={estatus}
                                                menuPortalTarget={document.body}
                                                className='p-1'
                                                onChange={(estatus) => setEstatusId(estatus.value)}
                                                value={estatus_id &&
                                                    estatus.map(e => {
                                                        return e.options;
                                                    }).flat().find(e => e.value === estatus_id)}
                                                isLoading={estatus.length === 0}
                                                styles={{
                                                    option: (base, state) => ({
                                                        ...base,
                                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                                    }),
                                                    input: (styles) => ({
                                                        ...styles,
                                                        ...dot()
                                                    }),
                                                    singleValue: (styles, { data }) => ({
                                                        ...styles,
                                                        ...dot(data.color)
                                                    }),
                                                    menuPortal: base => ({
                                                        ...base, zIndex: 9999
                                                    }),
                                                }}
                                            />
                                            <label htmlFor="selectEstatus" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Estatus</label>
                                        </div>
                                    </div>
                                    <span className='text-sm text-red-500'>{/* {errors.vence_en} */}</span>
                                </div>
                            </div>
                            <div className="md:w-full space-y-7">
                                <div className=''>
                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                        <div className="w-1/12 items-center flex justify-center">
                                            <ExclamationCircleIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                        </div>
                                        <div className="relative z-0 w-full group">
                                            <Select placeholder='--Seleccionar Prioridad--'
                                                isDisabled={estatus_tipo === 'completado' || !isPeticionOwner}
                                                options={prioridades}
                                                menuPortalTarget={document.body}
                                                className='p-1'
                                                onChange={(prioridad) => setPrioridadId(prioridad.value)}
                                                value={prioridad_id && prioridades.find(p => p.value === prioridad_id)}
                                                isLoading={prioridades.length === 0}
                                                styles={{
                                                    option: (base, state) => ({
                                                        ...base,
                                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                                    }),
                                                    input: (styles) => ({
                                                        ...styles,
                                                        ...dot()
                                                    }),
                                                    singleValue: (styles, { data }) => ({
                                                        ...styles,
                                                        ...dot(data.color)
                                                    }),
                                                    menuPortal: base => ({
                                                        ...base, zIndex: 9999
                                                    }),
                                                }}
                                            />
                                            <label htmlFor="selectPrioridades" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Prioridad</label>
                                        </div>
                                    </div>
                                    <span className='text-sm text-red-500'>{/* {errors.vence_en} */}</span>
                                </div>
                                <div className=''>
                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                        <div className="w-1/12 items-center flex justify-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-2' viewBox="0 0 384 512">
                                                <path d="M374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l320-320zM128 128c0-35.3-28.7-64-64-64S0 92.7 0 128s28.7 64 64 64s64-28.7 64-64zM384 384c0-35.3-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64s64-28.7 64-64z" />
                                            </svg>
                                        </div>
                                        <div className="relative z-0 w-full group">
                                            <input onChange={(e) => setPorcentaje(e.target.value)} value={porcentaje} disabled={estatus_tipo === 'completado'} type="number" id='numPorcentaje' name='porcentaje' className='block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer' min={0} max={100} required />
                                            <label htmlFor="numPorcentaje" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Porcentaje</label>
                                        </div>
                                    </div>
                                    <span className='text-sm text-red-500'>{/* {errors.vence_en} */}</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>}
                {/* Modal Footer */}
                <div className="flex justify-end w-full mt-4 gap-4">
                    <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-1 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </button>
                    <button onClick={() => setReopenModal(true)} type='button' hidden={estatus_tipo !== 'completado' || activeTab !== 'Editar'} className="disabled:bg-accent-2/70 text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">
                        Reabrir
                    </button>
                    <button onClick={handleAgregarGuardar} type='button' hidden={activeTab === 'Historial' || processingRequest || (activeTab === 'Editar' && estatus_tipo === 'completado')} disabled={!isValid} className="disabled:bg-accent-2/70 text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">
                        {activeTab === 'Agregar' ? 'Agregar' : 'Guardar'}
                    </button>
                    <button onClick={handleCloseModal} type='button' hidden={processingRequest} className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded-lg w-full sm:w-auto">
                        {(activeTab === 'Historial' || estatus_tipo === 'completado')  ? 'Cerrar' : 'Cancelar'}
                    </button>
                </div>
            </div>
        </ReactModal>
        <ReactModal
            closeTimeoutMS={800}
            isOpen={confirmationModal}
            contentLabel="Default Confirmation Modal"
            className='Modal'
            overlayClassName="Overlay"
            onRequestClose={handleCloseModal}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <div className="flex flex-col items-start">
                <div className="flex items-center w-full border-b-2">
                    <div className="text-gray-900 font-medium text-xl">¿Eliminar Usuario de Colaboradores?</div>
                </div>
                <div className="border-b-2 w-full py-4">
                    <p>El usuario <span className='font-semibold'>{prevUser?.name}</span> no tiene otras tareas asignadas en esta petición. ¿Deseas eliminar a <span className='font-semibold'>{prevUser?.name}</span> de Colaboradores de esta Solicitud?</p>
                </div>
                <div className="flex justify-end w-full mt-4 gap-4">
                    <button onClick={() => handleConfirmationModal('REMOVE_USER')} type='button' className="bg-accent-1 hover:bg-accent-2 text-white font-bold py-2 px-4 rounded">
                        Sí
                    </button>
                    <button onClick={() => handleConfirmationModal('KEEP_USER')} type='button' className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                        No
                    </button>
                </div>
            </div>
        </ReactModal>
        <ReactModal
            preventScroll={true}
            closeTimeoutMS={800}
            isOpen={reopenModal}
            contentLabel="Default Confirmation Modal"
            className='Modal'
            overlayClassName="Overlay"
            onRequestClose={handleCloseReopenModal}
            shouldCloseOnOverlayClick={true}
        >
            <div className="flex flex-col items-start">
                <div className="flex items-center w-full border-b-2">
                    <div className="text-gray-900 font-medium text-xl">Reabrir Tarea</div>
                    <button onClick={handleCloseReopenModal} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                        <XIcon className='w-5 h-5' />
                    </button>
                </div>
                <div className="border-b-2 w-full py-4 px-4 space-y-6">
                    <p>Nota: Al reabrir la tarea se le notificará al usuario asignado que se abrió de nuevo. Seleccione un estatus para reabrir la tarea.</p>
                    <div className="w-full inline-flex gap-4 my-4">
                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                            <div className="w-1/12 items-center flex justify-center mx-0.5">
                                <FlagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                            </div>
                            <div className="relative z-0 w-full group">
                                <Select placeholder='--Seleccionar Estatus--'
                                    options={estatus.filter(e => e.label === "Tipo: en progreso")}
                                    menuPortalTarget={document.body}
                                    className='p-1'
                                    onChange={(estatus) => setReopenEstatus(estatus.value)}
                                    isLoading={estatus.length === 0}
                                    styles={{
                                        option: (base, state) => ({
                                            ...base,
                                            backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                        }),
                                        singleValue: (styles, { data }) => ({
                                            ...styles,
                                            ...dot(data.color)
                                        }),
                                        menuPortal: base => ({
                                            ...base,
                                            zIndex: 9999
                                        }),
                                    }}
                                />
                                <label htmlFor="selectEstatus" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Estatus</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end w-full mt-4 gap-4">
                    <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </button>
                    <button onClick={reopenTarea} type='button' hidden={processingRequest} className="bg-accent-1 hover:bg-accent-2 text-white font-bold py-2 px-4 rounded">
                        Guardar
                    </button>
                    <button onClick={handleCloseReopenModal} type='button' hidden={processingRequest} className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                        Cancelar
                    </button>
                </div>
            </div>
        </ReactModal>
        </>
    )
}

export default TareasModal;