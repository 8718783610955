import React from 'react';
import DataTable from 'react-data-table-component';
import { CogIcon, PencilAltIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { AddButton, CustomLoader, DeleteButton, EditButton, FilterComponent, NoDataComponent, customStyles } from '../CustomTableComponents';
import { LinkIcon } from '@heroicons/react/outline';
import { useCustomModal } from '../../hooks/useCustomModal';
import Select from 'react-select';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';

const EstudiantesSelect = ({ data, nivel, onChange }) => {
    const [estudiantes, setEstudiantes] = React.useState([]);
    const [estudianteOptions, setEstudianteOptions] = React.useState([]);
    const [estudiantesLoaded, setEstudiantesLoaded] = React.useState(false);

    React.useEffect(() => {
        if (data) {
            RestClient.GetRequest(AppUrl.Estudiantes).then((result) => {
                setEstudiantes(result);
                let optionsMapped = [];
                const estudiantesSinCobro = result.filter((e) => e.nivel === nivel && !data.some((d) => d.id === e.id));
                estudiantesSinCobro.forEach(element => {
                    optionsMapped.push({ value: element.id, label: `${element.nombre} ${element.apellido}` });
                });
                setEstudianteOptions(optionsMapped);
                setEstudiantesLoaded(true);
            });
        }
    }, [data, nivel]);

    function onSelectChange(values) {
        const selectedEstudiantes = estudiantes.filter((e) => values.some((v) => v.value === e.id));
        onChange(selectedEstudiantes);
    }

    return (
        <Select
            placeholder='--Seleccionar Estudiantes--'
            isMulti
            options={estudianteOptions}
            menuPortalTarget={document.body}
            closeMenuOnSelect={false}
            isLoading={!estudiantesLoaded}
            onChange={onSelectChange}
            noOptionsMessage={() => `No hay estudiantes en ${nivel} sin este cobro`}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
    )
}

const EstudiantesColegiaturasTable = ({ data, nivel, onRemoveEstudiante, onAddEstudiante, canRemoveEstudiantes = false, canCreateUsers = false, canUpdateUsers = false, canUpdateEstudiantes = false }) => {

    const customModal = useCustomModal();

    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [pending, setPending] = React.useState(true);
    const [filteredItems, setFilteredItems] = React.useState([{}]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsDisplayed, setRowsDisplayed] = React.useState(10);

    const modalRef = React.useRef();

    const actions = React.useMemo(() => {
        const addingModal = () => {
            modalRef.current?.show();
        }

        return (
            <AddButton
                label='Cobrar Estudiantes'
                onClick={addingModal}
                icon={<LinkIcon className='w-5 h-5 text-white' />}
                disabled={!canRemoveEstudiantes}
                tooltipContent="'Aplicar Cobro' está marcado. Para agregar estudiantes al cobro de esta colegiatura, desmarca la opción."
            />
        )
    }, [modalRef, canRemoveEstudiantes]);

    const [selectedEstudiantes, setSelectedEstudiantes] = React.useState([]);
    React.useEffect(() => {
        const modalComponent = <EstudiantesSelect data={data} nivel={nivel} onChange={setSelectedEstudiantes} />;

        const _onConfirm = () => {
            onAddEstudiante(selectedEstudiantes);
            setSelectedEstudiantes([]);
        }

        const _onClose = () => {
            setSelectedEstudiantes([]);
        }

        if (!modalRef.current) {
            customModal.create(
                modalRef,
                {
                    title: 'Cobrar Estudiante',
                    body: modalComponent,
                    preventScroll: true,
                    confirmLabel: 'Agregar',
                    onConfirm: _onConfirm,
                    onClose: _onClose,
                    confirmDisabled: selectedEstudiantes.length === 0,
                    shouldCloseOnOverlayClick: false,
                    shouldCloseOnEsc: false
                },
            );
        } else {
            modalRef.current.update({
                body: modalComponent,
                onConfirm: _onConfirm,
                onClose: _onClose,
                confirmDisabled: selectedEstudiantes.length === 0,
            });
        }

    }, [data, customModal, modalRef, onAddEstudiante, selectedEstudiantes, nivel]);

    React.useEffect(() => {
        if (data) {
            const newFilteredItems = data.filter(
                item => (item.nombre && item.nombre.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.apellido && item.apellido.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.email && item.email.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.no_expediente && item.no_expediente.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.nivel && item.nivel.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.grupo && item.grupo.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.estatus_academico && item.estatus_academico.toLowerCase().includes(filterText.toLowerCase()))
            );
            setFilteredItems(newFilteredItems);
            setPending(false);
            setRowsDisplayed(Math.min(rowsPerPage, newFilteredItems.length));
        }
    }, [data, filterText, rowsPerPage]);

    function removingModal(itemId) {
        customModal.show(
            {
                title: 'Remover Estudiante',
                body: '¿Estás seguro de que quieres remover al estudiante del cobro de esta colegiatura?',
                confirmLabel: 'Remover',
                confirmStyle: 'danger',
                onConfirm: () => onRemoveEstudiante(itemId),
            },
        );
    }

    function onChangeRowsPerPage(currentRowsPerPage) {
        setRowsPerPage(currentRowsPerPage);
        if (filterText) {
            setRowsDisplayed(Math.min(currentRowsPerPage, filteredItems.length));
        }
    }

    const columns = [
        {
            cell: row => (
                <>
                    <div className="dropdown relative text-left">
                        <span className="rounded-md shadow-sm">
                            <button className="group max-w-xs rounded-full flex items-center text-sm focus:outline-none" type="button" aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items">
                                <span className="sr-only">Open table menu</span>
                                <CogIcon className='w-5 h-5 text-accent-1 hover:rotate-45 transition duration-300 group-focus:rotate-45' />
                            </button>
                        </span>
                        <div className={`dropdown-menu absolute invisible ${((data.indexOf(row) + 1) >= rowsDisplayed && 'origin-bottom-left') || 'origin-top-left'} -translate-y-2 scale-95 transform opacity-0 transition-all duration-300 z-50`}>
                            <div className={`left-4 translate-x-6 ${((data.indexOf(row) + 1) >= rowsDisplayed && '-translate-y-24 origin-bottom-left') || 'origin-top-left'} mt-2 w-36  divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none z-50`} aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items" role="menu">
                                <div className="py-1">
                                    <EditButton itemId={row.id} route={`/estudiantes/editar/${row.id}`} disabled={!canUpdateEstudiantes} />
                                    <DeleteButton itemId={row.id} onClickDelete={removingModal} label='Remover' disabled={!canRemoveEstudiantes} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ),

            button: true,
            width: '80px'
        },
        {
            name: 'Nombre Completo',
            selector: row => row.nombre + ' ' + row.apellido,
            grow: 1.2,
            sortable: true
        },
        {
            name: 'Usuario',
            selector: row => row.email,
            cell: row => row.usuario_id === null ?
                <div className='text-center mx-5 my-2 space-y-2'>
                    <span className='block italic text-gray-500'>(Aún no cuenta con usuario)</span>
                    {canCreateUsers &&
                        <Link to={'/usuarios/crear-con-estudiante/' + row.id} role='button' className='mx-7 block bg-accent-1 text-white p-2 rounded-xl font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                            Crear Usuario
                        </Link>}
                </div> :
                <div className='flex gap-2 items-center'>
                    {canUpdateUsers &&
                        <Link to={'/usuarios/editar/' + row.usuario_id} title='Editar Usuario'>
                            <PencilAltIcon className='bg-gray-200 p-1 rounded-full h-7 text-accent-1 hover:text-accent-2' />
                        </Link>}
                    <span>{row.email}</span>
                </div>,
            grow: 1.1,
            sortable: true
        },
        {
            name: 'Expediente',
            selector: row => row.no_expediente,
            cell: row => row.no_expediente === null ? <em className='text-gray-500'>(Pendiente)</em> : row.no_expediente,
            grow: 0.5,
            sortable: true
        },
        {
            name: 'Nivel',
            selector: row => row.nivel.toUpperCase(),
            grow: 0.5,
            sortable: true
        },
        {
            name: 'Grupo',
            selector: row => row.grupo,
            grow: 0.5,
            sortable: true
        },
        {
            name: 'Estatus Académico',
            selector: row => row.estatus_academico,
            grow: 0.8,
            sortable: true
        }
    ];

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <div className={`${!canRemoveEstudiantes && 'md:flex-row md:items-center'} flex flex-col items-end w-full justify-between gap-y-3 md:gap-0`}>
                <h5 hidden={canRemoveEstudiantes} className='text-sm text-accent-2 font-semibold italic'>*Nota: 'Aplicar Cobro' está marcado. Para remover estudiantes del cobro de esta colegiatura, desmarca la opción.</h5>
                <div className='inline-flex items-center mb-2 md:mb-0'>
                    <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                </div>
            </div>
        );
    }, [filterText, resetPaginationToggle, canRemoveEstudiantes]);

    return (
        <DataTable
            title='Estudiantes Cobrados'
            columns={columns}
            data={filteredItems}
            actions={actions}
            customStyles={customStyles}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            paginationComponentOptions={{ rowsPerPageText: 'Registros por pagina:', rangeSeparatorText: 'de' }}
            onChangeRowsPerPage={onChangeRowsPerPage}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            striped
            progressPending={pending}
            progressComponent={<CustomLoader />}
            noDataComponent={<NoDataComponent />}
        />
    );
};
export default EstudiantesColegiaturasTable;