import React from "react";
import { useLocation } from "react-router-dom";

const AppSuccessPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const transactionId = queryParams.get("id");

  return (
    <div id="appSuccessPage" className="flex items-center h-screen bg-white">
      <div className="p-4">
        <div className="flex justify-center w-full">
          <svg className="inline-flex w-56 h-56 mb-6" viewBox="0 0 64 64">
            <circle fill={"rgb(209, 250, 229)"} cx={32} cy={32} r={32} />
            <path
              fill={"rgb(16, 185, 129)"}
              d="m28.5 41-8-8 3-3 5 5 12-12 3 3z"
            />
          </svg>
        </div>
        <h1 className="text-2xl text-slate-800 font-bold mb-6 text-center">
          ¡Operación realizada exitosamente!
        </h1>
        <h3
          className="text-lg text-slate-800 font-semibold italic mb-6 text-center"
          hidden={!transactionId}
        >
          Referencia: {transactionId}
        </h3>
      </div>
    </div>
  );
};
export default AppSuccessPage;
