import React, { Component } from 'react'
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import { Link, Navigate } from 'react-router-dom';
import PagosTable from '../../components/pagos/PagosTable';
import { toast } from 'react-toastify';
var CryptoJS = require("crypto-js");

class PagosPage extends Component {

    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        var _moduloPermissions = [];
        if (_usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'pagos') !== undefined) {
            _moduloPermissions.push('create');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'pagos') !== undefined) {
            _moduloPermissions.push('update');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'delete' && ua.modulo_name === 'pagos') !== undefined) {
            _moduloPermissions.push('delete');
        }
        this.state = {
            usersAccess: _usersAccess,
            moduloPermissions: _moduloPermissions,
            dataPagos: null,
            authUser: null,
            paymentSuccessfull: false,
        }
    }

    componentDidMount() {
        // Get Auth User
        RestClient.GetRequest(AppUrl.CurrentUser).then((result) => {
            this.setState({ authUser: result });
        });
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        if (query.get("success")) {
            toast.success("Se registró el pago exitosamente");
            const sessionId = query.get('session_id');
            RestClient.GetRequest(AppUrl.StripePaymentSuccessfull + sessionId).then((result) => {
                if (result?.status === 200) {
                    this.setState({ paymentSuccessfull: true });
                }
            })
        }
        if (query.get("canceled")) {
            toast.info("Pago cancelado");
        }
    }

    componentDidUpdate(_, prevState) {
        if (prevState.authUser !== this.state.authUser || this.state.paymentSuccessfull) {
            if (this.state.authUser) {
                // Get Pagos
                RestClient.GetRequest(AppUrl.PagosUser + this.state.authUser.id).then((result) => {
                    const { pagos } = result;
                    this.setState({ dataPagos: pagos, paymentSuccessfull: false });
                });
            }
        }
    }

    render() {
        if (this.state.usersAccess.find(ua => ua.modulo_name === 'pagos') === undefined) {
            return <Navigate to='/access-denied' />
        }
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60 ml-0' : 'sm:ml-20 ml-0'} duration-300 px-2 sm:pt-24 pt-20 pb-6 sm:px-4 md:px-8 bg-gray-100`}>
                {/* Page Title */}
                <div className='sm:px-0 px-3'>
                    <div className='flex w-full'>
                        <h1 className='sm:text-xl text-lg font-medium'>Pagos</h1>
                    </div>
                    {/* Breadcrum Nav */}
                    <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                        {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                            <>
                                <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                                <span className='text-xs'>/</span>
                            </>}
                        <p className='text-gray-400'>Finanzas</p>
                        <span className='text-xs'>/</span>
                        <Link to='/pagos' className='text-dark-purple font-semibold hover:underline'>Pagos</Link>
                    </div>
                </div>
                {/* Data Table */}
                <div className='w-full flex justify-center sm:mt-6 mt-3'>
                    <div className='w-full flex flex-col justify-center bg-white rounded shadow-lg'>
                        <div className='sm:mt-6 sm:mx-12 mx-0 my-2 bg-gray-100 sm:rounded-none rounded shadow-lg'>
                            <fieldset disabled={this.state.dataPagos === null} className="flex flex-col lg:flex-row w-full lg:items-center p-5 space-y-3 lg:space-y-0">
                                <div className="w-3/5">
                                    <h1 className="font-semibold">Area:</h1>
                                </div>
                                <div className='flex flex-col md:flex-row w-full justify-start xl:max-w-md'>
                                    <div className="w-full inline-flex border-2 rounded bg-white border-gray-400 focus-within:border-accent-2 group">
                                        <div className="md:w-1/12 w-1/5 items-center flex justify-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-2' viewBox="0 0 512 512">
                                                <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" />
                                            </svg>
                                        </div>
                                        <div className="relative z-0 w-full group">
                                            <select id='selectArea' name="area" defaultValue={1} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                <option value="0" disabled className='text-gray-400'>-- Seleccione Área --</option>
                                                <option value="1">Cafetería</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div className='sm:mx-12 mx-0 sm:my-6 my-2'>
                            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                <PagosTable data={this.state.dataPagos} controls={this.state.moduloPermissions} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PagosPage