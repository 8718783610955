import { CalendarIcon, MinusIcon, PaperAirplaneIcon, PlusIcon, SearchIcon, StarIcon } from '@heroicons/react/outline';
import React, { Component } from 'react'
import { Link, Navigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import UsuariosRecordatoriosTable from '../../components/recordatorio-registros/UsuariosRecordatoriosTable';
import TextEditorInput from '../../components/form-controls/TextEditorInput';
import { Node } from 'slate';
import ReactModal from 'react-modal';
import { XIcon } from '@heroicons/react/solid';
import modalForm from '../../custom-css/ModalFormStyles';
import { toast } from 'react-toastify';
import RecordatorioPagoCafeteria from '../../templates/RecordatorioPagoCafeteria.json';
var CryptoJS = require("crypto-js");

ReactModal.setAppElement('#root');

const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <SearchIcon className='w-5 h-5' />
    </components.DropdownIndicator>
);

const Option = (props) => {
    const { searchValue, data } = props;
    const { label, value } = data.matchedField || { label: '', value: '' };
    const regex = new RegExp(searchValue, 'gi');
    const highlightedText = value.replace(regex, (match) => `<span class="bg-[#DEEBFF] rounded-lg">${match}</span>`);
    return (
        <>
            <components.Option {...props} />
            <span hidden={!searchValue} className='text-sm px-3 italic font-medium text-slate-500'>
                Se encontró "{searchValue}" en {label} &nbsp;
                <span hidden={label === 'nombre'} className='not-italic text-accent-1' dangerouslySetInnerHTML={{ __html: highlightedText }}></span>
            </span>
        </>
    )
}

class CrearRecordatorioRegistroPage extends Component {

    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        let today_str = new Date().toLocaleString("en-US", { timeZone: "America/Phoenix" });
        let today = new Date(today_str);
        today = today.setHours(today.getHours() - 7);
        this.state = {
            usersAccess: _usersAccess,
            authUser: null,
            tipo_id: 1, area_id: 1, selectedUsers: [{ userType: '', level: 'all', group: 'all', users: [], removed: [] }], totalUsers: [], titulo: 'Recordatorio de pago', mensaje: '', fechaEnvio: '', email: true,
            today, sendingOption: 'now', addingUser: null, searchValue: '', addModal: false, removedIndex: 0, removedModal: false, characterCount: 0,
            statusResult: 0, processingRequest: false, errors: [{ tipo_id: '', area_id: '', usuarios: '', titulo: '', mensaje: '', fecha_envio: '' }],
            userTypes: [{ value: 'estudiantes', label: 'Estudiantes' }, { value: 'tutores', label: 'Tutores' }],
            niveles: [], grupos: [], filteredGrupos: [], estudiantes: [], tutores: [], searchables: [], tipos: [], areas: [],
        }
    }

    componentDidMount() {
        // Get Auth User
        RestClient.GetRequest(AppUrl.CurrentUser).then((result) => {
            this.setState({ authUser: result });
        });
        // Set default template description
        this.handleMensaje(JSON.stringify(RecordatorioPagoCafeteria));
        // Get Tipos
        RestClient.GetRequest(AppUrl.RecordatorioTipos).then((result) => {
            this.setState({ tipos: result });
        });
        // Get Areas
        RestClient.GetRequest(AppUrl.RecordatorioAreas).then((result) => {
            this.setState({ areas: result });
        });
        // Get Niveles
        RestClient.GetRequest(AppUrl.Niveles).then((result) => {
            this.setState({ niveles: [] });
            result.forEach(element => {
                this.setState(prevState => ({
                    niveles: [...prevState.niveles, { value: element.id, label: element.nombre.charAt(0).toUpperCase() + element.nombre.slice(1) }]
                }));
            });
        });
        // Get Grupos
        RestClient.GetRequest(AppUrl.Grupos).then((result) => {
            var groupedOptions = [];
            result.forEach((grupo) => {
                if (parseInt(grupo.cupos_disponibles) > 0) {
                    var nivel = grupo.nivel;
                    if (!groupedOptions.some(group => group.label === nivel)) {
                        groupedOptions.push({ label: nivel, options: [{ value: grupo.id, label: grupo.nombre, nivel: grupo.nivel, ciclo: grupo.ciclo, cupos: grupo.cupos_disponibles }] });
                    }
                    else {
                        groupedOptions.find(group => group.label === nivel).options.push({ value: grupo.id, label: grupo.nombre, nivel: grupo.nivel, ciclo: grupo.ciclo, cupos: grupo.cupos_disponibles });
                    }
                }

            });
            this.setState({ grupos: groupedOptions, filteredGrupos: groupedOptions });
        });
        // Get Estudiantes
        RestClient.GetRequest(AppUrl.Estudiantes).then((result) => {
            this.setState({ estudiantes: result });
        });
        // Get Tutores
        RestClient.GetRequest(AppUrl.Tutores).then((result) => {
            this.setState({ tutores: result.filter(tutor => tutor.usuario_id !== null) });
        });
    }
    // Prevent scroll when Add Modal is open
    componentDidUpdate() {
        const { addModal } = this.state;
        let html = document.querySelector('html');
        addModal && (html.style.overflowY = 'hidden');
        !addModal && (html.style.overflowY = 'unset');
    }

    handleMensaje = (value) => {
        let descripcionPlainText = '';
        let plainTextConcat = "";
        let plainTextArray = JSON.parse(value).map(n => Node.string(n));

        if (plainTextArray.length > 1) {
            plainTextArray.forEach(element => {
                plainTextConcat += element;
            });
            descripcionPlainText += plainTextConcat;
        } else {
            descripcionPlainText += plainTextArray[0];
        }
        this.setState({ mensaje: value, characterCount: descripcionPlainText.length });
    }

    normalizeString = (string) => {
        string = string || '';
        string = string.toLowerCase();
        string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        string = string.trim();
        return string;
    }

    filterOptions = (candidate, input) => {
        if (input) {
            const { label, matricula, correo } = candidate.data;
            // Prepare search query
            let searchQuery = this.normalizeString(input);
            const foundInLabel = this.normalizeString(label).includes(searchQuery);
            const foundInMatricula = this.normalizeString(matricula).includes(searchQuery);
            const foundInCorreo = this.normalizeString(correo).includes(searchQuery);
            if (foundInLabel) {
                candidate.data.matchedField = { label: 'nombre', value: label };
            } else if (foundInMatricula) {
                candidate.data.matchedField = { label: 'matrícula', value: matricula };
            } else if (foundInCorreo) {
                candidate.data.matchedField = { label: 'correo', value: correo };
            }

            return foundInLabel || foundInMatricula || foundInCorreo;
        }
        return true;
    };

    closeRemovedModal = () => {
        this.setState({ removedModal: false });
    }

    closeAddModal = () => {
        this.setState({ addModal: false, addingUser: null });
    }

    onOpenAddModal = () => {
        const { estudiantes, tutores, totalUsers, selectedUsers } = this.state;
        let searchables = [...estudiantes, ...tutores].filter(s => s.usuario_id !== null && !totalUsers.some(tu => parseInt(tu.id, 10) === s.id && tu.tipo === s.tipo) && !selectedUsers.flatMap(su => su.removed).some(sur => parseInt(sur.id, 10) === s.id && sur.tipo === s.tipo));
        var groupedOptions = [];
        searchables.forEach(element => {
            const { id: value, tipo, nombre, apellido, email: correo } = element;
            if (!groupedOptions.some(o => o.label === tipo)) {
                groupedOptions.push({ label: tipo, options: [{ value, label: nombre + ' ' + apellido, tipo, matricula: tipo === 'estudiantes' ? element.no_expediente : null, correo }] });
            }
            else {
                groupedOptions.find(o => o.label === tipo).options.push({ value, label: nombre + ' ' + apellido, tipo, matricula: tipo === 'estudiantes' ? element.no_expediente : null, correo });
            }
        });
        this.setState({ addModal: true, searchables: groupedOptions });
    }

    onAddUser = () => {
        const { selectedUsers, addingUser, estudiantes, tutores } = this.state;
        const newUser = addingUser.tipo === 'estudiantes' ? estudiantes.find(e => e.id === addingUser.value) : tutores.find(t => t.id === addingUser.value);
        let newSelectedUsers = [];
        if (selectedUsers.some(su => su.userType === 'otros')) {
            const otherUsersIndex = selectedUsers.findIndex(su => su.userType === 'otros');
            const updatedOtherUsers = selectedUsers.find(su => su.userType === 'otros');
            updatedOtherUsers.users.push(newUser);
            newSelectedUsers = [...selectedUsers];
            newSelectedUsers.splice(otherUsersIndex, 1, updatedOtherUsers);
        } else {
            newSelectedUsers = [...selectedUsers.filter(su => su.userType !== ''), { userType: 'otros', level: 'all', group: 'all', users: [newUser], removed: [] }];
            
        }
        const { id, usuario_id, nombre, apellido, tipo, email } = newUser;
        const newTotalUser = {
            id: id + '_o',
            usuario_id,
            nombre,
            apellido,
            tipo,
            email,
            niveles: tipo === 'estudiantes' ? newUser.nivel : [...new Set(estudiantes.filter(e => newUser.estudiantes.some(ue => e.id === ue)).map(e => e.nivel))]
        }

        this.setState(prevState => {
            const updatedTotalUsers = [...prevState.totalUsers, newTotalUser];
            return { addModal: false, selectedUsers: newSelectedUsers, totalUsers: updatedTotalUsers, addingUser: null }
        });
        toast.success('Se agregó el usuario a la lista');
    }

    onRemoveUser = (dataId) => {
        const { selectedUsers } = this.state;
        const id = parseInt(dataId, 10);
        const type = dataId.match(/[a-zA-Z]/)[0] === 't' ? 'tutores' : dataId.match(/[a-zA-Z]/)[0] === 'e' ? 'estudiantes' : 'otros';
        const removingUser = selectedUsers.find(su => su.userType === type).users.find(user => user.id === id);
        const newUsers = selectedUsers.find(su => su.userType === type).users.filter(user => user !== removingUser);

        this.setState(prevState => {
            let updatedSelectedUsers = [...prevState.selectedUsers];
            const updatedTotalUsers = prevState.totalUsers.filter(user => user.id !== dataId);
            const selectedUsersToUpdate = updatedSelectedUsers.find(su => su.userType === type);
            if (selectedUsersToUpdate) {
                selectedUsersToUpdate.users = newUsers;
                selectedUsersToUpdate.removed = [...selectedUsersToUpdate.removed, removingUser];
                if (selectedUsersToUpdate.users.length === 0) {
                    updatedSelectedUsers = updatedSelectedUsers.filter((_, i) => i !== updatedSelectedUsers.indexOf(selectedUsersToUpdate));
                }
            }
            if (updatedSelectedUsers.length === 0) {
                updatedSelectedUsers = [{ userType: '', level: 'all', group: 'all', users: [], removed: [] }];
            }
            return { selectedUsers: updatedSelectedUsers, totalUsers: updatedTotalUsers }
        });
        toast.success('Se removió el usuario de la lista');
    }

    onReAddUser = (index) => {
        const { selectedUsers, removedIndex, estudiantes } = this.state;
        const addingUser = selectedUsers[removedIndex].removed[index];
        const userType = selectedUsers[removedIndex].userType;
        const { id, usuario_id, nombre, apellido, tipo, email } = addingUser;
        const newTotalUser = {
            id: userType === 'estudiantes' ? id + '_e' : userType === 'tutores' ? id + '_t' : id + '_o',
            usuario_id,
            nombre,
            apellido,
            tipo,
            email,
            niveles: tipo === 'estudiantes' ? addingUser.nivel : [...new Set(estudiantes.filter(e => addingUser.estudiantes.some(ue => e.id === ue)).map(e => e.nivel))]
        }

        this.setState(prevState => {
            const updatedSelectedUsers = [...prevState.selectedUsers];
            const updatedTotalUsers = [...prevState.totalUsers, newTotalUser];
            const selectedUsersToUpdate = updatedSelectedUsers[removedIndex];
            if (selectedUsersToUpdate) {
                selectedUsersToUpdate.users = [...selectedUsersToUpdate.users, addingUser];
                selectedUsersToUpdate.removed = selectedUsersToUpdate.removed.filter((_, i) => i !== index);
            }
            return { selectedUsers: updatedSelectedUsers, totalUsers: updatedTotalUsers, removedModal: selectedUsersToUpdate.removed.length !== 0 }
        });
        toast.success('Se agregó nuevamente el usuario a la lista');
    }

    filterUsersByCriteria = (type, value, selectedUser) => {
        const { estudiantes, tutores, selectedUsers } = this.state;
        let newUsers;
        let otherEstudiantes = [], otherTutores = [];
        if (selectedUsers.some(su => su.userType === 'otros')) {
            const otherUsers = selectedUsers.find(su => su.userType === 'otros');
            otherEstudiantes = otherUsers.users.filter(user => user.tipo === 'estudiantes');
            otherTutores = otherUsers.users.filter(user => user.tipo === 'tutores');
        }

        switch (type) {
            case 'userType':
                newUsers = value === 'estudiantes'
                    ? estudiantes.filter(e => e.usuario_id !== null && !otherEstudiantes.some(oe => oe.id === e.id)).filter(estudiante => {
                        if (selectedUser.level === 'all' && selectedUser.group === 'all') return true;
                        const isLevelCustom = selectedUser.level !== 'all';
                        const isGroupCustom = selectedUser.group !== 'all';

                        if (isLevelCustom && isGroupCustom) {
                            return (
                                selectedUser.level.some(l => l.value === estudiante.nivel_id) &&
                                selectedUser.group.some(g => g.value === estudiante.grupo_id)
                            );
                        } else if (isLevelCustom) {
                            return selectedUser.level.some(l => l.value === estudiante.nivel_id);
                        }
                        return selectedUser.group.some(g => g.value === estudiante.grupo_id);
                    })
                    : tutores.filter(t => !otherTutores.some(ot => ot.id === t.id)).filter(tutor => {
                        if (selectedUser.level === 'all' && selectedUser.group === 'all') return true;
                        const isLevelCustom = selectedUser.level !== 'all';
                        const isGroupCustom = selectedUser.group !== 'all';
                        const associatedStudents = estudiantes.filter(e => tutor.estudiantes.some(te => e.id === te));

                        if (isLevelCustom && isGroupCustom) {
                            return (
                                selectedUser.level.some(l => associatedStudents.some(as => l.value === as.nivel_id)) &&
                                selectedUser.group.some(g => associatedStudents.some(as => g.value === as.grupo_id))
                            );
                        } else if (isLevelCustom) {
                            return selectedUser.level.some(l => associatedStudents.some(as => l.value === as.nivel_id));
                        }
                        return selectedUser.group.some(g => associatedStudents.some(as => g.value === as.grupo_id));
                    });
                break;
            case 'level':
                newUsers = selectedUser.userType === 'estudiantes'
                    ? estudiantes.filter(e => e.usuario_id !== null && !otherEstudiantes.some(oe => oe.id === e.id)).filter(estudiante => {
                        if (value === 'all' && selectedUser.group === 'all') return true;
                        const isLevelCustom = value !== 'all';
                        const isGroupCustom = selectedUser.group !== 'all';

                        if (isLevelCustom && isGroupCustom) {
                            return (
                                value.some(l => l.value === estudiante.nivel_id) &&
                                selectedUser.group.some(g => g.value === estudiante.grupo_id)
                            );
                        } else if (isLevelCustom) {
                            return value.some(l => l.value === estudiante.nivel_id);
                        }
                        return selectedUser.group.some(g => g.value === estudiante.grupo_id);
                    })
                    : tutores.filter(t => !otherTutores.some(ot => ot.id === t.id)).filter(tutor => {
                        if (value === 'all' && selectedUser.group === 'all') return true;
                        const isLevelCustom = value !== 'all';
                        const isGroupCustom = selectedUser.group !== 'all';
                        const associatedStudents = estudiantes.filter(e => tutor.estudiantes.some(te => e.id === te));

                        if (isLevelCustom && isGroupCustom) {
                            return (
                                value.some(l => associatedStudents.some(as => l.value === as.nivel_id)) &&
                                selectedUser.group.some(g => associatedStudents.some(as => g.value === as.grupo_id))
                            );
                        } else if (isLevelCustom) {
                            return value.some(l => associatedStudents.some(as => l.value === as.nivel_id));
                        }
                        return selectedUser.group.some(g => associatedStudents.some(as => g.value === as.grupo_id));
                    });
                break;
            case 'group':
                newUsers = selectedUser.userType === 'estudiantes'
                    ? estudiantes.filter(e => e.usuario_id !== null && !otherEstudiantes.some(oe => oe.id === e.id)).filter(estudiante => {
                        if (selectedUser.level === 'all' && value === 'all') return true;
                        const isLevelCustom = selectedUser.level !== 'all';
                        const isGroupCustom = value !== 'all';

                        if (isLevelCustom && isGroupCustom) {
                            return (
                                selectedUser.level.some(l => l.value === estudiante.nivel_id) &&
                                value.some(g => g.value === estudiante.grupo_id)
                            );
                        } else if (isLevelCustom) {
                            return selectedUser.level.some(l => l.value === estudiante.nivel_id);
                        }
                        return value.some(g => g.value === estudiante.grupo_id);
                    })
                    : tutores.filter(t => !otherTutores.some(ot => ot.id === t.id)).filter(tutor => {
                        if (selectedUser.level === 'all' && value === 'all') return true;
                        const isLevelCustom = selectedUser.level !== 'all';
                        const isGroupCustom = value !== 'all';
                        const associatedStudents = estudiantes.filter(e => tutor.estudiantes.some(te => e.id === te));

                        if (isLevelCustom && isGroupCustom) {
                            return (
                                selectedUser.level.some(l => associatedStudents.some(as => l.value === as.nivel_id)) &&
                                value.some(g => associatedStudents.some(as => g.value === as.grupo_id))
                            );
                        } else if (isLevelCustom) {
                            return selectedUser.level.some(l => associatedStudents.some(as => l.value === as.nivel_id))
                        }
                        return value.some(g => associatedStudents.some(as => g.value === as.grupo_id));
                    });
                break;
            default:
                newUsers = selectedUser.userType === 'estudiantes'
                    ? estudiantes.filter(e => e.usuario_id !== null).filter(estudiante => {
                        if (selectedUser.level === 'all' && selectedUser.group === 'all') return true;
                        const isLevelCustom = selectedUser.level !== 'all';
                        const isGroupCustom = selectedUser.group !== 'all';

                        if (isLevelCustom && isGroupCustom) {
                            return (
                                selectedUser.level.some(l => l.value === estudiante.nivel_id) &&
                                selectedUser.group.some(g => g.value === estudiante.grupo_id)
                            );
                        } else if (isLevelCustom) {
                            return selectedUser.level.some(l => l.value === estudiante.nivel_id);
                        }
                        return selectedUser.group.some(g => g.value === estudiante.grupo_id);
                    })
                    : tutores.filter(tutor => {
                        if (selectedUser.level === 'all' && selectedUser.group === 'all') return true;
                        const isLevelCustom = selectedUser.level !== 'all';
                        const isGroupCustom = selectedUser.group !== 'all';
                        const associatedStudents = estudiantes.filter(e => tutor.estudiantes.some(te => e.id === te));

                        if (isLevelCustom && isGroupCustom) {
                            return (
                                selectedUser.level.some(l => associatedStudents.some(as => l.value === as.nivel_id)) &&
                                selectedUser.group.some(g => associatedStudents.some(as => g.value === as.grupo_id))
                            );
                        } else if (isLevelCustom) {
                            return selectedUser.level.some(l => associatedStudents.some(as => l.value === as.nivel_id))
                        }
                        return selectedUser.group.some(g => associatedStudents.some(as => g.value === as.grupo_id));
                    });
                break;
        }

        return newUsers;
    }

    updateSelectedUsers = (index, type, value) => {
        const { selectedUsers, estudiantes, grupos, filteredGrupos } = this.state;
        const selectedUsersCount = selectedUsers.length;
        let newSelectedUsers = [];
        let newFilteredGroups = filteredGrupos;
        /* Adding */
        if (index === selectedUsersCount) {
            newSelectedUsers = [...selectedUsers, { userType: '', level: 'all', group: 'all', users: [], removed: [] }];
        }
        /* Removing */
        else if (index === -1) {
            newSelectedUsers = selectedUsers.filter((_, i) => { return i !== value });
            /* Need to update existing users if 'Others' were removed */
            if (selectedUsers[value].userType === 'otros') {
                newSelectedUsers = newSelectedUsers.map(su =>
                    (su.userType === 'estudiantes' && selectedUsers[value].users.some(u => u.tipo === 'estudiantes')) ||
                        (su.userType === 'tutores' && selectedUsers[value].users.some(u => u.tipo === 'tutores'))
                        ? { ...su, users: this.filterUsersByCriteria(type, value, su), removed: [] }
                        : su
                );
            }
        }
        /* Update existing */
        else {
            newSelectedUsers = selectedUsers.map((su, i) => {
                if (index !== i) return su;

                let newUsers = this.filterUsersByCriteria(type, value, su);

                if (type === 'level') {
                    newFilteredGroups = grupos.filter(g => value !== 'all' ? value.some(l => l.label.toLowerCase() === g.label.toLowerCase()) : g.label);
                }
                return { ...su, [type]: value, users: newUsers, removed: [] };
            });
        }

        const newTotalUsers = newSelectedUsers.flatMap(su => su.users).map(user => {
            const selectedUser = newSelectedUsers.find(su => su.users.includes(user));
            const { id, nombre, apellido, tipo, email, usuario_id } = user;
            return {
                id: selectedUser.userType === 'estudiantes' ? id + '_e' : selectedUser.userType === 'tutores' ? id + '_t' : id + '_o',
                usuario_id,
                nombre,
                apellido,
                tipo,
                email,
                niveles: user.tipo === 'estudiantes' ? user.nivel : [...new Set(estudiantes.filter(e => user.estudiantes.some(ue => e.id === ue)).map(e => e.nivel))]
            };
        });

        this.setState({ selectedUsers: newSelectedUsers, totalUsers: newTotalUsers, filteredGrupos: newFilteredGroups });
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        //Disable controls while request is processed and clear error messages
        this.setState({ 
            processingRequest: true,
            errors: [{ tipo_id: '', area_id: '', usuarios: '', titulo: '', mensaje: '', fecha_envio: '' }],
        });

        const { tipo_id, area_id, totalUsers, titulo, mensaje, sendingOption, fechaEnvio, email, authUser, characterCount } = this.state;
        const usuarios = totalUsers.map((user) => ({ id: user.usuario_id, tipo: user.tipo }));

        const data = new FormData();
        data.append('tipo_id', tipo_id);
        data.append('area_id', area_id);
        data.append('usuarios', JSON.stringify(usuarios));
        data.append('titulo', titulo);
        data.append('mensaje', characterCount > 0 ? mensaje : '');
        data.append('fecha_enviado', sendingOption !== 'now' ? fechaEnvio : '');
        data.append('correo', email ? 1 : 0);
        data.append('usuario_id', authUser.id);

        RestClient.PostRequest(AppUrl.CreateRecordatorioRegistro, data).then((result) => {
            this.setState({ processingRequest: false, statusResult: result.status, errors: result.data });
            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });

    }

    renderUsuarios = (key) => {
        const { selectedUsers, userTypes, niveles, filteredGrupos } = this.state;
        var header = [];
        if (key === 'header') {
            for (let index = 0; index < selectedUsers.length; index++) {
                header.push(<tr key={index} className='bg-accent-2 flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-0'>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Tipo Usuario</th>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Nivel</th>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Grupo</th>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Cantidad</th>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Agregar/Remover</th>
                </tr>);
            }
            return header;
        }
        var body = [];
        if (key === 'body') {
            for (let index = 0; index < selectedUsers.length; index++) {
                body.push(<tr key={index} className='flex flex-col flex-no wrap md:table-row mb-2 md:mb-0'>
                    <td className='border p-1.5 max-h-[54px]'>
                        <Select
                            placeholder='--Seleccionar Tipo Usuario--'
                            options={userTypes.filter(ut => !selectedUsers.some(su => su.userType === ut.value))}
                            value={userTypes.find(ut => ut.value === selectedUsers[index].userType) ?? (selectedUsers[index].userType === 'otros' ? { value: '', label: 'Otros' } : null)}
                            onChange={(ut) => this.updateSelectedUsers(index, 'userType', ut.value)}
                            isDisabled={selectedUsers[index].userType === 'otros'}
                            menuPortalTarget={document.body}
                            className='md:p-1'
                            styles={{
                                option: (base, state) => ({
                                    ...base,
                                    backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                }),
                            }}
                        />
                    </td>
                    <td className='border p-1.5 max-h-[54px]'>
                        {selectedUsers[index].level === 'all' ?
                            <div className='w-full inline-flex justify-center gap-x-3'>
                                <input id={'checkLevels' + index} onChange={() => this.updateSelectedUsers(index, 'level', [])} disabled={!selectedUsers[index].userType || selectedUsers[index].userType === 'otros'} defaultChecked className='accent-accent-2 mx-1 peer' type="checkbox" style={{ transform: 'scale(1.5)' }} />
                                <label htmlFor={'checkLevels' + index} className='text-base peer-disabled:text-gray-400 text-gray-800 text-light font-normal'>Todos los niveles</label>
                            </div> :
                            <Select
                                placeholder='--Seleccionar Nivel(es)--'
                                isMulti
                                options={niveles}
                                value={selectedUsers[index].level}
                                onChange={(nivel) => this.updateSelectedUsers(index, 'level', nivel.length === niveles.length ? 'all' : nivel)}
                                isLoading={niveles.length === 0}
                                menuPortalTarget={document.body}
                                className='md:p-1'
                                styles={{
                                    option: (base, state) => ({
                                        ...base,
                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                    }),
                                }}
                            />}
                    </td>
                    <td className='border p-1.5 max-h-[54px]'>
                        {selectedUsers[index].group === 'all' ?
                            <div className='w-full inline-flex justify-center gap-x-3'>
                                <input id={'checkGroups' + index} onChange={() => this.updateSelectedUsers(index, 'group', [])} disabled={!selectedUsers[index].userType || selectedUsers[index].userType === 'otros'} defaultChecked className='accent-accent-2 mx-1 peer' type="checkbox" style={{ transform: 'scale(1.5)' }} />
                                <label htmlFor={'checkGroups' + index} className='text-base peer-disabled:text-gray-400 text-gray-800 text-light font-normal'>Todos los grupos</label>
                            </div> :
                            <Select
                                placeholder='--Seleccionar Grupo(s)--'
                                isMulti
                                options={filteredGrupos}
                                value={selectedUsers[index].group}
                                onChange={(group) => this.updateSelectedUsers(index, 'group', group.length === filteredGrupos.map(g => g.options).flat().length ? 'all' : group)}
                                isLoading={filteredGrupos.length === 0}
                                menuPortalTarget={document.body}
                                className='md:p-1'
                                styles={{
                                    option: (base, state) => ({
                                        ...base,
                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                    }),
                                }}
                            />}
                    </td>
                    <td className='border p-1.5 max-h-[54px]'>
                        <p hidden={!selectedUsers[index].userType} className='text-center'>{`${selectedUsers[index].users.length} ${selectedUsers[index].userType}`}</p>
                        <button type='button' onClick={() => this.setState({ removedModal: true, removedIndex: index })} className={`${!selectedUsers[index].removed.length ? 'hidden' : 'block'} mx-auto underline font-semibold italic`}>{`${selectedUsers[index].removed.length} removido(s)`}</button>
                    </td>
                    <td className='border p-2 max-h-[54px]'>
                        <div className='flex w-full justify-center gap-4'>
                            <button type='button' onClick={() => this.updateSelectedUsers((index + 1), '', '')} disabled={userTypes.length === 0 || (selectedUsers.some(su => su.userType === 'otros') ? selectedUsers.length > userTypes.length : selectedUsers.length === userTypes.length)} className='disabled:opacity-70 disabled:hover:bg-accent-2 bg-accent-2 p-2 rounded hover:bg-blue-600'>
                                <PlusIcon className='text-white h-5' />
                            </button>
                            <button type='button' disabled={selectedUsers.length === 1} onClick={() => this.updateSelectedUsers(-1, '', index)} className='disabled:opacity-70 disabled:hover:bg-accent-2 bg-accent-2 p-2 rounded hover:bg-blue-600'>
                                <MinusIcon className='text-white h-5' />
                            </button>
                        </div>
                    </td>
                </tr>);
            }
            return body;
        }
    }

    render() {
        if (this.state.usersAccess.find(ua => ua.modulo_name === 'recordatorios' && ua.permiso_name === 'create') === undefined) {
            return <Navigate to='/access-denied' />
        }
        if (this.state.statusResult === 200) {
            return <Navigate to='/recordatorio-registros' />
        }
        return (
            <>
                <div className={`w-auto min-h-screen ${this.props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60 ml-0' : 'sm:ml-20 ml-0'} duration-300 px-2 sm:pt-24 pt-20 pb-6 sm:px-4 md:px-8 bg-gray-100`}>
                    <div className='sm:px-0 px-3'>
                        {/* Page Title */}
                        <div className='flex w-full'>
                            <h1 className='sm:text-xl text-lg font-medium'>Crear Recordatorio</h1>
                        </div>
                        {/* Breadcrum Nav */}
                        <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                            {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                                <>
                                    <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                                    <span className='text-xs'>/</span>
                                </>}
                            <p className='text-gray-400'>Automatización</p>
                            <span className='text-xs'>/</span>
                            <Link to='/recordatorio-registros' className='font-semibold hover:underline'>Recordatorios</Link>
                            <span className='text-xs'>/</span>
                            <p className='text-dark-purple font-semibold'>Crear Recordatorio</p>
                        </div>
                    </div>
                    <div className='w-full flex justify-center mt-6'>
                        <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                            <div className='sm:w-11/12 w-full sm:my-8'>
                                <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                    <div className='bg-gray-50'>
                                        <div className='bg-white border'>
                                            <h1 className='text-accent-1 sm:text-2xl text-xl p-4'>Enviar Recordatorio</h1>
                                        </div>
                                        <form onSubmit={this.onFormSubmit} className='mt-4'>
                                            <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                                <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Tipo</h2>
                                                <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                    <div className=''>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group my-1">
                                                            <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                <StarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <select id='selectTipo' onChange={(e) => this.setState({ tipo_id: e.target.value })} name="tipo" defaultValue={this.state.tipo_id} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                    <option value="0" disabled className='text-gray-400'>-- Seleccione Tipo --</option>
                                                                    {this.state.tipos.map(tipo => (
                                                                        <option key={tipo.id} value={tipo.id}>{tipo.nombre}</option>
                                                                    ))}
                                                                </select>
                                                                <label htmlFor="selectTipo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Tipo</label>
                                                            </div>
                                                        </div>
                                                        <span className='text-sm text-red-500'>{this.state.errors.tipo_id}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                            <div className="w-1/12 items-center flex justify-center">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-2 mx-0.5' viewBox="0 0 512 512">
                                                                    <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" />
                                                                </svg>
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <select id='selectArea' onChange={(e) => this.setState({ area_id: e.target.value })} name="area" defaultValue={this.state.area_id} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                    <option value="0" disabled className='text-gray-400'>-- Seleccione Área --</option>
                                                                    {this.state.areas.map(area => {
                                                                        if (area.tipos.some(t => t.id === this.state.tipo_id)) {
                                                                            return (
                                                                                <option key={area.id} value={area.id}>{area.nombre}</option>
                                                                            )
                                                                        }
                                                                        return null;
                                                                    })}
                                                                </select>
                                                                <label htmlFor="selectArea" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Área</label>
                                                            </div>
                                                        </div>
                                                        <span className='text-sm text-red-500'>{this.state.errors.area_id}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="space-y-4 md:space-y-0 w-full p-4 text-gray-800 items-center mb-4">
                                                <h2 className="text-accent-1 font-semibold">Información Usuarios</h2>
                                                <div className='md:px-5 md:container'>
                                                    <table id='tblCondiciones' className='hidden md:inline-table w-full bg-white rounded-lg overflow-hidden md:shadow-md my-5'>
                                                        <thead className='text-white'>
                                                            {this.renderUsuarios('header')}
                                                        </thead>
                                                        <tbody className='flex-1 md:flex-none'>
                                                            {this.renderUsuarios('body')}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className='md:px-5'>
                                                    <UsuariosRecordatoriosTable data={this.state.totalUsers} onRemoveUser={this.onRemoveUser} onAdd={this.onOpenAddModal} title={`El recordatorio será enviado a los siguientes ${this.state.totalUsers.length} usuario(s)`} />
                                                    <span className='text-sm text-red-500'>{this.state.errors.usuarios}</span>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="md:flex-col space-y-4 w-full p-8 md:px-20  text-gray-500 items-center mb-4">
                                                <h5 className='text-accent-1 mb-8 max-w-sm'>Información Recordatorio</h5>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group bg-white">
                                                        <div className="w-1/12 flex justify-center items-center ml-1">
                                                            <PaperAirplaneIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => this.setState({ titulo: e.target.value })} defaultValue={this.state.titulo} id='txtTitulo' type="text" name="titulo" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtTitulo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Título</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.titulo}</span>
                                                </div>
                                                <div className=''>
                                                    <h5 className='mb-2 text-sm text-gray-400 peer-focus-within:text-accent-1'>Mensaje</h5>
                                                    <div className='bg-white px-4 py-2 border-2 rounded border-gray-400 focus-within:border-accent-2 peer'>
                                                        <TextEditorInput defaultValue={this.state.mensaje} placeholder='El mensaje dentro del recordatorio...' handleDescripcion={this.handleMensaje} />
                                                    </div>
                                                    <p className={`flex w-full justify-end text-xs ${this.state.characterCount > 500 && 'text-red-500 font-semibold'} text-gray-400`}>{this.state.characterCount}/500</p>
                                                    <span hidden={this.state.characterCount <= 500} className='text-sm text-red-500'>¡Estas sobre el límite de caracteres permitidos!</span>
                                                    <span className='text-sm text-red-500'>{this.state.errors.mensaje}</span>
                                                </div>
                                                <div className='border rounded bg-white p-3'>
                                                    <fieldset className="md:inline-flex justify-around items-center w-full md:py-5 space-y-2">
                                                        <span className="block text-left mb-4 md:mb-0">Enviar recordatorio:</span>
                                                        <div className="pl-4">
                                                            <div className="flex items-center gap-x-2">
                                                                <input type="radio" onClick={(e) => this.setState({ sendingOption: e.currentTarget.value })} name="enviarRadios" id="rdNow" value="now" defaultChecked />
                                                                <label htmlFor="rdNow">
                                                                    Inmediato
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="pl-4">
                                                            <div className="flex items-center gap-x-2">
                                                                <input type="radio" onClick={(e) => this.setState({ sendingOption: e.currentTarget.value })} name="enviarRadios" id="rdLater" value="later" />
                                                                <label htmlFor="rdLater">
                                                                    Programado
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <div hidden={this.state.sendingOption === 'now'} className='pt-8 pb-4 md:px-6 lg:px-8 px-2'>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 bg-white group">
                                                            <div className="w-1/12 pt-2 flex justify-center">
                                                                <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input onChange={(e) => this.setState({ fechaEnvio: e.target.value })} id='dateFechaEnvio' type="datetime-local" name="fecha_envio" min={new Date(this.state.today).toISOString().slice(0, new Date(this.state.today).toISOString().lastIndexOf(":"))} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required={this.state.sendingOption !== 'now'} />
                                                                <label htmlFor="dateFechaEnvio" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Fecha de envío</label>
                                                            </div>
                                                        </div>
                                                        <span className='text-sm text-red-500'>{this.state.errors.fecha_envio}</span>
                                                    </div>
                                                </div>
                                                <div className='px-3 mt-4'>
                                                    <div className='inline-flex gap-3'>
                                                        <input id='checkEmail' onChange={() => this.setState(prevState => ({ email: !prevState.email }))} defaultChecked className='accent-accent-2 peer' type="checkbox" style={{ transform: 'scale(1.5)' }} />
                                                        <label htmlFor='checkEmail' className='text-base peer-disabled:text-gray-400 text-gray-800 text-light font-normal'>También enviar al correo</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className='p-4 flex justify-center gap-4 bg-white'>
                                                <button hidden={!this.state.processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-4/5 sm:w-1/6 px-5 py-2.5 text-center'>
                                                    <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                </button>
                                                <button type="submit" disabled={this.state.characterCount > 500} hidden={this.state.processingRequest} className="disabled:bg-accent-2/70 text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                                <Link to='/recordatorio-registros' hidden={this.state.processingRequest} className={`${!this.state.processingRequest && 'flex'} justify-center items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Removed Users Modal */}
                <ReactModal
                    closeTimeoutMS={800}
                    isOpen={this.state.removedModal}
                    contentLabel="Default Confirmation Modal"
                    style={window.innerWidth < 1025 ? modalForm.minMedia1024px : modalForm.maxMedia1024px}
                    overlayClassName="Overlay"
                    onRequestClose={this.closeRemovedModal}
                    shouldCloseOnOverlayClick={true}
                >
                    <div className="flex flex-col items-start w-full h-full">
                        {/* Header */}
                        <div className="flex items-center w-full border-b-2">
                            <div className="text-gray-900 font-medium text-xl capitalize">{this.state.selectedUsers[this.state.removedIndex].userType} removidos</div>
                            <button onClick={this.closeRemovedModal} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                                <XIcon className='w-5 h-5' />
                            </button>
                        </div>
                        {/* Body */}
                        <div className="flex-1 border-b-2 py-4 w-full overflow-y-auto">
                            <table id='tblCondiciones' className='hidden md:inline-table w-full bg-white rounded-lg overflow-hidden md:shadow-md my-5'>
                                <thead className='text-white'>
                                    <tr className='bg-accent-2 flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-0'>
                                        <th className="border-b-2 md:border-none p-3.5 text-left">Nombre</th>
                                        <th className="border-b-2 md:border-none p-3.5 text-left">Correo</th>
                                        <th className="border-b-2 md:border-none p-3.5 text-left">Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.selectedUsers[this.state.removedIndex].removed.map((user, i) => (
                                        <tr key={user.id} className='flex flex-col flex-no wrap md:table-row mb-2 md:mb-0'>
                                            <td className='border p-1.5 max-h-[54px]'>{user.nombre + ' ' + user.apellido}</td>
                                            <td className='border p-1.5 max-h-[54px]'>{user.email}</td>
                                            <td className='border p-1.5 max-h-[54px]'>
                                                <button type='button' onClick={() => this.onReAddUser(i)} className='bg-accent-2 rounded-full block mx-auto p-2.5' title='Volver Agregar'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className='fill-white'>
                                                        <path d="M125.7 160H176c17.7 0 32 14.3 32 32s-14.3 32-32 32H48c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32s32 14.3 32 32v51.2L97.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L125.7 160z" />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* Footer */}
                        <div className="flex justify-end w-full mt-4 gap-4">
                            <button hidden={!this.state.processingRequest} type='button' disabled className='text-white bg-red-500 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </button>
                            <button onClick={this.closeRemovedModal} type='button' hidden={this.state.processingRequest} className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                                Cerrar
                            </button>
                        </div>
                    </div>
                </ReactModal>
                {/* Adding User Modal */}
                <ReactModal
                    closeTimeoutMS={800}
                    isOpen={this.state.addModal}
                    contentLabel="Default Confirmation Modal"
                    className='Modal'
                    overlayClassName="Overlay"
                    onRequestClose={this.closeAddModal}
                    shouldCloseOnOverlayClick={true}
                >
                    <div className="flex flex-col items-start w-full h-full">
                        {/* Header */}
                        <div className="flex items-center w-full border-b-2">
                            <div className="text-gray-900 font-medium text-xl capitalize">Agregar Usuario</div>
                            <button onClick={this.closeAddModal} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                                <XIcon className='w-5 h-5' />
                            </button>
                        </div>
                        {/* Body */}
                        <div className="flex-1 border-b-2 py-4 w-full overflow-y-auto">
                            <h3>Busqueda de usuarios</h3>
                            <Select
                                placeholder='Busqueda por nombre, matrícula o correo'
                                options={this.state.searchables}
                                onChange={(user) => this.setState({ addingUser: user })}
                                onInputChange={(value) => this.setState({ searchValue: value })}
                                components={{
                                    DropdownIndicator,
                                    Option: (props) => <Option {...props} searchValue={this.state.searchValue} />
                                }}
                                menuPortalTarget={document.body}
                                className='md:p-1'
                                filterOption={this.filterOptions}
                                styles={{
                                    option: (base, state) => ({
                                        ...base,
                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                    }),
                                    menuPortal: base => ({
                                        ...base, zIndex: 9999
                                    }),
                                }}
                            />
                        </div>
                        {/* Footer */}
                        <div className="flex justify-end w-full mt-2 gap-4">
                            <button hidden={!this.state.processingRequest} type='button' disabled className='text-white bg-red-500 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </button>
                            <button onClick={this.onAddUser} type='button' hidden={this.state.processingRequest} disabled={!this.state.addingUser} className="disabled:bg-accent-2/70 text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-4 py-2 text-center">
                                Agregar
                            </button>
                            <button onClick={this.closeAddModal} type='button' hidden={this.state.processingRequest} className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded-lg w-full sm:w-auto">
                                Cerrar
                            </button>
                        </div>
                    </div>
                </ReactModal>
            </>
        )
    }
}

export default CrearRecordatorioRegistroPage