import { BriefcaseIcon, UserGroupIcon as Group } from '@heroicons/react/outline';
import { ChevronDownIcon, InformationCircleIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import Select from 'react-select';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Modulos } from './CrearRolPage';
var CryptoJS = require("crypto-js");

// Create a constructor for functional component to get permissions
const useConstructor = (callBack = () => {}) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
}

//This has to be a functional component to use 'useParams()' from react-router-dom
const EditarRolPage = (props) => {

    const [nombre, setNombre] = useState('');
    const [permisos, setPermisos] = useState([]);
    const [selectedDepts, setSelectedDepts] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [allDepts, setAllDepts] = useState(true);
    const [tutor, setTutor] = useState(false);
    const [statusResult, setStatusResult] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [processingRequest, setProcessingRequest] = useState(false);
    const [errors, setErrors] = useState([{nombre: '', permisos: '',selected_deptos: ''}]);
    const [automatizacionCollapsed, setAutomatizacionCollapsed] = useState(false);
    const [personalizacionCollapsed, setPersonalizacionCollapsed] = useState(false);
    const [adminCollapsed, setAdminCollapsed] = useState(false);
    const [personalCollapsed, setPersonalCollapsed] = useState(false);
    const [escuelasCollapsed, setEscuelasCollapsed] = useState(false);
    const [finanzasCollapsed, setFinanzasCollapsed] = useState(false);
    const [usersAccess, setUsersAccess] = useState([{}]);

    //Get the passed :id from parent and pass it to the component to render it
    const rol = useParams();

    /// Decript permissions
    useConstructor(() => {
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUsersAccess(_usersAccess);
    });

    // Get Role by Id
    useEffect(() => {
        RestClient.GetRequest(AppUrl.RoleById + rol.id).then((result) => {
            setNombre(result.nombre);
            result.modulos_permisos.forEach(mp => {
                setPermisos(prevState => [...prevState, {'modulo': parseInt(mp.pivot.modulo_id), 'permiso': parseInt(mp.pivot.permiso_id)}]);
            });
            result.departamentos.forEach(d => {
                setSelectedDepts(prevState => [...prevState, d.id]);
            });
            setTutor(result.departamentos.length === 0);
            setLoaded(true);
        });
        // Load Departamentos
        RestClient.GetRequest(AppUrl.Departamentos).then((result) => {
            result.forEach(element => {
                setDepartamentos(prevState => ([...prevState, { value: element.id, label: element.nombre }]));
            });
        });
    }, [rol.id]);
    // UseEffect to determine if role has all depts asigned to it
    useEffect(() => {
      setAllDepts(selectedDepts.length === departamentos.length);
    }, [selectedDepts, departamentos])
    

    function handleCollapse(value) {
        switch (value) {
            case 'personalizacion':
                setPersonalizacionCollapsed(!personalizacionCollapsed);
                break;
            case 'admin':
                setAdminCollapsed(!adminCollapsed);
                break;
            case 'personal':
                setPersonalCollapsed(!personalCollapsed);
                break;
            case 'escuelas':
                setEscuelasCollapsed(!escuelasCollapsed);
                break;
            case 'automatizacion':
                setAutomatizacionCollapsed(!automatizacionCollapsed);
                break;
            case 'finanzas':
                setFinanzasCollapsed(!finanzasCollapsed);
                break;
            default:
                break;
        }
    }

    function handleCheck(checkbox) {
        // Get modulo and permission from check id
        var id = checkbox.id.split('_');
        var key = id[0];
        var permission = parseInt(id[1]);
        // reason: We need to compare two literal values
        // eslint-disable-next-line
        var modulo = Modulos.find(m => m.key == key);
        // If we did not find it, search in submodulos
        var parent = undefined;
        if (modulo === undefined) {
            var filtered = Modulos.filter(m => { return m.isCollapsable });
            filtered.forEach(mod => {
                // reason: We need to compare two literal values
                // eslint-disable-next-line
                var submodulo = mod.submodulos.find(s => s.key == key);
                if (submodulo !== undefined) {
                    parent = mod;
                    modulo = submodulo;
                }
            });
        }
        // Check if checkbox is checked
        if (checkbox.checked) {
            // Check if we have checked a parent Modulo
            if (modulo.isCollapsable) {
                modulo.submodulos.forEach(sub => {
                    var permissionToCheck = permisos.find(p => p.modulo === parseInt(sub.key) && p.permiso === permission);
                    var readPermissionCheck = permisos.find(p => p.modulo === parseInt(sub.key) && p.permiso === 2);
                    if (!permisos.includes(permissionToCheck) && !permisos.includes(readPermissionCheck) && permission !== 2) {
                        setPermisos(prevState => [...prevState, {'modulo': parseInt(sub.key), 'permiso': 2}, {'modulo': parseInt(sub.key), 'permiso': permission}]);
                        document.getElementById(modulo.key + '_2').checked = true;
                    }
                    else if (!permisos.includes(permissionToCheck)) {
                        setPermisos(prevState => [...prevState, {'modulo': parseInt(sub.key), 'permiso': permission}]);
                    }
                });
            }
            else {   
                var permissionToCheck = permisos.find(p => p.modulo === parseInt(modulo.key) && p.permiso === permission);
                var readPermissionCheck = permisos.find(p => p.modulo === parseInt(modulo.key) && p.permiso === 2);
                if (!permisos.includes(permissionToCheck) && !permisos.includes(readPermissionCheck) && permission !== 2) {
                    setPermisos(prevState => [...prevState, {'modulo': parseInt(modulo.key), 'permiso': 2}, {'modulo': parseInt(modulo.key), 'permiso': permission}]);
                    document.getElementById(modulo.key + '_2').checked = true;
                }
                else if (!permisos.includes(permissionToCheck)) {
                    setPermisos(prevState => [...prevState, {'modulo': parseInt(modulo.key), 'permiso': permission}]);
                }
                if (parent !== undefined) {
                    var submodulosLength = parent.submodulos.length - 1;
                    var count = 0;
                    parent.submodulos.forEach(sub => {
                        if (permisos.some(p => p.modulo === sub.key && p.permiso === permission)) {
                            count++;
                        }
                    });
                    if (submodulosLength === count) {
                        document.getElementById(parent.key + '_' + permission).checked = true;
                    }
                }
            }
        }
        else {
            if (modulo.isCollapsable) {
                modulo.submodulos.forEach(sub => {
                    var permisoToRemove = permisos.find(p => p.modulo === parseInt(sub.key) && p.permiso === permission);
                    // Check if we have removed 'Read' Permission
                    if (permisoToRemove.permiso === 2) {
                        setPermisos(prevState => prevState.filter(p => p.modulo !== sub.key));
                        
                        for (let index = 1; index < 5; index++) {
                            document.getElementById(sub.key + '_' + index).checked = false;
                            document.getElementById(modulo.key + '_' + index).checked = false;
                        }
                    }
                    else {
                        setPermisos(prevState => prevState.filter(p => p !== permisoToRemove));
                    }
                });
            }
            else {
                var permisoToRemove = permisos.find(p => p.modulo === parseInt(key) && p.permiso === permission);
                // Check if we have removed 'Read' Permission
                if (permisoToRemove.permiso === 2) {
                    setPermisos(permisos.filter(p => p.modulo !== modulo.key));
                    for (let index = 1; index < 5; index++) {
                        document.getElementById(modulo.key + '_' + index).checked = false;
                    }
                }
                else {
                    setPermisos(permisos.filter(function(permiso) { 
                        return permiso !== permisoToRemove;
                    }));
                } 
                if (parent !== undefined) {
                    document.getElementById(parent.key + '_' + permission).checked = false;
                }
            }
        }
    }

    function handleParentCheck(parentKey, permiso) {
        var parent = Modulos.find(m => m.key === parentKey);
        var submodulosLength = parent.submodulos.length;
        var count = 0;
        parent.submodulos.forEach(sub => {
            if (permisos.find(p => p.modulo === sub.key && p.permiso === permiso) !== undefined) {
                count++;
            }
        });
        if (submodulosLength === count) {
            return true;
        }
    }

    function onFormSubmit(e) {
        e.preventDefault();
        //Disable controls while request is processed
        setProcessingRequest(true);
        setErrors([{nombre: '', permisos: '', selected_deptos: ''}]);

        const data = new FormData();
        data.append('nombre', nombre);
        data.append('permisos', JSON.stringify(permisos));
        data.append('selected_deptos', tutor ? [] : allDepts ? departamentos.map(d => {return d.value}) : selectedDepts);
        data.append('tutor', tutor);

        RestClient.PostRequest(AppUrl.UpdateRol + rol.id, data).then((result) => {
            console.log(result);
            setStatusResult(result.status);
            setProcessingRequest(false);
            setErrors(result.data);
            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }
    
    if (usersAccess.find(ua => ua.modulo_name === 'roles' && ua.permiso_name === 'update') === undefined) {
        return <Navigate to='/access-denied' />
    }
    if (statusResult === 200) {
        return <Navigate to='/roles' />
    }
    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'ml-80' : 'ml-20'} duration-300 p-12 pt-24 pr-16 bg-gray-100`}>
            {/* Page Title */}
            <div className='flex w-full'>
                <h1 className='text-xl font-medium'>Editar Rol</h1>
            </div>
            {/* Breadcrum Nav */}
            <div className='flex w-full text-sm text-gray-500 mt-2 space-x-1'>
                {usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                    <>
                        <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                        <span className='text-xs'>/</span>
                    </>}
                <p className='text-gray-400'>Admin</p>
                <span className='text-xs'>/</span>
                <Link to='/roles' className='font-semibold hover:underline'>Roles</Link>
                <span className='text-xs'>/</span>
                <p className='text-dark-purple font-semibold'>Editar Rol</p>
            </div>
            {/* Main Section */}
            <div className='w-full flex justify-center mt-6'>
                <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                    <div className='w-11/12 mt-8 mb-8'>
                        <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                            <div className='bg-gray-50'>
                                <div className='bg-white border'>
                                    <h1 className='text-accent-1 text-2xl p-4'>Editar Rol</h1>
                                </div>
                                {!loaded ?
                                    <div className='flex w-full justify-center bg-white'>
                                        <Loading />
                                    </div> :
                                <form onSubmit={onFormSubmit} className='mt-4'>
                                    <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                        <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Rol</h2>
                                        <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                            <div className=''>
                                                <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                    <div className="w-1/12 pt-2 flex justify-center">
                                                        <BriefcaseIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                    </div>
                                                    <div className="relative z-0 w-full group">
                                                        <input onChange={(e) => setNombre(e.target.value)} id='txtNombre' defaultValue={nombre} type="text" name="nombre" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                        <label htmlFor="txtNombre" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nombre</label>
                                                    </div>
                                                </div>
                                                <span className='text-sm text-red-500'>{errors.nombre}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="md:inline-flex flex-col space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mt-4 mb-4">
                                        <div className="md:w-full mx-auto space-y-8">                                                
                                            <section className="relative">
                                                <div className="w-full mb-8 px-4">
                                                    <span className='text-sm text-red-500'>{errors.permisos}</span>
                                                    <div className="relative mt-5 flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-accent-2 text-white">
                                                        <div className="rounded-t mb-0 px-4 py-3 border-0">
                                                            <div className="flex flex-wrap items-center">
                                                                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                                                    <h3 className="font-semibold text-lg text-white">Información Permisos</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="block w-full overflow-x-auto ">
                                                            <table className="items-center w-full bg-transparent border-collapse">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-800 text-red-200 border-pink-700">Modulo</th>
                                                                        <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-800 text-red-200 border-pink-700">Consultar</th>
                                                                        <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-800 text-red-200 border-pink-700">Crear</th>
                                                                        <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-800 text-red-200 border-pink-700">Actualizar</th>
                                                                        <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-800 text-red-200 border-pink-700">Borrar</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Modulos.map((modulo, index) => (
                                                                        <>
                                                                            <tr key={index}>
                                                                                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center -mr-12"> 
                                                                                    {modulo.isCollapsable === true ? 
                                                                                    <button type='button' className='inline-flex items-center' onClick={() => handleCollapse(modulo.value)} >
                                                                                        {modulo.icon}
                                                                                        <span className="ml-3 font-semibold text-white"> {modulo.title} </span>
                                                                                        <ChevronDownIcon className={`${(
                                                                                            modulo.title === 'Automatización' ? automatizacionCollapsed :
                                                                                            modulo.title === 'Personalización' ? personalizacionCollapsed :
                                                                                            modulo.title === 'Admin' ? adminCollapsed : 
                                                                                            modulo.title === 'Personal' ? personalCollapsed :
                                                                                            modulo.title === 'Escuelas' ? escuelasCollapsed :
                                                                                            modulo.title === 'Finanzas' ? finanzasCollapsed : false) && 'rotate-180'} duration-300 h-5 ml-3`} />
                                                                                    </button> :
                                                                                    <>
                                                                                        {modulo.icon}
                                                                                        <span className="ml-3 font-semibold text-white"> {modulo.title} </span>
                                                                                    </>
                                                                                    }
                                                                                </th>
                                                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-8">
                                                                                    <input onChange={(e) => handleCheck(e.target)} disabled={!modulo.permissions.some(p => p === 'read')} defaultChecked={typeof(modulo.key) === 'string' ? handleParentCheck(modulo.key, 2) : permisos.find(p => p.modulo === modulo.key && p.permiso === 2) !== undefined} className='ml-7 accent-pink-800' type="checkbox" name="read" id={`${modulo.key}_2`} style={{transform: 'scale(1.5)'}} />
                                                                                </td>
                                                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-8">
                                                                                    <input onChange={(e) => handleCheck(e.target)} disabled={!modulo.permissions.some(p => p === 'create')} defaultChecked={typeof(modulo.key) === 'string' ? handleParentCheck(modulo.key, 1) : permisos.find(p => p.modulo === modulo.key && p.permiso === 1) !== undefined} className='ml-3 accent-pink-800' type="checkbox" name="create" id={`${modulo.key}_1`} style={{transform: 'scale(1.5)'}} />
                                                                                </td>
                                                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-8">
                                                                                    <input onChange={(e) => handleCheck(e.target)} disabled={!modulo.permissions.some(p => p === 'update')} defaultChecked={typeof(modulo.key) === 'string' ? handleParentCheck(modulo.key, 3) : permisos.find(p => p.modulo === modulo.key && p.permiso === 3) !== undefined} className='ml-7 accent-pink-800' type="checkbox" name="update" id={`${modulo.key}_3`} style={{transform: 'scale(1.5)'}} />
                                                                                </td>
                                                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-8">
                                                                                    <input onChange={(e) => handleCheck(e.target)} disabled={!modulo.permissions.some(p => p === 'delete')} defaultChecked={typeof(modulo.key) === 'string' ? handleParentCheck(modulo.key, 4) : permisos.find(p => p.modulo === modulo.key && p.permiso === 4) !== undefined} className='ml-5 accent-pink-800' type="checkbox" name="delete" id={`${modulo.key}_4`} style={{transform: 'scale(1.5)'}} />
                                                                                </td>
                                                                            </tr>
                                                                            {modulo.isCollapsable && modulo.submodulos !== undefined && modulo.submodulos.map((submodulo, index) => (
                                                                                <tr key={index} hidden={
                                                                                modulo.value === 'automatizacion' ? !automatizacionCollapsed :
                                                                                modulo.value === 'personalizacion' ? !personalizacionCollapsed : 
                                                                                modulo.value === 'admin' ? !adminCollapsed :
                                                                                modulo.value === 'personal' ? !personalCollapsed :
                                                                                modulo.value === 'escuelas' ? !escuelasCollapsed :
                                                                                modulo.value === 'finanzas' ? !finanzasCollapsed : true} >
                                                                                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center ml-8 -mr-12"> 
                                                                                        {submodulo.icon}
                                                                                        <span className="ml-3 font-semibold text-white"> {submodulo.title} </span>
                                                                                    </th>
                                                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-8">
                                                                                        <input onChange={(e) => handleCheck(e.target)} disabled={!submodulo.permissions.some(p => p === 'read')} checked={permisos.find(p => p.modulo === submodulo.key && p.permiso === 2) !== undefined} className='ml-7 accent-pink-800' type="checkbox" name="read" id={`${submodulo.key}_2`} style={{transform: 'scale(1.5)'}} />
                                                                                    </td>
                                                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-8">
                                                                                        <input onChange={(e) => handleCheck(e.target)} disabled={!submodulo.permissions.some(p => p === 'create')} checked={permisos.find(p => p.modulo === submodulo.key && p.permiso === 1) !== undefined} className='ml-3 accent-pink-800' type="checkbox" name="create" id={`${submodulo.key}_1`} style={{transform: 'scale(1.5)'}} />
                                                                                    </td>
                                                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-8">
                                                                                        <input onChange={(e) => handleCheck(e.target)} disabled={!submodulo.permissions.some(p => p === 'update')} checked={permisos.find(p => p.modulo === submodulo.key && p.permiso === 3) !== undefined} className='ml-7 accent-pink-800' type="checkbox" name="update" id={`${submodulo.key}_3`} style={{transform: 'scale(1.5)'}} />
                                                                                    </td>
                                                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-8">
                                                                                        <input onChange={(e) => handleCheck(e.target)} disabled={!submodulo.permissions.some(p => p === 'delete')} checked={permisos.find(p => p.modulo === submodulo.key && p.permiso === 4) !== undefined} className='ml-5 accent-pink-800' type="checkbox" name="delete" id={`${submodulo.key}_4`} style={{transform: 'scale(1.5)'}} />
                                                                                    </td>
                                                                                </tr>
                                                                            ))} 
                                                                        </>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>  
                                        </div>
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                                <div className='md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8 inline-flex items-center gap-2'>
                                                    <h2 className="">Permisos Adicionales: Solicitudes</h2>
                                                    <ReactTooltip className='tooltip-default' anchorId='tooltipPermisos-Adicionales-Peticiones' content='Selecciona los departamentos que el usuario podrá ver asociadas con las solicitudes' place="top" />
                                                    <InformationCircleIcon id='tooltipPermisos-Adicionales-Peticiones' className='h-5 text-accent-2' />
                                                </div>
                                                <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                    <div className='inline-flex gap-3'>
                                                        <input id='checkTutor' onChange={() => setTutor(!tutor)} disabled={!permisos.some(p => p.modulo === 2)} checked={tutor} className='accent-accent-2 peer' type="checkbox" style={{ transform: 'scale(1.5)' }} />
                                                        <label htmlFor='checkTutor' className='text-base peer-disabled:text-gray-400 text-gray-800 text-light font-normal'>El rol será usado por usuarios tipo 'Tutor' y/o 'Estudiante'</label>   
                                                    </div>
                                                    <div className={`${!tutor && 'inline-flex'} gap-3`} hidden={tutor}>
                                                        <input id='checkAllDepts' onChange={() => setAllDepts(!allDepts)} disabled={!permisos.some(p => p.modulo === 2)} className='accent-accent-2 peer' type="checkbox" checked={allDepts} style={{ transform: 'scale(1.5)' }} />
                                                        <label htmlFor='checkAllDepts' className='text-base peer-disabled:text-gray-400 text-gray-800 text-light font-normal'>Todos los departamentos</label>   
                                                    </div>
                                                    <div className=''>
                                                        <div className={`w-full ${!allDepts && !tutor && 'inline-flex'} border-2 rounded border-gray-400 focus-within:border-accent-2 group`} hidden={allDepts || tutor}>
                                                            <div className="w-1/12 items-center flex justify-center">
                                                                <Group className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <Select placeholder='--Seleccionar Departamentos--'
                                                                    isMulti
                                                                    options={departamentos}
                                                                    menuPortalTarget={document.body}
                                                                    className='md:p-1'
                                                                    onChange={(valor) => setSelectedDepts(valor.map((v) => {return v.value}))}
                                                                    value={departamentos.filter(d => selectedDepts.includes(d.value))}
                                                                    isLoading={departamentos.length === 0}
                                                                    isDisabled={!permisos.some(p => p.modulo === 2)}
                                                                    styles={{
                                                                        option: (base, state) => ({
                                                                            ...base,
                                                                            backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                                                        }),
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.selected_deptos}</span>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <hr />
                                    <div className='p-4 flex justify-center gap-4 bg-white'>
                                        <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </button>
                                        <button type="submit" hidden={processingRequest} className="text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                        <Link to='/roles' hidden={processingRequest} className={`${!processingRequest && 'flex'} items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                    </div>
                                </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditarRolPage