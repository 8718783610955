export default class AppUrl {

    /// Production
    static BaseURL = process.env.REACT_APP_BASE_URL;
    static UploadURL = process.env.REACT_APP_UPLOAD_URL;
    
    /// Development
    /* static BaseURL = 'http://192.168.0.17:8000/api';
    static UploadURL = 'http://192.168.0.17:8000/uploads'; */

    static Login = this.BaseURL + '/login';
    static Logout = this.BaseURL + '/logout/';
    static CurrentUser = this.BaseURL + '/user';
    static Users = this.BaseURL + '/users';
    static UsersNameType = this.BaseURL + '/users-name-type';
    static UsersById = this.BaseURL + '/users/';
    static RegisterUser = this.BaseURL + '/register';
    static ActivatingUser = this.BaseURL + '/activating-user/';
    static UpdateUser = this.BaseURL + '/users/update/';
    static UpdateProfile = this.BaseURL + '/users/update-profile/';
    static UpdateUserStatus = this.BaseURL + '/users/update-status/'
    static ActivateUser = this.BaseURL + '/activate-user/';
    static DeleteUser = this.BaseURL + '/users/delete/';

    static AllUserNotifications = this.BaseURL + '/notificaciones/';
    static NotificacionesSLA = this.BaseURL + '/notificaciones-sla/';
    static UserUnreadNotifications = this.BaseURL + '/notificaciones-unread/';
    static UserNotificationsCount = this.BaseURL + '/notificaciones-count/';
    static ToggleReadSingle = this.BaseURL + '/notificaciones/toggle-read/';
    static ToggleReadMultiple = this.BaseURL + '/notificaciones/toggle-read-multi';
    static MarkAllRead = this.BaseURL + '/notificaciones-all-read/';
    static DeleteNotificacionSingle = this.BaseURL + '/notificaciones/delete/';
    static DeleteNotificacionMultiple = this.BaseURL + '/notificaciones/delete-multi';

    static ConfiguracionesByUser = this.BaseURL + '/configuraciones-usuario/';
    static UpdateUserConfiguraciones = this.BaseURL + '/configuraciones-usuario/update/';

    static ProfilePics = this.UploadURL + '/profilepics/';
    static ArchivosAdjuntos = this.UploadURL + '/archivos_adjuntos/';
    
    static Roles = this.BaseURL + '/roles';
    static CreateRol = this.BaseURL + '/roles/create';
    static RoleById = this.BaseURL + '/roles/';
    static UpdateRol = this.BaseURL + '/roles/update/';
    static DeleteRol = this.BaseURL + '/roles/delete/';

    static Modulos = this.BaseURL + '/modulos';

    static Categorias = this.BaseURL + '/categorias';
    static CreateCategoria = this.BaseURL + '/categorias/create';
    static CategoriaById = this.BaseURL + '/categorias/';
    static UpdateCategoria = this.BaseURL + '/categorias/update/';
    static DeleteCategoria = this.BaseURL + '/categorias/delete/';

    static Subcategorias = this.BaseURL + '/subcategorias';
    static CreateSubcategoria = this.BaseURL + '/subcategorias/create';
    static SubcategoriaById = this.BaseURL + '/subcategorias/';
    static UpdateSubcategoria = this.BaseURL + '/subcategorias/update/';
    static DeleteSubcategoria = this.BaseURL + '/subcategorias/delete/';

    static Estatus = this.BaseURL + '/estatus';
    static CreateEstatus = this.BaseURL + '/estatus/create';
    static EstatusById = this.BaseURL + '/estatus/';
    static UpdateEstatus = this.BaseURL + '/estatus/update/';
    static DeleteEstatus = this.BaseURL + '/estatus/delete/';

    static Prioridades = this.BaseURL + '/prioridades';
    static CreatePrioridad = this.BaseURL + '/prioridades/create';
    static PrioridadById = this.BaseURL + '/prioridades/';
    static UpdatePrioridad = this.BaseURL + '/prioridades/update/';
    static DeletePrioridad = this.BaseURL + '/prioridades/delete/';

    static Departamentos = this.BaseURL + '/departamentos';
    static CreateDepartamento = this.BaseURL + '/departamentos/create';
    static DepartamentoById = this.BaseURL + '/departamentos/';
    static UpdateDepartamento = this.BaseURL + '/departamentos/update/';
    static DeleteDepartamento = this.BaseURL + '/departamentos/delete/';

    static Puestos = this.BaseURL + '/puestos';
    static CreatePuesto = this.BaseURL + '/puestos/create';
    static PuestoById = this.BaseURL + '/puestos/';
    static UpdatePuesto = this.BaseURL + '/puestos/update/';
    static DeletePuesto = this.BaseURL + '/puestos/delete/';

    static Tutores = this.BaseURL + '/tutores';
    static CreateTutor = this.BaseURL + '/tutores/create';
    static TutorById = this.BaseURL + '/tutores/';
    static UpdateTutor = this.BaseURL + '/tutores/update/';
    static DeleteTutor = this.BaseURL + '/tutores/delete/';

    static Administrativos = this.BaseURL + '/administrativos';
    static CreateAdministrativo = this.BaseURL + '/administrativos/create';
    static AdministrativoById = this.BaseURL + '/administrativos/';
    static UpdateAdministrativo = this.BaseURL + '/administrativos/update/';
    static UpdateAdministrativoProfile = this.BaseURL + '/administrativos/update-profile/';
    static DeleteAdministrativo = this.BaseURL + '/administrativos/delete/';

    static Estudiantes = this.BaseURL + '/estudiantes';
    static CreateEstudiante = this.BaseURL + '/estudiantes/create';
    static EstudianteById = this.BaseURL + '/estudiantes/';
    static EstudianteByTutorId = this.BaseURL + '/estudiantes-tutor/';
    static UpdateEstudiante = this.BaseURL + '/estudiantes/update/';
    static UpdateEstudianteProfile = this.BaseURL + '/estudiantes/update-profile/';
    static DeleteEstudiante = this.BaseURL + '/estudiantes/delete/';

    static Planteles = this.BaseURL + '/planteles';
    static CreatePlantel = this.BaseURL + '/planteles/create';
    static PlantelById = this.BaseURL + '/planteles/';
    static UpdatePlantel = this.BaseURL + '/planteles/update/';
    static DeletePlantel = this.BaseURL + '/planteles/delete/';

    static Peticiones = this.BaseURL + '/peticiones';
    static PeticionesByUserId = this.BaseURL + '/peticiones-sp/';
    static CreatePeticion = this.BaseURL + '/peticiones/create';
    static PeticionById = this.BaseURL + '/peticiones/';
    static UpdatePeticion = this.BaseURL + '/peticiones/update/';
    static AsignDepartamento = this.BaseURL + '/peticiones/asign-depto/';
    static AsignUsuario = this.BaseURL + '/peticiones/asign-user/';
    static ReopenPeticion = this.BaseURL + '/peticiones/reopen/';
    static DeletePeticion = this.BaseURL + '/peticiones/delete/';
    static UpdateColaboradores = this.BaseURL + '/peticiones/update-colaboradores/';

    static NotificacionesCorreo = this.BaseURL + '/notificaciones-correo';
    static CreateNotificacionCorreo = this.BaseURL + '/notificaciones-correo/create';
    static NotificacionCorreoById = this.BaseURL + '/notificaciones-correo/';
    static UpdateNotificacionCorreo = this.BaseURL + '/notificaciones-correo/update/';
    static DeleteNotificacionCorreo = this.BaseURL + '/notificaciones-correo/delete/';

    static ServiceLevelAgreements = this.BaseURL + '/slas';
    static CreateSLA = this.BaseURL + '/slas/create';
    static SLAById = this.BaseURL + '/slas/';
    static UpdateSLA = this.BaseURL + '/slas/update/';
    static UpdateSLAStatus = this.BaseURL + '/slas/update-status/';
    static DeleteSLA = this.BaseURL + '/slas/delete/';

    static Parentescos = this.BaseURL + '/parentescos';
    static Niveles = this.BaseURL + '/niveles';
    static Escolaridades = this.BaseURL + '/escolaridades';
    static Carreras = this.BaseURL + '/carreras';
    static Bancos = this.BaseURL + '/bancos';
    static EstatusAcademicos = this.BaseURL + '/estatus-academicos';

    static HistorialesByPeticionId = this.BaseURL + '/historiales-peticiones/';
    static HistorialesCountByPeticionId = this.BaseURL + '/historiales-peticiones-count/';
    static HistorialesByTareaId = this.BaseURL + '/historiales-tareas/';
    static HistorialesCountByTareaId = this.BaseURL + '/historiales-tareas-count/';
    static RespuestasById = this.BaseURL + '/respuestas/';
    static RespuestasByPeticionId = this.BaseURL + '/respuestas/';
    static RespuestasCountByPeticionId = this.BaseURL + '/respuestas-count/';

    static ReportePeticionesCharts = this.BaseURL + '/reporte-peticiones-charts';
    static ReportePeticionesTable = this.BaseURL + '/reporte-peticiones-table';

    static Ciclos = this.BaseURL + '/ciclos';
    static CreateCiclo = this.BaseURL + '/ciclos/create';
    static CicloById = this.BaseURL + '/ciclos/';
    static UpdateCiclo = this.BaseURL + '/ciclos/update/';
    static DeleteCiclo = this.BaseURL + '/ciclos/delete/';

    static Conceptos = this.BaseURL + '/conceptos';
    static CreateConcepto = this.BaseURL + '/conceptos/create';
    static ConceptoById = this.BaseURL + '/conceptos/';
    static UpdateConcepto = this.BaseURL + '/conceptos/update/';
    static DeleteConcepto = this.BaseURL + '/conceptos/delete/';

    static Becas = this.BaseURL + '/becas';
    static CreateBeca = this.BaseURL + '/becas/create';
    static BecaById = this.BaseURL + '/becas/';
    static UpdateBeca = this.BaseURL + '/becas/update/';
    static DeleteBeca = this.BaseURL + '/becas/delete/';

    static Descuentos = this.BaseURL + '/descuentos';
    static CreateDescuento = this.BaseURL + '/descuentos/create';
    static DescuentoById = this.BaseURL + '/descuentos/';
    static UpdateDescuento = this.BaseURL + '/descuentos/update/';
    static DeleteDescuento = this.BaseURL + '/descuentos/delete/';

    static Tesoreria = this.BaseURL + '/tesoreria';
    static CreateTesoreria = this.BaseURL + '/tesoreria/create';
    static TesoreriaById = this.BaseURL + '/tesoreria/';
    static UpdateTesoreria = this.BaseURL + '/tesoreria/update/';
    static DeleteTesoreria = this.BaseURL + '/tesoreria/delete/';

    static Inscripciones = this.BaseURL + '/inscripciones';
    static CreateInscripcion = this.BaseURL + '/inscripciones/create';
    static InscripcionById = this.BaseURL + '/inscripciones/';
    static UpdateInscripcion = this.BaseURL + '/inscripciones/update/';
    static DeleteInscripcion = this.BaseURL + '/inscripciones/delete/';

    static ColegiaturaRegistros = this.BaseURL + '/colegiatura-registros'
    static CreateColegiaturaRegistro = this.BaseURL + '/colegiatura-registros/create';
    static ColegiaturaRegistroById = this.BaseURL + '/colegiatura-registros/';
    static UpdateColegiaturaRegistro = this.BaseURL + '/colegiatura-registros/update/';

    static ColegiaturasByRegistroId = this.BaseURL + '/colegiaturas/registro/';
    static ColegiaturaById = this.BaseURL + '/colegiaturas/';
    static UpdateColegiatura = this.BaseURL + '/colegiaturas/update/';
    static ToggleColegiaturaCobro = this.BaseURL + '/colegiaturas/toggle-cobro/';
    static UpdateColegiaturaFecha = this.BaseURL + '/colegiaturas/update-fecha/';

    static PagoColegiaturaByIdAndEstudianteId = this.BaseURL + '/colegiatura-pagos/';
    static ProcessColegiaturaPago = this.BaseURL + '/colegiatura-pagos/process-payment';
    static CancelColegiaturaPago = this.BaseURL + '/colegiatura-pagos/cancel/';

    static Turnos = this.BaseURL + '/turnos';
    static CreateTurno = this.BaseURL + '/turnos/create';
    static TurnoById = this.BaseURL + '/turnos/';
    static UpdateTurno = this.BaseURL + '/turnos/update/';
    static DeleteTurno = this.BaseURL + '/turnos/delete/';

    static Grupos = this.BaseURL + '/grupos';
    static CreateGrupo = this.BaseURL + '/grupos/create';
    static GrupoById = this.BaseURL + '/grupos/';
    static UpdateGrupo = this.BaseURL + '/grupos/update/';
    static DeleteGrupo = this.BaseURL + '/grupos/delete/';

    static Tareas = this.BaseURL + '/tareas';
    static CreateTarea = this.BaseURL + '/tareas/create';
    static TareasById = this.BaseURL + '/tareas/';
    static TareasByPeticionId = this.BaseURL + '/tareas-peticiones/';
    static UpdateTarea = this.BaseURL + '/tareas/update/';
    static ReopenTarea = this.BaseURL + '/tareas/reopen/';
    static DeleteTarea = this.BaseURL + '/tareas/delete/';

    static Cafeteria = this.BaseURL + '/cafeteria';
    static CreateCafeteria = this.BaseURL + '/cafeteria/create';
    static CafeteriaById = this.BaseURL + '/cafeteria/';
    static UpdateCafeteria = this.BaseURL + '/cafeteria/update/';
    static DeleteCafeteria = this.BaseURL + '/cafeteria/delete/';

    static CafeteriaPagos = this.BaseURL + '/cafeteria-pagos';
    static CafeteriaPagosUser = this.BaseURL + '/cafeteria-pagos/user/';
    static CreateCafeteriaPago = this.BaseURL + '/cafeteria-pagos/create';
    static CafeteriaPagoById = this.BaseURL + '/cafeteria-pagos/';
    static StripePaymentSuccessfull = this.BaseURL + '/stripe-payment-successfull/';
    static StripePaymentSheet = this.BaseURL + '/cafeteria-pagos/payment-sheet';
    static CompletePaymentSheet = this.BaseURL + '/cafeteria-pagos/payment-sheet/';
    static CancelCafeteriaPago = this.BaseURL + '/cafeteria-pagos/cancel/';
    static ProcessCafeteriaPago = this.BaseURL + '/cafeteria-pagos/process-payment';

    static RecordatorioTipos = this.BaseURL + '/recordatorio-tipos';
    static RecordatorioAreas = this.BaseURL + '/recordatorio-areas';

    static RecordatorioRegistros = this.BaseURL + '/recordatorio-registros'
    static CreateRecordatorioRegistro = this.BaseURL + '/recordatorio-registros/create'
    static RecordatorioRegistroById = this.BaseURL + '/recordatorio-registros/'

    static AllUserReminders = this.BaseURL + '/recordatorios/';
    static UserUnreadReminders = this.BaseURL + '/recordatorios-unread/';
    static ToggleReminderRead = this.BaseURL + '/recordatorios/toggle-read/';
}