import React from 'react'
import { useParams } from 'react-router-dom';
import RespuestaComponent from '../../components/form-controls/RespuestaComponent';

const AppViewerPage = () => {

    const { value } = useParams();

    return (
        <div className='mx-4 my-2 rounded'>
            <RespuestaComponent value={value} />
        </div>
    )
}

export default AppViewerPage