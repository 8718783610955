import { AcademicCapIcon, CalendarIcon, CurrencyDollarIcon, StarIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../../components/Loading';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { CogIcon, InformationCircleIcon, LightningBoltIcon, PencilAltIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';
var CryptoJS = require("crypto-js");

// Create a constructor for functional component to get permissions
const useConstructor = (callBack = () => { }) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
}

const PercentSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-2' viewBox="0 0 384 512">
        <path d="M374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l320-320zM128 128c0-35.3-28.7-64-64-64S0 92.7 0 128s28.7 64 64 64s64-28.7 64-64zM384 384c0-35.3-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64s64-28.7 64-64z" />
    </svg>
);

//This has to be a functional component to use 'useParams()' from react-router-dom
const EditarColegiaturaRegistroPage = (props) => {

    const [tipo, setTipo] = useState('');
    const [nivel_id, setNivelId] = useState(0);
    const [ciclo_id, setCicloId] = useState(0);
    const [precio, setPrecio] = useState('');
    const [dia_limite, setDiaLimite] = useState(10);
    const [dias_habiles, setDiasHabiles] = useState(false);
    const [cargo_tipo, setCargoTipo] = useState('');
    const [cargo_valor, setCargoValor] = useState(10);
    const [aplicar_cobro, setAplicarCobro] = useState(false);
    const [colegiaturas, setColegiaturas] = useState([]);
    const [cantidad_cobrados, setCantidadCobrados] = useState(0);

    const [niveles, setNiveles] = useState([]);
    const [ciclos, setCiclos] = useState([]);
    const [dias, setDias] = useState([]);
    const [movedDates, setMovedDates] = useState([]);

    const [startingDate, setStartingDate] = useState('');
    const [changesMade, setChangesMade] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [processingRequest, setProcessingRequest] = useState(false);
    const [statusResult, setStatusResult] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [errors, setErrors] = useState([{ precio: '', dia_limite: '', dias_habiles: '', cargo_tipo: '', cargo_valor: '' }]);
    const [usersAccess, setUsersAccess] = useState([]);

    //Get the passed :id from parent and pass it to the component to render it
    const colegiaturaRegistro = useParams();

    /// Decript permissions
    useConstructor(() => {
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUsersAccess(_usersAccess);
    });

    // Get Colegiatura by Id
    useEffect(() => {
        RestClient.GetRequest(AppUrl.ColegiaturaRegistroById + colegiaturaRegistro.id).then((result) => {
            const { tipo, nivel_id, ciclo_id, precio, dia_limite, dias_habiles, cargo_tipo, cargo_valor, aplicar_cobro, colegiaturas, cantidad_cobrados } = result;
            setTipo(tipo);
            setNivelId(nivel_id);
            setCicloId(ciclo_id);
            setPrecio(precio);
            setDiaLimite(dia_limite);
            setDiasHabiles(dias_habiles === 1);
            setCargoTipo(cargo_tipo);
            setCargoValor(cargo_valor);
            setAplicarCobro(aplicar_cobro === 1);
            setColegiaturas(colegiaturas);
            setCantidadCobrados(cantidad_cobrados);

            setLoaded(true);
        });
    }, [colegiaturaRegistro.id]);
    // Get Collections
    useEffect(() => {
        // Get Niveles
        RestClient.GetRequest(AppUrl.Niveles).then((result) => {
            setNiveles(result);
        });
        // Get Ciclos
        RestClient.GetRequest(AppUrl.Ciclos).then((result) => {
            setCiclos(result);
        });
        // Set Días Límite
        let days = [];
        for (let index = 1; index <= 31; index++) {
            days.push(index);
            if (index === 31) {
                setDias(days);
            }
        }
    }, []);

    function onPrecioChange(e) {
        const newPrecio = parseFloat(e.target.value).toFixed(2);
        setPrecio(newPrecio);
    }

    function onPrecioBlur(e) {
        let value = e.target.value;
        if (!isNaN(parseFloat(value))) {
            value = parseFloat(value).toFixed(2);
        }
        e.target.value = value;
    }

    function onDiaSelect(e) {
        let dia = parseInt(e.target.value);
        setDiaLimite(dia);
        setMovedDates([]);
        setChangesMade(true);
    }

    function onDiasHabilesChange() {
        setDiasHabiles((prevState) => !prevState);
        setChangesMade(true);
    }

    function onStartingDateChange(e) {
        setStartingDate(e.target.value);
        setChangesMade(true);
    }

    function onToggleDropdown() {
        setIsSelectOpen((prevState) => !prevState);
    }

    function onSelectedType(value) {
        setCargoTipo(value);
        setIsSelectOpen(false);
    }

    function onCobroChange(e) {
        const newCobro = parseFloat(e.target.value).toFixed(2);
        setCargoValor(newCobro);
    }

    function onCobroBlur(e) {
        let value = e.target.value;
        if (!isNaN(parseFloat(value))) {
            value = parseFloat(value).toFixed(2);
        }
        e.target.value = value;
    }

    function validateDateLimit(date, day) {
        // Create a new Date object for the next month
        const year = date.getFullYear();
        const month = date.getMonth();
        const nextMonth = new Date(year, month + 1, 1);
        // Subtract one day of the next month to get the last day of the current month
        nextMonth.setDate(nextMonth.getDate() - 1);
        if (day > nextMonth.getDate()) {
            return nextMonth.getDate();
        }

        return day;
    }

    function validateDateForWeekends(date, index) {
        const dayOfWeek = date.getDay();
        let dateWasMoved = false;

        if (dayOfWeek === 0 || dayOfWeek === 6) {
            // If it's Sunday (0) or Saturday (6), move to Monday by adding 1 or 2 days.
            date.setDate(date.getDate() + (dayOfWeek === 0 ? 1 : 2));
            dateWasMoved = true;
        }

        if (dateWasMoved) {
            setMovedDates((prevState) => [...prevState, { index, reason: 'Se movió la fecha debido a fin de semana.' }])
        }

        return date;
    }

    function getObservedDate(occurrence, weekday, month, year) {
        const date = new Date(year, month, 1);
        date.setDate(1 + (weekday - date.getDay() + 7) % 7 + (occurrence - 1) * 7);
        return date;
    };

    function isHoliday(date) {
        const holidays = [
            { name: "Año Nuevo", month: 0, day: 1 },
            { name: "Día de la Constitución", observed: { occurrence: 1, weekday: 1, month: 1 }, }, // Observed on the first Monday of February
            { name: "Natalicio de Benito Juárez", observed: { occurrence: 3, weekday: 1, month: 2 } }, // Observed on the third Monday of March
            { name: "Día del Trabajo", month: 4, day: 1 },
            { name: "Día de la Independencia", month: 8, day: 16 },
            { name: "Día de la Revolución", observed: { occurrence: 3, weekday: 1, month: 10 } }, // Observed on the third Monday of November
            { name: "Navidad", month: 11, day: 25 },
        ];

        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        for (const holiday of holidays) {
            if ((month === holiday.month || month === holiday.observed?.month) && (
                (holiday.observed && day === getObservedDate(holiday.observed, year).getDate()) ||
                (!holiday.observed && day === holiday.day)
            )) {
                return holiday.name;
            }
        }
        return null;
    };

    function validateDateForHolidays(date, index) {
        const holidayName = isHoliday(date);
        const movedReason = `\nSe movió la fecha debido a día festivo: ${holidayName}`;
        if (holidayName === null) return date;

        date.setDate(date.getDate() + 1);

        setMovedDates((prevState) => {
            const newMovedDates = prevState.map((md) =>
                md.index === index
                    ? { ...md, reason: md.reason + movedReason }
                    : md
            );

            if (!newMovedDates.some((md) => md.index === index)) {
                newMovedDates.push({ index, reason: movedReason });
            }

            return newMovedDates;
        });

        return date;
    };

    function updateColegiaturas() {
        const [year, month, day] = startingDate.split('-');
        const newStartingDate = new Date(year, month - 1, day);
        
        let newColegiaturas = colegiaturas.map((colegiatura, index) => {
            const fechaLimiteString = colegiatura.fecha_limite;
            const [year, month, day] = fechaLimiteString.split('-');
            let fechaLimite = new Date(year, month - 1, day);
            if (startingDate && index === 0) {
                fechaLimite = newStartingDate;
            }
            if (!startingDate || (startingDate && index > 0)) {
                fechaLimite.setDate(validateDateLimit(fechaLimite, dia_limite));
            }
            if (dias_habiles && ((startingDate && index > 0) || (!startingDate))) {
                fechaLimite = validateDateForWeekends(fechaLimite, index);
                fechaLimite = validateDateForHolidays(fechaLimite, index);
            }

            return {
                ...colegiatura,
                mes: fechaLimite.toLocaleDateString('es-MX', { month: 'long' }),
                no_mes: fechaLimite.getMonth() + 1,
                fecha_limite: fechaLimite.toLocaleDateString('en-CA'),
                date_changed: true,
            }
        });

        setColegiaturas(newColegiaturas);
        setChangesMade(false);
    }

    function onAplicarCobroCheck(e) {
        const aplicandoCobro = e.target.checked;
        setAplicarCobro(aplicandoCobro);
        setColegiaturas((prevState) => {
            let updatedColegiaturas = prevState.map((colegiatura) => {
                return {
                    ...colegiatura,
                    aplicar_cobro: aplicandoCobro,
                    cobro_changed: true,
                }
            });
            return updatedColegiaturas;
        });
    }

    function updateDateColegiatura(index, newDate) {
        const [year, month, day] = newDate.split("-");
        const date = new Date(year, month - 1, day);

        setColegiaturas((prevState) => {
            let updatedColegiaturas = prevState.map((colegiatura, i) => {
                if (index !== i) return colegiatura;

                return {
                    ...colegiatura,
                    fecha_limite: newDate,
                    no_mes: date.getMonth() + 1,
                    mes: date.toLocaleDateString('es-MX', { month: 'long' }),
                    date_changed: true,
                }
            });
            return updatedColegiaturas;
        });
    }

    function updateCobroColegiatura(index, checkbox) {
        const aplicandoCobro = checkbox.checked;
        const updatedColegiaturas = colegiaturas.map((colegiatura, i) => {
            if (index !== i) return colegiatura;

            return {
                ...colegiatura,
                aplicar_cobro: aplicandoCobro,
                cobro_changed: true,
            }
        });

        const cobroValue = !updatedColegiaturas.some(c => !c.aplicar_cobro);

        setAplicarCobro(cobroValue);
        setColegiaturas(updatedColegiaturas);
    }

    function renderColegiaturas() {
        var body = [];
        for (let index = 0; index < colegiaturas.length; index++) {
            const movedDate = movedDates.find(md => md.index === index);
            body.push(<tr key={index} className='flex flex-col flex-no wrap md:table-row mb-2 md:mb-0'>
                <td className='border p-1.5 max-h-[54px]'>
                    {!processingRequest ?
                    <Link to={`/inscripciones-colegiaturas/editar-colegiatura/${colegiaturas[index].id}`} title='Editar' className='flex justify-center items-center text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-full w-10 h-10 mx-auto shadow shadow-slate-400 transition-colors'>
                        <PencilAltIcon className='w-5 h-5' />
                    </Link> :
                    <div className='flex justify-center items-center text-white bg-accent-2/70 font-medium rounded-full w-10 h-10 mx-auto transition-colors'>
                        <PencilAltIcon className='w-5 h-5' />
                    </div>}
                </td>
                <td className='border p-1.5 max-h-[54px]'>
                    <p className='text-center'>{colegiaturas[index].no_mes}</p>
                </td>
                <td className='border p-1.5 max-h-[54px]'>
                    <p className='text-center'>{colegiaturas[index].mes}</p>
                </td>
                <td className='border p-1.5 max-h-[54px]'>
                    <div className='flex justify-center items-center'>
                        <div className='relative inline-flex items-center rounded-full px-3 border-accent-1 focus:border hover:border hover:bg-slate-50 transition-colors'>
                            <input onChange={({ target: { value } }) => updateDateColegiatura(index, value)} value={colegiaturas[index].fecha_limite} type="date" name="fecha_limite" id={`fl_${index}`} className='outline-none bg-transparent' />
                            {movedDate && <>
                                <ReactTooltip className='tooltip-default' anchorId={`tooltipMovedDates_${index}`} content={movedDate.reason} place="top" />
                                <LightningBoltIcon id={`tooltipMovedDates_${index}`} className='absolute -right-6 w-5 h-5 text-accent-2' />
                            </>}
                        </div>
                    </div>
                </td>
                <td className='border p-1.5 max-h-[54px]'>
                    <input onChange={({ target }) => updateCobroColegiatura(index, target)} type="checkbox" checked={colegiaturas[index].aplicar_cobro} className='block mx-auto accent-accent-2 w-5 h-5' />
                </td>
                <td className='border p-2 max-h-[54px]'>
                    <p className='text-center'>{colegiaturas[index].cantidad_cobrados}</p>
                </td>
            </tr>);
        }

        return body;
    }

    function onFormSubmit(e) {
        e.preventDefault();
        //Disable controls while request is processed and clear error messages
        setProcessingRequest(true);
        setErrors([{ precio: '', dia_limite: '', dias_habiles: '', cargo_tipo: '', cargo_valor: '' }]);

        const data = new FormData();
        data.append('id', colegiaturaRegistro.id);
        data.append('tipo', tipo);
        data.append('nivel_id', nivel_id);
        data.append('ciclo_id', ciclo_id);
        data.append('precio', precio);
        data.append('dia_limite', dia_limite);
        data.append('dias_habiles', dias_habiles);
        data.append('cargo_tipo', cargo_tipo);
        data.append('cargo_valor', cargo_valor);
        data.append('aplicar_cobro', aplicar_cobro);
        data.append('colegiaturas', JSON.stringify(colegiaturas));

        RestClient.PostRequest(AppUrl.UpdateColegiaturaRegistro + colegiaturaRegistro.id, data).then((result) => {
            const { status, data, message } = result;
            const requestErrors = data ?? errors;
            setStatusResult(status);
            setProcessingRequest(false);
            setErrors(requestErrors);
            if (status === 200) {
                toast.success(message);
            }
            else {
                console.log(result);
                toast.error(message);
            }
        });
    }

    // Derrivative States
    const chargeTypes = [{ value: 'porcentaje', icon: <PercentSVG /> }, { value: 'cantidad', icon: <CurrencyDollarIcon className='w-6 h-6 text-gray-400 group-focus-within:text-accent-2' /> }];

    if (usersAccess.find(ua => ua.modulo_name === 'colegiaturas' && ua.permiso_name === 'update') === undefined) {
        return <Navigate to='/access-denied' />
    }
    if (statusResult === 200) {
        return <Navigate to='/inscripciones-colegiaturas' />
    }
    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60 ml-0' : 'sm:ml-20 ml-0'} duration-300 px-2 sm:pt-24 pt-20 pb-6 sm:px-4 md:px-8 bg-gray-100`}>
            <div className='sm:px-0 px-3'>
                {/* Page Title */}
                <div className='flex w-full'>
                    <h1 className='sm:text-xl text-lg font-medium'>Editar Registro de Colegiatura</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                    {usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                    <p className='text-gray-400'>Finanzas</p>
                    <span className='text-xs'>/</span>
                    <Link to='/inscripciones-colegiaturas' className='font-semibold hover:underline'>Inscripciones y Colegiaturas</Link>
                    <span className='text-xs'>/</span>
                    <p className='text-dark-purple font-semibold'>Editar Colegiatura Registro</p>
                </div>
            </div>
            <div className='w-full flex justify-center mt-6'>
                <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                    <div className='sm:w-11/12 w-full sm:my-8'>
                        <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                            <div className='bg-gray-50'>
                                <div className='bg-white border'>
                                    <h1 className='text-accent-1 sm:text-2xl text-xl p-4'>Editar Registro de Colegiatura</h1>
                                </div>
                                {!loaded ?
                                    <div className='flex w-full justify-center bg-white'>
                                        <Loading />
                                    </div> :
                                    <form onSubmit={onFormSubmit} className='mt-4'>
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Tipo</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <StarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select disabled id='selectTipo' name="tipo" defaultValue={tipo} className="disabled:cursor-not-allowed capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione Tipo De Colegiatura --</option>
                                                                <option value="m" data-frequency={1}>Mensual</option>
                                                                <option value="t" data-frequency={3}>Trimestral</option>
                                                                <option value="q" data-frequency={4}>Cuatrimestral</option>
                                                                <option value="s" data-frequency={6}>Semestral</option>
                                                                <option value="a" data-frequency={12}>Anual</option>
                                                                <option value="f" disabled className='text-gray-400 italic'>Pago completo por adelantado (Próximamente)</option>
                                                                <option value="c" disabled className='text-gray-400 italic'>Plan de pago personalizado (Próximamente)</option>
                                                            </select>
                                                            <label htmlFor="selectTipo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Tipo de Colegiatura</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Colegiatura</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className='mt-4'>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <AcademicCapIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select disabled id='selectNivel' name="nivel_id" defaultValue={nivel_id} className="disabled:cursor-not-allowed capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione Nivel Educativo --</option>
                                                                {niveles.map(item => {
                                                                    return <option key={item.id} value={item.id}>{item.nombre}</option>
                                                                })}
                                                            </select>
                                                            <label htmlFor="selectNivel" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nivel Educativo</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select disabled id='selectCiclo' name="ciclo_id" defaultValue={ciclo_id} className="disabled:cursor-not-allowed capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione Ciclo Escolar --</option>
                                                                {ciclos.map(item => {
                                                                    return <option key={item.id} value={item.id}>{item.nombre}{item.estatus === 1 && ' \u2022 (Ciclo Actual)'}</option>
                                                                })}
                                                            </select>
                                                            <label htmlFor="selectCiclo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Ciclo Escolar</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <CurrencyDollarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={onPrecioChange} onBlur={onPrecioBlur} defaultValue={precio} id='txtPrecio' type="number" step={0.01} min={0.01} name="precio" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtPrecio" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Precio</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.precio}</span>
                                                </div>
                                                <div className='border border-dashed border-gray-400 rounded px-5 py-10 space-y-4'>
                                                    <div className=''>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                            <div className="mx-2 items-center flex justify-center">
                                                                <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <select onChange={onDiaSelect} id='selectLimite' name="dia_limite" value={dia_limite} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                    <option value="0" disabled className='text-gray-400'>-- Seleccione Día Límite --</option>
                                                                    {dias.map(day => {
                                                                        return <option key={day} value={day}>{day}</option>
                                                                    })}
                                                                </select>
                                                                <label htmlFor="selectLimite" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Día límite de pago</label>
                                                            </div>
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.dia_limite}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className='inline-flex gap-2'>
                                                            <h5 className='md:text-sm text-xs text-slate-800 text-light font-normal mb-1'>Solo días hábiles</h5>
                                                            <ReactTooltip className='tooltip-default' anchorId='tooltipDiasHabiles' content='Si esta opción es marcada, en el caso de que la fecha límite de pago caiga en día inhábil o festivo, la fecha se recorre al día hábil siguiente.' place="top" />
                                                            <InformationCircleIcon id='tooltipDiasHabiles' className='h-5 text-accent-2' />
                                                        </div>
                                                        <div className="min-h-[1.5rem] mb-0.5 block pl-2">
                                                            <input onChange={onDiasHabilesChange} defaultChecked={dias_habiles} className='accent-accent-2' type="checkbox" style={{ transform: 'scale(1.5)' }} />
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.dias_habiles}</span>
                                                    </div>
                                                    <div className='group'>
                                                        <h5 className='md:text-sm text-xs text-gray-400 text-light font-normal mb-1 group-focus-within:text-accent-1 transition-colors'>Especificar fecha del primer cobro <span className='text-xs'>(Opcional)</span></h5>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group transition-colors">
                                                            <div className="mx-2 items-center flex justify-center">
                                                                <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2 transition-colors' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input onChange={onStartingDateChange} id='dateStartingDate' type='date' name="starting_date" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 group-focus-within:border-accent-2 transition-colors" placeholder=" " />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='group'>
                                                        <div className='inline-flex gap-x-2'>
                                                            <h5 className='md:text-sm text-xs text-gray-400 text-light font-normal mb-1 group-focus-within:text-accent-1 transition-colors'>Cobro del <span className='font-medium'>{cargo_tipo === 'cantidad' ? '$ ' : ''}{isNaN(cargo_valor) ? '0.00' : cargo_valor}{cargo_tipo === 'porcentaje' ? ' %' : ''}</span></h5>
                                                            <ReactTooltip className='tooltip-default' anchorId='tooltipCobro' content='Indica en porcentaje o cantidad fija cuánto será el sobrecargo si la colegiatura se paga después del día límite de pago.' place="top" />
                                                            <InformationCircleIcon id='tooltipCobro' className='h-5 text-accent-2' />
                                                        </div>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group transition-colors">
                                                            <div className='relative w-16'>
                                                                <div className=' py-2 px-3 border-r-2 border-gray-400 group-focus-within:border-accent-2 cursor-pointer w-16' onClick={onToggleDropdown}>
                                                                    {chargeTypes.find(type => type.value === cargo_tipo).icon}
                                                                    <div className={`${isSelectOpen ? 'rotate-180' : 'rotate-0'} content-[""] absolute top-[50%] right-2 w-0 h-0 border-4 border-b-0 border-transparent border-t-[#666] transition-all`}></div>
                                                                </div>
                                                                <ul className={`${isSelectOpen ? 'block' : 'hidden'} list-none p-0 m-0 border border-t-0 absolute top-[100%] left-0 right-0`}>
                                                                    {chargeTypes.map((type) => (
                                                                        <li key={type.value} onClick={() => onSelectedType(type.value)} className='py-2 px-3 cursor-pointer bg-white shadow hover:bg-sky-50' title={type.value}>
                                                                            {type.icon}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input onChange={onCobroChange} onBlur={onCobroBlur} defaultValue={isNaN(cargo_valor) ? 0.00 : cargo_valor} type="number" step={0.01} min={0.01} name="valor_cargo" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent appearance-none focus:outline-none focus:ring-0 transition-colors" placeholder=" " required />
                                                            </div>
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.cargo_tipo}</span>
                                                        <span className='text-sm text-red-500'>{errors.cargo_valor}</span>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <div className='inline-flex gap-2'>
                                                        <h5 className='md:text-sm text-xs text-slate-800 text-light font-normal mb-1'>Aplicar cobro</h5>
                                                        <ReactTooltip className='tooltip-default' anchorId='tooltipAplicarCobro' content='Si esta opción es marcada, la colegiatura se asociará a todos los estudiantes en el nivel y ciclo seleccionado.' place="top" />
                                                        <InformationCircleIcon id='tooltipAplicarCobro' className='h-5 text-accent-2' />
                                                    </div>
                                                    <div className="min-h-[1.5rem] mb-0.5 block pl-2">
                                                        <input onChange={onAplicarCobroCheck} checked={aplicar_cobro} className='accent-accent-2' type="checkbox" style={{ transform: 'scale(1.5)' }} />
                                                    </div>
                                                </div>
                                                <div className='flex justify-end'>
                                                    <button type="button" onClick={updateColegiaturas} disabled={!changesMade} className='disabled:bg-accent-2/60 disabled:hover:translate-y-0 disabled:shadow-none flex justify-center items-center bg-accent-2 rounded-full px-4 py-2 shadow-md shadow-slate-500 hover:-translate-y-1 transition duration-300 group'>
                                                        <span className='text-slate-100 text-base font-medium mr-2'>Actualizar Colegiaturas</span>
                                                        <CogIcon className='w-5 h-5 text-slate-100 group-hover:rotate-45 group-disabled:rotate-0 transition-all' />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div hidden={colegiaturas.length === 0} className="space-y-4 md:space-y-0 w-full p-4 text-gray-800 items-center mb-4">
                                            <h2 className="text-accent-1 font-semibold inline-flex items-center">Colegiaturas
                                                <span className="rounded-full bg-accent-2 flex justify-center items-center px-1.5 mx-2">
                                                    <span className='text-white font-semibold text-sm'>{colegiaturas.length}</span>
                                                </span>
                                            </h2>
                                            <div className='md:container'>
                                                <table id='tblCondiciones' className='hidden md:inline-table w-full bg-white rounded-lg overflow-hidden md:shadow-md my-5'>
                                                    <thead className='text-white'>
                                                        <tr className='bg-accent-2 flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-0'>
                                                            <th className="border-b-2 md:border-none p-3.5 text-left"></th>
                                                            <th className="border-b-2 md:border-none p-3.5 text-left">No. Mes</th>
                                                            <th className="border-b-2 md:border-none p-3.5 text-left">Mes</th>
                                                            <th className="border-b-2 md:border-none p-3.5 text-left">Fecha Límite de Pago</th>
                                                            <th className="border-b-2 md:border-none p-3.5 text-left">Aplicar Cobro</th>
                                                            <th className="border-b-2 md:border-none p-3.5 text-left">Cobrados</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='flex-1 md:flex-none'>
                                                        {renderColegiaturas()}
                                                    </tbody>
                                                    <tfoot className='text-white'>
                                                        <tr className='bg-accent-2 flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-0'>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th>Cobros Totales: {cantidad_cobrados}</th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                        <hr hidden={colegiaturas.length === 0} />
                                        <div className={`p-4 ${colegiaturas.length === 0 ? 'hidden' : 'flex'} justify-center gap-4 bg-white`}>
                                            <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-4/5 sm:w-1/6 px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" disabled={changesMade} className={`disabled:bg-accent-2/70 ${processingRequest ? 'hidden' : 'inline-flex'} items-center text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center group`}>
                                                <span>Guardar Colegiaturas</span>
                                                <span className="rounded-full bg-white flex justify-center items-center px-1.5 mx-2">
                                                    <span className='group-disabled:text-accent-2/70 group-hover:text-accent-2 text-accent-1 font-semibold text-sm'>{colegiaturas.length}</span>
                                                </span>
                                            </button>
                                            <Link to='/inscripciones-colegiaturas' hidden={processingRequest} className={`${!processingRequest && 'flex'} justify-center items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                        </div>
                                    </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditarColegiaturaRegistroPage