import React, { Component } from 'react';
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading';
import { ChevronRightIcon, DocumentDownloadIcon } from '@heroicons/react/solid';
import BgWatermark from '../../resources/images/ReporteBGWatermark.png';
import { jsPDF } from "jspdf";

const bg_watermark = new Image();
bg_watermark.src = BgWatermark;

const MAX_WIDTH = 1336;
const MAX_HEIGHT = 668;

/* Custom Plugins */
const custom_canvas_background_image = {
    id: 'custom_canvas_background_image',
    beforeDraw: (chart) => {
        if (bg_watermark.complete) {
            const ctx = chart.ctx;
            const { top, left, width, height } = chart.chartArea;
            ctx.drawImage(bg_watermark, left, top, width, height);
        } else {
            bg_watermark.onload = () => chart.draw();
        }
    }
};

const after_load_plugin = {
    id: 'after_load_plugin',
    afterRender: (chart) => {
        var canvas = document.getElementById('cvsJPEG'),
            context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        var base_image = new Image();
        base_image.src = chart.toBase64Image();
        if (base_image.complete) {
            if (base_image.width > base_image.height) {
                if (base_image.width > MAX_WIDTH) {
                    base_image.height = base_image.height * (MAX_WIDTH / base_image.width);
                    base_image.width = MAX_WIDTH;
                }
            } else {
                if (base_image.height > MAX_HEIGHT) {
                    base_image.width = base_image.width * (MAX_HEIGHT / base_image.height);
                    base_image.height = MAX_HEIGHT;
                }
            }
            canvas.width = base_image.width;
            canvas.height = base_image.height;
            context.fillStyle = "white";
            context.fillRect(0, 0, canvas.width, canvas.height);
            context.drawImage(base_image, 0, 0, base_image.width, base_image.height);
        } else {
            base_image.onload = () => chart.render();
        }

    }
};

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    custom_canvas_background_image,
    after_load_plugin
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
            font: {
                size: 18
            }
        },
        custom_canvas_background_image
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels: labels,
    datasets: [
        {
            fill: true,
            label: 'Dataset 2',
            data: [100],
            borderColor: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`,
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};

const loadImage = src =>
    new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
});

class ChartsComponent extends Component {

    constructor() {
        super();
        this.state = {
            options: options,
            data: data,
            dates: null,
            loading: null,
        }
    }

    componentDidMount() {
        const newOptions = { ...options, plugins: { ...options.plugins, title: { ...options.plugins.title, text: this.props.modulo.charAt(0).toUpperCase() + this.props.modulo.slice(1) } } }
        this.setState({ options: newOptions, loading: null });
        const newlabels = this.timeRange(this.props.range);
        this.setState({ data: { ...data, labels: newlabels }, loading: null });
    }

    timeRange = (range) => {
        let labels = [];
        let dates = [];
        let startingDate = new Date(range[0]);
        let endingDate = new Date(range[1]);
        // Validate if data search is for a YEAR or more
        if (startingDate.getFullYear() !== endingDate.getFullYear()) {
            // If there is only one year difference, send the 12 months
            if (Math.abs(startingDate.getFullYear() - endingDate.getFullYear()) === 1) {
                while (startingDate <= endingDate) {
                    labels.push(startingDate.toLocaleDateString('es-MX', { month: 'short' }) + ' ' + startingDate.getFullYear());
                    // Search for month and year
                    dates.push({ type: 'MONTH_YEAR', date: new Date(startingDate) });
                    startingDate = new Date(startingDate.setMonth(startingDate.getMonth() + 1));
                }
            }
            else {
                while (startingDate <= endingDate) {
                    labels.push(startingDate.getFullYear().toString());
                    // Search for year
                    dates.push({ type: 'YEAR', date: new Date(startingDate) });
                    startingDate = new Date(startingDate.setFullYear(startingDate.getFullYear() + 1));
                }
            }
        }
        // Validate if data search is for a MONTH or more
        else if (startingDate.getMonth() !== endingDate.getMonth()) {
            // If there is only one month difference, send the full Month in Days
            if (Math.abs(startingDate.getMonth() - endingDate.getMonth()) === 1) {
                while (startingDate <= endingDate) {
                    labels.push(startingDate.toLocaleDateString('es-MX', { month: 'short' }) + ' ' + startingDate.getDate());
                    // Search for month and day
                    dates.push({ type: 'MONTH_DAY', date: new Date(startingDate) });
                    startingDate = new Date(startingDate.setDate(startingDate.getDate() + 1));
                }
            }
            else {
                while (startingDate <= endingDate) {
                    labels.push(startingDate.toLocaleDateString('es-MX', { month: 'short' }));
                    // Search for month and year
                    dates.push({ type: 'MONTH_YEAR', date: new Date(startingDate) });
                    startingDate = new Date(startingDate.setMonth(startingDate.getMonth() + 1));
                }
            }
        }
        else {
            while (startingDate <= endingDate) {
                labels.push(startingDate.toLocaleDateString('es-MX', { weekday: 'short' }) + ' ' + startingDate.getDate());
                // Search for month and day
                dates.push({ type: 'MONTH_DAY', date: new Date(startingDate) });
                startingDate = new Date(startingDate.setDate(startingDate.getDate() + 1));
            }
        }
        this.setState({ dates: dates });
        return labels;
    }

    collectData = () => {
        this.setState({ loading: true });
        this.props.setLoading(true);
        switch (this.props.modulo) {
            case 'solicitudes':
                const formdata = new FormData();
                formdata.append('parametros', JSON.stringify(this.props.parametros));
                formdata.append('dates', JSON.stringify(this.state.dates));
                formdata.append('order_by', this.props.orderBy);
                RestClient.PostRequest(AppUrl.ReportePeticionesCharts, formdata).then((result) => {
                    if (this.props.chartType === 'pay') {
                        const newData = this.formatDataSets(result);
                        this.setState({ data: newData[0], loading: false });
                    } else {
                        const newDatasets = this.formatDataSets(result);
                        const newlabels = this.timeRange(this.props.range);
                        this.setState(prevState => ({ data: { ...prevState.data, datasets: newDatasets, labels: newlabels }, loading: false }));
                    }
                    this.props.setLoading(false);
                });
                break;

            default:
                break;
        }
    }

    formatDataSets = (_data) => {
        let dataset = [], payLabels = [], borderColor = [], backgroundColor = [], dataCount = [];
        this.props.parametros.forEach(element => {
            if (element.condicion === this.props.orderBy.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
                if (element.operador === 'equals' || element.operador === 'not equals') {
                    element.valor.forEach((val, v_index) => {
                        let r = Math.floor(Math.random() * 256), b = Math.floor(Math.random() * 256), g = Math.floor(Math.random() * 256);
                        if (this.props.chartType === 'pay') {
                            payLabels.push(val.label);
                            borderColor.push(`rgb(${r}, ${b}, ${g})`);
                            backgroundColor.push(`rgba(${r}, ${b}, ${g}, 0.5)`);
                            dataCount.push(_data[v_index].reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0));
                        } else {
                            dataset.push({
                                fill: this.props.chartType === 'área',
                                label: val.label,
                                data: _data[v_index],
                                borderColor: `rgb(${r}, ${b}, ${g})`,
                                backgroundColor: `rgba(${r}, ${b}, ${g}, 0.5)`,
                            });
                        }

                    });
                } else {
                    let r = Math.floor(Math.random() * 256), b = Math.floor(Math.random() * 256), g = Math.floor(Math.random() * 256);
                    dataset.push({
                        fill: this.props.chartType === 'área',
                        label: this.props.orderBy.charAt(0).toUpperCase() + this.props.orderBy.slice(1) + (element.operador === 'empty' ? ': Esta Vacío' : ': No Esta Vacío'),
                        data: _data[0],
                        borderColor: `rgb(${r}, ${b}, ${g})`,
                        backgroundColor: `rgba(${r}, ${b}, ${g}, 0.5)`,
                    });
                }
            }
        });
        if (this.props.chartType === 'pay') {
            dataset.push({
                labels: payLabels,
                datasets: [
                    {
                        label: '# de ' + this.props.modulo,
                        data: dataCount,
                        borderColor: borderColor,
                        backgroundColor: backgroundColor,
                    }]
            });
        }
        return dataset;
    }

    renderChartType = () => {
        switch (this.props.chartType) {
            case 'área':
                return <Line ref={(instance) => { this.Chart = instance; }} options={this.state.options} data={this.state.data} />;
            case 'columna':
                return <Bar ref={(instance) => { this.Chart = instance; }} options={this.state.options} data={this.state.data} />;
            case 'línea':
                return <Line ref={(instance) => { this.Chart = instance; }} options={this.state.options} data={this.state.data} />;
            case 'pay':
                return <Pie ref={(instance) => { this.Chart = instance; }} options={this.state.options} data={this.state.data} />;
            default:
                break;
        }
    }

    changeColors = () => {
        const newDataColors = this.state.data.datasets.map(d => {
            let r = Math.floor(Math.random() * 256), b = Math.floor(Math.random() * 256), g = Math.floor(Math.random() * 256);
            if (this.props.chartType === 'pay') {
                const newBorderColors = [];
                const newBackgroundColors = [];
                for (let index = 0; index < d.borderColor.length; index++) {
                    r = Math.floor(Math.random() * 256); b = Math.floor(Math.random() * 256); g = Math.floor(Math.random() * 256);
                    newBorderColors.push(`rgb(${r}, ${b}, ${g})`);
                    newBackgroundColors.push(`rgba(${r}, ${b}, ${g}, 0.5)`);
                }
                return { ...d, borderColor: newBorderColors, backgroundColor: newBackgroundColors }
            } else return { ...d, borderColor: `rgb(${r}, ${b}, ${g})`, backgroundColor: `rgba(${r}, ${b}, ${g}, 0.5)` }
        });
        this.setState(prevState => ({ data: { ...prevState.data, datasets: newDataColors } }));
    }

    downloadImage = (type) => {
        var canvas = document.getElementById('cvsJPEG');
        var a = document.createElement('a');
        if (type === 'image/jpeg') {
            a.href = canvas.toDataURL("image/jpeg");
        } else a.href = this.Chart.toBase64Image(type, 1);
        a.download = this.props.modulo + (type === 'image/png' ? '.png' : '.jpeg');
        a.click();
        a.remove();
    }

    downloadPDF = () => {
        var canvas = document.getElementById('cvsJPEG');
        var pdf = new jsPDF('landscape');
        var max_width = pdf.internal.pageSize.getWidth();
        var max_height = pdf.internal.pageSize.getHeight();
        loadImage(canvas.toDataURL("image/jpeg", 1)).then(imgData => {
            if (imgData.width > imgData.height) {
                if (imgData.width > max_width) {
                    imgData.height = imgData.height * (max_width / imgData.width);
                    imgData.width = max_width;
                }
            } else {
                if (imgData.height > max_height) {
                    imgData.width = imgData.width * (max_height / imgData.height);
                    imgData.height = max_height;
                }
            }
            pdf.addImage(imgData, 'JPEG', 0, 0, imgData.width, imgData.height);
            pdf.save(this.props.modulo + ".pdf");
        });
    }

    render() {
        return (
            <div id='divChart' hidden={this.state.loading === null || !this.props.visible} className='p-5 w-full bg-gray-100 rounded-lg shadow-lg'>
                {this.state.loading ?
                    <div className='flex w-full justify-center bg-white'>
                        <Loading />
                    </div> :
                    <>
                        <div className='w-full flex justify-between pb-2.5'>
                            <h1 className='pl-3 font-normal text-2xl text-accent-1'>Gráfica</h1>
                            <div className='flex gap-4'>
                                {/* Change Colors */}
                                <button type='button' onClick={this.changeColors} className='inline-flex justify-between bg-white rounded-lg shadow-lg px-3 py-2 gap-2 hover:bg-accent-2 duration-300 items-center group'>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 rainbow-gradient" viewBox="0 0 20 20">
                                        <linearGradient id="linear-gradient">
                                            <stop offset="0%" stopColor="#ff0000" />
                                            <stop offset="10%" stopColor="#ff9a00" />
                                            <stop offset="20%" stopColor="#d1de21" />
                                            <stop offset="30%" stopColor="#4fdc4a" />
                                            <stop offset="40%" stopColor="#3fdad8" />
                                            <stop offset="50%" stopColor="#2fc9e2" />
                                            <stop offset="60%" stopColor="#1c7fee" />
                                            <stop offset="70%" stopColor="#5f15f2" />
                                            <stop offset="80%" stopColor="#ba0cf8" />
                                            <stop offset="90%" stopColor="#fb07d9" />
                                            <stop offset="90%" stopColor="#fb07d9" />
                                            <stop offset="100%" stopColor="#ff0000" />
                                        </linearGradient>
                                        <path fillRule="evenodd" fill='url(#linear-gradient)' d="M4 2a2 2 0 00-2 2v11a3 3 0 106 0V4a2 2 0 00-2-2H4zm1 14a1 1 0 100-2 1 1 0 000 2zm5-1.757l4.9-4.9a2 2 0 000-2.828L13.485 5.1a2 2 0 00-2.828 0L10 5.757v8.486zM16 18H9.071l6-6H16a2 2 0 012 2v2a2 2 0 01-2 2z" clipRule="evenodd" />
                                    </svg>
                                    <span className='text-accent-1 font-bold text-sm group-hover:text-white duration-300'>Cambiar Colores</span>
                                </button>
                                {/* Export Image */}
                                <div className='dropdown relative inline-block text-left'>
                                    <button type='button' className='inline-flex justify-between bg-white rounded-lg shadow-lg px-3 py-2 gap-2 hover:bg-accent-2 duration-300 items-center group' aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items">
                                        <span className='text-accent-1 font-bold text-sm group-hover:text-white duration-300'>Exportar Imagen</span>
                                        <ChevronRightIcon className='w-5 h-5 text-contrast-1 group-focus:rotate-90 duration-300' />
                                    </button>
                                    <div className="dropdown-menu invisible origin-top-left -translate-y-2 scale-95 transform opacity-0 transition-all duration-300">
                                        <div className="absolute left-0 mt-1 w-[11.5rem] origin-top-left divide-y-2 divide-gray-100 rounded-md bg-blue-100 shadow-lg outline-none" aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items" role="menu">
                                            <div className='p-1'>
                                                <button type='button' onClick={() => this.downloadImage('image/png')} className='rounded text-accent-1 hover:text-accent-2 hover:bg-blue-200 pl-2 py-2 w-full text-left font-medium'>
                                                    PNG
                                                </button>
                                            </div>
                                            <div className='p-1'>
                                                <button type='button' onClick={() => this.downloadImage('image/jpeg')} className='rounded text-accent-1 hover:text-accent-2 hover:bg-blue-200 pl-2 py-2 w-full text-left font-medium'>
                                                    JPEG
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Export PDF */}
                                <button type='button' onClick={this.downloadPDF} className='inline-flex justify-between bg-white rounded-lg shadow-lg px-3 py-2 gap-2 hover:bg-accent-2 duration-300 items-center group'>
                                    <DocumentDownloadIcon className='w-5 h-5 text-contrast-1' />
                                    <span className='text-accent-1 font-bold text-sm group-hover:text-white duration-300'>Exportar PDF</span>
                                </button>
                            </div>
                        </div>
                        <div className={`${this.props.chartType === 'pay' && '2xl:px-80 xl:px-60 lg:px-40 md:px-20'} w-full`}>
                            {this.renderChartType()}
                        </div>
                        <canvas id='cvsJPEG' className='absolute hidden' width={MAX_WIDTH} height={MAX_HEIGHT}></canvas>
                    </>
                }
            </div>
        );
    }
}

export default ChartsComponent;