import React, { useState } from 'react'
import { CustomModalContext } from '../context/CustomModalContext';

export const CustomModalProvider = ({ children }) => {
    const [modals, setModals] = useState([]);

    const createModal = (ref, modalProps) => {
        if (modals.some((m) => m.ref === ref)) return;

        const newModal = { ref, id: Date.now(), modalProps };
        setModals([...modals, newModal]);
    };

    const addModal = (modalProps) => {
        const newModal = { id: Date.now(), modalProps };
        setModals([...modals, newModal]);
    };

    const removeModal = (id) => {
        const updatedModals = modals.filter((modal) => modal.id !== id);
        setModals(updatedModals);
    };

    return (
        <CustomModalContext.Provider value={{ modals, createModal, addModal, removeModal }}>
            {children}
        </CustomModalContext.Provider>
    )
}
