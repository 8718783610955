import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PDFViewerPage = () => {
    const { fileName } = useParams();

    const [pdfFile, setPdfFile] = useState(null);
    const [numPages, setNumPages] = useState();

    useEffect(() => {
        async function loadPDF() {
            try {
                const response = await fetch(`/files/${fileName}.pdf`);
                const blob = await response.blob();
                const file = new File([blob], `${fileName}.pdf`, { type: 'application/pdf' });
                setPdfFile(file);
            } catch (error) {
                console.error('Error fetching PDF:', error);
            }
        }
        loadPDF();
    }, [fileName])

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    function onDocumentLoadError(error) {
        console.error(error);
    }

    return (
        <div>
            <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
                {Array.from(new Array(numPages), (_, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
            </Document>
        </div>
    )
}

export default PDFViewerPage