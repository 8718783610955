import { CalendarIcon, PaperAirplaneIcon, StarIcon, UserIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import UsuariosRecordatoriosTable from '../../components/recordatorio-registros/UsuariosRecordatoriosTable';
import TextEditorInput from '../../components/form-controls/TextEditorInput';
import Loading from '../../components/Loading';
var CryptoJS = require("crypto-js");

// Create a constructor for functional component to get permissions
const useConstructor = (callBack = () => { }) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
}

//This has to be a functional component to use 'useParams()' from react-router-dom
const EditarRecordatorioRegistroPage = (props) => {

    const [tipoId, setTipoId] = useState(0);
    const [areaId, setAreaId] = useState(0);
    const [usuario, setUsuario] = useState('');
    const [usuarios, setUsuarios] = useState([]);
    const [titulo, setTitulo] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [enviado, setEnviado] = useState(true);
    const [fechaEnviado, setFechaEnviado] = useState('');
    const [wasEmailed, setWasEmailed] = useState(true);
    
    const [tipos, setTipos] = useState([]);
    const [areas, setAreas] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [usersAccess, setUsersAccess] = useState([]);

    //Get the passed :id from parent and pass it to the component to render it
    const recordatorio = useParams();

    /// Decript permissions
    useConstructor(() => {
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUsersAccess(_usersAccess);
    });

    // Get Pago by Id and set days for cafetería
    useEffect(() => {
        RestClient.GetRequest(AppUrl.RecordatorioRegistroById + recordatorio.id).then((result) => {
            const { tipo_id, area_id, usuario, usuarios, titulo, mensaje, enviado, fecha_enviado, correo } = result;
            setTipoId(tipo_id);
            setAreaId(area_id);
            setUsuario(usuario);
            setUsuarios(usuarios);
            setTitulo(titulo);
            setMensaje(mensaje);
            setEnviado(enviado === 1);
            setFechaEnviado(fecha_enviado);
            setWasEmailed(correo === 1);
            
            setLoaded(true);
        });
    }, [recordatorio.id]);
    // Get collections
    useEffect(() => {
        // Get Tipos
        RestClient.GetRequest(AppUrl.RecordatorioTipos).then((result) => {
            setTipos(result);
        });
        // Get Areas
        RestClient.GetRequest(AppUrl.RecordatorioAreas).then((result) => {
            setAreas(result);
        });
    }, []);

    if (usersAccess.find(ua => ua.modulo_name === 'recordatorios' && ua.permiso_name === 'update') === undefined) {
        return <Navigate to='/access-denied' />
    }
    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60 ml-0' : 'sm:ml-20 ml-0'} duration-300 px-2 sm:pt-24 pt-20 pb-6 sm:px-4 md:px-8 bg-gray-100`}>
            <div className='sm:px-0 px-3'>
                {/* Page Title */}
                <div className='flex w-full'>
                    <h1 className='sm:text-xl text-lg font-medium'>Ver Recordatorio</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                    {usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                    <p className='text-gray-400'>Automatización</p>
                    <span className='text-xs'>/</span>
                    <Link to='/recordatorio-registros' className='font-semibold hover:underline'>Recordatorios</Link>
                    <span className='text-xs'>/</span>
                    <p className='text-dark-purple font-semibold'>Ver Recordatorio</p>
                </div>
            </div>
            <div className='w-full flex justify-center mt-6'>
                <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                    <div className='sm:w-11/12 w-full sm:my-8'>
                        <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                            <div className='bg-gray-50'>
                                <div className='bg-white border'>
                                    <h1 className='text-accent-1 sm:text-2xl text-xl p-4'>Recordatorio {loaded && (enviado ? 'Enviado' : 'Por Enviar')}</h1>
                                </div>
                                {!loaded ? 
                                <div className='flex w-full justify-center bg-white'>
                                    <Loading />
                                </div> :
                                <form className='mt-4'>
                                    <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                        <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Tipo</h2>
                                        <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                            <div className=''>
                                                <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group my-1">
                                                    <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                        <StarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                    </div>
                                                    <div className="relative z-0 w-full group">
                                                        <select id='selectTipo' name="tipo" defaultValue={tipoId} disabled className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                            <option value="0" disabled className='text-gray-400'>-- Seleccione Tipo --</option>
                                                            {tipos.map(tipo => (
                                                                <option key={tipo.id} value={tipo.id}>{tipo.nombre}</option>
                                                            ))}
                                                        </select>
                                                        <label htmlFor="selectTipo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Tipo</label>
                                                    </div>
                                                </div>
                                                {/* <span className='text-sm text-red-500'>{this.state.errors.tipo_id}</span> */}
                                            </div>
                                            <div className=''>
                                                <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                    <div className="w-1/12 items-center flex justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-2 mx-0.5' viewBox="0 0 512 512">
                                                            <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" />
                                                        </svg>
                                                    </div>
                                                    <div className="relative z-0 w-full group">
                                                        <select id='selectArea' name="area" defaultValue={areaId} disabled className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                            <option value="0" disabled className='text-gray-400'>-- Seleccione Área --</option>
                                                            {areas.map(area => (
                                                                <option key={area.id} value={area.id}>{area.nombre}</option>
                                                            ))}
                                                        </select>
                                                        <label htmlFor="selectArea" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Área</label>
                                                    </div>
                                                </div>
                                                {/* <span className='text-sm text-red-500'>{this.state.errors.area_id}</span> */}
                                            </div>
                                            <div className=''>
                                                <div className="w-full inline-flex gap-4">
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                            <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <p id='pOperador' className='block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer'>{usuario}</p>
                                                            <label htmlFor="pOperador" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Operación realizada por</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="space-y-4 md:space-y-0 w-full p-4 text-gray-800 items-center mb-4">
                                        <h2 className="text-accent-1 font-semibold">Información Usuarios</h2>
                                        <div className='md:px-5 md:py-5'>
                                            <UsuariosRecordatoriosTable 
                                                data={usuarios} 
                                                title={`El recordatorio ${enviado ? 'se envió' : 'será enviado'} a los siguientes ${usuarios.length} usuario(s)`} 
                                                controls={false}
                                            />
                                            {/* <span className='text-sm text-red-500'>{this.state.errors.usuarios}</span> */}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="md:flex-col space-y-4 w-full p-8 md:px-20  text-gray-500 items-center mb-4">
                                        <h5 className='text-accent-1 mb-8 max-w-sm'>Información Recordatorio</h5>
                                        <div className=''>
                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group bg-white">
                                                <div className="w-1/12 flex justify-center items-center ml-1">
                                                    <PaperAirplaneIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                </div>
                                                <div className="relative z-0 w-full group">
                                                    <input defaultValue={titulo} readOnly id='txtTitulo' type="text" name="titulo" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                    <label htmlFor="txtTitulo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Título</label>
                                                </div>
                                            </div>
                                            {/* <span className='text-sm text-red-500'>{this.state.errors.titulo}</span> */}
                                        </div>
                                        <div className=''>
                                            <h5 className='mb-2 text-sm text-gray-400 peer-focus-within:text-accent-1'>Mensaje</h5>
                                            <div className='bg-white px-4 py-2 border-2 rounded border-gray-400 focus-within:border-accent-2 peer'>
                                                <TextEditorInput defaultValue={mensaje} disabled />
                                            </div>
                                            {/* <span className='text-sm text-red-500'>{this.state.errors.mensaje}</span> */}
                                        </div>
                                        <div className='border rounded bg-white p-3'>
                                            <div className='sm:p-4 md:px-6 lg:px-8 px-2'>
                                                <p className='mb-8 text-base text-slate-600'>Enviado: <span className='font-semibold'>{enviado ? 'Sí' : 'No'}</span></p>
                                                <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 bg-white group mb-8">
                                                    <div className="w-1/12 pt-2 flex justify-center">
                                                        <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                    </div>
                                                    <div className="relative z-0 w-full group">
                                                        <input /* onChange={(e) => this.setState({ fechaEnvio: e.target.value })} */ defaultValue={fechaEnviado} readOnly id='dateFechaEnvio' type="datetime-local" name="fecha_envio" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                        <label htmlFor="dateFechaEnvio" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">{enviado ? 'Fecha de envío' : 'Programado para'}</label>
                                                    </div>
                                                </div>
                                                <p className='text-base text-slate-600'>También {enviado ? 'se envío' : 'se enviará'} al correo: <span className='font-semibold'>{wasEmailed ? 'Sí' : 'No'}</span></p>
                                                {/* <span className='text-sm text-red-500'>{this.state.errors.fecha_envio}</span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='p-4 flex justify-center gap-4 bg-white'>
                                        <Link to='/recordatorio-registros' className='flex justify-center items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>Regresar</Link>
                                    </div>
                                </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditarRecordatorioRegistroPage