import { BellIcon, CogIcon, PaperAirplaneIcon, UserCircleIcon, UserIcon } from '@heroicons/react/outline';
import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import ConfiguracionesPanel from '../../components/perfil/ConfiguracionesPanel';
import DatosPanel from '../../components/perfil/DatosPanel';
import PerfilPanel from '../../components/perfil/PerfilPanel';
import { ChevronDownIcon } from '@heroicons/react/solid';

const PerfilPage = (props) => {
    // Get Auth user
    const user = useOutletContext().user;
    // Use Params
    const config = useParams();
    // Panel Info
    const [activePanel, setActivePanel] = useState('Mi Perfil');
    const [icon, setIcon] = useState(<UserCircleIcon className='w-5 h-5 flex-shrink-0 text-accent-2' />);
    const [profileMenu, setProfileMenu] = useState(false);

    useEffect(() => {
        if (config && Object.keys(config).length > 0) {
            setActivePanel('Configuraciones');
            setIcon(<CogIcon className='w-5 h-5 flex-shrink-0 text-accent-2' />);
        }
    }, [config]);

    useEffect(() => {
        const menu = document.getElementById("profileMenu");
        document.addEventListener("click", (event) => {
            const isClickInside = menu.contains(event.target);
            if (!isClickInside) {
                setProfileMenu(false);
            }
        });
    }, []);
    

    function handleSectionSelect(activePanel) {
        setActivePanel(activePanel);
        setProfileMenu(false);
        switch (activePanel) {
            case 'Mi Perfil':
                setIcon(<UserCircleIcon className='w-5 h-5 flex-shrink-0 text-accent-2' />);
                break;
            case 'Datos Personales':
                setIcon(<UserIcon className='w-5 h-5 flex-shrink-0 text-accent-2' />);
                break;
            case 'Configuraciones':
                setIcon(<CogIcon className='w-5 h-5 flex-shrink-0 text-accent-2' />);
                break;
            default:
                break;
        }
    }

    function renderPanel() {
        switch (activePanel) {
            case 'Mi Perfil':
                return <PerfilPanel />
            case 'Datos Personales':
                return <DatosPanel id={user.user_type_id} user_type={user.user_type} />
            case 'Configuraciones':
                return <ConfiguracionesPanel id={user.id} />
            default:
                return <PerfilPanel />
        }
    }

    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60 ml-0' : 'sm:ml-20 ml-0'} duration-300 px-2 sm:pt-24 pt-20 pb-6 sm:px-4 md:px-8 bg-gray-100`}>
            {/* Page Title */}
            <div className='sm:px-0 px-3'>
                <div className='flex w-full'>
                    <h1 className='sm:text-xl text-lg font-medium'>Mi Perfil</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                    <Link to='/perfil' className='text-dark-purple font-semibold hover:underline'>Perfil</Link>
                </div>
            </div>
            {/* Main Section */}
            <div className='w-full flex justify-center sm:mt-6 mt-3'>
                <div className='w-full bg-white rounded shadow-lg mb-8'>
                    <div className='flex flex-col md:flex-row md:-mr-px'>
                        {/* Sidebar */}
                        <div className='flex flex-nowrap md:overflow-x-scroll md:block md:overflow-auto px-3 py-4 md:py-6 border-b md:border-b-0 md:border-r border-slate-200 min-w-[15rem] space-y-3'>
                            <div className='block md:hidden w-full'>
                                <div id='profileMenu' className='relative inline-block text-left w-2/3 z-10'>
                                    <button type="button" onClick={() => setProfileMenu(prevState => (!prevState))} className='focus:outline-accent-2 flex w-full items-center justify-around px-2.5 py-2 rounded-md whitespace-nowrap bg-blue-50 group' aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items">
                                        <span className="sr-only">Open profile menu</span>
                                        {icon}
                                        <span className='text-sm font-medium text-accent-2'>{activePanel}</span>
                                        <ChevronDownIcon className='w-5 h-5 text-accent-2' />
                                    </button>
                                    <div className={`dropdown-menu ${!profileMenu && 'invisible opacity-0 -translate-y-2 scale-95'} sm:origin-top-right origin-top-left transform transition-all duration-300`}>
                                        <div className="absolute left-0 mt-1 w-[11.5rem] origin-top-left divide-y-2 divide-gray-100 rounded-md bg-white shadow-lg outline-none border border-slate-200 -z-50" aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items" role="menu">
                                            <ul className='block mr-0 space-y-2'>
                                                <li className='mr-0.5 md:mr-0 md:mb-0.5'>
                                                    <button type='button' disabled={user === null} onClick={() => handleSectionSelect('Mi Perfil')} className={`hover:bg-blue-50 focus:outline-accent-2 flex w-full items-center px-2.5 py-2 rounded whitespace-nowrap ${activePanel === 'Mi Perfil' && 'bg-blue-50'} group`}>
                                                        <UserCircleIcon className={`group-hover:text-accent-2 w-5 h-5 flex-shrink-0 ${activePanel === 'Mi Perfil' ? 'text-accent-2' : 'text-slate-400'} mr-2`} />
                                                        <span className={`group-hover:text-accent-2 text-sm font-medium ${activePanel === 'Mi Perfil' ? 'text-accent-2' : 'text-slate-600'}`}>Mi Perfil</span>
                                                    </button>
                                                </li>
                                                <li className='mr-0.5 md:mr-0 md:mb-0.5'>
                                                    <button type='button' disabled={user === null} onClick={() => handleSectionSelect('Datos Personales')} className={`hover:bg-blue-50 focus:outline-accent-2 flex w-full items-center px-2.5 py-2 rounded whitespace-nowrap ${activePanel === 'Datos Personales' && 'bg-blue-50'} group`}>
                                                        <UserIcon className={`group-hover:text-accent-2 w-5 h-5 flex-shrink-0 ${activePanel === 'Datos Personales' ? 'text-accent-2' : 'text-slate-400'} mr-2`} />
                                                        <span className={`group-hover:text-accent-2 text-sm font-medium ${activePanel === 'Datos Personales' ? 'text-accent-2' : 'text-slate-600'}`}>Datos Personales</span>
                                                    </button>
                                                </li>
                                                <li className='mr-0.5 md:mr-0 md:mb-0.5'>
                                                    <button type='button' disabled={user === null} onClick={() => handleSectionSelect('Configuraciones')} className={`hover:bg-blue-50 focus:outline-accent-2 flex w-full items-center px-2.5 py-2 rounded whitespace-nowrap ${activePanel === 'Configuraciones' && 'bg-blue-50'} group`}>
                                                        <CogIcon className={`group-hover:text-accent-2 w-5 h-5 flex-shrink-0 ${activePanel === 'Configuraciones' ? 'text-accent-2' : 'text-slate-400'} mr-2`} />
                                                        <span className={`group-hover:text-accent-2 text-sm font-medium ${activePanel === 'Configuraciones' ? 'text-accent-2' : 'text-slate-600'}`}>Configuraciones</span>
                                                    </button>
                                                </li>
                                                <li className='mr-0.5 md:mr-0 md:mb-0.5'>
                                                    <Link to={'/notificaciones'} disabled={user === null} onClick={() => handleSectionSelect('Notificaciones')} className={`hover:bg-blue-50 focus:outline-accent-2 flex w-full items-center px-2.5 py-2 rounded whitespace-nowrap ${activePanel === 'Notificaciones' && 'bg-blue-50'} group`}>
                                                        <BellIcon className={`group-hover:text-accent-2 w-5 h-5 flex-shrink-0 ${activePanel === 'Notificaciones' ? 'text-accent-2' : 'text-slate-400'} mr-2`} />
                                                        <span className={`group-hover:text-accent-2 text-sm font-medium ${activePanel === 'Notificaciones' ? 'text-accent-2' : 'text-slate-600'}`}>Mis Notificaciones</span>
                                                    </Link>
                                                </li>
                                                <li className='mr-0.5 md:mr-0 md:mb-0.5'>
                                                    <Link to={'/recordatorios'} disabled={user === null} onClick={() => handleSectionSelect('Recordatorios')} className={`hover:bg-blue-50 focus:outline-accent-2 flex w-full items-center px-2.5 py-2 rounded whitespace-nowrap ${activePanel === 'Recordatorios' && 'bg-blue-50'} group`}>
                                                        <PaperAirplaneIcon className={`group-hover:text-accent-2 w-5 h-5 rotate-45 flex-shrink-0 ${activePanel === 'Recordatorios' ? 'text-accent-2' : 'text-slate-400'} mr-2`} />
                                                        <span className={`group-hover:text-accent-2 text-sm font-medium ${activePanel === 'Recordatorios' ? 'text-accent-2' : 'text-slate-600'}`}>Mis Recordatorios</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='hidden md:block'>
                                <ul className='flex flex-nowrap md:block mr-3 md:mr-0 md:space-y-2'>
                                    <li className='mr-0.5 md:mr-0 md:mb-0.5'>
                                        <button type='button' disabled={user === null} onClick={() => handleSectionSelect('Mi Perfil')} className={`hover:bg-blue-50 focus:outline-accent-2 flex w-full items-center px-2.5 py-2 rounded whitespace-nowrap ${activePanel === 'Mi Perfil' && 'bg-blue-50'} group`}>
                                            <UserCircleIcon className={`group-hover:text-accent-2 w-5 h-5 flex-shrink-0 ${activePanel === 'Mi Perfil' ? 'text-accent-2' : 'text-slate-400'} mr-2`} />
                                            <span className={`group-hover:text-accent-2 text-sm font-medium ${activePanel === 'Mi Perfil' ? 'text-accent-2' : 'text-slate-600'}`}>Mi Perfil</span>
                                        </button>
                                    </li>
                                    <li className='mr-0.5 md:mr-0 md:mb-0.5'>
                                        <button type='button' disabled={user === null} onClick={() => handleSectionSelect('Datos Personales')} className={`hover:bg-blue-50 focus:outline-accent-2 flex w-full items-center px-2.5 py-2 rounded whitespace-nowrap ${activePanel === 'Datos Personales' && 'bg-blue-50'} group`}>
                                            <UserIcon className={`group-hover:text-accent-2 w-5 h-5 flex-shrink-0 ${activePanel === 'Datos Personales' ? 'text-accent-2' : 'text-slate-400'} mr-2`} />
                                            <span className={`group-hover:text-accent-2 text-sm font-medium ${activePanel === 'Datos Personales' ? 'text-accent-2' : 'text-slate-600'}`}>Datos Personales</span>
                                        </button>
                                    </li>
                                    <li className='mr-0.5 md:mr-0 md:mb-0.5'>
                                        <button type='button' disabled={user === null} onClick={() => handleSectionSelect('Configuraciones')} className={`hover:bg-blue-50 focus:outline-accent-2 flex w-full items-center px-2.5 py-2 rounded whitespace-nowrap ${activePanel === 'Configuraciones' && 'bg-blue-50'} group`}>
                                            <CogIcon className={`group-hover:text-accent-2 w-5 h-5 flex-shrink-0 ${activePanel === 'Configuraciones' ? 'text-accent-2' : 'text-slate-400'} mr-2`} />
                                            <span className={`group-hover:text-accent-2 text-sm font-medium ${activePanel === 'Configuraciones' ? 'text-accent-2' : 'text-slate-600'}`}>Configuraciones</span>
                                        </button>
                                    </li>
                                    <li className='mr-0.5 md:mr-0 md:mb-0.5'>
                                        <Link to={'/notificaciones'} disabled={user === null} onClick={() => handleSectionSelect('Notificaciones')} className={`hover:bg-blue-50 focus:outline-accent-2 flex w-full items-center px-2.5 py-2 rounded whitespace-nowrap ${activePanel === 'Notificaciones' && 'bg-blue-50'} group`}>
                                            <BellIcon className={`group-hover:text-accent-2 w-5 h-5 flex-shrink-0 ${activePanel === 'Notificaciones' ? 'text-accent-2' : 'text-slate-400'} mr-2`} />
                                            <span className={`group-hover:text-accent-2 text-sm font-medium ${activePanel === 'Notificaciones' ? 'text-accent-2' : 'text-slate-600'}`}>Mis Notificaciones</span>
                                        </Link>
                                    </li>
                                    <li className='mr-0.5 md:mr-0 md:mb-0.5'>
                                        <Link to={'/recordatorios'} disabled={user === null} onClick={() => handleSectionSelect('Recordatorios')} className={`hover:bg-blue-50 focus:outline-accent-2 flex w-full items-center px-2.5 py-2 rounded whitespace-nowrap ${activePanel === 'Recordatorios' && 'bg-blue-50'} group`}>
                                            <PaperAirplaneIcon className={`group-hover:text-accent-2 w-5 h-5 rotate-45 flex-shrink-0 ${activePanel === 'Recordatorios' ? 'text-accent-2' : 'text-slate-400'} mr-2`} />
                                            <span className={`group-hover:text-accent-2 text-sm font-medium ${activePanel === 'Recordatorios' ? 'text-accent-2' : 'text-slate-600'}`}>Mis Recordatorios</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* Panel */}
                        {!user ? <div className='flex w-full justify-center bg-white'>
                            <Loading />
                        </div> :
                            renderPanel()}
                    </div>
                </div>
            </div>
        </div>
    );


}

export default PerfilPage;