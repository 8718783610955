import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { CogIcon, XIcon } from '@heroicons/react/solid';
import ReactModal from 'react-modal';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import { toast } from 'react-toastify';
import { AddButton, CustomLoader, DeleteButton, EditButton, FilterComponent, NoDataComponent, customStyles } from '../CustomTableComponents';
import ExpandableColegiaturasTable from './ExpandableColegiaturasTable';

ReactModal.setAppElement('#root');

const ColegiaturaRegistrosTable = ({ data, controls, hasUpdated }) => {

    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [pending, setPending] = React.useState(true);
    const [filteredItems, setFilteredItems] = React.useState([{}]);
    const [showModal, setShowModal] = React.useState(false);
    const [dataId, setDataId] = React.useState(0);
    const [processingRequest, setProcessingRequest] = React.useState(false);

    // eslint-disable-next-line
    handleOpenModal = handleOpenModal.bind(this);
    // eslint-disable-next-line
    handleCloseModal = handleCloseModal.bind(this);

    const actions = React.useMemo(() => {
        return (
            <AddButton route='/inscripciones-colegiaturas/crear-colegiatura' disabled={!controls.includes('create')} />
        );
    }, [controls]);

    function handleOpenModal(dataId) {
        setShowModal(true);
        setDataId(dataId);
    }
    function handleCloseModal() {
        setShowModal(false);
    }
    function numeroConComas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    function formatDate(date) {
        const [datePart, timePart] = date.split(" ");
        const [year, month, day] = datePart.split("-").map(Number);
    
        const formattedDate = new Intl.DateTimeFormat('es-MX', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(new Date(year, month - 1, day));
    
        if (!timePart) {
            return formattedDate;
        }
    
        const [hour, minute, second] = timePart.split(":").map(Number);
        const formattedTime = new Date(year, month - 1, day, hour, minute, second)
            .toLocaleTimeString('en-US', {
                hour12: true,
                hour: 'numeric',
                minute: '2-digit',
                second: '2-digit'
            });
    
        return `${formattedDate} ${formattedTime}`;
    }
    function dateStort(rowA, rowB) {
        return new Date(rowA.fecha_ultimo_cobro) - new Date(rowB.fecha_ultimo_cobro);
    }

    function handleDelete() {
        //Disable controls while request is processed
        setProcessingRequest(true);

        RestClient.GetRequest(AppUrl.DeleteInscripcion + dataId).then((result) => {
            setProcessingRequest(false);
            if (result.status === 200) {
                toast.success(result.message);
            }
            else if (result.status === 204) {
                toast.warn(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
            handleCloseModal();
            //TO DO: Refresh page upon delete
        });
    }

    useEffect(() => {
        if (data) {
            setFilteredItems(data.filter(
                item => (item.nombre && item.nombre.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.tipo && item.tipo.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.valor && item.valor.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.created_at && item.created_at.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.updated_at && item.updated_at.toLowerCase().includes(filterText.toLowerCase()))
            ));
            setPending(false);
        }
    }, [data, filterText])

    const columns = [
        {
            cell: row => (
                <>
                    <div className="dropdown relative text-left">
                        <span className="rounded-md shadow-sm">
                            <button className="group max-w-xs rounded-full flex items-center text-sm focus:outline-none" type="button" aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items">
                                <span className="sr-only">Open table menu</span>
                                <CogIcon className='w-5 h-5 text-accent-1 hover:rotate-45 transition duration-300 group-focus:rotate-45' />
                            </button>
                        </span>
                        <div className={`dropdown-menu absolute invisible ${(row.id >= filteredItems.length - 1 && 'origin-bottom-left') || 'origin-top-left'} -translate-y-2 scale-95 transform opacity-0 transition-all duration-300 z-50`}>
                            <div className={`left-4 translate-x-6 ${(row.id >= filteredItems.length - 1 && '-translate-y-24 origin-bottom-left') || 'origin-top-left'} mt-2 w-36  divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none z-50`} aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items" role="menu">
                                <div className="py-1">
                                    <EditButton itemId={row.id} route={`/inscripciones-colegiaturas/editar-colegiatura-registro/${row.id}`} disabled={!controls.includes('update')} />
                                    <DeleteButton itemId={row.id} onClickDelete={() => handleOpenModal(row.id)} disabled={!controls.includes('delete')} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ),

            button: true,
            width: '100px'
        },
        {
            name: 'Tipo',
            selector: row => row.tipo.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
            sortable: true,
            grow: 0.2
        },
        {
            name: 'Nivel',
            selector: row => row.nivel.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
            sortable: true,
            grow: 0.2
        },
        {
            name: 'Ciclo',
            selector: row => row.ciclo,
            sortable: true,
            grow: 1.6
        },
        {
            name: 'Precio',
            selector: row => `$ ${numeroConComas(row.precio)}`,
            sortable: true,
            grow: 0.2
        },
        {
            name: 'Último Cobro',
            selector: row => row.fecha_ultimo_cobro,
            cell: row => row.fecha_ultimo_cobro === null ? <em className='text-gray-500'>(Aún no se cobra)</em> : formatDate(row.fecha_ultimo_cobro),
            sortable: true,
            sortFunction: dateStort,
            grow: 1
        },
        {
            name: 'Cobrados',
            selector: row => row.cantidad_cobrados,
            sortable: true,
            grow: 0.2
        },
        {
            name: 'Colegiaturas',
            selector: row => row.cantidad_colegiaturas,
            sortable: true,
            grow: 0.2
        },
        {
            name: 'Fecha Registrado',
            selector: row => row.created_at,
            sortable: true,
            grow: 1.5
        },
        {
            name: 'Fecha Actualizado',
            selector: row => row.updated_at,
            sortable: true,
            grow: 1.5
        }
    ];

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <>
            <DataTable
                title='Colegiaturas'
                columns={columns}
                data={filteredItems}
                expandableRows
                expandableRowsComponent={ExpandableColegiaturasTable}
                expandableRowsComponentProps={{ hasUpdated }}
                actions={actions}
                customStyles={customStyles}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                striped
                progressPending={pending}
                progressComponent={<CustomLoader />}
                noDataComponent={<NoDataComponent />}
            />

            <ReactModal
                closeTimeoutMS={800}
                isOpen={showModal}
                contentLabel="Default Confirmation Modal"
                className='Modal'
                overlayClassName="Overlay"
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
            >
                <div className="flex flex-col items-start">
                    <div className="flex items-center w-full border-b-2">
                        <div className="text-gray-900 font-medium text-xl">Eliminar Colegiatura</div>
                        <button onClick={handleCloseModal} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                            <XIcon className='w-5 h-5' />
                        </button>
                    </div>
                    <div className="border-b-2 w-full py-4">
                        <p>¿Estas seguro que quieres eliminar esta colegiatura?</p>
                    </div>
                    <div className="flex justify-end w-full mt-4 gap-4">
                        <button hidden={!processingRequest} type='button' disabled className='text-white bg-red-500 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </button>
                        <button onClick={handleDelete} type='button' hidden={processingRequest} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
                            Eliminar
                        </button>
                        <button onClick={handleCloseModal} type='button' hidden={processingRequest} className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                            Cancelar
                        </button>
                    </div>
                </div>
            </ReactModal>
        </>
    );
};
export default ColegiaturaRegistrosTable;