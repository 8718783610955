import React, { Component } from 'react';
import LoadingSVG from '../resources/animations/loading.svg';

class Loading extends Component {
    render() {
        return (
            <div style={{ padding: '24px' }}>
                <img src={LoadingSVG} alt="Loading" />
                <div className='text-center text-accent-1'>Cargando...</div>
            </div>
        );
    }
}

export default Loading;