import { ChevronRightIcon, PlusIcon, XIcon } from '@heroicons/react/solid';
import React, { Component } from 'react';
import Select from 'react-select';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import EscalacionCard from './EscalacionCard';
import ReactModal from 'react-modal';
import TextEditorInput from '../form-controls/TextEditorInput';
import { BellIcon, DocumentTextIcon, MailIcon, UsersIcon } from '@heroicons/react/outline';
import RespuestaComponent from '../form-controls/RespuestaComponent';
import { toast } from 'react-toastify';
import modalForm from '../../custom-css/ModalFormStyles';

class EscalacionesComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { 
        // Escalaciones States
        tiempo: this.props.tiempo !== undefined ? this.props.tiempo : {dias: 0, horas: 0, minutos: 0}, usuarios: [], 
        selectedUsers: this.props.usuarios !== undefined ? JSON.parse(`[${this.props.usuarios}]`) : [], notificacionesCorreo: [], 
        selectedNCs: [], notificacionesModal: false, selectedNotificacion: 'new', 
        //New Notificaciones Correo states
        nombre: '', descripcion: '', NCUsers: [], asunto: '', mensaje: '', characterCount: 0,
        // Request/Form states
        loaded: false, processingRequest: false, statusResult: 0, errors: {
            dias: '', horas: '', minutos: '', usuarios: ''
        } }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.Administrativos).then((result) => {
            var groupedOptions = [];
            result.filter(a => a.usuario_id !== null).forEach(element => {
                var depto = element.depto;
                if (!groupedOptions.some(o => o.label === depto)) {
                    groupedOptions.push({label: depto, options: [{value: parseInt(element.usuario_id), label: element.nombre + ' ' + element.apellido}]});
                }
                else {
                    groupedOptions.find(o => o.label === depto).options.push({value: parseInt(element.usuario_id), label: element.nombre + ' ' + element.apellido});
                }
            });
            this.setState({ usuarios: groupedOptions });
        });
        RestClient.GetRequest(AppUrl.NotificacionesCorreo).then((result) => {
            this.setState({ notificacionesCorreo: result.filter(nc => nc.id !== 1) });
            ((this.props.NCs !== undefined && this.props.NCs !== null) ? this.props.NCs.split(',') : []).forEach(id => {
                this.setState(prevState => ({ 
                    selectedNCs: [...prevState.selectedNCs, result.find(nc => nc.id === parseInt(id))]
                }));
            })
            this.setState({ loaded: true });
        });   
    }

    componentDidUpdate(prevProps, prevState) {
        // Prevent scroll when Reopen Modal is open
        if (prevState.notificacionesModal !== this.state.notificacionesModal) {
            let html = document.querySelector('html');
            this.state.notificacionesModal && (html.style.overflowY = 'hidden');
            !this.state.notificacionesModal && (html.style.overflowY = 'unset');
        }
        if (prevState.tiempo !== this.state.tiempo) {
            this.props.handleTiempo(this.state.tiempo);
        }
        if (prevState.selectedUsers !== this.state.selectedUsers) {
            this.props.handleUsers(this.state.selectedUsers.map(su => su.value));
        }
        if (prevState.selectedNCs !== this.state.selectedNCs) {
            this.props.handleNCs(this.state.selectedNCs.map(nc => nc.id));
        }
        if (prevProps.errors !== this.props.errors) {
            this.setState({ errors: {dias: this.props.errors.dias, horas: this.props.errors.horas, minutos: this.props.errors.minutos, usuarios: this.props.errors.usuarios} })
        }
    }

    renderSelect = (value) => {
        var values = [];
        for (let index = 0; index < value; index++) {
            values.push(<option key={index} value={index}>{index}</option>);
        }
        return values;
    }

    handleCloseModal = () => {
        this.setState({ notificacionesModal: false });
    }

    handleSelectedNCUsers = (users) => {
        var defaultValue = [];
        users.forEach(element => {
            var current_filter = this.state.usuarios.filter(u => u.options.find(o => o.value === element.id) !== undefined).map(u => u.options).flat();
            if (current_filter.length > 0) {
                defaultValue.push(current_filter.find(cf => cf.value === element.id));
            }
        });
        return defaultValue;
    }

    handleMensaje = (value) => {
        let count = 0;
        const jsonValue = JSON.parse(value);
        const flatten = (members) => {
            let children = [];
            return members.map(mem => {
                const m = { ...mem }; // use spread operator
                if (m.children && m.children.length) {
                    children = [...children, ...m.children];
                }
                delete m.children; // this will not affect the original array object
                return m;
            }).concat(children.length ? flatten(children) : children);
        };
        let flattenedArray = flatten(jsonValue).forEach(element => {
            if (Object.hasOwn(element, 'text')) {
                count += element.text.length;
            }
        });
        if (flattenedArray && jsonValue.some(j => j.type === 'bulleted-list' || j.type === 'numbered-list')) {
            var jsonChild = {
              type: '',
              children: []
            };
            flattenedArray.forEach(child => {
              if (Object.hasOwn(child, 'type') && (child.type === 'bulleted-list' || child.type === 'numbered-list')) {
                jsonChild.type = child.type;
              }
              else {
                if (Object.hasOwn(child, 'type') && child.type === 'list-item') {
                  jsonChild.children.push({type: child.type, children: []});
                } else {
                  jsonChild.children.find(c => c.type === 'list-item' && c.children.length === 0).children.push(child);
                }          
              }
            });
            value = JSON.stringify([jsonChild]);
          }
        this.setState({ mensaje: value, characterCount: count });
    }

    handleNCUserNames = (users) => {
        var nombres = [];
        users.forEach(element => {
            var current_filter = this.state.usuarios.filter(u => u.options.find(o => o.value === element.id) !== undefined).map(u => u.options).flat();
            if (current_filter.length > 0) {
                nombres.push(current_filter.find(cf => cf.value === element.id).label);
            }
        });
        return nombres;
    }

    getUpdatedNotificacionesCorreo = (selectedNC) => {
        RestClient.GetRequest(AppUrl.NotificacionesCorreo).then((result) => {
            this.setState({ notificacionesCorreo: result.filter(nc => nc.id !== 1), selectedNotificacion: selectedNC, processingRequest: false });
        });
    }

    onSaveNotificacionesCorreo = (e, select = false) => {
        e.preventDefault();
        //Disable controls while request is processed and clear error messages
        this.setState({ processingRequest: true, errors: [{ nombre: '', descripcion: '', selectedUsers: '', asunto: '', mensaje: '' }] });

        const data = new FormData();
        data.append('nombre', this.state.nombre);
        data.append('descripcion', this.state.descripcion);
        data.append('selectedUsers', this.state.NCUsers.length > 0 ? JSON.stringify(this.state.NCUsers) : '');
        data.append('asunto', this.state.asunto);
        data.append('mensaje', this.state.mensaje);

        RestClient.PostRequest(AppUrl.CreateNotificacionCorreo, data).then((result) => {
            this.setState({ processingRequest: false, statusResult: result.status });
            if (result.status === 200) {
                toast.success(result.message);
                this.setState({ errors: [{ nombre: '', descripcion: '', selectedUsers: '', asunto: '', mensaje: '' }] });
                if (select) {
                    this.onSaveNCAndSelect(result.id);
                }
                else {
                    this.getUpdatedNotificacionesCorreo(result.id);
                }
            }
            else {
                console.log(result);
                toast.error(result.message);
                this.setState({ errors: result.data })
            }
        });
    }

    onSelectNotificacionCorreo = (e) => {
        e.preventDefault();
        this.setState(prevState => ({ 
            selectedNCs: [...prevState.selectedNCs, this.state.notificacionesCorreo.find(nc => nc.id === this.state.selectedNotificacion)]
         }));
        this.handleCloseModal();
    }

    onRemoveSelectedNC = (id) => {
        this.setState({ selectedNCs: this.state.selectedNCs.filter(nc => nc.id !== id) });
    }

    onSaveNCAndSelect = (selectedNC)=> {
        RestClient.GetRequest(AppUrl.NotificacionesCorreo).then((result) => {
            this.setState(prevState => ({ 
                notificacionesCorreo: result.filter(nc => nc.id !== 1), 
                selectedNCs: [...prevState.selectedNCs, result.find(nc => parseInt(nc.id) === selectedNC)],
                selectedNotificacion: selectedNC, 
                processingRequest: false 
            }));
        });
        this.handleCloseModal();
    }

    render() {
        return (
            <>
                <div className='grid md:grid-cols-4 grid-cols-3 w-full px-5 py-3 gap-y-6'>
                    <div className='md:col-span-1 col-span-3'>
                        <div className='flex w-full md:justify-center items-center p-1'>
                            <span>Escalar después de:</span>
                        </div>
                    </div>
                    <div className='col-span-1'>
                        <div className='inline-flex items-center w-full gap-4 group'>
                            <label htmlFor={this.props.name + "Dias"} className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Dias</label>
                            <input name='dias' type="number" id={this.props.name + "Dias"} onChange={(e) => this.setState(prevState => {
                                let tiempo = Object.assign({}, prevState.tiempo);
                                tiempo.dias = e.target.value;
                                return { tiempo };
                            })} className='border-2 border-gray-400 rounded p-1 text-center w-20 focus:outline-none focus:ring-0 focus:border-accent-2' min={0} defaultValue={this.state.tiempo.dias} required />
                        </div>
                        <span className='text-sm text-red-500'>{this.state.errors.dias}</span>
                    </div>
                    <div className='col-span-1'>
                        <div className='inline-flex items-center w-full gap-4 group'>
                            <label htmlFor={this.props.name + "Horas"} className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Horas</label>
                            <select name="horas" id={this.props.name + "Horas"} onChange={(e) => this.setState(prevState => {
                                let tiempo = Object.assign({}, prevState.tiempo);
                                tiempo.horas = e.target.value;
                                return { tiempo };
                            })} defaultValue={0} className='border-2 border-gray-400 rounded p-1 px-4 focus:outline-none focus:ring-0 focus:border-accent-2' required>
                                {this.renderSelect(24)}
                            </select>
                        </div>
                        <span className='text-sm text-red-500'>{this.state.errors.horas}</span>
                    </div>
                    <div className='col-span-1'>
                        <div className='inline-flex items-center w-full gap-4 group'>
                            <label htmlFor={this.props.name + "Minutos"} className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Minutos</label>
                            <select name="minutos" id={this.props.name + "Minutos"} onChange={(e) => this.setState(prevState => {
                                let tiempo = Object.assign({}, prevState.tiempo);
                                tiempo.minutos = e.target.value;
                                return { tiempo };
                            })} defaultValue={this.state.tiempo.minutos} className='border-2 border-gray-400 rounded p-1 px-4 focus:outline-none focus:ring-0 focus:border-accent-2' required>
                                {this.renderSelect(60)}
                            </select>
                        </div>
                        <span className='text-sm text-red-500'>{this.state.errors.minutos}</span>
                    </div>
                </div>
                <div className='inline-flex w-full px-5 py-3 items-center gap-4'>
                    <label htmlFor={this.props.name + "Usuarios"} className='whitespace-nowrap font-bold'>Escalar A:</label>
                    <Select placeholder='--Seleccionar Usuario(s)--'
                        id={this.props.name + "Usuarios"}
                        isMulti
                        options={this.state.usuarios}
                        value={this.state.selectedUsers}
                        menuPortalTarget={document.body}
                        className='md:p-1 md:w-5/6 w-full'
                        onChange={(valores) => this.setState({ selectedUsers: valores })}
                        isLoading={this.state.usuarios.length === 0}
                        styles={{
                            option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                            }),
                            menuPortal: base => ({ 
                                ...base, 
                                zIndex: 9999 
                            }),
                        }}
                    />
                    
                </div>
                <span className='text-sm text-red-500'>{this.state.errors.usuarios}</span>
                <div className='flex w-full px-5 py-3'>
                    <div className='dropdown relative inline-block text-left'>
                        <button type='button' className='inline-flex items-center bg-accent-2 p-2 rounded hover:bg-blue-600 group' aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items">
                            <span className='text-white font-medium'>Seleccionar Acciones</span>
                            <ChevronRightIcon className='h-5 text-white group-focus:rotate-90 duration-300' />
                        </button>
                        <div className="dropdown-menu invisible origin-top-left -translate-y-2 scale-95 transform opacity-0 transition-all duration-300">
                            <div className="absolute left-0 mt-1 w-[11.5rem] origin-top-left divide-y-2 divide-gray-100 rounded-md bg-blue-100 shadow-lg outline-none" aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items" role="menu">
                                <div className='p-1'>
                                    <button type='button' onClick={() => this.setState({ notificacionesModal: true, selectedNotificacion: 'new' })} className='rounded text-accent-1 hover:text-accent-2 hover:bg-blue-200 pl-2 py-2 w-full text-left font-medium'>
                                        Notificaciones
                                    </button>
                                </div>
                                <div className='p-1'>
                                    <button type='button' className='rounded text-accent-1 hover:text-accent-2 hover:bg-blue-200 pl-2 py-2 w-full text-left font-medium'>
                                        Actualizar Campo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Cards */}
                <div className='md:grid md:grid-cols-3 flex flex-wrap w-full px-5 py-3 md:gap-3'>
                    {/* Notification Card */}
                    {this.props.NCs !== undefined && this.props.NCs !== null && !this.state.loaded ? 
                    <div className='flex w-full justify-start gap-4'>
                        <span className='text-base text-gray-500 italic'>Cargando Notificaciones...</span>
                        <svg className="animate-spin h-6 w-6 text-accent-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                    : this.state.selectedNCs.map((nc, index) => (
                        <div key={index} className='md:col-span-1 w-full'>
                            <EscalacionCard key={nc.id} _id={nc.id} _name={nc.nombre} _users={this.handleNCUserNames(nc.usuarios)} _subject={nc.asunto} _message={nc.mensaje} handleRemoveNC={this.onRemoveSelectedNC} />
                        </div>
                    ))}
                </div>

                {/* Notificaciones Modal */}
                <ReactModal
                id='notificacionesModal'
                style={window.innerWidth < 1025 ? modalForm.minMedia1024px : modalForm.maxMedia1024px}
                closeTimeoutMS={800}
                isOpen={this.state.notificacionesModal}
                contentLabel="Default Confirmation Modal"
                overlayClassName="Overlay"
                onRequestClose={this.handleCloseModal}
                shouldCloseOnOverlayClick={false}
                >
                <div className="flex flex-col items-start p-2 px-4 rounded h-full">
                    <div className="flex items-center w-full border-b-2">
                        <div className="text-gray-900 font-medium text-xl">Seleccionar Notificación por Correo</div>
                        <button onClick={this.handleCloseModal} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                            <XIcon className='w-5 h-5' />
                        </button>
                    </div>
                    <div className="md:flex border-b-2 w-full my-2 max-h-[70vh] overflow-y-auto">
                        <div className='md:w-1/3 space-y-6 md:h-full w-full'>
                            <ul className='bg-gray-300 md:h-full md:max-h-full border-2 border-gray-400 overflow-y-auto overflow-x-hidden max-h-36'>
                                <li className={`border-b py-2 ${this.state.selectedNotificacion === 'new' && 'bg-white'}`}>
                                    <button type='button' onClick={() => this.setState({ selectedNotificacion: 'new' })} className='inline-flex mx-4 w-full items-center text-accent-2 gap-2'>
                                        <PlusIcon className='h-5' />
                                        <span className='font-bold'>Agregar Notificación</span>
                                    </button>
                                </li>
                                {this.state.notificacionesCorreo.map(nc => (
                                <li key={nc.id} className={`border-b py-2 ${this.state.selectedNotificacion === nc.id && 'bg-white'}`}>
                                    <div className='inline-flex w-full mx-4 gap-2' onClick={() => this.setState({ selectedNotificacion: nc.id, characterCount: 0 })}>
                                        <input type="radio" readOnly checked={this.state.selectedNotificacion === nc.id} name='notificaciones_correo' id={nc.id} />
                                        <label htmlFor={nc.id}>{nc.nombre}</label>
                                    </div>
                                </li>
                                ))}
                            </ul>
                        </div>
                        <div className='md:w-2/3 md:p-3 md:py-5 mt-6 md:mt-0'>
                            <div className='flex flex-col w-full space-y-6'>
                                <div className='w-full'>
                                    <div className={`w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group ${this.state.selectedNotificacion !== 'new' && 'bg-gray-100'}`}>
                                        <div className="w-1/12 pt-2 flex justify-center">
                                            <BellIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                        </div>
                                        <div className="relative z-0 w-full group">
                                            <input onChange={(e) => this.setState({ nombre: e.target.value })} disabled={this.state.selectedNotificacion !== 'new'} value={this.state.selectedNotificacion !== 'new' ? this.state.notificacionesCorreo.find(nc => nc.id === this.state.selectedNotificacion).nombre : this.state.nombre} id='txtNombre' type="text" name="nombre" className="disabled:text-gray-500 disabled:cursor-not-allowed block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                            <label htmlFor="txtNombre" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nombre</label>
                                        </div>
                                    </div>
                                    <span className='text-sm text-red-500'>{this.state.errors.nombre}</span>
                                </div>
                                <div className='w-full'>
                                    <div className={`w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group ${this.state.selectedNotificacion !== 'new' && 'bg-gray-100'}`}>
                                        <div className="w-1/12 pt-2 flex justify-center">
                                            <DocumentTextIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                        </div>
                                        <div className="relative z-0 w-full group">
                                            <textarea onChange={(e) => this.setState({ descripcion: e.target.value })} disabled={this.state.selectedNotificacion !== 'new'} value={this.state.selectedNotificacion !== 'new' ? this.state.notificacionesCorreo.find(nc => nc.id === this.state.selectedNotificacion).descripcion === null ? '' : this.state.notificacionesCorreo.find(nc => nc.id === this.state.selectedNotificacion).descripcion : this.state.descripcion} id='taDescripcion' name="descripcion" className="disabled:text-gray-500 disabled:cursor-not-allowed block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                            <label htmlFor="taDescripcion" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Descripción(Opcional)</label>
                                        </div>
                                    </div>
                                    <span className='text-sm text-red-500'>{this.state.errors.descripcion}</span>
                                </div>
                                <div className='w-full'>
                                    <div className={`w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group ${this.state.selectedNotificacion !== 'new' && 'bg-gray-100'}`}>
                                        <div className="w-1/12 pt-2 flex justify-center">
                                            <UsersIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                        </div>
                                        <div className="relative z-0 w-full group">
                                            <Select placeholder='--Seleccionar Usuario(s)--'
                                                id='selectNCUsuarios'
                                                isMulti
                                                isDisabled={this.state.selectedNotificacion !== 'new'}
                                                options={this.state.usuarios}
                                                value={this.state.selectedNotificacion !== 'new' ? this.handleSelectedNCUsers(this.state.notificacionesCorreo.find(nc => nc.id === this.state.selectedNotificacion).usuarios) : this.state.NCUsers}
                                                menuPortalTarget={document.body}
                                                className='p-1 w-full'
                                                onChange={(valores) => this.setState({ NCUsers: valores })}
                                                isLoading={this.state.usuarios.length === 0}
                                                styles={{
                                                    option: (base, state) => ({
                                                        ...base,
                                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                                    }),
                                                    menuPortal: base => ({ 
                                                        ...base, 
                                                        zIndex: 9999 
                                                    }),
                                                }}
                                            />
                                            <label htmlFor="selectNCUsuarios" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Notificar A</label>
                                        </div>
                                    </div>
                                    <span className='text-sm text-red-500'>{this.state.errors.selectedUsers}</span>
                                </div>
                                <div className='w-full'>
                                    <div className={`w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group ${this.state.selectedNotificacion !== 'new' && 'bg-gray-100'}`}>
                                        <div className="w-1/12 pt-2 flex justify-center">
                                            <MailIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                        </div>
                                        <div className="relative z-0 w-full group">
                                            <input onChange={(e) => this.setState({ asunto: e.target.value })} disabled={this.state.selectedNotificacion !== 'new'} value={this.state.selectedNotificacion !== 'new' ? this.state.notificacionesCorreo.find(nc => nc.id === this.state.selectedNotificacion).asunto : this.state.asunto} id='txtAsunto' type="text" name="asunto" className="disabled:text-gray-500 disabled:cursor-not-allowed block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                            <label htmlFor="txtAsunto" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Asunto</label>
                                        </div>
                                    </div>
                                    <span className='text-sm text-red-500'>{this.state.errors.asunto}</span>
                                </div>
                                <div className='w-full'>
                                    <h5 className='mb-2 text-sm text-gray-400 peer-focus-within:text-accent-1'>Mensaje</h5>
                                    <div className='bg-white px-4 py-2 border-2 rounded border-gray-400 focus-within:border-accent-2 peer overflow-y-auto max-h-[218px]'>
                                        {this.state.selectedNotificacion === 'new' ?
                                        <TextEditorInput placeholder='El mensaje que se enviara por correo...' handleDescripcion={(descripcionValue) => this.handleMensaje(descripcionValue)} /> :
                                        <RespuestaComponent key={this.state.selectedNotificacion} value={this.state.notificacionesCorreo.find(nc => nc.id === this.state.selectedNotificacion).mensaje} />}
                                    </div>
                                    <p className={`${this.state.selectedNotificacion === 'new' ? 'flex' : 'hidden'} w-full justify-end text-xs ${this.state.characterCount > 500 && 'text-red-500 font-semibold'} text-gray-400`}>{this.state.characterCount}/500</p>
                                    <span hidden={this.state.characterCount <= 500} className='text-sm text-red-500'>¡Estas sobre el límite de caracteres permitidos!</span>
                                    <span className='text-sm text-red-500'>{this.state.errors.mensaje}</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="flex justify-center w-full mt-4 gap-4">
                        <button hidden={!this.state.processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </button>
                        <button onClick={this.onSaveNotificacionesCorreo} type='button' hidden={this.state.processingRequest || this.state.selectedNotificacion !== 'new'} disabled={this.state.characterCount > 500} className="disabled:bg-accent-2/70 bg-accent-2 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                            Guardar
                        </button>
                        <button onClick={(e) => this.state.selectedNotificacion === 'new' ? this.onSaveNotificacionesCorreo(e, true) : this.onSelectNotificacionCorreo(e)} type='button' hidden={this.state.processingRequest || this.state.selectedNCs.some(nc => nc.id === this.state.selectedNotificacion)} className="bg-accent-2 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                            {this.state.selectedNotificacion === 'new' ? 'Guardar y Seleccionar' : 'Seleccionar'}
                        </button>
                        <button onClick={() => this.onRemoveSelectedNC(this.state.selectedNotificacion)} type='button' hidden={this.state.processingRequest || !this.state.selectedNCs.some(nc => nc.id === this.state.selectedNotificacion)} className="bg-accent-2 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                            Deseleccionar
                        </button>
                        <button onClick={this.handleCloseModal} type='button' hidden={this.state.processingRequest} className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                            Cancelar
                        </button>
                    </div>
                </div>
            </ReactModal>
            </>
        );
    }
}

export default EscalacionesComponent;