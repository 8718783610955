import { AcademicCapIcon, CalendarIcon, CollectionIcon, UserGroupIcon } from '@heroicons/react/outline';
import { PencilAltIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
var CryptoJS = require("crypto-js");

// Create a constructor for functional component to get permissions
const useConstructor = (callBack = () => { }) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
}

//This has to be a functional component to use 'useParams()' from react-router-dom
const EditarGrupoPage = (props) => {
    // Grupo States
    const [nombre, setNombre] = useState('');
    const [nivel_id, setNivelId] = useState('');
    const [turno_id, setTurnoId] = useState('');
    const [ciclo_id, setCicloId] = useState('');
    const [plantel_id, setPlantelId] = useState('');
    const [cupos, setCupos] = useState('');
    // Request/Form states
    const [statusResult, setStatusResult] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [processingRequest, setProcessingRequest] = useState(false);
    const [errors, setErrors] = useState([{ nombre: '', nivel_id: '', turno_id: '', ciclo_id: '', plantel_id: '', cupos: '' }]);
    const [usersAccess, setUsersAccess] = useState([]);
    // Collections
    const [niveles, setNiveles] = useState([]);
    const [turnos, setTurnos] = useState([]);
    const [ciclos, setCiclos] = useState([]);
    const [planteles, setPlanteles] = useState([]);

    //Get the passed :id from parent and pass it to the component to render it
    const grupo = useParams();

    /// Decript permissions
    useConstructor(() => {
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUsersAccess(_usersAccess);
    });

    // Get Collections
    useEffect(() => {
        // Get Niveles
        RestClient.GetRequest(AppUrl.Niveles).then((result) => {
            setNiveles(result);
        });
        // Get Turnos
        RestClient.GetRequest(AppUrl.Turnos).then((result) => {
            setTurnos(result);
        });
        // Get Ciclos
        RestClient.GetRequest(AppUrl.Ciclos).then((result) => {
            setCiclos(result);
        });
        // Get Planteles
        RestClient.GetRequest(AppUrl.Planteles).then((result) => {
            setPlanteles(result);
        });
    }, [])  

    // Get Grupo by Id
    useEffect(() => {
        RestClient.GetRequest(AppUrl.GrupoById + grupo.id).then((result) => {
            setNombre(result.nombre);
            setNivelId(result.nivel_id);
            setTurnoId(result.turno_id);
            setCicloId(result.ciclo_id);
            setPlantelId(result.plantel_id);
            setCupos(result.cupos_maximos);
            setLoaded(true);
        });
    }, [grupo.id]);

    function onFormSubmit(e) {
        e.preventDefault();
        //Disable controls while request is processed and clear error messages
        setProcessingRequest(true);
        setErrors([{ nombre: '', nivel_id: '', turno_id: '', ciclo_id: '', plantel_id: '', cupos: '' }]);

        const data = new FormData();
        data.append('nombre', nombre);
        data.append('nivel_id', nivel_id);
        data.append('turno_id', turno_id);
        data.append('ciclo_id', ciclo_id);
        data.append('plantel_id', plantel_id);
        data.append('cupos', cupos);

        RestClient.PostRequest(AppUrl.UpdateGrupo + grupo.id, data).then((result) => {
            setStatusResult(result.status);
            setProcessingRequest(false);
            setErrors(result.data);
            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    if (usersAccess.find(ua => ua.modulo_name === 'grupos' && ua.permiso_name === 'update') === undefined) {
        return <Navigate to='/access-denied' />
    }
    if (statusResult === 200) {
        return <Navigate to='/grupos' />
    }
    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60' : 'sm:ml-20 ml-8'} duration-300 p-12 sm:pt-24 pt-20 sm:pr-16 pr-0 bg-gray-100`}>
                <div className='sm:px-0 px-3'>
                    {/* Page Title */}
                    <div className='flex w-full'>
                        <h1 className='sm:text-xl text-lg font-medium'>Editar Grupo</h1>
                    </div>
                    {/* Breadcrum Nav */}
                    <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                        {usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                            <>
                                <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                                <span className='text-xs'>/</span>
                            </>}
                        <p className='text-gray-400'>Escuelas</p>
                        <span className='text-xs'>/</span>
                        <Link to='/grupos' className='font-semibold hover:underline'>Grupos</Link>
                        <span className='text-xs'>/</span>
                        <p className='text-dark-purple font-semibold'>Editar Grupo</p>
                    </div>
                </div>
                {/* Main Section */}
                <div className='w-full flex justify-center mt-6'>
                    <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                        <div className='sm:w-11/12 w-full sm:my-8 my-2'>
                            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                <div className='bg-gray-50'>
                                    <div className='bg-white border'>
                                        <h1 className='text-accent-1 sm:text-2xl text-xl p-4'>Editar Grupo</h1>
                                    </div>
                                    {!loaded ?
                                        <div className='flex w-full justify-center bg-white'>
                                            <Loading />
                                        </div> :
                                    <form onSubmit={onFormSubmit} className='mt-4'>
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Grupo</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <UserGroupIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setNombre(e.target.value)} id='txtNombre' name="nombre" defaultValue={nombre} type="text" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtNombre" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nombre</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.nombre}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <AcademicCapIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select onChange={(e) => setNivelId(e.target.value)} id='selectNivel' name="nivel_id" defaultValue={nivel_id} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione Nivel Educativo --</option>
                                                                {niveles.map(item => {
                                                                    return <option key={item.id} value={item.id}>{item.nombre}</option>
                                                                })}
                                                            </select>
                                                            <label htmlFor="selectNivel" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nivel Educativo</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.nivel_id}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex gap-4">
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group my-1">
                                                            <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <select onChange={(e) => setTurnoId(e.target.value)} id='selectTurno' name="turno_id" defaultValue={turno_id} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                    <option value="0" disabled className='text-gray-400'>-- Seleccione Turno --</option>
                                                                    {turnos.map(item => {
                                                                        return <option key={item.id} value={item.id}>{item.nombre}</option>
                                                                    })}
                                                                </select>
                                                                <label htmlFor="selectTurno" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Turno</label>
                                                            </div>
                                                        </div>
                                                        {usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'turnos') !== undefined &&
                                                            <div className='flex items-center'>
                                                                <Link to={'/turnos/editar/' + turno_id} title='Editar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PencilAltIcon className='h-5' />
                                                                </Link>
                                                            </div>}
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.turno_id}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex gap-4">
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group my-1">
                                                            <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <select onChange={(e) => setCicloId(e.target.value)} id='selectCiclo' name="ciclo_id" defaultValue={ciclo_id} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                    <option value="0" disabled className='text-gray-400'>-- Seleccione Ciclo Escolar --</option>
                                                                    {ciclos.map(item => {
                                                                        return <option key={item.id} value={item.id}>{item.nombre}{item.estatus === 1 && ' \u2022 (Ciclo Actual)'}</option>
                                                                    })}
                                                                </select>
                                                                <label htmlFor="selectCiclo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Ciclo Escolar</label>
                                                            </div>
                                                        </div>
                                                        {usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'ciclos') !== undefined &&
                                                            <div className='flex items-center'>
                                                                <Link to={'/ciclos/editar/' + ciclo_id} title='Editar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PencilAltIcon className='h-5' />
                                                                </Link>
                                                            </div>}
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.ciclo_id}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex gap-4">
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group my-1">
                                                            <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                <svg className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M288 0H400C408.8 0 416 7.164 416 16V64C416 72.84 408.8 80 400 80H320V95.53L410.3 160H512C547.3 160 576 188.7 576 224V448C576 483.3 547.3 512 512 512H336V400C336 373.5 314.5 352 288 352C261.5 352 240 373.5 240 400V512H64C28.65 512 0 483.3 0 448V224C0 188.7 28.65 160 64 160H165.7L256 95.53V32C256 14.33 270.3 0 288 0V0zM288 192C261.5 192 240 213.5 240 240C240 266.5 261.5 288 288 288C314.5 288 336 266.5 336 240C336 213.5 314.5 192 288 192zM80 224C71.16 224 64 231.2 64 240V304C64 312.8 71.16 320 80 320H112C120.8 320 128 312.8 128 304V240C128 231.2 120.8 224 112 224H80zM448 304C448 312.8 455.2 320 464 320H496C504.8 320 512 312.8 512 304V240C512 231.2 504.8 224 496 224H464C455.2 224 448 231.2 448 240V304zM80 352C71.16 352 64 359.2 64 368V432C64 440.8 71.16 448 80 448H112C120.8 448 128 440.8 128 432V368C128 359.2 120.8 352 112 352H80zM464 352C455.2 352 448 359.2 448 368V432C448 440.8 455.2 448 464 448H496C504.8 448 512 440.8 512 432V368C512 359.2 504.8 352 496 352H464z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <select onChange={(e) => setPlantelId(e.target.value)} id='selectPlantel' name="plantel_id" defaultValue={plantel_id} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                    <option value="0" disabled className='text-gray-400'>-- Seleccione Plantel --</option>
                                                                    {planteles.map(item => {
                                                                        return <option key={item.id} value={item.id}>{item.nombre}</option>
                                                                    })}
                                                                </select>
                                                                <label htmlFor="selectPlantel" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Plantel</label>
                                                            </div>
                                                        </div>
                                                        {usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'planteles') !== undefined &&
                                                            <div className='flex items-center'>
                                                                <Link to={'/planteles/editar/' + plantel_id} title='Editar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PencilAltIcon className='h-5' />
                                                                </Link>
                                                            </div>}
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.plantel_id}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <CollectionIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setCupos(e.target.value)} id='numberCupos' defaultValue={cupos} type='number' name="cupos" min={1} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="numberCupos" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Cupos Máximos</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.cupos}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='p-4 flex justify-center gap-4 bg-white'>
                                            <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" hidden={processingRequest} className="disabled:bg-accent-2/70 text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                            <Link to='/grupos' hidden={processingRequest} className={`${!processingRequest && 'flex'} justify-center items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                        </div>
                                    </form>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default EditarGrupoPage