import { ArrowRightIcon } from '@heroicons/react/solid';
import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
var CryptoJS = require("crypto-js");

class ActivacionPasoTres extends Component {

    state = { statusResult: 0, processingRequest: false, firstScreen: '' }

    onFormSubmit = (e) => {
        e.preventDefault();
        //Disable controls while request is processed
        this.setState({ processingRequest: true});

        const data = {
            email: this.props.email,
            password: this.props.password
        }
        RestClient.PostRequest(AppUrl.Login, data).then((result)=>{
            this.setState({ statusResult: result.status, processingRequest: false });
            if (result.status === 200) {
                toast.success(result.message);
                localStorage.setItem('token', result.token);
                var accessos = result.accessos.sort((a, b) => { return a.modulo - b.modulo });
                localStorage.setItem('usersAccess', CryptoJS.AES.encrypt(JSON.stringify(result.accessos), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu').toString());
                this.setState({ firstScreen: '/' + (accessos[0].modulo_name === 'dashboard' ? '' : accessos[0].modulo_name) })
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    render() {
        if (this.state.statusResult === 200) {
            return <Navigate to={this.state.firstScreen} />
        }
        return (
            <div className='max-w-md mx-auto'>
                <div className='text-center'>
                    <svg className='inline-flex w-16 h-16 fill-current mb-6' viewBox='0 0 64 64'>
                        <circle className='text-emerald-100' cx={32} cy={32} r={32}></circle>
                        <path className='text-emerald-500' d='m28.5 41-8-8 3-3 5 5 12-12 3 3z'></path>
                    </svg>
                    <h1 className='text-3xl text-slate-200 font-bold mb-8'>¡Listo! Tu cuenta ha sido activada y puedes comenzar a usarla. 🎉</h1>
                    <button hidden={!this.state.processingRequest} type='button' disabled className='text-white bg-contrast-1 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center ml-auto'>
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </button>
                    <button type='submit' hidden={this.state.processingRequest} onClick={this.onFormSubmit} className={`px-3 py-2 bg-contrast-1 hover:bg-rose-700 text-white ${!this.state.processingRequest && 'inline-flex'} items-center font-semibold rounded-md gap-2 shadow-md`}>
                        <span>Acceder</span>
                        <ArrowRightIcon className='fill-current w-5 h-5' />
                    </button>
                </div>
            </div>
        );
    }
}

export default ActivacionPasoTres;