import axios from "axios"

class RestClient {

    static async GetRequest(getUrl, cancelSignal = null, retries = 3, backoffTime = 1000) {
        axios.defaults.headers.common['x-origin'] = 'ALFA_APP';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
        
        try {
            const response = await axios.get(getUrl, { signal: cancelSignal });
            return response.data;
        } catch (error) {
            
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (axios.isCancel(error)) {
                console.log("axios request cancelled", error.message, getUrl);
            } else {
                
                if (error.response) {
                    if (error.response.status === 500) {
                        window.alert("internal server error");
                    } else if (error.response.status === 404) {
                        window.alert("Resource not found");
                    } else if (error.response.status === 401) {
                        window.alert("Unauthorized user");
                        localStorage.removeItem('token');
                        localStorage.removeItem('usersAccess');
                        window.location.href = "/login";
                    } else if (error.response.status === 429 && retries > 0) {
                        // Handle multiple request in short time
                        console.log('Too Many Requests. Retrying in ' + backoffTime + 'ms...');
                        return new Promise(resolve => setTimeout(resolve, backoffTime))
                            .then(() => this.GetRequest(getUrl, null, retries - 1, backoffTime * 2))
                    }

                } else if (error.request) {
                    console.log("error.request", error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    window.alert(error.message);
                }
            }
        }
    }

    static async PostRequest(postUrl, json, config = false) {
        axios.defaults.headers.common['x-origin'] = 'ALFA_APP';
        const controller = new AbortController();
        let _config = {
            headers: {
                'Content-Type': 'aplication/x-www-form-urlencoded'
            },
            signal: controller.signal
        }
        try {
            if (config) {
                const response = await axios.post(postUrl, json, _config);
                return response.data;
            } else {
                const response = await axios.post(postUrl, json);
                return response.data;
            }
        } catch (error) {
            controller.abort();
            return error.response.data;
        } finally {
            controller.abort();
        }
    }
}

export default RestClient