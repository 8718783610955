import { useContext } from "react";
import { CustomModalContext } from "../context/CustomModalContext";

export const useCustomModal = () => {
  const context = useContext(CustomModalContext);

  if (!context) {
    throw new Error(
      "useCustomModal must be used within the CustomModalProvider"
    );
  }

  const { createModal, addModal } = context;

  const create = (ref, modalProps) => {
    createModal(ref, modalProps);
  };

  const show = (modalProps) => {
    addModal(modalProps);
  };

  return {
    create,
    show,
  };
};
