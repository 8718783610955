import { ChevronDownIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { Link, Navigate, useOutletContext } from 'react-router-dom';
import Loading from '../../components/Loading';
import PeticionesTable from '../../components/peticiones/PeticionesTable';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
var CryptoJS = require("crypto-js");
// Create a constructor for functional component to get permissions
const useConstructor = (callBack = () => { }) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
}

const PeticionesPage = (props) => {
    // Get Auth user
    const user = useOutletContext().user;
    // Peticiones Page States
    const [usersAccess, setUsersAccess] = useState([]);
    const [moduloPermissions, setModuloPermissions] = useState([]);
    const [dataPeticiones, setDataPeticiones] = useState(null);
    const [view, setView] = useState('todas');
    const [filter, setFilter] = useState('en progreso');
    const [hasUpdated, setHasUpdated] = useState(false);
    const [status_collapsed, setStatusCollapsed] = useState(window.innerWidth > 640);
    const [view_collapsed, setViewCollapsed] = useState(window.innerWidth > 640);

    useConstructor(() => {
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        var _moduloPermissions = [];
        if (_usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'peticiones') !== undefined) {
            _moduloPermissions.push('create');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'peticiones') !== undefined) {
            _moduloPermissions.push('update');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'delete' && ua.modulo_name === 'peticiones') !== undefined) {
            _moduloPermissions.push('delete');
        }
        setUsersAccess(_usersAccess);
        setModuloPermissions(_moduloPermissions);
    });

    useEffect(() => {
        if (user?.user_type) {
            RestClient.GetRequest(AppUrl.PeticionesByUserId + user.id).then((result) => {
                setDataPeticiones(result);
            });
        }
    }, [user, hasUpdated]);

    function handleUpdate() {
        setHasUpdated(!hasUpdated);
    }

    function handleFilterData(view, status) {
        let filteredPeticiones = null;
        switch (view) {
            case 'todas':
                filteredPeticiones = dataPeticiones;
                break;
            case 'colaborador':
                filteredPeticiones = dataPeticiones?.filter(p => p.colaboradores !== null && p.colaboradores.some(c => parseInt(c) === user.id));
                break;
            case 'asignadas':
                filteredPeticiones = dataPeticiones?.filter(p => p.asignado_id !== null && parseInt(p.asignado_id) === user.id);
                break;
            case 'solicitante':
                filteredPeticiones = dataPeticiones?.filter(p => parseInt(p.usuario_id) === user.id);
                break;
            default:
                filteredPeticiones = dataPeticiones;
                break;
        }
        if(status !== 'todas') return filteredPeticiones?.filter(p => p.tipo === status); 
        else return filteredPeticiones;
    }

    if (usersAccess.find(ua => ua.modulo_name === 'peticiones') === undefined) {
        return <Navigate to='/access-denied' />
    }
    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60 ml-0' : 'sm:ml-20 ml-0'} duration-300 px-2 sm:pt-24 pt-20 pb-6 sm:px-4 md:px-8 bg-gray-100`}>
            {/* Page Title */}
            <div className='sm:px-0 px-3'>
                <div className='flex w-full'>
                    <h1 className='sm:text-xl text-lg font-medium'>Solicitudes</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                    {usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                    <Link to='/peticiones' className='text-dark-purple font-semibold hover:underline'>Solicitudes</Link>
                </div>
            </div>
            {/* Data Table And Filter */}
            <div className='w-full flex justify-center sm:mt-6 mt-3'>
                <div className='w-full flex-col justify-center bg-white rounded shadow-lg'>
                    {!user ? <div className='flex w-full justify-center bg-white'>
                        <Loading />
                    </div> :
                    <>
                    <div hidden={user?.user_type !== 'administrativos'} className='sm:mx-12 mx-0 sm:mt-8 sm:mb-0 my-2 bg-gray-100 sm:rounded-none rounded shadow-lg'>
                        <fieldset className="flex flex-col lg:flex-row w-full lg:items-center sm:p-5 py-2.5 px-5 sm:space-y-3 lg:space-y-0">
                            <div onClick={() => window.innerWidth <= 640 && setViewCollapsed(!view_collapsed)} className="xl:w-[32.5%] md:w-[17.5%] w-full h-full sm:flex-none inline-flex items-center justify-between">
                                <h1 className="sm:text-base text-lg font-semibold">Mostrar:</h1>
                                <ChevronDownIcon className={`sm:hidden w-7 h-7 text-slate-500 ${view_collapsed && 'rotate-180'} duration-300`} />
                            </div>
                            <Collapse isOpened={view_collapsed}>                            
                                <div className='flex flex-col lg:flex-row w-full justify-start lg:gap-8 gap-3 sm:mt-0 mt-3'>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => setView(e.target.value)} className="w-4 h-4 accent-blue-600" type="radio" name="viewTables" id="radioViewTodas" value="todas" defaultChecked/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioViewTodas">
                                            Todas
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => setView(e.target.value)} className="w-4 h-4 accent-blue-600" type="radio" name="viewTables" id="radioColaborador" value="colaborador"/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioColaborador">
                                            Soy Colaborador
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => setView(e.target.value)} className="w-4 h-4 accent-blue-600" type="radio" name="viewTables" id="radioAsignadas" value="asignadas"/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioAsignadas">
                                            Asignadas a mí
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => setView(e.target.value)} className="w-4 h-4 accent-blue-600" type="radio" name="viewTables" id="radioSolicitante" value="solicitante"/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioSolicitante">
                                            Soy Solicitante
                                        </label>
                                    </div>
                                </div>
                            </Collapse>
                        </fieldset>
                    </div>
                    <div className={`${user?.user_type === 'administrativos' ? 'sm:my-0' : 'sm:my-8'} sm:mx-12 mx-0 my-2 bg-gray-100 sm:rounded-none rounded shadow-lg`}>
                        <fieldset className="flex flex-col lg:flex-row w-full lg:items-center sm:p-5 py-2.5 px-5 sm:space-y-3 lg:space-y-0">
                            <div onClick={() => window.innerWidth <= 640 && setStatusCollapsed(!status_collapsed)} className="xl:w-2/5 md:w-1/4 w-full h-full sm:flex-none inline-flex items-center justify-between">
                                <h1 className="sm:text-base text-lg font-semibold">Filtrar por:</h1>
                                <ChevronDownIcon className={`sm:hidden w-7 h-7 text-slate-500 ${status_collapsed && 'rotate-180'} duration-300`} />
                            </div>
                            <Collapse isOpened={status_collapsed}>                            
                                <div className='flex flex-col md:flex-row w-full justify-start md:gap-8 gap-3 sm:mt-0 mt-3'>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => setFilter(e.target.value)} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioTodas" value="todas"/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioTodas">
                                            Todas
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => setFilter(e.target.value)} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioAbiertas" value="en progreso" defaultChecked/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioAbiertas">
                                            En Progreso
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => setFilter(e.target.value)} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioCerradas" value="completado"/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioCerradas">
                                            Completadas
                                        </label>
                                    </div>
                                </div>
                            </Collapse>
                        </fieldset>
                    </div>
                    </>}
                    <div className='sm:mx-12 mx-0 sm:my-8 my-2'>
                        <div id='divPeticionesTable' className='w-full bg-gray-100 rounded-lg shadow-lg'>
                            <PeticionesTable data={handleFilterData(view, filter)} controls={moduloPermissions} hasUpdated={handleUpdate} authUser={user} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PeticionesPage;