import { ArrowLeftIcon } from '@heroicons/react/solid';
import React, { Component } from 'react';

class ActivacionPasoDos extends Component {

    state={userConfiguraciones: this.props.userConfiguraciones, updatedConfiguraciones: [], processingRequest: this.props.processingRequest}

    handleDisabledSwitch = (modulo) => {
        // Get config count per modulo
        const configCount = this.state.userConfiguraciones.find(c => c.modulo === modulo).configuraciones.length;
        // count how many inactive configs the user has made
        let updatingCount = 0;
        this.state.updatedConfiguraciones.forEach((c) => {
            if (c.modulo === modulo && !c.activo) {
                updatingCount++;
            } else if (c.modulo === modulo && c.activo) {
                updatingCount--;
            }
        });
        // Get how many inactive configs the user already has from db
        let dbCount = 0;
        this.state.userConfiguraciones.find(c => c.modulo === modulo).configuraciones.forEach((c) => {
            if (c.activo === "0") {
                dbCount++;
            }
        });
        // If all configs are disabled for Modulo
        if ((updatingCount + dbCount) === configCount) {
            return true;
        }
        // If there are no active values from db
        else if (!this.state.userConfiguraciones.find(c => c.modulo === modulo).configuraciones.some(c => c.activo === "1") && (updatingCount + dbCount) === configCount) {
            return true;
        }
        return null;
    }

    handleSwitchConfig = (_switch, modulo) => {
        if (!this.state.updatedConfiguraciones.some(config => config.id === _switch.id)) {
            const newConfiguraciones = JSON.parse(JSON.stringify(this.state.updatedConfiguraciones));
            newConfiguraciones.push({id: _switch.id, activo: _switch.checked, modulo: modulo});
            this.setState(prevState => ({
                updatedConfiguraciones: [...prevState.updatedConfiguraciones, {id: _switch.id, activo: _switch.checked, modulo: modulo}]
            }));
            this.props.handleUpdatedConfiguraciones(newConfiguraciones);
        }
        else {
            const newConfiguraciones = JSON.parse(JSON.stringify(this.state.updatedConfiguraciones));
            this.setState({updatedConfiguraciones: this.state.updatedConfiguraciones.filter(config => config.id !== _switch.id)})
            this.props.handleUpdatedConfiguraciones(newConfiguraciones.filter(config => config.id !== _switch.id));
        }
        
    }

    render() {
        return (
            <div className='max-w-2xl mx-auto bg-white rounded-lg shadow-lg px-8 py-6'>
                <h1 className='text-3xl text-slate-800 font-bold mb-6'>Configuraciones</h1>
                <p className='text-slate-600 font-semibold mb-6'>Puedes configurar tus notificaciones que se enviaran a la plataforma y a tu correo. <span className='text-sm italic'>Nota: Estas configuraciones también se pueden cambiar desde tu perfil en otro momento.</span></p>
                <hr />
                <div className='p-6 space-y-6'>
                    {this.state.userConfiguraciones.map(section => (
                    <section key={section.modulo} className='md:w-11/12'>
                        <h3 className='text-xl leading-[1.375] tracking-[-.01em] text-slate-800 font-bold mb-1 capitalize'>Notificaciones de {section.modulo.replace(/(peticiones|petición)/g, function(_, p1) { return (p1 === 'peticiones') ? 'solicitudes' : 'solicitud';})}</h3>
                        <ul>
                            {/* Configuracion */}
                            {section.configuraciones.map(config => (
                                <li key={config.configuracion_id} className='flex justify-between items-center py-3 border-b border-slate-200'>
                                {/* Left */}
                                <div>
                                    <div className='text-slate-800 font-semibold capitalize'>{config.configuracion.nombre}</div>
                                    <div className='text-sm text-slate-600'>{config.configuracion.descripcion.replace(/(peticiones|petición)/g, function(_, p1) { return (p1 === 'peticiones') ? 'solicitudes' : 'solicitud';})}</div>
                                </div>
                                {/* Right */}
                                <div className='inline-flex items-center ml-4'>
                                    <div className="min-h-[1.5rem] mb-0.5 block pl-12">
                                        <input onChange={(e) => this.handleSwitchConfig(e.target, config.configuracion.modulo)} id={config.configuracion_id} defaultChecked={config.activo === "1"} className="mt-[.135rem] rounded-[2.5rem] duration-300 ease-in-out after:rounded-full after:shadow-2xl after:duration-300 checked:after:translate-x-[1.3125rem] h-5 relative float-left -ml-12 w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-slate-800/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-accent-1 checked:bg-accent-2 checked:bg-none checked:bg-right" type="checkbox" />
                                        <label className="mb-2 ml-1 font-normal cursor-pointer select-none text-sm" htmlFor={config.configuracion_id}>{this.state.updatedConfiguraciones.some(c => c.id === config.configuracion_id && c.activo === true) ? 'Activo' : this.state.updatedConfiguraciones.some(c => c.id === config.configuracion_id && c.activo === false) ? 'Desactivado' : config.activo === "1" ? 'Activo' : 'Desactivado'}</label>
                                    </div>
                                </div>
                            </li>
                            ))}
                            {/* Correo */}
                            <li className='flex justify-between items-center py-3 border-b border-slate-200'>
                                {/* Left */}
                                <div>
                                    <div className='text-slate-800 font-semibold'>Por Correo</div>
                                    <div className='text-sm text-slate-600'>Se te notificará por correo electrónico cuando tengas una nueva notificación de {section.modulo.replace(/(peticiones|petición)/g, function(_, p1) { return (p1 === 'peticiones') ? 'solicitudes' : 'solicitud';})}. <em className='block'>Nota: Estos correos se envían al correo institucional que el colegio te brindo cuando se creó tu cuenta, el que usas para acceder a esta plataforma.</em></div>
                                </div>
                                {/* Right */}
                                <div className='inline-flex items-center ml-4'>
                                    <div className="min-h-[1.5rem] mb-0.5 block pl-12">
                                        <input onChange={(e) => this.handleSwitchConfig(e.target)} id={section.modulo + '_correo'} disabled={this.handleDisabledSwitch(section.modulo) !== null} defaultChecked={this.state.userConfiguraciones.find(c => c.modulo === section.modulo).configuraciones.some(c => c.correo === "1")} className="disabled:opacity-70 disabled:cursor-default mt-[.135rem] rounded-[2.5rem] duration-300 ease-in-out after:rounded-full after:shadow-2xl after:duration-300 checked:after:translate-x-[1.3125rem] h-5 relative float-left -ml-12 w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-slate-800/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-accent-1 checked:bg-accent-2 checked:bg-none checked:bg-right peer" type="checkbox" />
                                        <label className="peer-disabled:opacity-70 peer-disabled:cursor-default mb-2 ml-1 font-normal cursor-pointer select-none text-sm" htmlFor={section.modulo + '_correo'}>{this.state.updatedConfiguraciones.some(c => c.id === section.modulo + '_correo' && c.activo === true) ? 'Activo' : this.state.updatedConfiguraciones.some(c => c.id === section.modulo + '_correo' && c.activo === false) ? 'Desactivado' : this.state.userConfiguraciones.find(c => c.modulo === section.modulo).configuraciones.some(c => c.correo === "1") ? 'Activo' : 'Desactivado'}</label>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </section>
                    ))}
                </div>
                <hr  className='mb-8'/>
                <div className='flex items-center justify-between'>
                    <button hidden={!this.props.processingRequest} type='button' disabled className='text-white bg-contrast-1 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center ml-auto'>
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </button>
                    <button type='button' hidden={this.props.processingRequest} className={`text-sm underline hover:no-underline ${!this.props.processingRequest && 'inline-flex'} items-center group`} onClick={() => this.props.handleStep(1)}>
                        <ArrowLeftIcon className='w-4 h-4 group-hover:-translate-x-2 duration-300 transform' /> 
                        Regresar
                    </button>
                    <button type='button' hidden={this.props.processingRequest} onClick={() => this.props.handleActivate()} className='disabled:bg-contrast-1/70 px-3 py-2 bg-contrast-1 hover:bg-rose-700 text-white font-semibold ml-auto rounded-md'>Terminar</button>
                </div>
            </div>
        );
    }
}

export default ActivacionPasoDos;