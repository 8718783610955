import React from 'react';
import DataTable from 'react-data-table-component';
import { CalendarIcon, CheckIcon, CogIcon } from '@heroicons/react/solid';
import Loading from '../Loading';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import { toast } from 'react-toastify';
import { DeleteButton, EditButton, FilterComponent, NoDataComponent, expandableStyles } from '../CustomTableComponents';
import { useCustomModal } from '../../hooks/useCustomModal';

function formatDate(date) {
    const [datePart, timePart] = date.split(" ");
    const [year, month, day] = datePart.split("-").map(Number);

    const formattedDate = new Intl.DateTimeFormat('es-MX', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).format(new Date(year, month - 1, day));

    if (!timePart) {
        return formattedDate;
    }

    const [hour, minute, second] = timePart.split(":").map(Number);
    const formattedTime = new Date(year, month - 1, day, hour, minute, second)
        .toLocaleTimeString('en-US', {
            hour12: true,
            hour: 'numeric',
            minute: '2-digit',
            second: '2-digit'
        });

    return `${formattedDate} ${formattedTime}`;
}

const DateController = ({ row, callback = null }) => {
    const [updatingDateRow, setUpdatingDateRow] = React.useState(false);
    const [date, setDate] = React.useState(row.fecha_limite);
    const [processingRequest, setProcessingRequest] = React.useState(false);

    function updateDate(value) {
        setDate(value);
    }

    function handleRequest() {
        if (row.fecha_limite === date) {
            setUpdatingDateRow(false);
            return;
        }
        setProcessingRequest(true);

        const data = new FormData();
        data.append('fecha_limite', date);
        RestClient.PostRequest(AppUrl.UpdateColegiaturaFecha + row.id, data).then((result) => {
            setProcessingRequest(false);
            if (result.status === 200) {
                toast.success(result.message);
                setUpdatingDateRow(false);
                callback?.();
            }
            else if (result.status === 204) {
                toast.warn(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    if (updatingDateRow) {
        return (
            <div className='flex justify-start items-center space-x-4 w-full'>
                <div className='inline-flex items-center rounded-full px-3 py-2 border border-accent-1 bg-slate-50 transition-colors'>
                    <input onChange={({ target: { value } }) => updateDate(value)} value={date} disabled={processingRequest} type="date" name="fecha_limite" id={`fl_${row.id}`} className='outline-none bg-transparent text-accent-2' />
                </div>
                <button onClick={handleRequest} type='button' disabled={processingRequest} title='Guardar' className='disabled:hover:bg-accent-1 disabled:hover:translate-y-0 inline-flex justify-center items-center bg-accent-1 rounded-full p-2 shadow-md hover:bg-accent-2 hover:-translate-y-1 transition-all duration-300'>
                    {!processingRequest ?
                        <CheckIcon className='w-5 h-5' /> :
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    }
                </button>
            </div>
        )
    }
    return (
        <div className='flex justify-start items-center space-x-20 w-full'>
            <span>{formatDate(date)}</span>
            <button onClick={() => setUpdatingDateRow(true)} type='button' title='Cambiar fecha' className='inline-flex justify-center items-center bg-accent-1 rounded-full p-2 shadow-md hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                <CalendarIcon className='w-5 h-5' />
            </button>
        </div>
    )
}

let searchTimer;
const CobroSwitch = ({ row, disabled = false, callback = null }) => {

    const customModal = useCustomModal();

    const [checked, setChecked] = React.useState(row.aplicar_cobro === 1);
    const [label, setLabel] = React.useState(row.aplicar_cobro === 1 ? 'Aplicado' : 'No Aplicado');

    function handleRequest() {
        RestClient.GetRequest(AppUrl.ToggleColegiaturaCobro + row.id).then((result) => {
            if (result.status === 200) {
                toast.success(result.message);
                setLabel((prevState) => prevState === 'Aplicado' ? 'No Aplicado' : 'Aplicado');
                callback?.();
            }
            else if (result.status === 204) {
                toast.warn(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    function handleOnCancel() {
        setChecked(true);
    }

    function requestCompleted(result) {
        if (result.status === 200) {
            toast.success(result.message);
            setLabel((prevState) => prevState === 'Aplicado' ? 'No Aplicado' : 'Aplicado');
            callback?.();
        }
        else if (result.status === 204) {
            toast.warn(result.message);
        }
        else {
            console.log(result);
            toast.error(result.message);
        }
    }

    function handleCobroChange(event) {
        const isChecked = event.target.checked;
        const shouldntToggle = (isChecked && row.aplicar_cobro === 1) || (!isChecked && row.aplicar_cobro === 0);
        setChecked(isChecked);
        // Clear previous timer
        clearTimeout(searchTimer);
        // Set new timeout to handle cobro change
        searchTimer = setTimeout(() => {
            if (!isChecked && row.aplicar_cobro === 1) {
                customModal.show(
                    {
                        title: 'Remover Cobro',
                        body: `¿Estas seguro de que quieres remover el cobro de los ${row.cantidad_cobrados} estudiante(s)?`,
                        confirmLabel: 'Remover',
                        confirmStyle: 'danger',
                        onConfirm: {
                            requestPromise: RestClient.GetRequest(AppUrl.ToggleColegiaturaCobro + row.id),
                            requestComplete: requestCompleted,
                        },
                        onRequestCanceled: handleOnCancel
                    },
                );
                return;
            }
            if (shouldntToggle) {
                return;
            }

            handleRequest();
        }, 1500);
    }

    return (
        <div className="min-h-[1.5rem] inline-flex items-center pl-12">
            <input onChange={handleCobroChange} id={"checkCobro" + row.id} disabled={disabled} className="peer disabled:opacity-40 disabled:cursor-not-allowed mt-[.135rem] rounded-[2.5rem] duration-300 ease-in-out after:rounded-full after:shadow-2xl after:duration-300 checked:after:translate-x-[1.3125rem] h-5 relative float-left -ml-12 w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-accent-1/40 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:bg-contrast-1 checked:bg-none checked:bg-right" type="checkbox" checked={checked} />
            <label className="peer-disabled:text-gray-400 peer-disabled:cursor-not-allowed ml-3 font-normal cursor-pointer select-none text-xs" htmlFor={"checkCobro" + row.id}>{label}</label>
        </div>
    );
}

const ExpandableColegiaturasTable = (props) => {
    const { id: registro_id, nivel, ciclo } = props.data;
    const { hasUpdated } = props;

    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [pending, setPending] = React.useState(true);
    const [filteredItems, setFilteredItems] = React.useState([{}]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsDisplayed, setRowsDisplayed] = React.useState(10);

    // Handles colegiaturas and if they have been updated
    const [colegiaturas, setColegiaturas] = React.useState([{}]);
    const [loadedColegiaturas, setLoadedColegiaturas] = React.useState(false);

    const loadData = React.useCallback(() => {
        RestClient.GetRequest(AppUrl.ColegiaturasByRegistroId + registro_id).then((colegiaturas) => {
            setColegiaturas(colegiaturas);
            setLoadedColegiaturas(true);
        });
    }, [registro_id]);
    // Load data on first render
    React.useEffect(() => {
        loadData();
    }, [loadData]);
    // Handles filter
    React.useEffect(() => {
        // Searches for filter
        if (colegiaturas) {
            const newFilteredItems = colegiaturas.filter(
                item => (item.no_mes && item.no_mes.toString().toLowerCase().includes(filterText.toLowerCase()))
                    || (item.mes && item.mes.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.fecha_limite && item.fecha_limite.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.fecha_ultimo_cobro && item.fecha_ultimo_cobro.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.cantidad_cobrados && item.cantidad_cobrados.toString().toLowerCase().includes(filterText.toLowerCase()))
            );
            setFilteredItems(newFilteredItems);
            setPending(false);
            setRowsDisplayed(Math.min(rowsPerPage, newFilteredItems.length));
        }
    }, [colegiaturas, filterText, rowsPerPage]);

    function updateData() {
        loadData();
        hasUpdated?.();
    }

    function dateStort(rowA, rowB) {
        return new Date(rowA.fecha_ultimo_cobro) - new Date(rowB.fecha_ultimo_cobro);
    }

    function onChangeRowsPerPage(currentRowsPerPage) {
        setRowsPerPage(currentRowsPerPage);
        if (filterText) {
            setRowsDisplayed(Math.min(currentRowsPerPage, filteredItems.length));
        }
    }

    const columns = [
        {
            cell: row => (
                <>
                    <div className="dropdown relative text-left">
                        <span className="rounded-md shadow-sm">
                            <button className="group max-w-xs rounded-full flex items-center text-sm focus:outline-none" type="button" aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items">
                                <span className="sr-only">Open table menu</span>
                                <CogIcon className='w-5 h-5 text-white hover:rotate-45 transition duration-300 group-focus:rotate-45' />
                            </button>
                        </span>
                        <div className={`dropdown-menu absolute invisible ${((colegiaturas.indexOf(row) + 1) >= rowsDisplayed && 'origin-bottom-left') || 'origin-top-left'} -translate-y-2 scale-95 transform opacity-0 transition-all duration-300 z-50`}>
                            <div className={`left-4 translate-x-6 ${((colegiaturas.indexOf(row) + 1) >= rowsDisplayed && '-translate-y-24 origin-bottom-left') || 'origin-top-left'} mt-2 w-36  divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none z-50`} aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items" role="menu">
                                <div className="py-1">
                                    <EditButton itemId={row.id} route={`/inscripciones-colegiaturas/editar-colegiatura/${row.id}`} />
                                    <DeleteButton itemId={row.id} /* onClickDelete={() => handleOpenModal('Remover', row.id)} */ disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ),

            button: true,
            width: '100px'
        },
        {
            name: 'No. Mes',
            selector: row => row.no_mes,
            sortable: true,
            grow: 0.2
        },
        {
            name: 'Mes',
            selector: row => row.mes,
            sortable: true,
            grow: 0.5
        },
        {
            name: 'Fecha Límite',
            selector: row => row.fecha_limite,
            cell: row => <DateController row={row} callback={updateData} />,
            sortable: true,
            grow: 0.7
        },
        {
            name: 'Último Cobro',
            selector: row => row.fecha_ultimo_cobro,
            cell: row => row.fecha_ultimo_cobro === null ? <em className='text-slate-200'>(Aún no se cobra)</em> : formatDate(row.fecha_ultimo_cobro),
            sortable: true,
            sortFunction: dateStort,
            grow: 0.6
        },
        {
            name: 'Aplicar Cobro',
            selector: row => row.aplicar_cobro,
            cell: row => <CobroSwitch row={row} callback={updateData} />,
            sortable: true,
            grow: 0.6,
        },
        {
            name: 'Cobrados',
            selector: row => row.cantidad_cobrados,
            sortable: true,
            grow: 0.5,
        },
    ];

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <div className='flex w-full justify-between items-center'>
                <h1 className='text-white font-semibold'>Colegiaturas <span className='uppercase'>{nivel} - {ciclo}</span></h1>
                <div className='inline-flex items-center'>
                    <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                </div>
            </div>

        );
    }, [filterText, resetPaginationToggle, nivel, ciclo]);

    if (!loadedColegiaturas) {
        return <div className='flex w-full justify-center bg-white'>
            <Loading />
        </div>
    }
    return (
        <pre className='w-full flex justify-center'>
            <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                <div className='w-11/12'>
                    <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                        <DataTable
                            columns={columns}
                            data={filteredItems}
                            customStyles={expandableStyles}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            paginationComponentOptions={{ rowsPerPageText: 'Registros por pagina:', rangeSeparatorText: 'de' }}
                            onChangeRowsPerPage={onChangeRowsPerPage}
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            progressPending={pending}
                            noDataComponent={<NoDataComponent />}
                        />
                    </div>
                </div>
            </div>
        </pre>
    );
}

export default ExpandableColegiaturasTable;