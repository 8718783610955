import React, { useContext } from 'react'
import { CustomModalContext } from '../../context/CustomModalContext';
import CustomModal from './CustomModal';

export const CustomModalContainer = () => {
    const context = useContext(CustomModalContext);

    if (!context) {
        throw new Error(
            "CustomModalContainer must be used within the CustomModalProvider"
        );
    }

    const { modals, removeModal } = context;

    const _onClose = (modalID) => {
        const modal = modals.find((m) => m.id === modalID);
        modal.modalProps.onClose?.();
        removeModal(modalID);
    }

    return modals.map(({ ref, id,
        modalProps: {
            body,
            title,
            onConfirm,
            onCancel,
            onOpen,
            onRequestCanceled,
            shouldCloseOnOverlayClick,
            shouldCloseOnEsc,
            preventScroll,
            confirmLabel,
            cancelLabel,
            confirmDisabled,
            confirmStyle,
        } }) => (
        <CustomModal
            key={id}
            ref={ref}
            id={id}
            body={body}
            title={title}
            onConfirm={onConfirm}
            onCancel={onCancel}
            onClose={_onClose}
            onOpen={onOpen}
            onRequestCanceled={onRequestCanceled}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            shouldCloseOnEsc={shouldCloseOnEsc}
            preventScroll={preventScroll}
            confirmLabel={confirmLabel}
            cancelLabel={cancelLabel}
            confirmDisabled={confirmDisabled}
            confirmStyle={confirmStyle}
        />
    ))
}
