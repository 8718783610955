import React from 'react'
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TextEditorInput from '../../components/form-controls/TextEditorInput';
import { Node } from 'slate';

const AppEditorPage = () => {

    const { value } = useParams();

    const [descripcion, setDescripcion] = useState('');
    const [characterCount, setCharacterCount] = useState(0);

    useEffect(() => {
        if (value) {
            handleDescripcionText(value);
        }
    }, [value]);


    function handleDescripcionText(value) {
        let descripcionPlainText = '';
        let plainTextConcat = "";
        let plainTextArray = JSON.parse(value).map(n => Node.string(n));

        if (plainTextArray.length > 1) {
            plainTextArray.forEach(element => {
                plainTextConcat += element;
            });
            descripcionPlainText += plainTextConcat;
        } else {
            descripcionPlainText += plainTextArray[0];
        }

        setDescripcion(value);
        setCharacterCount(descripcionPlainText.length);
    }

    function getDescripcionValue() {
        return descripcion;
    }
    function getDescripcionCount() {
        return characterCount;
    }
    // This exposes the func above to retrieve the descripcion value, character count and placeholder from react native app
    window.getDescripcionValue = getDescripcionValue;
    window.getDescripcionCount = getDescripcionCount;

    return (
        <div className='h-screen flex flex-col justify-between'>
            <div>
                <TextEditorInput defaultValue={descripcion} handleDescripcion={handleDescripcionText} placeholder={'Escribe aquí...'} />
            </div>
            <p className={`sticky bottom-0 text-xs text-end ${characterCount > 500 && 'text-red-500 font-semibold'} text-gray-400`}>{characterCount}/500</p>
            <span hidden={characterCount <= 500} className='sticky bottom-0 text-sm text-start text-red-500'>¡Estas sobre el límite de caracteres permitidos!</span>
        </div>
    )
}

export default AppEditorPage