import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import { AcademicCapIcon, CalendarIcon, ChevronRightIcon, CollectionIcon, CurrencyDollarIcon, StarIcon, UserGroupIcon } from '@heroicons/react/outline';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { InformationCircleIcon, PencilAltIcon } from '@heroicons/react/solid';
import EstudiantesColegiaturasTable from '../../components/inscripciones-colegiaturas/EstudiantesColegiaturasTable';
import { Collapse } from 'react-collapse';
import { toast } from 'react-toastify';
import { useCustomModal } from '../../hooks/useCustomModal';
var CryptoJS = require("crypto-js");

// Create a constructor for functional component to get permissions
const useConstructor = (callBack = () => { }) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
}

//This has to be a functional component to use 'useParams()' from react-router-dom
const EditarColegiaturaPage = (props) => {

    const customModal = useCustomModal();

    const [no_mes, setNoMes] = useState(0);
    const [mes, setMes] = useState('');
    const [fecha_limite, setFechaLimite] = useState('');
    const [fecha_ultimo_cobro, setFechaUltimoCobro] = useState('');
    const [aplicar_cobro, setAplicarCobro] = useState(false);
    const [cantidad_cobrados, setCantidadCobrados] = useState(0);
    const [estudiantes, setEstudiantes] = useState([]);
    const [registro, setRegistro] = useState({
        id: '',
        tipo: '',
        nivel: '',
        ciclo: '',
        precio: '',
        dia_limite: '',
        cargo_tipo: '',
        cargo_valor: '',
        fecha_ultimo_cobro: '',
        cantidad_cobrados: '',
        cantidad_colegiaturas: '',
    });

    const [aplicarCobroType, setAplicarCobroType] = useState('personalizar');
    const [removedEstudiantes, setRemovedEstudiantes] = useState([]);
    const [registroCollapsed, setRegistroCollapsed] = useState(false);
    const [processingRequest, setProcessingRequest] = useState(false);
    const [statusResult, setStatusResult] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [errors, setErrors] = useState([{ no_mes: '', mes: '', fecha_limite: '' }]);
    const [usersAccess, setUsersAccess] = useState([]);

    //Get the passed :id from parent and pass it to the component to render it
    const colegiatura = useParams();

    /// Decript permissions
    useConstructor(() => {
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUsersAccess(_usersAccess);
    });

    // Get Pago by Id and set days for cafetería
    useEffect(() => {
        RestClient.GetRequest(AppUrl.ColegiaturaById + colegiatura.id).then((result) => {
            const { no_mes, mes, fecha_limite, fecha_ultimo_cobro, aplicar_cobro, cantidad_cobrados, estudiantes, registro } = result;
            setNoMes(no_mes);
            setMes(mes);
            setFechaLimite(fecha_limite);
            setFechaUltimoCobro(fecha_ultimo_cobro);
            setAplicarCobro(aplicar_cobro);
            setCantidadCobrados(cantidad_cobrados);
            setEstudiantes(estudiantes);
            setRegistro(registro);

            setLoaded(true);
        });
    }, [colegiatura.id]);

    function onRegistroCollapse() {
        setRegistroCollapsed((prevState) => !prevState);
    }

    function onFechaLimiteChange(e) {
        const dateString = e.target.value;
        const [year, month, day] = dateString.split("-");
        const newDate = new Date(year, month - 1, day);

        setNoMes(newDate.getMonth() + 1);
        setMes(newDate.toLocaleDateString('es-MX', { month: 'long' }));
        setFechaLimite(dateString);
    }

    function onReAddEstudiantes() {
        const updatedEstudiantes = [...estudiantes];
        updatedEstudiantes.unshift(...removedEstudiantes);
        setEstudiantes(updatedEstudiantes);
        setRemovedEstudiantes([]);
        toast.info('Se agregaron de nuevo los estudiantes');
        setAplicarCobro(true);
    }

    function onAplicarCobroChange() {
        if (removedEstudiantes.length > 0 && !aplicar_cobro) {
            customModal.show(
                {
                    title: 'Estudiantes removidos',
                    body: 'Removiste estudiantes del cobro de esta colegiatura. Al marcar esta opción, se agregarán nuevamente y se le hará el cobro a TODOS los estudiantes en el nivel y ciclo de esta colegiatura. ¿Deseas continuar?',
                    confirmLabel: 'Continuar',
                    onConfirm: onReAddEstudiantes,
                },
            );
        } else {
            setAplicarCobro((prevState) => !prevState);
        }
    }

    function onAplicarCobroTypeChange(e) {
        const value = e.target.value;
        setAplicarCobroType(value);
    }

    function onRemoveEstudiante(id) {
        const removeIndex = estudiantes.findIndex((e) => e.id === id);
        const updatedEstudiantes = [...estudiantes];
        setRemovedEstudiantes((prevState) => [...prevState, ...updatedEstudiantes.splice(removeIndex, 1)]);
        setEstudiantes(updatedEstudiantes);
        setAplicarCobro(false);
        toast.info('Se removió el estudiante, recuerda guardar para aplicar los cambios');
    }

    function onAddEstudiante(values) {
        const updatedEstudiantes = [...estudiantes];
        updatedEstudiantes.unshift(...values);
        setEstudiantes(updatedEstudiantes);
        toast.info('Se agregó el estudiante, recuerda guardar para aplicar los cambios');
    }

    function onFormSubmit(e) {
        e.preventDefault();
        //Disable controls while request is processed and clear error messages
        setProcessingRequest(true);
        setErrors([{ no_mes: '', mes: '', fecha_limite: '' }]);

        const data = new FormData();
        data.append('id', colegiatura.id);
        data.append('no_mes', no_mes);
        data.append('mes', mes);
        data.append('fecha_limite', fecha_limite);
        data.append('aplicar_cobro', aplicar_cobro);
        data.append('aplicar_cobro_tipo', aplicarCobroType);
        data.append('estudiantes', aplicarCobroType === 'personalizar' ? JSON.stringify(estudiantes) : JSON.stringify([]));

        RestClient.PostRequest(AppUrl.UpdateColegiatura + colegiatura.id, data).then((result) => {
            const { status, data, message } = result;
            const requestErrors = data ?? errors;
            setStatusResult(status);
            setProcessingRequest(false);
            setErrors(requestErrors);
            if (status === 200) {
                toast.success(message);
            }
            else {
                console.log(result);
                toast.error(message);
            }
        });
    }

    // Derrivative states
    const canRemoveEstudiantes = !aplicar_cobro;
    const canCreateUsers = usersAccess.find(ua => ua.modulo_name === 'usuarios' && ua.permiso_name === 'create') !== undefined;
    const canUpdateEstudiantes = usersAccess.find(ua => ua.modulo_name === 'estudiantes' && ua.permiso_name === 'update') !== undefined;


    if (usersAccess.find(ua => ua.modulo_name === 'colegiaturas' && ua.permiso_name === 'update') === undefined) {
        return <Navigate to='/access-denied' />
    }
    if (statusResult === 200) {
        return <Navigate to='/inscripciones-colegiaturas' />
    }
    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60 ml-0' : 'sm:ml-20 ml-0'} duration-300 px-2 sm:pt-24 pt-20 pb-6 sm:px-4 md:px-8 bg-gray-100`}>
            <div className='sm:px-0 px-3'>
                {/* Page Title */}
                <div className='flex w-full'>
                    <h1 className='sm:text-xl text-lg font-medium'>Editar Colegiatura</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                    {usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                    <p className='text-gray-400'>Finanzas</p>
                    <span className='text-xs'>/</span>
                    <Link to='/inscripciones-colegiaturas' className='font-semibold hover:underline'>Inscripciones y Colegiaturas</Link>
                    <span className='text-xs'>/</span>
                    <p className='text-dark-purple font-semibold'>Editar Colegiatura</p>
                </div>
            </div>
            <div className='w-full flex justify-center mt-6'>
                <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                    <div className='sm:w-11/12 w-full sm:my-8'>
                        <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                            <div className='bg-gray-50'>
                                <div className='bg-white border'>
                                    <h1 className='text-accent-1 sm:text-2xl text-xl p-4'>Editar Colegiatura</h1>
                                </div>
                                {!loaded ?
                                    <div className='flex w-full justify-center bg-white'>
                                        <Loading />
                                    </div> :
                                    <form onSubmit={onFormSubmit}>
                                        <div className={`flex bg-white border justify-between items-center hover:bg-blue-50 hover:cursor-pointer`} onClick={onRegistroCollapse}>
                                            <h1 className='text-accent-1 sm:text-2xl text-xl p-4'>Información Registro</h1>
                                            <ChevronRightIcon className={`w-9 h-9 text-accent-1 ${registroCollapsed && 'rotate-90'} duration-300 mr-4`} />
                                        </div>
                                        <Collapse isOpened={registroCollapsed}>
                                            <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                                <div className='flex justify-start items-center md:w-1/3 mx-auto max-w-sm md:mb-0 mb-8 gap-x-3'>
                                                    <h2 className="text-accent-1">Información Registro</h2>
                                                    <Link to={`/inscripciones-colegiaturas/editar-colegiatura-registro/${registro.id}`} title='Editar' className='flex justify-center items-center text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-full w-10 h-10 shadow shadow-slate-400 transition-colors'>
                                                        <PencilAltIcon className='w-5 h-5' />
                                                    </Link>
                                                </div>
                                                <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                    <div className='mt-4'>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                            <div className="mx-2 items-center flex justify-center">
                                                                <StarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input type='text' disabled id='txtTipo' name="tipo" defaultValue={registro.tipo} className="disabled:cursor-not-allowed capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                                <label htmlFor="txtTipo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Tipo de Colegiatura</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                            <div className="mx-2 items-center flex justify-center">
                                                                <AcademicCapIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input type='text' disabled id='txtNivel' name="nivel_id" defaultValue={registro.nivel} className="disabled:cursor-not-allowed capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                                <label htmlFor="txtNivel" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nivel Educativo</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                            <div className="mx-2 items-center flex justify-center">
                                                                <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input type='text' disabled id='txtCiclo' name="ciclo_id" defaultValue={registro.ciclo} className="disabled:cursor-not-allowed capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                                <label htmlFor="txtCiclo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Ciclo Escolar</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                            <div className="mx-2 pt-2 flex justify-center">
                                                                <CurrencyDollarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input type="text" disabled id='txtPrecio' name="precio" defaultValue={registro.precio} className="disabled:cursor-not-allowed block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                                <label htmlFor="txtPrecio" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Precio</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                            <div className="mx-2 items-center flex justify-center">
                                                                <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input type='text' disabled id='txtLimite' name="dia_limite" defaultValue={registro.dia_limite} className="disabled:cursor-not-allowed capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                                <label htmlFor="txtLimite" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Día límite de pago</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                            <div className="mx-2 items-center flex justify-center">
                                                                <CurrencyDollarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input type='text' disabled id='txtCargo' name="tipo" defaultValue={`${registro.cargo_tipo === 'cantidad' ? '$' : ''} ${registro.cargo_valor} ${registro.cargo_tipo === 'porcentaje' ? '%' : ''}`} className="disabled:cursor-not-allowed capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                                <label htmlFor="txtCargo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Cobro despúes de fecha límite</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 bg-white group">
                                                            <div className="mx-2 pt-2 flex justify-center">
                                                                <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input type="text" disabled id='textRegistroFechaUltimoCobro' name="registro_fecha_ultimo_cobro" defaultValue={registro.fecha_ultimo_cobro} className="disabled:cursor-not-allowed block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                                <label htmlFor="textRegistroFechaUltimoCobro" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Fecha del Último Cobro</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 bg-white group">
                                                            <div className="mx-2 pt-2 flex justify-center">
                                                                <UserGroupIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input disabled type="text" id='textRegistroCantidadCobrados' name="registro_cantidad_cobrados" defaultValue={registro.cantidad_cobrados} className="disabled:cursor-not-allowed block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                                <label htmlFor="textRegistroCantidadCobrados" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Cantidad de Alúmnos Cobrados Totales</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 bg-white group">
                                                            <div className="mx-2 pt-2 flex justify-center">
                                                                <CollectionIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input disabled type="text" id='textRegistroCantidadcolegiaturas' name="registro_cantidad_colegiaturas" defaultValue={registro.cantidad_colegiaturas} className="disabled:cursor-not-allowed block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                                <label htmlFor="textRegistroCantidadcolegiaturas" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Cantidad de Colegiaturas Totales</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </Collapse>
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Colegiatura</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className='mt-4'>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="mx-2 items-center flex justify-center">
                                                            <StarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input type='text' readOnly id='txtNoMes' name="no_mes" value={no_mes} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                            <label htmlFor="txtNoMes" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">No. Mes</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.no_mes}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="mx-2 items-center flex justify-center">
                                                            <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input type='text' readOnly id='txtMes' name="mes" value={mes} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                            <label htmlFor="txtMes" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Mes</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.mes}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 bg-white group">
                                                        <div className="mx-2 pt-2 flex justify-center">
                                                            <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={onFechaLimiteChange} value={fecha_limite} id='dateFechaLimite' type="date" name="fecha_limite" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="dateFechaLimite" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Fecha Límite</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.fecha_limite}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 bg-white group">
                                                        <div className="mx-2 pt-2 flex justify-center">
                                                            <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input readOnly value={fecha_ultimo_cobro} id='textFechaUltimoCobro' type="text" name="fecha_ultimo_cobro" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                            <label htmlFor="textFechaUltimoCobro" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Fecha del Último Cobro</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <div className='inline-flex gap-x-2'>
                                                        <h5 className='text-sm text-slate-800 text-light font-normal mb-1'>Aplicar cobro</h5>
                                                        <ReactTooltip className='tooltip-default' anchorId='tooltipAplicarCobro' content='Si esta opción es marcada, la colegiatura se asociará a todos los estudiantes en el nivel y ciclo seleccionado.' place="top" />
                                                        <InformationCircleIcon id='tooltipAplicarCobro' className='h-5 text-accent-2' />
                                                    </div>
                                                    <div className="min-h-[1.5rem] mb-0.5 block pl-2">
                                                        <input onChange={onAplicarCobroChange} checked={aplicar_cobro} name='aplicar_cobro' className='accent-accent-2' type="checkbox" style={{ transform: 'scale(1.5)' }} />
                                                    </div>
                                                    <div hidden={aplicar_cobro} className='ml-8 w-full space-y-2 mt-2'>
                                                        <div className="flex items-center space-x-4">
                                                            <input onClick={onAplicarCobroTypeChange} className="scale-125 accent-blue-600" type="radio" name="aplicar_cobro_type" id="radioPersonalizar" value="personalizar" defaultChecked />
                                                            <label className="text-sm font-semibold text-gray-600 italic space-x-1" htmlFor="radioPersonalizar">
                                                                Solo aplicar cobro de esta colegiatura a los estudiantes seleccionados abajo
                                                                <ReactTooltip className='tooltip-default font-normal not-italic' anchorId='tooltipPersonalizar' content="Si se seleccionan todos los estudiantes en el nivel y ciclo de esta colegiatura, 'Aplicar Cobro' será activado nuevamente." place="top" />
                                                                <InformationCircleIcon id='tooltipPersonalizar' className='inline-flex mb-0.5 w-5 h-5 text-accent-2' />
                                                            </label>
                                                        </div>
                                                        <div className="flex items-center space-x-4">
                                                            <input onClick={onAplicarCobroTypeChange} className="scale-125 accent-blue-600" type="radio" name="aplicar_cobro_type" id="radioRemover" value="remover" />
                                                            <label className="text-sm font-semibold text-gray-600 italic" htmlFor="radioRemover">
                                                                Remover cobro de esta colegiatura a todos los estudiantes
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`${!aplicar_cobro && 'pt-2'}`}>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 bg-white group">
                                                        <div className="mx-2 pt-2 flex justify-center">
                                                            <UserGroupIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input readOnly value={cantidad_cobrados} id='textCantidadCobrados' type="text" name="cantidad_cobrados" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                            <label htmlFor="textCantidadCobrados" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Cantidad de Alúmnos Cobrados</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="space-y-4 md:space-y-0 w-full p-4 text-gray-800 items-center mb-4">
                                            {(!aplicar_cobro && aplicarCobroType === 'remover') || (aplicar_cobro && !estudiantes.length) ?
                                                <h3 className='text-lg text-center text-accent-2 font-semibold italic'>*Se {aplicar_cobro && !estudiantes.length ? 'agregará' : 'removerá'} el cobro de esta colegiatura a todos los estudiantes en&nbsp;
                                                    <span className='uppercase font-bold'>{registro.nivel}</span>
                                                    &nbsp;dentro del ciclo&nbsp;
                                                    <span className='uppercase font-bold'>{registro.ciclo}</span>
                                                    .
                                                </h3> :
                                                <div className='md:container'>
                                                    <EstudiantesColegiaturasTable
                                                        data={estudiantes}
                                                        nivel={registro.nivel}
                                                        onRemoveEstudiante={onRemoveEstudiante}
                                                        onAddEstudiante={onAddEstudiante}
                                                        canRemoveEstudiantes={canRemoveEstudiantes}
                                                        canCreateUsers={canCreateUsers}
                                                        canUpdateEstudiantes={canUpdateEstudiantes}
                                                    />
                                                </div>}
                                        </div>
                                        <hr />
                                        <div className='p-4 flex justify-center gap-4 bg-white'>
                                            <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" hidden={processingRequest} className="disabled:bg-accent-2/70 text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                            <Link to='/inscripciones-colegiaturas' hidden={processingRequest} className={`${!processingRequest && 'flex'} justify-center items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                        </div>
                                    </form>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditarColegiaturaPage