import { CashIcon, CreditCardIcon, CurrencyDollarIcon, ExternalLinkIcon, HashtagIcon, MinusIcon, PlusIcon, UserIcon } from '@heroicons/react/outline';
import React, { useCallback, useEffect, useState } from 'react'
import { Link, Navigate, useOutletContext } from 'react-router-dom';
import Select from 'react-select';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
var CryptoJS = require("crypto-js");

// Create a constructor for functional component to get permissions
const useConstructor = (callBack = () => { }) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
}

const CrearPagoPage = (props) => {
    // Get Auth user
    const user = useOutletContext().user;
    // Pago States
    const [pagador_id, setPagadorId] = useState('');
    const [pagador_type, setPagadorType] = useState('');
    const [estudiante_id, setEstudianteId] = useState('');
    const [referencia, setReferencia] = useState('');
    const [subtotal, setSubTotal] = useState(0.00);
    const [total, setTotal] = useState(0.00);
    const [forma_pago, setFormaPago] = useState('efectivo');
    const [dias, setDias] = useState([]);
    const [productos, setProductos] = useState([{ value: '', label: '' }]);
    const [tutores, setTutores] = useState([]);
    const [estudiantes, setEstudiantes] = useState([]);
    const [filteredEstudiantes, setFilteredEstudiantes] = useState([]);
    const [pagadores, setPagadores] = useState([]);
    const [cafeteria_productos, setCafeteriaProductos] = useState([]);
    const [filteredCafeteriaProductos, setFilteredCafeteriaProductos] = useState([]);
    // Request/Form states
    // eslint-disable-next-line
    const [area, setArea] = useState('cafeteria');
    const [today, setToday] = useState(null);
    const [week, setWeek] = useState(null);
    const [days, setDays] = useState([]);
    const [statusResult, setStatusResult] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [processingRequest, setProcessingRequest] = useState(false);
    const [errors, setErrors] = useState([{ pagador_id: '', estudiante_id: '', dias: '', forma_pago: '', referencia: '', productos: '' }]);
    const [usersAccess, setUsersAccess] = useState([]);

    /// Decript permissions
    useConstructor(() => {
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUsersAccess(_usersAccess);
        let today_str = new Date().toLocaleString("en-US", { timeZone: "America/Phoenix" });
        let today = new Date(today_str);
        today = today.setHours(today.getHours() - 7);
        setToday(new Date(today));
    });


    const handlePayee = useCallback((payee) => {
        // Clear selected estudiante
        setEstudianteId('');
        if (payee.type === 'tutores') {
            const tutor = tutores.find(t => t.value === payee.value);
            setFilteredEstudiantes(estudiantes.filter(e => tutor.estudiantes.some(es => es === e.value)))
        } else {
            setFilteredEstudiantes(estudiantes);
        }
        setPagadorId(payee.value);
        setPagadorType(payee.type);
    }, [tutores, estudiantes]);

    const handleDays = useCallback(() => {
        const daysOfWeek = [{ value: 1, label: 'Lunes' }, { value: 2, label: 'Martes' }, { value: 3, label: 'Miércoles' }, { value: 4, label: 'Jueves' }, { value: 5, label: 'Viernes' }];
        const dayOfWeek = today.getDay();
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
        const endDate = new Date(today);
        endDate.setDate(today.getDate() + (5 - dayOfWeek));
        const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
        const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() + 1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
        setWeek({ startDate: formattedStartDate, endDate: formattedEndDate });
        const availableDays = daysOfWeek.slice(dayOfWeek > 0 ? dayOfWeek - 1 : dayOfWeek);
        setDays(availableDays);
        setDias(availableDays.length === 1 ? availableDays : availableDays.filter(ad => ad.value === dayOfWeek));
    }, [today]);

    // Get Collections and set days for cafetería
    useEffect(() => {
        // set days for cafetería checkboxes
        handleDays();
        // Get Tutores
        RestClient.GetRequest(AppUrl.Tutores).then((result) => {
            setTutores([]);
            result.forEach(element => {
                setTutores(prevState => [...prevState, { value: element.id, label: element.nombre + ' ' + element.apellido, type: 'tutores', estudiantes: element.estudiantes }]);
            });
        });
        // Get Estudiantes
        RestClient.GetRequest(AppUrl.Estudiantes).then((result) => {
            setEstudiantes([]);
            setFilteredEstudiantes([]);
            result.forEach(element => {
                setEstudiantes(prevState => [...prevState, { value: element.id, label: element.nombre + ' ' + element.apellido, type: 'estudiantes' }]);
                setFilteredEstudiantes(prevState => [...prevState, { value: element.id, label: element.nombre + ' ' + element.apellido, type: 'estudiantes' }]);
            });
        });
        // Get Productos Cafetería
        RestClient.GetRequest(AppUrl.Cafeteria).then((result) => {
            var groupedOptions = [{ label: 'productos', options: [] }, { label: 'otros', options: [{ value: 'other', label: 'Varios', price: 0.00 }] }];
            result.forEach(element => {
                groupedOptions.find(go => go.label === 'productos').options.push({ value: element.id, label: element.producto, price: element.precio });
            });
            setCafeteriaProductos(groupedOptions);
            setFilteredCafeteriaProductos(groupedOptions);
        });
    }, [handleDays]);

    // Set payers and filter students
    useEffect(() => {
        if (tutores.length > 0 && estudiantes.length > 0 && pagadores.length === 0) {
            let pagadores = [...tutores, ...estudiantes];
            var groupedOptions = [];
            pagadores.forEach(element => {
                const { value, label, type } = element;
                if (!groupedOptions.some(o => o.label === type)) {
                    groupedOptions.push({ label: type, options: [{ value, label, type }] });
                }
                else {
                    groupedOptions.find(o => o.label === type).options.push({ value, label, type });
                }
            });
            setPagadores(groupedOptions);
            setLoaded(true);
            if (user && user.user_type === 'tutores') {
                const tutor = { value: user.user_type_id, type: 'tutores' };
                handlePayee(tutor);
                setFormaPago('tarjeta');
            }
        }

    }, [tutores, estudiantes, pagadores, user, handlePayee]);


    function updateCart(index, value, type = '') {
        const productCount = productos.length;
        let newProductos = [];
        if (index === productCount) {
            newProductos = [...productos, { value: '', label: '' }];
        } else if (index === -1) {
            newProductos = productos.filter((_, i) => { return i !== value });
        } else {
            newProductos = productos.map((producto, _index) => {
                if (_index === index) {
                    if (type) {
                        return { ...producto, [type]: type === 'price' ? parseFloat(value) : parseInt(value, 10)};
                    }
                    const prod = cafeteria_productos.flatMap(cp => cp.options).find(cp => cp.value === value);
                    return { ...prod, cantidad: 1 };
                }
                else return producto;
            });
        }
        const newSubTotal = newProductos.filter(np => np.value !== '').reduce((accumulator, producto) => accumulator + (producto.price * producto.cantidad), 0);
        const newTotal = newSubTotal * dias.length;
        const updatedCafeteriaProductos = cafeteria_productos.map(cp => {
            const updatedOptions = cp.options.filter(cpo => !newProductos.some(np => np.value === cpo.value));
            return { ...cp, options: updatedOptions };
        });
        setFilteredCafeteriaProductos(updatedCafeteriaProductos);
        setProductos(newProductos);
        setSubTotal(newSubTotal);
        setTotal(newTotal);
    }

    function updateDays(checked, day) {
        const newDays = checked ? [...dias, day] : dias.filter(d => d.value !== day.value);
        const newTotal = subtotal * newDays.length;

        setDias(newDays);
        setTotal(newTotal);
    }

    function renderProductos(key) {
        var header = [];
        if (key === 'header') {
            for (let index = 0; index < productos.length; index++) {
                header.push(<tr key={index} className='bg-accent-2 flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-0'>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Producto</th>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Precio</th>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Cantidad</th>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Agregar/Remover</th>
                </tr>);
            }
            return header;
        }
        var body = [];
        if (key === 'body') {
            for (let index = 0; index < productos.length; index++) {
                body.push(<tr key={index} className='flex flex-col flex-no wrap md:table-row mb-2 md:mb-0'>
                    <td className='border p-1.5 max-h-[54px]'>
                        <Select placeholder='--Seleccionar Producto--'
                            options={
                                (area === 'cafeteria' && filteredCafeteriaProductos) || []
                            }
                            value={cafeteria_productos.flatMap(cp => cp.options).find(cp => cp.value === productos[index].value) || null}
                            menuPortalTarget={document.body}
                            className='md:p-1'
                            onChange={(producto) => updateCart(index, producto.value)}
                            isLoading={
                                (area === 'cafeteria' && cafeteria_productos.length === 0) || false
                            }
                            styles={{
                                option: (base, state) => ({
                                    ...base,
                                    backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                }),
                            }}
                        />
                    </td>
                    <td className='border p-1.5 max-h-[54px]'>
                        <p className='text-center'>$ {!isNaN(parseFloat(productos[index].price)) ? parseFloat(productos[index].price).toFixed(2) : '0.00'}</p> 
                    </td>
                    <td className='border p-1.5 min-h-[54px]'>
                        <div className="w-full flex justify-center items-center">
                            <div className={`w-1/2 relative z-0 border-2 rounded-full border-gray-400 focus-within:border-accent-2 my-1 ${productos[index].value === '' && 'bg-slate-100'}`}>
                                {productos[index].value === 'other' ? 
                                    <input key={'other'} onChange={(e) => updateCart(index, parseFloat(e.target.value).toFixed(2), 'price')} onBlur={(e) => e.target.value = parseFloat(e.target.value).toFixed(2)} defaultValue={'0.00'} disabled={productos[index].value === ''} id="numCantidad" type="number" name="cantidad" min={0.01} step={0.01} className="text-center block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " /> :
                                    <input key={'product'} onChange={(e) => updateCart(index, parseInt(e.target.value) || 1, 'cantidad')} value={productos[index].cantidad || 1} disabled={productos[index].value === ''} id="numCantidad" type="number" name="cantidad" min={1} step={1} pattern="[0-9]*" className="text-center block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                }
                            </div>
                        </div>
                    </td>
                    <td className='border p-2 max-h-[54px]'>
                        <div className='flex w-full justify-center gap-4'>
                            <button type='button' onClick={() => updateCart((index + 1), '')} disabled={cafeteria_productos.flatMap(cp => cp.options).length === 0 || (cafeteria_productos.flatMap(cp => cp.options).length === productos.length)} className='disabled:opacity-70 disabled:hover:bg-accent-2 bg-accent-2 p-2 rounded hover:bg-blue-600'>
                                <PlusIcon className='text-white h-5' />
                            </button>
                            <button type='button' disabled={productos.length === 1} onClick={() => updateCart(-1, index)} className='disabled:opacity-70 disabled:hover:bg-accent-2 bg-accent-2 p-2 rounded hover:bg-blue-600'>
                                <MinusIcon className='text-white h-5' />
                            </button>
                        </div>
                    </td>
                </tr>);
            }
            return body;
        }
    }

    function renderProductosMobile() {
        var _productos = [];
        for (let index = 0; index < productos.length; index++) {
            _productos.push(
                /* Row */
                <div key={index} className='w-full mb-2'>
                    {/* Producto */}
                    <div className='inline-flex w-full'>
                        <div className="w-1/2">
                            <div className='bg-accent-2 flex items-center rounded-tl-lg mb-2 h-full border-b-2'>
                                <h5 className="p-3 text-left text-white font-bold">Producto</h5>
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-center border-2 border-l-0 rounded-tr-lg bg-gray-100 border-slate-300 -mt-px">
                            <Select placeholder='--Seleccionar Producto--'
                                options={
                                    (area === 'cafeteria' && filteredCafeteriaProductos) || []
                                }
                                value={cafeteria_productos.flatMap(cp => cp.options).find(cp => cp.value === productos[index].value) || null}
                                menuPortalTarget={document.body}
                                className='inline-flex w-full h-full items-center justify-center'
                                onChange={(producto) => updateCart(index, producto.value)}
                                isLoading={
                                    (area === 'cafeteria' && cafeteria_productos.length === 0) || false
                                }
                                styles={{
                                    option: (base, state) => ({
                                        ...base,
                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                    }),
                                    control: (base) => ({
                                        ...base,
                                        height: '90%',
                                        width: '90%',
                                    })
                                }}
                            />
                        </div>
                    </div>
                    {/* Precio */}
                    <div className={`${productos[index].value === 'other' ? 'hidden' : 'inline-flex'} w-full`}>
                        <div className="w-1/2">
                            <div className='bg-accent-2 flex items-center mb-2 h-full border-b-2'>
                                <h5 className="p-3 text-left text-white font-bold">Precio</h5>
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-center border-2 border-l-0 border-t-0 bg-gray-100 border-slate-300">
                            <p className='text-center'>$ {productos[index].price || '0.00'}</p>
                        </div>
                    </div>
                    {/* Cantidad */}
                    <div className='inline-flex w-full'>
                        <div className="w-1/2">
                            <div className='bg-accent-2 flex items-center mb-2 h-full border-b-2'>
                                <h5 className="p-3 text-left text-white font-bold">Cantidad</h5>
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-center border-2 border-l-0 border-t-0 bg-gray-100 border-slate-300">
                            <div className={`w-1/2 relative z-0 border-2 rounded-full border-gray-400 focus-within:border-accent-2 my-1 ${productos[index].value === '' && 'bg-slate-100'}`}>
                                {productos[index].value === 'other' ? 
                                    <input key={'other'} onChange={(e) => updateCart(index, parseFloat(e.target.value).toFixed(2), 'price')} onBlur={(e) => e.target.value = parseFloat(e.target.value).toFixed(2)} defaultValue={0.01} disabled={productos[index].value === ''} id="numCantidadMobile" type="number" name="cantidad_mobile" min={0.01} step={0.01} className="text-center block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " /> :
                                    <input key={'product'} onChange={(e) => updateCart(index, parseInt(e.target.value) || 1, 'cantidad')} value={productos[index].cantidad || 1} disabled={productos[index].value === ''} id="numCantidadMobile" type="number" name="cantidad_mobile" min={1} step={1} pattern="[0-9]*" className="text-center block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                }
                            </div>
                        </div>
                    </div>
                    {/* Agregar/Remover */}
                    <div className='inline-flex w-full'>
                        <div className="w-1/2">
                            <div className='bg-accent-2 flex items-center rounded-bl-lg mb-2 h-full border-b-2'>
                                <h5 className="w-full p-3 text-left text-white font-bold break-words">Agregar/ Remover</h5>
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-center border-2 border-l-0 border-t-0 bg-gray-100 border-slate-300 rounded-br-lg mb-px">
                            <div className='flex w-full justify-center gap-4'>
                                <button type='button' onClick={() => updateCart((index + 1), '')} disabled={cafeteria_productos.flatMap(cp => cp.options).length === 0 || (cafeteria_productos.flatMap(cp => cp.options).length === productos.length)} className='disabled:opacity-70 disabled:hover:bg-accent-2 bg-accent-2 p-2 rounded hover:bg-blue-600'>
                                    <PlusIcon className='text-white h-5' />
                                </button>
                                <button type='button' disabled={productos.length === 1} onClick={() => updateCart(-1, index)} className='disabled:opacity-70 disabled:hover:bg-accent-2 bg-accent-2 p-2 rounded hover:bg-blue-600'>
                                    <MinusIcon className='text-white h-5' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return _productos;
    }

    function onFormSubmit(e) {
        e.preventDefault();
        //Disable controls while request is processed and clear error messages
        setProcessingRequest(true);
        setErrors([{ pagador_id: '', estudiante_id: '', dias: '', forma_pago: '', referencia: '', productos: '' }]);

        const data = new FormData();
        data.append('area', area);
        data.append('usuario_id', user.id);
        data.append('pagador_id', pagador_id);
        data.append('pagador_type', pagador_type);
        data.append('estudiante_id', estudiante_id);
        data.append('forma_pago', forma_pago);
        data.append('referencia', referencia);
        data.append('productos', JSON.stringify(productos));
        data.append('dias', JSON.stringify(dias));
        data.append('total', total);

        RestClient.PostRequest(AppUrl.CreatePago, data).then((result) => {
            setStatusResult(result.status);
            setProcessingRequest(false);
            setErrors(result.data);
            if (result.status === 200) {
                toast.success(result.message);
            } else if (result.status === 302) {
                window.location.replace(result.checkout_session.checkoutURL);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }


    if (usersAccess.find(ua => ua.modulo_name === 'pagos' && ua.permiso_name === 'create') === undefined) {
        return <Navigate to='/access-denied' />
    }
    if (statusResult === 200) {
        return <Navigate to='/pagos' />
    }
    if (statusResult === 302) {
        return null;
    }
    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60 ml-0' : 'sm:ml-20 ml-0'} duration-300 px-2 sm:pt-24 pt-20 pb-6 sm:px-4 md:px-8 bg-gray-100`}>
            <div className='sm:px-0 px-3'>
                {/* Page Title */}
                <div className='flex w-full'>
                    <h1 className='sm:text-xl text-lg font-medium'>Crear Pago</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                    {usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                    <p className='text-gray-400'>Finanzas</p>
                    <span className='text-xs'>/</span>
                    <Link to='/pagos' className='font-semibold hover:underline'>Pagos</Link>
                    <span className='text-xs'>/</span>
                    <p className='text-dark-purple font-semibold'>Crear Pago</p>
                </div>
            </div>
            {/* Main Section */}
            <div className='w-full flex justify-center mt-6'>
                <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                    <div className='sm:w-11/12 w-full sm:my-8'>
                        <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                            <div className='bg-gray-50'>
                                <div className='bg-white border'>
                                    <h1 className='text-accent-1 sm:text-2xl text-xl p-4'>Registrar Pago</h1>
                                </div>
                                {!loaded ?
                                    <div className='flex w-full justify-center bg-white'>
                                        <Loading />
                                    </div> :
                                    <form onSubmit={onFormSubmit} className='mt-4'>
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Área</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-2' viewBox="0 0 512 512">
                                                                <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" />
                                                            </svg>
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select id='selectArea' name="area" defaultValue={1} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione Área --</option>
                                                                <option value="1">Cafetería</option>
                                                            </select>
                                                            <label htmlFor="selectArea" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Área</label>
                                                        </div>
                                                    </div>
                                                    {/* <span className='text-sm text-red-500'>{errors.producto}</span> */}
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4 mt-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Pago</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex gap-4">
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                            <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <Select placeholder='--Seleccionar Pagador--'
                                                                    options={pagadores}
                                                                    menuPortalTarget={document.body}
                                                                    className='p-1'
                                                                    onChange={handlePayee}
                                                                    isLoading={pagadores.length === 0}
                                                                    isDisabled={user.user_type !== 'administrativos'}
                                                                    value={user.user_type !== 'administrativos' ?
                                                                        { value: user.id, label: `${user.nombre} ${user.apellido}`, type: user.user_type } :
                                                                        pagadores.map(u => u.options).flat().filter(p => p.value === pagador_id)
                                                                    }
                                                                    styles={{
                                                                        option: (base, state) => ({
                                                                            ...base,
                                                                            backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                                                        }),
                                                                    }}
                                                                />
                                                                <label htmlFor="selectPagadores" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Pagando</label>
                                                            </div>
                                                        </div>
                                                        {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === pagador_type) !== undefined &&
                                                            <div className='flex items-center'>
                                                                <Link to={`/${pagador_type}/crear`} title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PlusIcon className='h-5' />
                                                                </Link>
                                                            </div>}
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.pagador_id}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex gap-4">
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                            <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <Select placeholder='--Seleccionar Estudiante--'
                                                                    options={filteredEstudiantes}
                                                                    menuPortalTarget={document.body}
                                                                    className='p-1'
                                                                    value={filteredEstudiantes.find(fe => fe.value === estudiante_id) || null}
                                                                    onChange={(usuario) => setEstudianteId(usuario.value)}
                                                                    isLoading={estudiantes.length === 0}
                                                                    styles={{
                                                                        option: (base, state) => ({
                                                                            ...base,
                                                                            backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                                                        }),
                                                                    }}
                                                                />
                                                                <label htmlFor="selectEstudiante" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Estudiante</label>
                                                            </div>
                                                        </div>
                                                        {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'estudiantes') !== undefined &&
                                                            <div className='flex items-center'>
                                                                <Link to='/estudiantes/crear' title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PlusIcon className='h-5' />
                                                                </Link>
                                                            </div>}
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.estudiante_id}</span>
                                                </div>
                                                <div hidden={area !== 'cafeteria'} className=''>
                                                    <fieldset className={`border ${errors.dias && 'border-red-500'} rounded bg-white p-3`}>
                                                        <legend className='px-1 text-sm text-gray-400 flex justify-between items-center gap-2'>
                                                            <span>Días a pagar, Semana: {week.startDate} a {week.endDate}</span>
                                                        </legend>
                                                        <div className='grid lg:grid-cols-3 grid-cols-2'>
                                                            {days.length === 0 ?
                                                                <div className='col-span-3'>
                                                                    <p className='italic text-slate-500 text-sm font-medium'>Pagos de cafetería únicamente disponibles de Domingo a Viernes</p>
                                                                </div>
                                                                : days.map((day, index) => (
                                                                    <div key={day.value} className='px-3 inline-flex gap-3 col-span-1'>
                                                                        <input id={'checkDay' + day.value} onChange={(e) => updateDays(e.target.checked, day)} disabled={days.length === 1} defaultChecked={days.length === 1 || (today.getDay() > 0 && index === 0)} className='accent-accent-2 peer' type="checkbox" style={{ transform: 'scale(1.5)' }} />
                                                                        <label htmlFor={'checkDay' + day.value} className='text-base peer-disabled:text-gray-400 text-gray-800 text-light font-normal'>
                                                                            {day.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </fieldset>
                                                    <span className='text-sm text-red-500'>{errors.dias}</span>
                                                </div>
                                                <div hidden={user.user_type !== 'administrativos'}>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            {(forma_pago === 'efectivo' && <CashIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />) ||
                                                                (forma_pago === 'tarjeta' && <CreditCardIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />) ||
                                                                (forma_pago === 'deposito' && <CurrencyDollarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />)}
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select id='selectFormaPago' onChange={(e) => setFormaPago(e.target.value)} name="forma_pago" value={forma_pago} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione Método --</option>
                                                                <option value="efectivo">Efectivo</option>
                                                                <option value="tarjeta">Tarjeta</option>
                                                                <option value="deposito">Depósito</option>
                                                            </select>
                                                            <label htmlFor="selectFormaPago" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Método de Pago</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.forma_pago}</span>
                                                </div>
                                                <div hidden={forma_pago !== 'deposito'}>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <HashtagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setReferencia(e.target.value)} id='textReferencia' type='text' name="referencia" maxLength={85} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                            <label htmlFor="textReferencia" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Referencia</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.referencia}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="space-y-4 md:space-y-0 w-full p-4 text-gray-800 items-center mb-4">
                                            <h2 className="text-accent-1 font-semibold">Información Productos</h2>
                                            <div className='md:px-5 md:container'>
                                                <table id='tblCondiciones' className='hidden md:inline-table w-full bg-white rounded-lg overflow-hidden md:shadow-md my-5'>
                                                    <thead className='text-white'>
                                                        {renderProductos('header')}
                                                    </thead>
                                                    <tbody className='flex-1 md:flex-none'>
                                                        {renderProductos('body')}
                                                    </tbody>
                                                    <tfoot className='flex-1 justify-end'>
                                                        <tr>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th className='pr-3'>
                                                                <p className='text-end font-bold text-base'>${subtotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} x Día</p>
                                                                <p className='text-end font-bold text-base'>Total a pagar x {dias.length} Día(s): ${total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                            </th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                                {/* Mobile */}
                                                <div className='md:hidden flex-col w-full'>
                                                    {renderProductosMobile()}
                                                    <p className='text-end font-bold text-lg'>${subtotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} x Día</p>
                                                    <p className='text-end font-bold text-lg'>Total a pagar x {dias.length} Día(s): ${total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                </div>
                                                <span className='text-sm text-red-500'>{errors.productos}</span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='p-4 flex justify-center gap-4 bg-white'>
                                            <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-4/5 sm:w-1/6 px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" hidden={processingRequest} disabled={today.getDay() === 6} className={`${!processingRequest && 'inline-flex'} disabled:bg-accent-2/70 justify-center items-center gap-x-2 text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>
                                                {forma_pago === 'tarjeta' ? 'Procesar Pago' : 'Guardar'}
                                                {forma_pago === 'tarjeta' && <ExternalLinkIcon className='h-6 w-6 text-white' />}
                                            </button>
                                            <Link to='/pagos' hidden={processingRequest} className={`${!processingRequest && 'flex'} justify-center items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                        </div>
                                    </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CrearPagoPage