import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import UsuariosTable from '../../components/usuarios/UsuariosTable';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
var CryptoJS = require("crypto-js");

class UsuariosPage extends Component {

    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        var _moduloPermissions = [];
        if (_usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'usuarios') !== undefined) {
            _moduloPermissions.push('create');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'usuarios') !== undefined) {
            _moduloPermissions.push('update');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'delete' && ua.modulo_name === 'usuarios') !== undefined) {
            _moduloPermissions.push('delete');
        }
        this.state = {
            usersAccess: _usersAccess,
            moduloPermissions: _moduloPermissions,
            dataUsuarios: null,
            filter: 'todos',
            hasUpdated: false,
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.Users).then((result) => {
            this.setState({ dataUsuarios: result });
        });
    }

    componentDidUpdate(_, prevState) {
        if (prevState.hasUpdated !== this.state.hasUpdated) {
            RestClient.GetRequest(AppUrl.Users).then((result) => {
                this.setState({ dataUsuarios: result });
            });
        }
    }

    handleHasUpdated = () => {
        this.setState({ hasUpdated: !this.state.hasUpdated });
    }

    handleFilterData = (filter) => {
        switch (filter) {
            case 'todos':
                return this.state.dataUsuarios;
            case 'tutores':
                return this.state.dataUsuarios.filter(u => u.user_type === 'tutores');
            case 'administrativos':
                return this.state.dataUsuarios.filter(u => u.user_type === 'administrativos');
            case 'estudiantes':
                return this.state.dataUsuarios.filter(u => u.user_type === 'estudiantes');
            default:
                return this.state.dataUsuarios;
        }
    }

    render() {
        if (this.state.usersAccess.find(ua => ua.modulo_name === 'usuarios') === undefined) {
            return <Navigate to='/access-denied' />
        }
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'ml-80' : 'ml-20'} duration-300 p-12 pt-24 pr-16 bg-gray-100`}>
                {/* Page Title */}
                <div className='flex w-full'>
                    <h1 className='text-xl font-medium'>Usuarios</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 mt-2 space-x-1'>
                    {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                    <>
                        <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                        <span className='text-xs'>/</span>
                    </>}
                    <p className='text-gray-400'>Admin</p>
                    <span className='text-xs'>/</span>
                    <Link to='/usuarios' className='text-dark-purple font-semibold hover:underline'>Usuarios</Link>
                </div>
                {/* Data Table And Filter */}
                <div className='w-full flex justify-center mt-6'>
                    <div className='w-full flex-col justify-center bg-white rounded shadow-lg'>
                        <div className='mx-12 mt-8 mb-8 bg-gray-100 rounded shadow-lg'>
                            <fieldset disabled={this.state.dataUsuarios === null} className="flex flex-col lg:flex-row w-full lg:items-center p-5 space-y-3 lg:space-y-0">
                                <div className="w-3/5">
                                    <h1 className="font-semibold">Filtrar por:</h1>
                                </div>                            
                                <div className='flex flex-col md:flex-row w-full justify-start md:gap-8 gap-4'>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioTodos" value="todos" defaultChecked/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioTodos">
                                            Todos
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioTutores" value="tutores"/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioTutores">
                                            Tutores
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioAdministrativos" value="administrativos"/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioAdministrativos">
                                            Administrativos
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioEstudiantes" value="estudiantes"/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioEstudiantes">
                                            Estudiantes
                                        </label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div className='mx-12 mt-8 mb-8'>
                            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                <UsuariosTable data={this.handleFilterData(this.state.filter)} controls={this.state.moduloPermissions} hasUpdated={this.handleHasUpdated} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UsuariosPage;