import { EyeIcon, EyeOffIcon, PaperAirplaneIcon, SearchIcon, XIcon } from '@heroicons/react/outline'
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, PaperAirplaneIcon as PaperAirplaneSolidIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { Link, useOutletContext, useParams } from 'react-router-dom'
import RespuestaComponent from '../../components/form-controls/RespuestaComponent';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { Collapse } from 'react-collapse';

const RecordatoriosPage = (props) => {
    // Get Auth user
    const user = useOutletContext().user;
    // Use Params
    const { id: reminderId } = useParams();
    // Recordatorio Page States
    const [allRecordatorios, setAllRecordatorios] = useState([]);
    const [unreadRecordatorios, setUnreadRecordatorios] = useState([]);
    const [readRecordatorios, setReadRecordatorios] = useState([]);
    const [recordatoriosLoaded, setRecordatoriosLoaded] = useState(false);
    const [activeTab, setActiveTab] = useState('unread');
    const [groupMenu, setGroupMenu] = useState(false);
    const [selectedReminder, setSelectedReminder] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [processingRequest, setProcessingRequest] = useState(false);

    useEffect(() => {
        if (user) {
            RestClient.GetRequest(AppUrl.AllUserReminders + user.id).then((result) => {
                setAllRecordatorios(result);
                setUnreadRecordatorios(result.filter(r => r.leido === 0));
                setReadRecordatorios(result.filter(r => r.leido === 1));
                setRecordatoriosLoaded(true);
                if (reminderId) {
                    setSelectedReminder(result.find(r => r.id === parseInt(reminderId)));
                }
            });
        }
    }, [user, reminderId]);

    // Control menu event listener
    useEffect(() => {
        if (recordatoriosLoaded) {
            const menu = document.getElementById("groupMenu");
            document.addEventListener("click", (event) => {
                const isClickInside = menu.contains(event.target);
                if (!isClickInside) {
                    setGroupMenu(false);
                }
            });
        }
    }, [recordatoriosLoaded]);


    function onRefresh() {
        setProcessingRequest('onRefresh');

        RestClient.GetRequest(AppUrl.AllUserReminders + user.id).then((result) => {
            setAllRecordatorios(result);
            setUnreadRecordatorios(result.filter(r => r.leido === 0));
            setReadRecordatorios(result.filter(r => r.leido === 1));
            setProcessingRequest(false);
        });
    }

    function onSearch(value) {
        const searchingArray = activeTab === 'unread' ? unreadRecordatorios : readRecordatorios;
        let resultArray = [];
        if (value) {
            let searchQuery = normalizeString(value);
            resultArray = searchingArray.filter(sa => {
                const { titulo, mensaje } = sa;
                return normalizeString(titulo).includes(searchQuery) || normalizeString(mensaje).includes(searchQuery);
            });
            if (activeTab === 'unread') {
                setUnreadRecordatorios(resultArray);
            } else {
                setReadRecordatorios(resultArray);
            }
        } else {
            setUnreadRecordatorios(allRecordatorios.filter(r => r.leido === 0));
            setReadRecordatorios(allRecordatorios.filter(r => r.leido === 1));
        }
    }

    function normalizeString(string) {
        string = string || '';
        string = string.toLowerCase();
        string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        string = string.trim();
        return string;
    }

    function onReadUnread() {
        setProcessingRequest('onReadUnread');

        const data = new FormData();
        data.append('usuario_id', user.id);

        RestClient.PostRequest(AppUrl.ToggleReminderRead + selectedReminder.id, data).then((result) => {
            setProcessingRequest(false);

            const { data } = result;
            setAllRecordatorios(data);
            setUnreadRecordatorios(data.filter(r => r.leido === 0));
            setReadRecordatorios(data.filter(r => r.leido === 1));
            setSelectedReminder(prevState => ({ ...prevState, leido: !prevState.leido }));

            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    function onNavigateReminders(value) {
        const currentIndex = allRecordatorios.indexOf(selectedReminder);
        const newSelectedReminder = allRecordatorios[currentIndex + value];
        setSelectedReminder(newSelectedReminder);
    }

    function formatTimestamp(timestamp, fullFormat = false) {
        const currentDate = new Date();
        const date = new Date(timestamp);
        const timeDifference = currentDate.getTime() - date.getTime();
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const isToday = (
            date.getDate() === currentDate.getDate() &&
            date.getMonth() === currentDate.getMonth() &&
            date.getFullYear() === currentDate.getFullYear()
        );
        const isThisYear = date.getFullYear() === currentDate.getFullYear();

        // Check if the timestamp is from today
        if (isToday) {
            const options = { hour: 'numeric', minute: 'numeric', hour12: fullFormat === false };
            const formattedDateTime = date.toLocaleString('es-MX', options).replace(/\b0:/, '12:');
            return !fullFormat ? formattedDateTime : `${formattedDateTime} (${getTimeAgo(daysDifference, timeDifference)})`;
        }

        // Check if the timestamp is from this year
        if (isThisYear) {
            const options = !fullFormat ? { month: 'short', day: 'numeric' } : { weekday: 'short', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' };
            const formattedDateTime = date.toLocaleString('es-MX', options);
            return !fullFormat ? formattedDateTime : `${formattedDateTime} (${getTimeAgo(daysDifference, timeDifference)})`;
        }

        // Default format for timestamps not from this year
        const formattedDateTime = !fullFormat ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : date.toLocaleString('es-MX', { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' });
        return !fullFormat ? formattedDateTime : `${formattedDateTime} (${getTimeAgo(daysDifference, timeDifference)})`;
    }

    function getTimeAgo(daysDifference, timeDifference) {
        if (daysDifference < 14) {
            if (daysDifference === 0) {
                const minutesDifference = Math.floor(timeDifference / (1000 * 60));
                const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

                if (hoursDifference >= 1) {
                    return `Hace ${hoursDifference} ${hoursDifference === 1 ? 'hora' : 'horas'}`;
                } else if (minutesDifference >= 1) {
                    return `Hace ${minutesDifference} ${minutesDifference === 1 ? 'minuto' : 'minutos'}`;
                } else {
                    return 'Hace unos segundos';
                }
            }
            const timeUnits = [
                { unit: 'día', value: 1 },
                { unit: 'hora', value: 24 },
                { unit: 'minuto', value: 60 },
            ];

            for (const unit of timeUnits) {
                const timeValue = Math.floor(daysDifference * unit.value);
                if (timeValue > 0) {
                    return `Hace ${timeValue} ${unit.unit}${timeValue === 1 ? '' : 's'}`;
                }
            }
        }

        return '';
    }

    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60 ml-0' : 'sm:ml-20 ml-0'} duration-300 px-2 sm:pt-24 pt-20 pb-6 sm:px-4 md:px-8 bg-gray-100`}>
            {/* Page Title */}
            <div className='sm:px-0 px-3'>
                <div className='flex w-full'>
                    <h1 className='sm:text-xl text-lg font-medium'>Mis Recordatorios</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                    <Link to='/perfil' className='font-semibold hover:underline'>Perfil</Link>
                    <span className='text-xs'>/</span>
                    <Link to='/recordatorios' className='text-dark-purple font-semibold hover:underline'>Recordatorios</Link>
                </div>
            </div>
            <main className='flex-grow mt-2'>
                {!recordatoriosLoaded ?
                    <div className='flex w-full justify-center bg-white'>
                        <Loading />
                    </div> :
                    <div className='flex md:flex-row flex-col relative h-full'>
                        {/* Sidebar */}
                        <div className='w-full md:w-auto'>
                            <div className={`bg-white border-slate-200 ${props.sidebar ? 'md:w-56' : 'md:w-72'} xl:w-72 w-full md:border-r flex-shrink-0 top-16 sticky md:rounded-tl-md md:rounded-bl-md md:rounded-r-none rounded-md duration-300`}>
                                <div>
                                    {/* Group Header */}
                                    <div className='z-10 top-0 sticky'>
                                        <div className='flex items-center bg-white border-slate-200 px-5 border-b h-16 md:rounded-none rounded-t-md'>
                                            <div className='flex justify-between items-center w-full'>
                                                <div id='groupMenu' className='relative inline-block text-left flex-grow z-10 rounded-full border border-slate-200'>
                                                    <button type="button" /* onClick={() => setGroupMenu(prevState => (!prevState))} */ className='flex w-full items-center justify-around py-1'>
                                                        <PaperAirplaneIcon className='w-5 h-5 rotate-45 text-accent-2' />
                                                        <div className='overflow-hidden text-ellipsis whitespace-nowrap'>
                                                            <span className='text-slate-800 font-semibold md:text-sm'>Pagos - Cafetería</span>
                                                        </div>
                                                        <ChevronDownIcon className='w-5 h-5 text-accent-2' />
                                                    </button>
                                                    <div className={`dropdown-menu ${!groupMenu && 'invisible opacity-0 -translate-y-2 scale-95'} sm:origin-top-right origin-top-left transform transition-all duration-300`}>
                                                        <div className="absolute left-0 mt-1 w-[11.5rem] origin-top-left divide-y-2 divide-gray-100 rounded-md bg-white shadow-lg outline-none border border-slate-200 -z-50" aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items" role="menu">
                                                            <ul className='block mr-0 space-y-2'>
                                                                <li className='mr-0.5 md:mr-0 md:mb-0.5'>
                                                                    <button type='button' disabled={user === null} /* onClick={() => handleSectionSelect('Mi Perfil')} */ className={`hover:bg-blue-50 focus:outline-accent-2 flex w-full items-center px-2.5 py-2 rounded whitespace-nowrap  group`}>
                                                                        <span className={`group-hover:text-accent-2 text-sm font-medium `}>Todos</span>
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type='button' onClick={onRefresh} title='Actualizar' disabled={processingRequest} className='disabled:bg-gray-100 disabled:shadow-none shadow-sm p-1.5 bg-white border-slate-200 border rounded flex-shrink-0 ml-2 group'>
                                                    {processingRequest === 'onRefresh' ?
                                                        <svg className="animate-spin h-5 w-5 text-accent-2 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg> :
                                                        <svg xmlns="http://www.w3.org/2000/svg" className='fill-slate-500' height="1em" viewBox="0 0 512 512">
                                                            <path d="M125.7 160H176c17.7 0 32 14.3 32 32s-14.3 32-32 32H48c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32s32 14.3 32 32v51.2L97.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L125.7 160z" />
                                                        </svg>}
                                                </button>
                                                <button type="button" onClick={() => setIsSidebarOpen(prevState => !prevState)} className='md:hidden shadow-sm px-2 py-1 bg-white border-slate-200 border rounded flex-shrink-0 ml-2 group'>
                                                    <ChevronDownIcon className='w-5 h-5 text-accent-2' />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Group Body */}
                                    <Collapse isOpened={isSidebarOpen}>
                                        <div className='flex flex-col px-5 py-4 min-h-[70vh]'>
                                            {/* Search Form */}
                                            <form className='relative mt-2'>
                                                <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                    <div className="w-1/5 flex items-center justify-center">
                                                        <SearchIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                    </div>
                                                    <div className="relative z-0 w-full group">
                                                        <input onChange={(e) => onSearch(e.target.value)} id='txtSearch' type="search" name="search" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                        <label htmlFor="txtSearch" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Buscar</label>
                                                    </div>
                                                </div>
                                            </form>
                                            {/* Tabs */}
                                            <div className='relative mt-4'>
                                                <ul className='lg:-mx-8 sm:-mx-6 font-medium text-sm flex-nowrap flex justify-around -mx-4 relative'>
                                                    <li className='lg:first:pl-8 sm:first:pl-6 first:pl-4 mr-6'>
                                                        <button type="button" onClick={() => setActiveTab('unread')} className={`${activeTab === 'unread' ? 'text-accent-2 border-accent-2 border-b-2' : 'text-slate-500'} pb-3 whitespace-nowrap block`}>
                                                            Nuevas
                                                        </button>
                                                    </li>
                                                    <li className='lg:first:pl-8 sm:first:pl-6 first:pl-4 mr-6'>
                                                        <button type="button" onClick={() => setActiveTab('read')} className={`${activeTab === 'read' ? 'text-accent-2 border-accent-2 border-b-2' : 'text-slate-500'} pb-3 whitespace-nowrap block`}>
                                                            Leídas
                                                        </button>
                                                    </li>
                                                </ul>
                                                <div className='border-b border-slate-200' aria-hidden={true} />
                                            </div>
                                            {/* Reminders List */}
                                            <div className='mt-4 flex flex-col justify-between flex-grow h-full'>
                                                <div className='flex-grow'>
                                                    <div className='text-slate-400 uppercase font-semibold text-xs mb-3'>
                                                        Recordatorios ({(activeTab === 'unread' ? unreadRecordatorios : readRecordatorios).length})
                                                    </div>
                                                    <ul className='mb-6 overflow-x-hidden overflow-y-auto max-h-[50vh]'>
                                                        {(activeTab === 'unread' ? unreadRecordatorios : readRecordatorios).map(recordatorio => (
                                                            <li key={recordatorio.id} className='border-b'>
                                                                <button type="button" onClick={() => { setSelectedReminder(recordatorio); window.innerWidth < 640 && setIsSidebarOpen(false) }} className={`hover:bg-blue-50 ${selectedReminder?.id === recordatorio.id && 'bg-blue-50'} text-left p-2 rounded w-full flex cursor-pointer`}>
                                                                    <div className='overflow-hidden text-ellipsis whitespace-nowrap flex-grow'>
                                                                        {/* Title */}
                                                                        <div className='overflow-hidden text-ellipsis whitespace-nowrap'>
                                                                            <span className={`text-slate-800 ${recordatorio.leido ? 'font-medium' : 'font-bold'} text-sm`}>{recordatorio.titulo}</span>
                                                                        </div>
                                                                        {/* Timestamp */}
                                                                        <div className='text-slate-500 font-medium text-xs text-end'>{formatTimestamp(recordatorio.created_at)}</div>
                                                                    </div>
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className='text-slate-400 uppercase font-semibold text-xs'>
                                                    Total Recordatorios ({allRecordatorios.length})
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                        <div className='md:hidden flex flex-col flex-grow mt-2'>
                            {/* Header */}
                            <div className='top-16 sticky'>
                                <div className='px-4 bg-white border-slate-200 border-b justify-between items-center h-24 flex rounded-t-md'>
                                    {/* Left Controls */}
                                    <div className='flex flex-col gap-y-2'>
                                        <button type="button" onClick={onReadUnread} disabled={!selectedReminder || processingRequest} className='disabled:bg-gray-100 disabled:shadow-none inline-flex items-center shadow-sm p-1.5 bg-white border-slate-200 border rounded flex-shrink-0 group'>
                                            <span className='group-disabled:text-slate-400 text-sm font-medium mr-2'>Marcar como{selectedReminder?.leido ? ' no' : ''} leído</span>
                                            {processingRequest === 'onReadUnread' ?
                                                <svg className="animate-spin h-5 w-5 text-accent-2 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg> :
                                                selectedReminder?.leido ? 
                                                <EyeOffIcon className='group-disabled:text-slate-400 w-5 h-5 text-slate-500' /> :
                                                <EyeIcon className='group-disabled:text-slate-400 w-5 h-5 text-slate-500' />
                                            }
                                        </button>
                                        <button type="button" onClick={() => setSelectedReminder(null)} disabled={!selectedReminder || processingRequest} className='disabled:bg-gray-100 disabled:shadow-none inline-flex justify-between items-center shadow-sm p-1.5 bg-white border-slate-200 border rounded flex-shrink-0 group'>
                                            <span className='group-disabled:text-slate-400 text-sm font-medium mr-2'>Cerrar</span>
                                            <XIcon className='group-disabled:text-slate-400 w-5 h-5 text-slate-500' />
                                        </button>
                                    </div>
                                    {/* Right Controls */}
                                    <div className='flex items-center'>
                                        <div className='text-xs mr-1'>
                                            <span className='font-medium px-1'>{!selectedReminder ? '0' : allRecordatorios.indexOf(selectedReminder) + 1}</span>
                                            <span className='text-slate-500'>de</span>
                                            <span className='font-medium px-1'>{allRecordatorios.length}</span>
                                        </div>
                                        <button type="button" onClick={() => onNavigateReminders(-1)} disabled={!selectedReminder || processingRequest || allRecordatorios[0].id === selectedReminder.id} className='disabled:bg-gray-100 disabled:shadow-none shadow-sm p-1.5 bg-white border-slate-200 border rounded flex-shrink-0 ml-2 group'>
                                            <ChevronLeftIcon className='group-disabled:text-slate-400 w-5 h-5 text-accent-2' />
                                        </button>
                                        <button type="button" onClick={() => onNavigateReminders(+1)} disabled={!selectedReminder || processingRequest || allRecordatorios[allRecordatorios.length - 1].id === selectedReminder.id} className='disabled:bg-gray-100 disabled:shadow-none shadow-sm p-1.5 bg-white border-slate-200 border rounded flex-shrink-0 ml-2 group'>
                                            <ChevronRightIcon className='group-disabled:text-slate-400 w-5 h-5 text-accent-2' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='px-5 flex-grow overflow-x-hidden overflow-y-auto max-h-[84vh] border-x border-b rounded-b-md border-slate-200 pb-5'>
                                {!selectedReminder ?
                                    <div className='flex flex-col justify-center items-center h-full gap-y-4 py-10'>
                                        <PaperAirplaneSolidIcon className='w-20 h-20 rotate-45 text-slate-300' />
                                        <h1 className='uppercase font-bold text-slate-300 text-lg'>Recordatorios</h1>
                                    </div> :
                                    <>
                                        {/* Title */}
                                        <header className='mt-4 mb-8'>
                                            <h1 className='text-slate-800 leading-snug font-bold text-xl ml-2 mb-1'>{selectedReminder.titulo}</h1>
                                            <h6 className='text-sm font-medium text-slate-600 self-center ml-2 mb-1'
                                                title={new Date(selectedReminder.created_at).toLocaleString('es-MX', { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' })}
                                            >
                                                {formatTimestamp(selectedReminder.created_at, true)}
                                            </h6>
                                        </header>
                                        {/* Message */}
                                        <div className='shadow-lg px-6 py-4 bg-white border-slate-200 border rounded-sm'>
                                            <RespuestaComponent key={selectedReminder.id} value={selectedReminder.mensaje} />
                                        </div>
                                    </>}
                            </div>
                        </div>
                        {/* Reminder Body */}
                        <div className='hidden md:translate-x-0 duration-300 transition-transform flex-col translate-x-0 flex-grow md:flex rounded-tr-md'>
                            {/* Header */}
                            <div className='top-16 sticky'>
                                <div className={`lg:px-5 px-1 bg-slate-50 border-slate-200 border-b justify-between items-center ${props.sidebar ? 'h-32' : 'h-24'} lg:h-16 flex flex-wrap`}>
                                    {/* Left Controls */}
                                    <div className='flex lg:flex-row lg:gap-0 flex-col gap-y-2'>
                                        <button type="button" onClick={onReadUnread} disabled={!selectedReminder || processingRequest} className='disabled:bg-gray-100 disabled:shadow-none inline-flex items-center shadow-sm p-1.5 bg-white border-slate-200 border rounded flex-shrink-0 ml-2 group'>
                                            <span className='group-disabled:text-slate-400 text-sm font-medium mr-2'>Marcar como{selectedReminder?.leido ? ' no' : ''} leído</span>
                                            {processingRequest === 'onReadUnread' ?
                                                <svg className="animate-spin h-5 w-5 text-accent-2 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg> :
                                                selectedReminder?.leido ? 
                                                <EyeOffIcon className='group-disabled:text-slate-400 w-5 h-5 text-slate-500' /> :
                                                <EyeIcon className='group-disabled:text-slate-400 w-5 h-5 text-slate-500' />
                                                
                                            }
                                        </button>
                                        <button type="button" onClick={() => setSelectedReminder(null)} disabled={!selectedReminder || processingRequest} className='disabled:bg-gray-100 disabled:shadow-none inline-flex justify-between items-center shadow-sm p-1.5 bg-white border-slate-200 border rounded flex-shrink-0 ml-2 group'>
                                            <span className='group-disabled:text-slate-400 text-sm font-medium mr-2'>Cerrar</span>
                                            <XIcon className='group-disabled:text-slate-400 w-5 h-5 text-slate-500' />
                                        </button>
                                    </div>
                                    {/* Right Controls */}
                                    <div className='flex items-center'>
                                        <div className='text-xs mr-1'>
                                            <span className='font-medium px-1'>{!selectedReminder ? '0' : allRecordatorios.indexOf(selectedReminder) + 1}</span>
                                            <span className='text-slate-500'>de</span>
                                            <span className='font-medium px-1'>{allRecordatorios.length}</span>
                                        </div>
                                        <button type="button" onClick={() => onNavigateReminders(-1)} disabled={!selectedReminder || processingRequest || allRecordatorios[0].id === selectedReminder.id} className='disabled:bg-gray-100 disabled:shadow-none shadow-sm p-1.5 bg-white border-slate-200 border rounded flex-shrink-0 ml-2 group'>
                                            <ChevronLeftIcon className='group-disabled:text-slate-400 w-5 h-5 text-accent-2' />
                                        </button>
                                        <button type="button" onClick={() => onNavigateReminders(+1)} disabled={!selectedReminder || processingRequest || allRecordatorios[allRecordatorios.length - 1].id === selectedReminder.id} className='disabled:bg-gray-100 disabled:shadow-none shadow-sm p-1.5 bg-white border-slate-200 border rounded flex-shrink-0 ml-2 group'>
                                            <ChevronRightIcon className='group-disabled:text-slate-400 w-5 h-5 text-accent-2' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* Body */}
                            <div className='md:px-5 sm:px-6 px-4 flex-grow overflow-x-hidden overflow-y-auto max-h-[84vh] border-r border-b rounded-br-md border-slate-200'>
                                {!selectedReminder ?
                                    <div className='flex flex-col justify-center items-center h-full gap-y-4'>
                                        <PaperAirplaneSolidIcon className='w-20 h-20 rotate-45 text-slate-300' />
                                        <h1 className='uppercase font-bold text-slate-300 text-lg'>Recordatorios</h1>
                                    </div> :
                                    <>
                                        {/* Title */}
                                        <header className='sm:justify-between sm:items-start sm:flex mt-4 mb-8'>
                                            <h1 className='sm:mb-0 text-slate-800 leading-snug font-bold text-xl ml-2 mb-1'>{selectedReminder.titulo}</h1>
                                            <h6 className='text-sm font-medium text-slate-600 self-center max-w-[150px] lg:max-w-none'
                                                title={new Date(selectedReminder.created_at).toLocaleString('es-MX', { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' })}
                                            >
                                                {formatTimestamp(selectedReminder.created_at, true)}
                                            </h6>
                                        </header>
                                        {/* Message */}
                                        <div className='shadow-lg px-6 py-4 bg-white border-slate-200 border rounded-sm'>
                                            <RespuestaComponent key={selectedReminder.id} value={selectedReminder.mensaje} />
                                        </div>
                                    </>}
                            </div>
                        </div>
                    </div>}
            </main>
        </div>
    )
}

export default RecordatoriosPage