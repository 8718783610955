import React, { Component } from 'react';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import { Link, Navigate } from 'react-router-dom';
import { AcademicCapIcon, CalendarIcon, CurrencyDollarIcon, MinusIcon, PlusIcon, StarIcon } from '@heroicons/react/outline';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { CogIcon, InformationCircleIcon, LightningBoltIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';
var CryptoJS = require("crypto-js");

const PercentSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-2' viewBox="0 0 384 512">
        <path d="M374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l320-320zM128 128c0-35.3-28.7-64-64-64S0 92.7 0 128s28.7 64 64 64s64-28.7 64-64zM384 384c0-35.3-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64s64-28.7 64-64z" />
    </svg>
);

class CrearColegiaturaPage extends Component {

    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.state = {
            usersAccess: _usersAccess,
            // Colegiatura states
            tipo: { value: 'm', frequency: 1 }, nivel_id: 0, ciclo_id: 0, precio: 0, colegiaturas: [], dia_limite: 10, cargo_tipo: 'porcentaje', cargo_valor: 10, aplicar_cobro: false,
            // Collections
            niveles: [], ciclos: [], dias: [], charge_types: [{ value: 'porcentaje', icon: <PercentSVG /> }, { value: 'cantidad', icon: <CurrencyDollarIcon className='w-6 h-6 text-gray-400 group-focus-within:text-accent-2' /> }],
            // Request/Form states
            is_select_open: false, changes_made: false, dias_habiles: false, starting_date: '', moved_dates: [], statusResult: 0, processingRequest: false, 
            errors: [{ tipo: '', nivel_id: '', ciclo_id: '', precio: '', dia_limite: '', dias_habiles: '', cargo_tipo: '', cargo_valor: '' }]
        }
    }

    componentDidMount() {
        // Get Niveles
        RestClient.GetRequest(AppUrl.Niveles).then((result) => {
            this.setState({ niveles: result });
        });
        // Get Ciclos
        RestClient.GetRequest(AppUrl.Ciclos).then((result) => {
            this.setState({ ciclos: result });
        });
        // Set Días Límite
        let days = [];
        for (let index = 1; index <= 31; index++) {
            days.push(index);
            if (index === 31) {
                this.setState({ dias: days });
            }
        }
    }

    validateDateForWeekends = (date, index) => {
        const dayOfWeek = date.getDay();
        let dateWasMoved = false;

        if (dayOfWeek === 0 || dayOfWeek === 6) {
            // If it's Sunday (0) or Saturday (6), move to Monday by adding 1 or 2 days.
            date.setDate(date.getDate() + (dayOfWeek === 0 ? 1 : 2));
            dateWasMoved = true;
        }

        if (dateWasMoved) {
            this.setState((prevState) => ({
                moved_dates: [
                    ...prevState.moved_dates,
                    { index, reason: 'Se movió la fecha debido a fin de semana.' },
                ],
            }));
        }

        return date;
    }

    getObservedDate = (occurrence, weekday, month, year) => {
        const date = new Date(year, month, 1);
        date.setDate(1 + (weekday - date.getDay() + 7) % 7 + (occurrence - 1) * 7);
        return date;
    };

    isHoliday = (date) => {
        const holidays = [
            { name: "Año Nuevo", month: 0, day: 1 },
            { name: "Día de la Constitución", observed: { occurrence: 1, weekday: 1, month: 1 }, }, // Observed on the first Monday of February
            { name: "Natalicio de Benito Juárez", observed: { occurrence: 3, weekday: 1, month: 2 } }, // Observed on the third Monday of March
            { name: "Día del Trabajo", month: 4, day: 1 },
            { name: "Día de la Independencia", month: 8, day: 16 },
            { name: "Día de la Revolución", observed: { occurrence: 3, weekday: 1, month: 10 } }, // Observed on the third Monday of November
            { name: "Navidad", month: 11, day: 25 },
        ];

        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        for (const holiday of holidays) {
            if ((month === holiday.month || month === holiday.observed?.month) && (
                (holiday.observed && day === this.getObservedDate(holiday.observed, year).getDate()) ||
                (!holiday.observed && day === holiday.day)
            )) {
                return holiday.name;
            }
        }
        return null;
    };

    validateDateForHolidays = (date, index) => {
        const holidayName = this.isHoliday(date);
        const movedReason = `\nSe movió la fecha debido a día festivo: ${holidayName}`;
        if (holidayName === null) return date;

        date.setDate(date.getDate() + 1);

        this.setState((prevState) => {
            const newMovedDates = prevState.moved_dates.map((md) =>
                md.index === index
                    ? { ...md, reason: md.reason + movedReason }
                    : md
            );

            if (!newMovedDates.some((md) => md.index === index)) {
                newMovedDates.push({ index, reason: movedReason });
            }

            return {
                moved_dates: newMovedDates,
            };
        });

        return date;
    };

    validateDateLimit = (date, day) => {
        // Create a new Date object for the next month
        const year = date.getFullYear();
        const month = date.getMonth();
        const nextMonth = new Date(year, month + 1, 1);

        // Subtract one day of the next month to get the last day of the current month
        nextMonth.setDate(nextMonth.getDate() - 1);

        if (day > nextMonth.getDate()) {
            return nextMonth.getDate();
        }

        return day;
    }

    generateColegiaturas = () => {
        const { ciclos, ciclo_id, tipo, dia_limite, dias_habiles, starting_date, aplicar_cobro } = this.state;
        const ciclo = ciclos.find(c => c.id === ciclo_id);
        let year = ciclo.fecha_inicial.split('-')[0];
        let month = ciclo.fecha_inicial.split('-')[1] - 1;
        let date = ciclo.fecha_inicial.split('-')[2];
        const fechaInicial = new Date(year, month, date);
        year = ciclo.fecha_final.split('-')[0];
        month = ciclo.fecha_final.split('-')[1] - 1;
        date = ciclo.fecha_final.split('-')[2];
        const fechaFinal = new Date(year, month, date);

        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const daysDifference = Math.floor((fechaFinal - fechaInicial) / millisecondsPerDay);

        if (daysDifference <= 0) return;

        let totalMonths = Math.ceil(daysDifference / 30.44); // Average days per month

        switch (tipo.value) {
            case "m": totalMonths /= tipo.frequency; break;
            case "t": totalMonths /= tipo.frequency; break;
            case "q": totalMonths /= tipo.frequency; break;
            case "s": totalMonths /= tipo.frequency; break;
            case "a": totalMonths /= tipo.frequency; break;
            default: return;
        }

        let newColegiaturas = [];
        year = starting_date.split('-')[0];
        month = starting_date.split('-')[1] - 1;
        date = starting_date.split('-')[2];
        let startingDate = starting_date ? new Date(year, month, date) : fechaInicial;
        for (let index = 0; index < Math.round(totalMonths); index++) {
            // Validate Month increase by Colegiatura Tipo
            const shouldIncreaseMonth = (starting_date && index > 0) || (!starting_date && tipo.value === "m") || (tipo.value !== "m" && index > 0);
            if (shouldIncreaseMonth) {
                startingDate.setDate(1);
                startingDate.setMonth(startingDate.getMonth() + tipo.frequency);
            }
            // Set Date Limit for payments except if the starting date was set
            if (!starting_date || index > 0) {
                startingDate.setDate(this.validateDateLimit(startingDate, dia_limite));
            }
            if ((!starting_date && dias_habiles) || (starting_date && index > 0 && dias_habiles)) {
                startingDate = this.validateDateForWeekends(startingDate, index);
                startingDate = this.validateDateForHolidays(startingDate, index);
            }
            // Add Colegiatura collection
            newColegiaturas.push({
                id: index,
                mes: startingDate.toLocaleDateString('es-MX', { month: 'long' }),
                no_mes: startingDate.getMonth() + 1,
                fecha_limite: startingDate.toLocaleDateString('en-CA'),
                aplicar_cobro: aplicar_cobro,
            });
        }

        this.setState({ colegiaturas: newColegiaturas, changes_made: false });
    }

    onTipoSelect = (e) => {
        const selectElement = e.target;
        let value = e.target.value;
        const selectedIndex = selectElement.selectedIndex;
        const selectedOption = selectElement.options[selectedIndex];
        const frequency = parseInt(selectedOption.getAttribute("data-frequency"), 10);

        this.setState({ tipo: { value, frequency }, moved_dates: [], colegiaturas: [] });
    }

    onCicloSelect = (e) => {
        let cicloId = parseInt(e.target.value);

        this.setState((prevState) => ({ ciclo_id: cicloId, moved_dates: [], changes_made: prevState.colegiaturas.length !== 0 }));
    }

    onPrecioBlur = (e) => {
        let value = e.target.value;
        if (!isNaN(parseFloat(value))) {
            value = parseFloat(value).toFixed(2);
        }
        e.target.value = value;
    }

    onCobroBlur = (e) => {
        let value = e.target.value;
        if (!isNaN(parseFloat(value))) {
            value = parseFloat(value).toFixed(2);
        }
        e.target.value = value;
    }

    onDiaSelect = (e) => {
        let dia = parseInt(e.target.value);

        this.setState((prevState) => ({ dia_limite: dia, moved_dates: [], changes_made: prevState.colegiaturas.length !== 0 }));
    }

    onToggleDropdown = () => {
        this.setState((prevState) => ({ is_select_open: !prevState.is_select_open }));
    }

    onSelectedType = (value) => {
        this.setState({ 
            cargo_tipo: value,
            is_select_open: false,
        });
    }

    onAplicarCobroCheck = (checkbox) => {
        this.setState((prevState) => {
            let updatedColegiaturas = [];
            if (prevState.colegiaturas.length > 0) {
                updatedColegiaturas = prevState.colegiaturas.map((colegiatura) => {
                    return {
                        ...colegiatura,
                        aplicar_cobro: checkbox.checked,
                    }
                });
            }

            return {
                aplicar_cobro: checkbox.checked,
                colegiaturas: updatedColegiaturas
            }
        });
    }

    updateDateColegiatura = (index, newDate) => {
        const dateParts = newDate.split("-");
        const year = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10) - 1;
        const day = parseInt(dateParts[2], 10);

        const date = new Date(year, month, day);

        this.setState((prevState) => ({
            colegiaturas: prevState.colegiaturas.map((colegiatura, i) => {
                if (index !== i) return colegiatura;

                return {
                    ...colegiatura,
                    fecha_limite: newDate,
                    no_mes: date.getMonth() + 1,
                    mes: date.toLocaleDateString('es-MX', { month: 'long' })
                }
            }),
        }));
    }

    updateCobroColegiatura = (index, checkbox) => {
        const { colegiaturas } = this.state;
        const updatedColegiaturas = colegiaturas.map((colegiatura, i) => {
            if (index !== i) return colegiatura;

            return {
                ...colegiatura,
                aplicar_cobro: checkbox.checked,
            }
        });

        const cobroValue = !updatedColegiaturas.some(c => !c.aplicar_cobro);

        this.setState({
            aplicar_cobro: cobroValue,
            colegiaturas: updatedColegiaturas,
        });
    }

    calculateNextDate = () => {
        const { colegiaturas, tipo, ciclos, ciclo_id } = this.state;
        const lastElementIndex = colegiaturas.length - 1;
        const lastElement = colegiaturas[lastElementIndex];
        const dateParts = lastElement.fecha_limite.split("-");
        const year = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10) - 1;
        const day = parseInt(dateParts[2], 10);
        const nextDate = new Date(year, month, day);
        nextDate.setMonth(nextDate.getMonth() + tipo.frequency);

        const ciclo = ciclos.find(c => c.id === ciclo_id);
        const fechaFinal = new Date(ciclo.fecha_final);

        if (nextDate > fechaFinal) {
            return null;
        }

        return nextDate;
    }

    addColegiatura = () => {
        this.setState((prevState) => {
            const nextDate = this.calculateNextDate();

            if (!nextDate) return prevState;

            return {
                colegiaturas: [
                    ...prevState.colegiaturas,
                    {
                        id: prevState.colegiaturas.length,
                        mes: nextDate.toLocaleDateString('es-MX', { month: 'long' }),
                        no_mes: nextDate.getMonth() + 1,
                        fecha_limite: nextDate.toLocaleDateString('en-CA'),
                        aplicar_cobro: prevState.aplicar_cobro,
                    }
                ],
            }
        });
    }

    removeColegiatura = (index) => {
        this.setState((prevState) => {
            const updatedColegiaturas = prevState.colegiaturas
            updatedColegiaturas.splice(index, 1);

            return {
                colegiaturas: updatedColegiaturas,
            }
        });
    }

    renderColegiaturas = () => {
        const { colegiaturas, moved_dates } = this.state;
        var body = [];
        for (let index = 0; index < colegiaturas.length; index++) {
            const movedDate = moved_dates.find(md => md.index === index);
            body.push(<tr key={index} className='flex flex-col flex-no wrap md:table-row mb-2 md:mb-0'>
                <td className='border p-1.5 max-h-[54px]'>
                    <p className='text-center'>{colegiaturas[index].no_mes}</p>
                </td>
                <td className='border p-1.5 max-h-[54px]'>
                    <p className='text-center'>{colegiaturas[index].mes}</p>
                </td>
                <td className='border p-1.5 max-h-[54px]'>
                    <div className='flex justify-center items-center'>
                        <div className='relative inline-flex items-center rounded-full px-3 border-accent-1 focus:border hover:border hover:bg-slate-50 transition-colors'>
                            <input onChange={({ target: { value } }) => this.updateDateColegiatura(index, value)} value={colegiaturas[index].fecha_limite} type="date" name="fecha_limite" id={`fl_${index}`} className='outline-none bg-transparent' />
                            {movedDate && <>
                                <ReactTooltip className='tooltip-default' anchorId={`tooltipMovedDates_${index}`} content={movedDate.reason} place="top" />
                                <LightningBoltIcon id={`tooltipMovedDates_${index}`} className='absolute -right-6 w-5 h-5 text-accent-2' />
                            </>}
                        </div>
                    </div>
                </td>
                <td className='border p-1.5 max-h-[54px]'>
                    <input onChange={({ target }) => this.updateCobroColegiatura(index, target)} type="checkbox" checked={colegiaturas[index].aplicar_cobro} className='block mx-auto accent-accent-2 w-5 h-5' />
                </td>
                <td className='border p-2 max-h-[54px]'>
                    <div className='flex w-full justify-center gap-4'>
                        <button type='button' onClick={this.addColegiatura} disabled={this.calculateNextDate() === null} className='disabled:opacity-70 disabled:hover:bg-accent-2 bg-accent-2 p-2 rounded hover:bg-blue-600'>
                            <PlusIcon className='text-white h-5' />
                        </button>
                        <button type='button' onClick={() => this.removeColegiatura(index)} disabled={colegiaturas.length === 1} className='disabled:opacity-70 disabled:hover:bg-accent-2 bg-accent-2 p-2 rounded hover:bg-blue-600'>
                            <MinusIcon className='text-white h-5' />
                        </button>
                    </div>
                </td>
            </tr>);
        }

        return body;
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        //Disable controls while request is processed and clear error messages
        this.setState({ processingRequest: true, errors: [{ tipo: '', nivel_id: '', ciclo_id: '', precio: '', dia_limite: '', dias_habiles: '', cargo_tipo: '', cargo_valor: '' }] });

        const data = new FormData();
        data.append('tipo', this.state.tipo.value);
        data.append('nivel_id', this.state.nivel_id);
        data.append('ciclo_id', this.state.ciclo_id);
        data.append('precio', this.state.precio);
        data.append('dia_limite', this.state.dia_limite);
        data.append('dias_habiles', this.state.dias_habiles);
        data.append('cargo_tipo', this.state.cargo_tipo);
        data.append('cargo_valor', this.state.cargo_valor);
        data.append('aplicar_cobro', this.state.aplicar_cobro);
        data.append('colegiaturas', JSON.stringify(this.state.colegiaturas));

        RestClient.PostRequest(AppUrl.CreateColegiaturaRegistro, data).then((result) => {
            const { status, data, message } = result;
            const errors = data ?? this.state.errors;
            this.setState({ statusResult: status, processingRequest: false, errors: errors });
            if (status === 200) {
                toast.success(message);
            }
            else {
                console.log(result);
                toast.error(message);
            }
        });
    }

    render() {
        if (this.state.usersAccess.find(ua => ua.modulo_name === 'colegiaturas' && ua.permiso_name === 'create') === undefined) {
            return <Navigate to='/access-denied' />
        }
        if (this.state.statusResult === 200) {
            return <Navigate to='/inscripciones-colegiaturas' />
        }
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60 ml-0' : 'sm:ml-20 ml-0'} duration-300 px-2 sm:pt-24 pt-20 pb-6 sm:px-4 md:px-8 bg-gray-100`}>
                <div className='sm:px-0 px-3'>
                    {/* Page Title */}
                    <div className='flex w-full'>
                        <h1 className='sm:text-xl text-lg font-medium'>Crear Colegiatura</h1>
                    </div>
                    {/* Breadcrum Nav */}
                    <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                        {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                            <>
                                <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                                <span className='text-xs'>/</span>
                            </>}
                        <p className='text-gray-400'>Finanzas</p>
                        <span className='text-xs'>/</span>
                        <Link to='/inscripciones-colegiaturas' className='font-semibold hover:underline'>Inscripciones y Colegiaturas</Link>
                        <span className='text-xs'>/</span>
                        <p className='text-dark-purple font-semibold'>Crear Colegiatura</p>
                    </div>
                </div>
                <div className='w-full flex justify-center mt-6'>
                    <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                        <div className='sm:w-11/12 w-full sm:my-8'>
                            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                <div className='bg-gray-50'>
                                    <div className='bg-white border'>
                                        <h1 className='text-accent-1 sm:text-2xl text-xl p-4'>Registrar Colegiatura</h1>
                                    </div>
                                    <form onSubmit={this.onFormSubmit} className='mt-4'>
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Tipo</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <StarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select onChange={this.onTipoSelect} id='selectTipo' name="tipo" defaultValue={"m"} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione Tipo De Colegiatura --</option>
                                                                <option value="m" data-frequency={1}>Mensual</option>
                                                                <option value="t" data-frequency={3}>Trimestral</option>
                                                                <option value="q" data-frequency={4}>Cuatrimestral</option>
                                                                <option value="s" data-frequency={6}>Semestral</option>
                                                                <option value="a" data-frequency={12}>Anual</option>
                                                                <option value="f" disabled className='text-gray-400 italic'>Pago completo por adelantado (Próximamente)</option>
                                                                <option value="c" disabled className='text-gray-400 italic'>Plan de pago personalizado (Próximamente)</option>
                                                            </select>
                                                            <label htmlFor="selectTipo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Tipo de Colegiatura</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.tipo}</span>
                                                </div>

                                            </div>
                                        </div>
                                        <hr />
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Colegiatura</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className='mt-4'>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <AcademicCapIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select onChange={(e) => this.setState({ nivel_id: e.target.value })} id='selectNivel' name="nivel_id" defaultValue={0} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione Nivel Educativo --</option>
                                                                {this.state.niveles.map(item => {
                                                                    return <option key={item.id} value={item.id}>{item.nombre}</option>
                                                                })}
                                                            </select>
                                                            <label htmlFor="selectNivel" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nivel Educativo</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.nivel_id}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select onChange={this.onCicloSelect} id='selectCiclo' name="ciclo_id" defaultValue={0} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione Ciclo Escolar --</option>
                                                                {this.state.ciclos.map(item => {
                                                                    return <option key={item.id} value={item.id}>{item.nombre}{item.estatus === 1 && ' \u2022 (Ciclo Actual)'}</option>
                                                                })}
                                                            </select>
                                                            <label htmlFor="selectCiclo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Ciclo Escolar</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.ciclo_id}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <CurrencyDollarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => this.setState({ precio: parseFloat(e.target.value).toFixed(2) })} onBlur={this.onPrecioBlur} id='txtPrecio' type="number" step={0.01} min={0.01} name="precio" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtPrecio" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Precio</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.precio}</span>
                                                </div>
                                                <div className='border border-dashed border-gray-400 rounded px-5 py-10 space-y-4'>
                                                    <div className=''>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                            <div className="mx-2 items-center flex justify-center">
                                                                <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <select onChange={this.onDiaSelect} id='selectLimite' name="dia_limite" value={this.state.dia_limite} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                    <option value="0" disabled className='text-gray-400'>-- Seleccione Día Límite --</option>
                                                                    {this.state.dias.map(day => {
                                                                        return <option key={day} value={day}>{day}</option>
                                                                    })}
                                                                </select>
                                                                <label htmlFor="selectLimite" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Día límite de pago</label>
                                                            </div>
                                                        </div>
                                                        <span className='text-sm text-red-500'>{this.state.errors.dia_limite}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className='inline-flex gap-2'>
                                                            <h5 className='md:text-sm text-xs text-slate-800 text-light font-normal mb-1'>Solo días hábiles</h5>
                                                            <ReactTooltip className='tooltip-default' anchorId='tooltipDiasHabiles' content='Si esta opción es marcada, en el caso de que la fecha límite de pago caiga en día inhábil o festivo, la fecha se recorre al día hábil siguiente.' place="top" />
                                                            <InformationCircleIcon id='tooltipDiasHabiles' className='h-5 text-accent-2' />
                                                        </div>
                                                        <div className="min-h-[1.5rem] mb-0.5 block pl-2">
                                                            <input onChange={() => this.setState((prevState) => ({ dias_habiles: !this.state.dias_habiles, changes_made: prevState.colegiaturas.length !== 0 }))} className='accent-accent-2' type="checkbox" style={{ transform: 'scale(1.5)' }} />
                                                        </div>
                                                        <span className='text-sm text-red-500'>{this.state.errors.dias_habiles}</span>
                                                    </div>
                                                    <div className='group'>
                                                        <h5 className='md:text-sm text-xs text-gray-400 text-light font-normal mb-1 group-focus-within:text-accent-1 transition-colors'>Especificar fecha del primer cobro <span className='text-xs'>(Opcional)</span></h5>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group transition-colors">
                                                            <div className="mx-2 items-center flex justify-center">
                                                                <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2 transition-colors' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input onChange={(e) => this.setState((prevState) => ({ starting_date: e.target.value, changes_made: prevState.colegiaturas.length !== 0 }))} id='dateStartingDate' type='date' name="starting_date" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 group-focus-within:border-accent-2 transition-colors" placeholder=" " />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='group'>
                                                        <div className='inline-flex gap-x-2'>
                                                            <h5 className='md:text-sm text-xs text-gray-400 text-light font-normal mb-1 group-focus-within:text-accent-1 transition-colors'>Cobro del <span className='font-medium'>{this.state.cargo_tipo === 'cantidad' ? '$ ' : ''}{isNaN(this.state.cargo_valor) ? '0.00' : this.state.cargo_valor}{this.state.cargo_tipo === 'porcentaje' ? ' %' : ''}</span></h5>
                                                            <ReactTooltip className='tooltip-default' anchorId='tooltipCobro' content='Indica en porcentaje o cantidad fija cuánto será el sobrecargo si la colegiatura se paga después del día límite de pago.' place="top" />
                                                            <InformationCircleIcon id='tooltipCobro' className='h-5 text-accent-2' />
                                                        </div>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group transition-colors">
                                                            <div className='relative w-16'>
                                                                <div className=' py-2 px-3 border-r-2 border-gray-400 group-focus-within:border-accent-2 cursor-pointer w-16' onClick={this.onToggleDropdown}>
                                                                    {this.state.charge_types.find(type => type.value === this.state.cargo_tipo).icon}
                                                                    <div className={`${this.state.is_select_open ? 'rotate-180' : 'rotate-0'} content-[""] absolute top-[50%] right-2 w-0 h-0 border-4 border-b-0 border-transparent border-t-[#666] transition-all`}></div>
                                                                </div>
                                                                <ul className={`${this.state.is_select_open ? 'block' : 'hidden'} list-none p-0 m-0 border border-t-0 absolute top-[100%] left-0 right-0`}>
                                                                    {this.state.charge_types.map((type) => (
                                                                        <li key={type.value} onClick={() => this.onSelectedType(type.value)} className='py-2 px-3 cursor-pointer bg-white shadow hover:bg-sky-50' title={type.value}>
                                                                            {type.icon}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input onChange={(e) => this.setState({cargo_valor: parseFloat(e.target.value).toFixed(2) })} onBlur={this.onCobroBlur} defaultValue={isNaN(this.state.cargo_valor) ? 0.00 : this.state.cargo_valor} type="number" step={0.01} min={0.01} name="valor_cargo" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent appearance-none focus:outline-none focus:ring-0 transition-colors" placeholder=" " required />
                                                            </div>
                                                        </div>
                                                        <span className='text-sm text-red-500'>{this.state.errors.cargo_tipo}</span>
                                                        <span className='text-sm text-red-500'>{this.state.errors.cargo_valor}</span>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <div className='inline-flex gap-2'>
                                                        <h5 className='md:text-sm text-xs text-slate-800 text-light font-normal mb-1'>Aplicar cobro</h5>
                                                        <ReactTooltip className='tooltip-default' anchorId='tooltipAplicarCobro' content='Si esta opción es marcada, la colegiatura se asociará a todos los estudiantes en el nivel y ciclo seleccionado.' place="top" />
                                                        <InformationCircleIcon id='tooltipAplicarCobro' className='h-5 text-accent-2' />
                                                    </div>
                                                    <div className="min-h-[1.5rem] mb-0.5 block pl-2">
                                                        <input onChange={({ target }) => this.onAplicarCobroCheck(target)} checked={this.state.aplicar_cobro} className='accent-accent-2' type="checkbox" style={{ transform: 'scale(1.5)' }} />
                                                    </div>
                                                </div>
                                                <div className='flex justify-end'>
                                                    <button type="button" onClick={this.generateColegiaturas} disabled={(!this.state.tipo || !this.state.ciclo_id || !this.state.dia_limite) || (this.state.colegiaturas.length > 0 && !this.state.changes_made)} className='disabled:bg-accent-2/60 disabled:hover:translate-y-0 disabled:shadow-none flex justify-center items-center bg-accent-2 rounded-full px-4 py-2 shadow-md shadow-slate-500 hover:-translate-y-1 transition duration-300 group'>
                                                        <span className='text-slate-100 text-base font-medium mr-2'>{this.state.changes_made ? 'Actualizar' : 'Generar'} Colegiaturas</span>
                                                        <CogIcon className='w-5 h-5 text-slate-100 group-hover:rotate-45 group-disabled:rotate-0 transition-all' />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div hidden={this.state.colegiaturas.length === 0} className="space-y-4 md:space-y-0 w-full p-4 text-gray-800 items-center mb-4">
                                            <h2 className="text-accent-1 font-semibold inline-flex items-center">Colegiaturas a Generar
                                                <span className="rounded-full bg-accent-2 flex justify-center items-center px-1.5 mx-2">
                                                    <span className='text-white font-semibold text-sm'>{this.state.colegiaturas.length}</span>
                                                </span>
                                            </h2>
                                            <div className='md:container'>
                                                <table id='tblCondiciones' className='hidden md:inline-table w-full bg-white rounded-lg overflow-hidden md:shadow-md my-5'>
                                                    <thead className='text-white'>
                                                        <tr className='bg-accent-2 flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-0'>
                                                            <th className="border-b-2 md:border-none p-3.5 text-left">No. Mes</th>
                                                            <th className="border-b-2 md:border-none p-3.5 text-left">Mes</th>
                                                            <th className="border-b-2 md:border-none p-3.5 text-left">Fecha Límite de Pago</th>
                                                            <th className="border-b-2 md:border-none p-3.5 text-left">Aplicar Cobro</th>
                                                            <th className="border-b-2 md:border-none p-3.5 text-left">Agregar/Remover</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='flex-1 md:flex-none'>
                                                        {this.renderColegiaturas()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <hr hidden={this.state.colegiaturas.length === 0} />
                                        <div className={`p-4 ${this.state.colegiaturas.length === 0 ? 'hidden' : 'flex'} justify-center gap-4 bg-white`}>
                                            <button hidden={!this.state.processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-4/5 sm:w-1/6 px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" disabled={this.state.changes_made} className={`disabled:bg-accent-2/70 ${this.state.processingRequest ? 'hidden' : 'inline-flex'} items-center text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center group`}>
                                                <span>Registrar Colegiaturas</span>
                                                <span className="rounded-full bg-white flex justify-center items-center px-1.5 mx-2">
                                                    <span className='group-disabled:text-accent-2/70 group-hover:text-accent-2 text-accent-1 font-semibold text-sm'>{this.state.colegiaturas.length}</span>
                                                </span>
                                            </button>
                                            <Link to='/inscripciones-colegiaturas' hidden={this.state.processingRequest} className={`${!this.state.processingRequest && 'flex'} justify-center items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CrearColegiaturaPage