import React, { Component } from 'react';
import { Collapse } from 'react-collapse';
import { ChartBarIcon, CheckIcon, ChevronDownIcon, DuplicateIcon, ExclamationCircleIcon, FlagIcon, TableIcon, TagIcon, TicketIcon, UserGroupIcon, XIcon } from '@heroicons/react/solid';
import { CalendarIcon } from '@heroicons/react/outline';
import Select from 'react-select';
import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

class FilterReportes extends Component {

    constructor() {
        super();
        this.state = {
            modulo: '',
            range: '',
            f_inicial: '', f_final: '',
            condiciones: [{ condicion: '', operador: '', valor: '' }], valorHeader: false, isValid: false,
            // Collections
            categorias: [], subcategorias: [], departamentos: [], estatus: [], prioridades: [],
            showModal: false,
            parametros: '',
            display: '',
            orderBy: '',
            chartType: ''
        }
    }

    renderCondiciones = (key) => {
        var header = [];
        if (key === 'header') {
            for (let index = 0; index < this.state.condiciones.length; index++) {
                header.push(<tr key={index} className='bg-accent-2 flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-0'>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Condición</th>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Operador</th>
                    <th className={`border-b-2 md:border-none p-3.5 text-left md:max-h-[54px] h-full`} hidden={!this.state.valorHeader}>Valor</th>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Agregar/Remover</th>
                </tr>);
            }
            return header;
        }
        var body = [];
        if (key === 'body') {
            for (let index = 0; index < this.state.condiciones.length; index++) {
                body.push(<tr key={index} className='flex flex-col flex-no wrap md:table-row mb-2 md:mb-0'>
                    <td className='border p-1.5 max-h-[54px]'>
                        <select name="selectCondicion" onChange={(e) => this.handleCondiciones(index, 'condicion', e.target.value)} id="selectCondicion" value={this.state.condiciones[index].condicion} aria-label="Select Condition" className='w-full border p-2 bg-slate-100'>
                            <option value="" disabled>-- Selecciona Condición --</option>
                            <option value="categorias">Categoría</option>
                            <option value="subcategorias">Subcategoría</option>
                            <option value="departamentos">Departamento</option>
                            <option value="estatus">Estatus</option>
                            <option value="prioridades">Prioridad</option>
                        </select>
                    </td>
                    <td className='border p-1.5 max-h-[54px]'>
                        <select id="selectOperator" onChange={(e) => this.handleCondiciones(index, 'operador', e.target.value)} name="selectOperator" disabled={this.state.condiciones[index].condicion === ''} value={this.state.condiciones[index].operador} aria-label="Select Operator" className='disabled:bg-slate-200 w-full border p-2 bg-slate-100'>
                            <option value="" disabled>-- Selecciona Operador --</option>
                            <option value="equals">es</option>
                            <option value="not equals">no es</option>
                            <option value="empty">esta vacío</option>
                            <option value="not empty">no está vacío</option>
                        </select>
                    </td>
                    <td id={'tdValor' + index} className='border p-1.5 min-h-[54px] md:max-w-md' hidden={!this.state.valorHeader}>
                        {this.state.condiciones[index].operador !== '' && this.state.condiciones[index].operador !== 'empty' && this.state.condiciones[index].operador !== 'not empty' ?
                            <Select placeholder='--Seleccionar Valor(es)--'
                                isMulti
                                options={
                                    (this.state.condiciones[index].condicion === 'categorias' && this.state.categorias) ||
                                    (this.state.condiciones[index].condicion === 'subcategorias' && this.state.subcategorias) ||
                                    (this.state.condiciones[index].condicion === 'departamentos' && this.state.departamentos) ||
                                    (this.state.condiciones[index].condicion === 'estatus' && this.state.estatus) ||
                                    (this.state.condiciones[index].condicion === 'prioridades' && this.state.prioridades)
                                }
                                value={this.state.condiciones[index].valor}
                                menuPortalTarget={document.body}
                                className='md:p-1'
                                onChange={(valor) => this.handleCondiciones(index, 'valor', valor)}
                                isLoading={
                                    (this.state.condiciones[index].condicion === 'categorias' && this.state.categorias.length === 0) ||
                                    (this.state.condiciones[index].condicion === 'subcategorias' && this.state.subcategorias.length === 0) ||
                                    (this.state.condiciones[index].condicion === 'departamentos' && this.state.departamentos.length === 0) ||
                                    (this.state.condiciones[index].condicion === 'estatus' && this.state.estatus.length === 0) ||
                                    (this.state.condiciones[index].condicion === 'prioridades' && this.state.prioridades.length === 0)
                                }
                                styles={{
                                    option: (base, state) => ({
                                        ...base,
                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                    }),
                                }}
                            /> : <div className='p-2 text-transparent'>empty</div>}
                    </td>
                    <td className='border p-2 max-h-[54px]'>
                        <div className='flex w-full justify-center gap-4'>
                            <button type='button' onClick={() => this.setState(prevState => ({ condiciones: [...prevState.condiciones, { condicion: '', operador: '', valor: '' }], isValid: false }))} className='bg-accent-2 p-2 rounded hover:bg-blue-600'>
                                <PlusIcon className='text-white h-5' />
                            </button>
                            <button type='button' disabled={this.state.condiciones.length === 1}
                                onClick={() => this.setState(prevState => ({
                                    condiciones: prevState.condiciones.filter((condicion, _index) => { return _index !== index }),
                                    valorHeader: prevState.condiciones.filter((condicion, _index) => { return _index !== index }).some(c => c.operador === 'equals' || c.operador === 'not equals'),
                                    isValid: prevState.condiciones.filter((condicion, _index) => { return _index !== index }).some(c => c.operador === "") ? false : true
                                })
                                )} className='disabled:opacity-70 disabled:hover:bg-accent-2 bg-accent-2 p-2 rounded hover:bg-blue-600'>
                                <MinusIcon className='text-white h-5' />
                            </button>
                        </div>
                    </td>
                </tr>);
            }
            return body;
        }
    }

    handleCondiciones = (index, type, value) => {
        const newConditions = this.state.condiciones.map((condicion, _index) => {
            if (_index === index) {
                if (type === 'condicion') {
                    return { ...condicion, [type]: value, valor: '' };
                }
                return { ...condicion, [type]: value };
            }
            else return condicion;
        });
        this.setState({
            condiciones: newConditions, valorHeader: newConditions.some(c => c.operador === 'equals' || c.operador === 'not equals'),
            isValid: newConditions.some(c => c.operador === "" || ((c.operador === 'equals' || c.operador === 'not equals') && (c.valor === "" || c.valor.length === 0))) ? false :
                (newConditions.some(c => c.operador === 'equals' || c.operador === 'not equals') && (type === 'operador' ? Array.isArray(newConditions[index].valor) && newConditions[index].valor.length !== 0 : true) && (type === 'valor' ? Array.isArray(value) && value.length !== 0 : true)) ||
                (newConditions.some(c => c.operador === 'empty' || c.operador === 'not empty'))
        });
        if (newConditions.some(c => c.operador === 'equals' || c.operador === 'not equals')) {
            this.handleValorSelect(newConditions[index].condicion);
        }
    }

    handleValorSelect = (condicion) => {
        switch (condicion) {
            case 'categorias':
                if (this.state.categorias.length === 0) {
                    RestClient.GetRequest(AppUrl.Categorias).then((result) => {
                        this.setState({ categorias: [] });
                        result.forEach(element => {
                            this.setState(prevState => ({
                                categorias: [...prevState.categorias, { value: element.id, label: element.nombre }]
                            }));
                        });
                    });
                }
                break;
            case 'subcategorias':
                if (this.state.subcategorias.length === 0) {
                    RestClient.GetRequest(AppUrl.Subcategorias).then((result) => {
                        this.setState({ subcategorias: [] });
                        result.forEach(element => {
                            this.setState(prevState => ({
                                subcategorias: [...prevState.subcategorias, { value: element.id, label: element.nombre }]
                            }));
                        });
                    });
                }
                break;
            case 'departamentos':
                if (this.state.departamentos.length === 0) {
                    RestClient.GetRequest(AppUrl.Departamentos).then((result) => {
                        this.setState({ departamentos: [] });
                        result.forEach(element => {
                            if (this.props.deptos.some(d => d === element.id)) {
                                this.setState(prevState => ({
                                    departamentos: [...prevState.departamentos, { value: element.id, label: element.nombre }]
                                }));
                            }
                        });
                    });
                }
                break;
            case 'estatus':
                if (this.state.estatus.length === 0) {
                    RestClient.GetRequest(AppUrl.Estatus).then((result) => {
                        var options = [];
                        var groupedOptions = [];
                        result.forEach((element, index) => {
                            var group = element.tipo;
                            if ((index + 1) !== result.length && group === result[index + 1].tipo) {
                                options.push({ value: element.id, label: element.nombre, color: element.color });
                            }
                            else {
                                options.push({ value: element.id, label: element.nombre, color: element.color });
                                groupedOptions.push({ label: 'Tipo: ' + group, options: options });
                                options = [];
                            }
                        });
                        this.setState({ estatus: groupedOptions });
                    });
                }
                break;
            case 'prioridades':
                if (this.state.prioridades.length === 0) {
                    RestClient.GetRequest(AppUrl.Prioridades).then((result) => {
                        this.setState({ prioridades: [] });
                        result.forEach(element => {
                            this.setState(prevState => ({
                                prioridades: [...prevState.prioridades, { value: element.id, label: element.nombre }]
                            }));
                        });
                    });
                }
                break;
            default:
                break;
        }
    }

    handleCloseModal = () => {
        this.setState({ showModal: false })
    }

    formatParametros = (condicion, operador, valor) => {
        let result = "";
        result = condicion.charAt(0).toUpperCase() + condicion.slice(1);
        result += ` ${operador === 'equals' ? 'es' : operador === 'not equals' ? 'no es' : operador === 'empty' ? 'esta vacío' : 'no esta vacío'} `;
        result += (operador === 'equals' || operador === 'not equals') ? JSON.stringify(Array.from(valor).map(v => v.label)) : '';
        return result;
    }

    render() {
        return (
            <div className='sm:mx-12 mx-0 sm:my-8 my-2 bg-gray-100 rounded shadow-lg'>
                <fieldset className={`flex ${(this.state.chartType === '' && this.state.display !== 'tabla') ? 'flex-col sm:space-y-3' : 'md:flex-row flex-wrap flex-col'} w-full lg:items-center py-2.5 px-5 lg:space-y-0 gap-2`}>
                    {/* Modulo */}
                    <div className={`${(this.state.chartType === '' && this.state.display !== 'tabla') && 'w-full'} h-full sm:flex-none inline-flex items-center justify-between`}>
                        <div className={`sm:text-base text-lg font-semibold flex items-center ${this.state.modulo !== '' ? 'text-accent-2 bg-accent-2/25 px-2 rounded-lg border-2 border-accent-2' : 'text-slate-500'} duration-500 transition-all ease-in-out`}>
                            <span>Modulo</span>
                            <span className='capitalize font-bold'>{this.state.modulo !== '' && ' : ' + this.state.modulo}</span>
                            <button type='button' onClick={() => this.setState({ modulo: '', range: '', parametros: '', display: '', orderBy: '', chartType: '' })} className={`${this.state.modulo !== '' ? 'scale-100' : 'scale-0'} ml-2 text-slate-500 hover:bg-white/50 hover:text-accent-2 rounded-full p-1 duration-300 transition-all ease-in-out`}>
                                <XIcon className='w-4 h-4 fill-current' />
                            </button>
                        </div>
                        <ChevronDownIcon className={`sm:hidden w-7 h-7 text-slate-500 ${true && 'rotate-180'} duration-300`} />
                    </div>
                    {/* Range */}
                    <div className={`${(this.state.chartType === '' && this.state.display !== 'tabla') && 'w-full'} ${this.state.modulo === '' ? 'opacity-0 scale-0 w-0 h-0' : 'opacity-100 scale-100'} sm:flex-none inline-flex items-center justify-between duration-500 transition-all ease-in-out`}>
                        <div className={`sm:text-base text-lg font-semibold flex items-center ${this.state.range !== '' ? 'text-accent-2 bg-accent-2/25 px-2 rounded-lg border-2 border-accent-2' : 'text-slate-500'} duration-500 transition-all ease-in-out`}>
                            <span>Rango</span>
                            <span className='capitalize font-bold'>{this.state.range !== '' && ' : ' + this.state.range}</span>
                            <button type='button' onClick={() => this.setState({ range: '', parametros: '', display: '', orderBy: '', chartType: '' })} className={`${this.state.range !== '' ? 'scale-100' : 'scale-0'} ml-2 text-slate-500 hover:bg-white/50 hover:text-accent-2 rounded-full p-1 duration-300 transition-all ease-in-out`}>
                                <XIcon className='w-4 h-4 fill-current' />
                            </button>
                        </div>
                    </div>
                    {/* Parametros */}
                    <div className={`${(this.state.chartType === '' && this.state.display !== 'tabla') && 'w-full'} ${this.state.range === '' ? 'opacity-0 scale-0 w-0 h-0' : 'opacity-100 scale-100'} sm:flex-none inline-flex items-center justify-between duration-500 transition-all ease-in-out`}>
                        <div className={`sm:text-base text-lg font-semibold flex items-center ${this.state.parametros !== '' ? 'text-accent-2 bg-accent-2/25 px-2 rounded-lg border-2 border-accent-2' : 'text-slate-500'} duration-500 transition-all ease-in-out`}>
                            <span>Parámetros</span>
                            <span className='capitalize font-bold'>{this.state.parametros !== '' && ' : '}</span>
                            <button type='button' onClick={() => this.setState({ showModal: true })} className='mx-2 font-bold hover:underline italic'>{this.state.parametros}</button>
                            <button type='button' onClick={() => this.setState({ parametros: '', display: '', orderBy: '', chartType: '' })} className={`${this.state.parametros !== '' ? 'scale-100' : 'scale-0'} ml-2 text-slate-500 hover:bg-white/50 hover:text-accent-2 rounded-full p-1 duration-300 transition-all ease-in-out`}>
                                <XIcon className='w-4 h-4 fill-current' />
                            </button>
                        </div>
                    </div>
                    {/* Display */}
                    <div className={`${(this.state.chartType === '' && this.state.display !== 'tabla') && 'w-full'} ${this.state.parametros === '' ? 'opacity-0 scale-0 w-0 h-0' : 'opacity-100 scale-100'} sm:flex-none inline-flex items-center justify-between duration-500 transition-all ease-in-out`}>
                        <div className={`sm:text-base text-lg font-semibold flex items-center ${this.state.display !== '' ? 'text-accent-2 bg-accent-2/25 px-2 rounded-lg border-2 border-accent-2' : 'text-slate-500'} duration-500 transition-all ease-in-out`}>
                            <span>Desplegar</span>
                            <span className='capitalize font-bold'>{this.state.display !== '' && ' : ' + this.state.display}</span> 
                            <button type='button' onClick={() => this.setState({ display: '', orderBy: '', chartType: '' })} className={`${this.state.display !== '' ? 'scale-100' : 'scale-0'} ml-2 text-slate-500 hover:bg-white/50 hover:text-accent-2 rounded-full p-1 duration-300 transition-all ease-in-out`}>
                                <XIcon className='w-4 h-4 fill-current' />
                            </button>
                        </div>
                    </div>
                    {/* Order By */}
                    <div className={`${this.state.chartType === '' && 'w-full'} ${(this.state.display === '' || this.state.display === 'tabla') ? 'opacity-0 scale-0 w-0 h-0' : 'opacity-100 scale-100'} sm:flex-none inline-flex items-center justify-between duration-500 transition-all ease-in-out`}>
                        <div className={`sm:text-base text-lg font-semibold flex items-center ${this.state.orderBy !== '' ? 'text-accent-2 bg-accent-2/25 px-2 rounded-lg border-2 border-accent-2' : 'text-slate-500'} duration-500 transition-all ease-in-out`}>
                            <span>Gráfica - Ordenar Por</span>
                            <span className='capitalize font-bold'>{this.state.orderBy !== '' && ' : ' + this.state.orderBy}</span>
                            <button type='button' onClick={() => this.setState({ orderBy: '', chartType: '' })} className={`${this.state.orderBy !== '' ? 'scale-100' : 'scale-0'} ml-2 text-slate-500 hover:bg-white/50 hover:text-accent-2 rounded-full p-1 duration-300 transition-all ease-in-out`}>
                                <XIcon className='w-4 h-4 fill-current' />
                            </button>
                        </div>
                    </div>
                    {/* Chart Type */}
                    <div className={`${this.state.chartType === '' && 'w-full'} ${this.state.orderBy === '' ? 'opacity-0 scale-0 w-0 h-0' : 'opacity-100 scale-100'} sm:flex-none inline-flex items-center justify-between duration-500 transition-all ease-in-out`}>
                        <div className={`sm:text-base text-lg font-semibold flex items-center ${this.state.chartType !== '' ? 'text-accent-2 bg-accent-2/25 px-2 rounded-lg border-2 border-accent-2' : 'text-slate-500'} duration-500 transition-all ease-in-out`}>
                            <span>Tipo De Gráfica</span>
                            <span className='capitalize font-bold'>{this.state.chartType !== '' && ' : ' + this.state.chartType}</span>
                            <button type='button' onClick={() => this.setState({ chartType: '' })} className={`${this.state.chartType !== '' ? 'scale-100' : 'scale-0'} ml-2 text-slate-500 hover:bg-white/50 hover:text-accent-2 rounded-full p-1 duration-300 transition-all ease-in-out`}>
                                <XIcon className='w-4 h-4 fill-current' />
                            </button>
                        </div>
                    </div>
                    <Collapse isOpened={true}>
                        <div className='sm:flex sm:flex-col md:flex-row w-full justify-start md:justify-center sm:mt-0 mt-3'>
                            {/* Modulos */}
                            <div className={`grid grid-cols-2 sm:flex flex-wrap items-center gap-2 ${this.state.modulo !== '' ? 'scale-0 h-0 w-0' : 'scale-100'} duration-500 transition-all ease-in-out`}>
                                <button type='button' onClick={() => {this.setState({ modulo: 'solicitudes' }); this.props.setModulo('solicitudes');}} className='col-span-1 group p-4 bg-white flex gap-4 items-center rounded shadow-lg target:shadow-none target:bg-accent-2'>
                                    <TicketIcon className='h-8 w-8 text-accent-2 group-target:text-white' />
                                    <div className='mx-4'>
                                        <span className='font-semibold text-accent-2 group-target:text-white'>Solicitudes</span>
                                    </div>
                                </button>
                            </div>
                            {/* Range */}
                            <div className={`grid md:grid-cols-3 grid-cols-2 lg:flex flex-wrap items-center gap-2 ${(this.state.modulo === '' || this.state.range !== '') ? 'scale-0 h-0 w-0' : 'scale-100'} duration-500 transition-all ease-in-out`}>
                                {/* Fecha Inicial */}
                                <div>
                                    <div className='w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group bg-white'>
                                        <div className='w-1/2 items-center flex justify-center'>
                                            <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                        </div>
                                        <div className="relative z-0 w-full group">
                                            <input onChange={(e) => this.setState({ f_inicial: e.target.value })} id='dateFechaInicial' type='date' name="fecha_inicial" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                            <label htmlFor="dateFechaInicial" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Fecha Inicial</label>
                                        </div>
                                    </div>
                                </div>
                                {/* Fecha Final */}
                                <div>
                                    <div className='w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group bg-white'>
                                        <div className='w-1/2 items-center flex justify-center'>
                                            <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                        </div>
                                        <div className="relative z-0 w-full group">
                                            <input onChange={(e) => this.setState({ f_final: e.target.value })} id='dateFechaFinal' type='date' name="fecha_final" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                            <label htmlFor="dateFechaFinal" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Fecha Final</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex items-center'>
                                    <button type='button' title='Confirmar' disabled={this.state.f_inicial === '' || this.state.f_final === ''} onClick={() => {this.setState({ range: new Date(this.state.f_inicial).toLocaleDateString('es-MX') + ' - ' + new Date(this.state.f_final).toLocaleDateString('es-MX') }); this.props.setRange(this.state.f_inicial, this.state.f_final);}} className='disabled:bg-accent-1/70 disabled:translate-y-0 p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                        <CheckIcon className='h-5' />
                                    </button>
                                </div>
                            </div>
                            {/* Parametros */}
                            <div className={`flex items-center gap-2 ${(this.state.range === '' || this.state.parametros !== '') ? 'scale-0 h-0 w-0' : 'scale-100'} duration-500 transition-all ease-in-out`}>
                                <div className=''>
                                    <table id='tblCondiciones' className='hidden md:inline-table w-full bg-white rounded-lg overflow-hidden md:shadow-md my-5'>
                                        <thead className='text-white'>
                                            {this.renderCondiciones('header')}
                                        </thead>
                                        <tbody className='flex-1 md:flex-none'>
                                            {this.renderCondiciones('body')}
                                            <tr>
                                                <td colSpan="100%" className='text-center align-middle py-2'>
                                                    <button type='button' onClick={() => {this.setState({ parametros: 'Click para ver' }); this.props.setParametros(this.state.condiciones);}} disabled={!this.state.isValid} className='disabled:bg-accent-1/70 text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>Confirmar</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/* Mobile */}
                                    {/* <div className='md:hidden flex-col w-full'>
                                        {this.renderCondicionesMobile()}
                                    </div> */}
                                </div>
                            </div>
                            {/* Display */}
                            <div className={`grid md:grid-cols-3 grid-cols-2 lg:flex flex-wrap items-center gap-2 ${(this.state.parametros === '' || this.state.display !== '') ? 'scale-0 h-0 w-0' : 'scale-100'} duration-500 transition-all ease-in-out`}>
                                <button type='button' onClick={() => {this.setState({ display: 'tabla' }); this.props.setDisplay('tabla'); this.props.generateTable(); this.props.resetLoading();}} className='col-span-1 group p-4 bg-white flex gap-4 items-center rounded shadow-lg target:shadow-none target:bg-accent-2'>
                                    <TableIcon className='w-8 h-8 fill-accent-2' />
                                    <div className='mx-4'>
                                        <span className='font-semibold text-accent-2 group-target:text-white'>Tabla</span>
                                    </div>
                                </button>
                                <button type='button' onClick={() => {this.setState({ display: 'gráfica' }); this.props.setDisplay('gráfica'); this.props.resetLoading();}} className='col-span-1 group p-4 bg-white flex gap-4 items-center rounded shadow-lg target:shadow-none target:bg-accent-2'>
                                    <ChartBarIcon className='w-8 h-8 fill-accent-2' />
                                    <div className='mx-4'>
                                        <span className='font-semibold text-accent-2 group-target:text-white'>Gráfica</span>
                                    </div>
                                </button>
                                <button type='button' onClick={() => {this.setState({ display: 'tabla y gráfica' }); this.props.setDisplay('tabla y gráfica'); this.props.generateTable(); this.props.resetLoading();}} className='col-span-1 group p-4 bg-white flex gap-4 items-center rounded shadow-lg target:shadow-none target:bg-accent-2'>
                                    <DuplicateIcon className='w-8 h-8 fill-accent-2' />
                                    <div className='mx-4'>
                                        <span className='font-semibold text-accent-2 group-target:text-white'>Tabla y Gráfica</span>
                                    </div>
                                </button>
                            </div>
                            {/* Order By */}
                            <div className={`grid md:grid-cols-3 grid-cols-2 lg:flex flex-wrap items-center gap-2 ${((this.state.display === '' || this.state.display === 'tabla') || this.state.orderBy !== '') ? 'scale-0 h-0 w-0' : 'scale-100'} duration-500 transition-all ease-in-out`}>
                                <button type='button' onClick={() => {this.setState({ orderBy: 'categorías' }); this.props.setOrderBy('categorías');}} className={`col-span-1 group p-4 bg-white ${this.state.condiciones.some(c => c.condicion === 'categorias') ? 'flex' : 'hidden'} gap-4 items-center rounded shadow-lg target:shadow-none target:bg-accent-2`}>
                                    <TagIcon className='w-8 h-8 fill-accent-2' />
                                    <div className='mx-4'>
                                        <span className='font-semibold text-accent-2 group-target:text-white'>Categorías</span>
                                    </div>
                                </button>
                                <button type='button' onClick={() => {this.setState({ orderBy: 'subcategorías' }); this.props.setOrderBy('subcategorías');}} className={`col-span-1 group p-4 bg-white ${this.state.condiciones.some(c => c.condicion === 'subcategorias') ? 'flex' : 'hidden'} gap-4 items-center rounded shadow-lg target:shadow-none target:bg-accent-2`}>
                                    <TagIcon className='w-8 h-8 fill-accent-2' />
                                    <div className='mx-4'>
                                        <span className='font-semibold text-accent-2 group-target:text-white'>Subcategorías</span>
                                    </div>
                                </button>
                                <button type='button' onClick={() => {this.setState({ orderBy: 'departamentos' }); this.props.setOrderBy('departamentos');}} className={`col-span-1 group p-4 bg-white ${this.state.condiciones.some(c => c.condicion === 'departamentos') ? 'flex' : 'hidden'} gap-4 items-center rounded shadow-lg target:shadow-none target:bg-accent-2`}>
                                    <UserGroupIcon className='w-8 h-8 fill-accent-2' />
                                    <div className='mx-4'>
                                        <span className='font-semibold text-accent-2 group-target:text-white'>Departamentos</span>
                                    </div>
                                </button>
                                <button type='button' onClick={() => {this.setState({ orderBy: 'estatus' }); this.props.setOrderBy('estatus');}} className={`col-span-1 group p-4 bg-white ${this.state.condiciones.some(c => c.condicion === 'estatus') ? 'flex' : 'hidden'} gap-4 items-center rounded shadow-lg target:shadow-none target:bg-accent-2`}>
                                    <FlagIcon className='w-8 h-8 fill-accent-2' />
                                    <div className='mx-6'>
                                        <span className='font-semibold text-accent-2 group-target:text-white'>Estatus</span>
                                    </div>
                                </button>
                                <button type='button' onClick={() => {this.setState({ orderBy: 'prioridades' }); this.props.setOrderBy('prioridades');}} className={`col-span-1 group p-4 bg-white ${this.state.condiciones.some(c => c.condicion === 'prioridades') ? 'flex' : 'hidden'} gap-4 items-center rounded shadow-lg target:shadow-none target:bg-accent-2`}>
                                    <ExclamationCircleIcon className='w-8 h-8 fill-accent-2' />
                                    <div className='mx-6'>
                                        <span className='font-semibold text-accent-2 group-target:text-white'>Prioridades</span>
                                    </div>
                                </button>
                            </div>
                            {/* Chart Types */}
                            <div className={`grid md:grid-cols-3 grid-cols-2 lg:flex flex-wrap items-center gap-2 ${(this.state.orderBy === '' || this.state.chartType !== '') ? 'scale-0 h-0 w-0' : 'scale-100'} duration-500 transition-all ease-in-out`}>
                                <button type='button' onClick={() => {this.setState({ chartType: 'columna' }); this.props.setChartType('columna'); this.props.generateGraph();}} className='min-w-[200px] col-span-1 group p-4 bg-white flex gap-4 items-center rounded shadow-lg target:shadow-none target:bg-accent-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-8 h-8 fill-accent-2'>
                                        <path d="M32 32c17.7 0 32 14.3 32 32V400c0 8.8 7.2 16 16 16H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-44.2 0-80-35.8-80-80V64C0 46.3 14.3 32 32 32zM160 224c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm128-64V320c0 17.7-14.3 32-32 32s-32-14.3-32-32V160c0-17.7 14.3-32 32-32s32 14.3 32 32zm64 32c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32zM480 96V320c0 17.7-14.3 32-32 32s-32-14.3-32-32V96c0-17.7 14.3-32 32-32s32 14.3 32 32z" />
                                    </svg>
                                    <div className='mx-4'>
                                        <span className='font-semibold text-accent-2 group-target:text-white'>Columna</span>
                                    </div>
                                </button>
                                <button type='button' onClick={() => {this.setState({ chartType: 'línea' }); this.props.setChartType('línea'); this.props.generateGraph();}} className='col-span-1 group p-4 bg-white flex gap-4 items-center rounded shadow-lg target:shadow-none target:bg-accent-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-8 h-8 fill-accent-2'>
                                        <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z" />
                                    </svg>
                                    <div className='mx-4'>
                                        <span className='font-semibold text-accent-2 group-target:text-white'>Línea</span>
                                    </div>
                                </button>
                                <button type='button' onClick={() => {this.setState({ chartType: 'área' }); this.props.setChartType('área'); this.props.generateGraph();}} className='col-span-1 group p-4 bg-white flex gap-4 items-center rounded shadow-lg target:shadow-none target:bg-accent-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-8 h-8 fill-accent-2'>
                                        <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm96 288H448c17.7 0 32-14.3 32-32V251.8c0-7.6-2.7-15-7.7-20.8l-65.8-76.8c-12.1-14.2-33.7-15-46.9-1.8l-21 21c-10 10-26.4 9.2-35.4-1.6l-39.2-47c-12.6-15.1-35.7-15.4-48.7-.6L135.9 215c-5.1 5.8-7.9 13.3-7.9 21.1v84c0 17.7 14.3 32 32 32z" />
                                    </svg>
                                    <div className='mx-4'>
                                        <span className='font-semibold text-accent-2 group-target:text-white'>Área</span>
                                    </div>
                                </button>
                                <button type='button' onClick={() => {this.setState({ chartType: 'pay' }); this.props.setChartType('pay'); this.props.generateGraph();}} className='col-span-1 group p-4 bg-white flex items-center rounded shadow-lg target:shadow-none target:bg-accent-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-8 h-8 fill-accent-2 mx-2'>
                                        <path d="M304 240V16.6c0-9 7-16.6 16-16.6C443.7 0 544 100.3 544 224c0 9-7.6 16-16.6 16H304zM32 272C32 150.7 122.1 50.3 239 34.3c9.2-1.3 17 6.1 17 15.4V288L412.5 444.5c6.7 6.7 6.2 17.7-1.5 23.1C371.8 495.6 323.8 512 272 512C139.5 512 32 404.6 32 272zm526.4 16c9.3 0 16.6 7.8 15.4 17c-7.7 55.9-34.6 105.6-73.9 142.3c-6 5.6-15.4 5.2-21.2-.7L320 288H558.4z" />
                                    </svg>
                                    <div className='mx-6'>
                                        <span className='font-semibold text-accent-2 group-target:text-white'>Pay</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </Collapse>
                </fieldset>
                <ReactModal
                    closeTimeoutMS={800}
                    isOpen={this.state.showModal}
                    contentLabel="Default Confirmation Modal"
                    className='Modal'
                    overlayClassName="Overlay"
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnOverlayClick={true}
                >
                    <div className="flex flex-col items-start">
                        <div className="flex items-center w-full border-b-2">
                            <div className="text-gray-900 font-medium text-xl">Parámetros</div>
                            <button onClick={this.handleCloseModal} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                                <XIcon className='w-5 h-5' />
                            </button>
                        </div>
                        <div className="border-b-2 w-full py-4">
                            <span>Este reporte muestra {this.state.modulo} que cumplan el siguiente criterio:</span>
                            <ul className='my-2'>
                                {this.state.condiciones.map((item, index) => (
                                    <li key={index} className='font-semibold indent-4'>{this.formatParametros(item.condicion, item.operador, item.valor)}</li>
                                ))}
                            </ul>
                            
                        </div>
                        <div className="flex justify-end w-full mt-4 gap-4">
                            <button onClick={this.handleCloseModal} type='button' className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                                Ok
                            </button>
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}

export default FilterReportes;