import React, { useEffect } from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import { CogIcon, LinkIcon, XIcon } from '@heroicons/react/solid';
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';


const customStyles = {
    subHeader: {
        style: {
            backgroundColor: '#3d6bec'
        }
    },
    headRow: {
        style: {
            border: 'none',
        },
    },
    headCells: {
        style: {
            backgroundColor: '#123CB8',
            color: 'white',
            fontSize: '14px',
        },
    },
    rows: {
        style: {
            backgroundColor: '#3d6bec',
            color: 'white',
        },
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
            color: 'white',
            backgroundColor: '#123CB8',
        },
    },
    pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '32px',
        margin: 'px',
        cursor: 'pointer',
        transition: '0.4s',
        color: 'white',
        fill: 'white',
        backgroundColor: 'transparent',
        '&:disabled': {
            cursor: 'unset',
            color: 'white',
            fill: 'white',
        },
        /* '&:hover:not(:disabled)': {
            backgroundColor: theme.button.hover,
        },
        '&:focus': {
            outline: 'none',
            backgroundColor: theme.button.focus,
        }, */
    },
};

const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;
`;

const Button = styled.button`
    background-color: #123CB8;
    border: none;
    color: white;
    padding: 8px 32px 8px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 3px;
    &:hover {
        cursor: pointer;
    }
`;

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className='flex items-center'>
        <TextField
            id="search"
            type="text"
            placeholder="Buscar"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </div>
);

const AddSubcategoria = ({onClickAdd, loaded}) => {
    return (
        <button onClick={e => onClickAdd(e.target)} disabled={!loaded}
            id='btnAsociarSubcategorias' className='disabled:bg-accent-2 disabled:hover:translate-y-0 p-2 bg-accent-1 flex gap-1 items-center rounded-full shadow-lg hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
            {loaded ? <LinkIcon className='h-5 w-5 text-white' /> : 
            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>}
            <div className='mx-1 flex items-center'>
                <span className='font-semibold text-sm text-white'>Asociar Subcategorías</span>
            </div>
        </button>
    );
}

const EditSubcategoria = ({ subcategoriaId }) => {
    return (
        <Link to={'/categorias-subcategorias/editar-subcategoria/' + subcategoriaId}
            id={'btnEditSubcategorias' + subcategoriaId} className="flex w-full justify-between px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-accent-2 hover:text-white" role="menuitem">
            Editar
        </Link>
    );
}

const RemoveSubcategoria = ({ subcategoriaId, onClickRemove }) => {
    return (
        <button type='button' onClick={e => onClickRemove(e.target)}
            id={'btnRemoveSubcategoria' + subcategoriaId} className="flex w-full justify-between px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-accent-2 hover:text-white" role="menuitem">
            Remover
        </button>
    );
}

const animatedComponents = makeAnimated();

const ExpandableSubcategoriasTable = (props) => {

    const _data = [{}];
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [pending, setPending] = React.useState(true);
    const [filteredItems, setFilteredItems] = React.useState(_data);
    const [showModal, setShowModal] = React.useState(false);
    const [showRemoveModal, setShowRemoveModal] = React.useState(false);
    const [dataId, setDataId] = React.useState(0);
    const [processingRequest, setProcessingRequest] = React.useState(false);

    // Handles subcategorias and if they have been updated
    const [hasUpdated, setHasUpdated] = React.useState(false);
    const [subcategorias, setSubcategorias] = React.useState([{}]);
    const [loadedSubcategorias, setLoadedSubcategorias] = React.useState(false);
    useEffect(() => {
        RestClient.GetRequest(AppUrl.CategoriaById + props.data.id).then((result) =>{
            setSubcategorias(result.subcategorias);
            setLoadedSubcategorias(true);
            setHasUpdated(false);
        });
    }, [hasUpdated, props.data.id]);

    // Handles filter and loads all Subcategorias
    const [allSubcategorias, setAllSubcategorias] = React.useState([{}]);
    const [loadedAllSubcategorias, setLoadedAllSubcategorias] = React.useState(false);
    useEffect(() => {
        //get all subcategorías
        if (!loadedAllSubcategorias) {
            RestClient.GetRequest(AppUrl.Subcategorias).then((result) =>{
                var optionsMapped = [];
                result.forEach(element => {
                    optionsMapped.push({value: element.id, label: element.nombre});
                });
                setAllSubcategorias(optionsMapped);
                setLoadedAllSubcategorias(true);
            });
        }
        // Searches for filter
        if (subcategorias) {
            setFilteredItems(subcategorias.filter(
                item => (item.nombre && item.nombre.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.created_at && item.created_at.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.updated_at && item.updated_at.toLowerCase().includes(filterText.toLowerCase()))
            ));
            setPending(false);
        }
    }, [subcategorias, filterText, loadedAllSubcategorias])

    function handleOpenModal(modal, dataId = 0) {
        if (modal === 'Asociar') {
            setShowModal(true);
        }
        else {
            setShowRemoveModal(true)
            setDataId(dataId);
        }
        
    }
    function handleCloseModal(modal) {
        if (modal === 'Asociar') {
            setShowModal(false);
        }
        else {
            setShowRemoveModal(false)
        }
    }
    // Handles the update of relationships
    const [selectedItems, setSelectedItems] = React.useState([{}]);
    function asociarSubcategorias() {
        //Disable controls while request is processed
        setProcessingRequest(true);

        const formData = new FormData();
        formData.append('subcategorias', JSON.stringify(selectedItems));

        RestClient.PostRequest(AppUrl.CategoriaById + props.data.id + '/subcategorias', formData).then((result) => {
            setProcessingRequest(false);
            if (result.status === 200) {
                toast.success(result.message);
                setHasUpdated(true);
                handleCloseModal('Asociar');
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }
    // Handle single remove relationship
    function handleRemove() {
        //Disable controls while request is processed
        setProcessingRequest(true);

        RestClient.GetRequest(AppUrl.CategoriaById + props.data.id + '/subcategorias/remove/' + dataId).then((result) => {
            setProcessingRequest(false);
            if (result.status === 200) {
                toast.success(result.message);
                setHasUpdated(true);
                handleCloseModal('Remover');
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    const columns = [
        {
            cell: row => (
                <>
                    <div className="dropdown relative text-left">
                        <span className="rounded-md shadow-sm">
                            <button className="group max-w-xs rounded-full flex items-center text-sm focus:outline-none" type="button" aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items">
                                <span className="sr-only">Open table menu</span>
                                <CogIcon className='w-5 h-5 text-white hover:rotate-45 transition duration-300 group-focus:rotate-45' />
                            </button>
                        </span>
                        <div className={`dropdown-menu absolute invisible ${(row.id >= filteredItems.length - 1 && 'origin-bottom-left') || 'origin-top-left'} -translate-y-2 scale-95 transform opacity-0 transition-all duration-300 z-50`}>
                            <div className={`left-4 translate-x-6 ${(row.id >= filteredItems.length - 1 && '-translate-y-24 origin-bottom-left') || 'origin-top-left'} mt-2 w-36  divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none z-50`} aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items" role="menu">
                                <div className="py-1">
                                    <EditSubcategoria subcategoriaId={row.id} />
                                    <RemoveSubcategoria subcategoriaId={row.id} onClickRemove={() => handleOpenModal('Remover', row.id)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ),

            button: true,
            width: '100px'
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true
        },
        {
            name: 'Fecha Registrado',
            selector: row => row.created_at,
            sortable: true
        },
        {
            name: 'Fecha Actualizado',
            selector: row => row.updated_at,
            sortable: true
        }
    ];

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <div className='flex w-full justify-between items-center'>
                <h1 className='text-white font-semibold'>Subcategorías Asociadas</h1>
                <div className='inline-flex gap-4'>
                    <AddSubcategoria onClickAdd={() => handleOpenModal('Asociar')} loaded={loadedAllSubcategorias} />
                    <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                </div>
            </div>
            
        );
    }, [filterText, resetPaginationToggle, loadedAllSubcategorias]);

    if (!loadedSubcategorias) {
        return <div className='flex w-full justify-center bg-white'>
            <Loading />
        </div>
    }
    return (
        <pre className='w-full flex justify-center'>
            <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                <div className='w-11/12'>
                    <div className='w-full bg-gray-100 rounded-lg shadow-lg'>  
                        <DataTable
                            columns={columns}
                            data={filteredItems}
                            customStyles={customStyles}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            //striped
                            progressPending={pending}
                            //progressComponent={<CustomLoader />}
                        />
                    </div>
                </div>
            </div>
            <ReactModal
                closeTimeoutMS={800}
                isOpen={showModal}
                contentLabel="Default Confirmation Modal"
                className='Modal'
                overlayClassName="Overlay"
                onRequestClose={() => handleCloseModal('Asociar')}
                shouldCloseOnOverlayClick={true}
            >
                <div className="flex flex-col items-start">
                    <div className="flex items-center w-full border-b-2">
                        <div className="text-gray-900 font-medium text-xl">Asociar Subcategorías</div>
                        <button onClick={() => handleCloseModal('Asociar')} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                            <XIcon className='w-5 h-5' />
                        </button>
                    </div>
                    <div className="border-b-2 w-full py-6">
                    <Select placeholder='--Seleccionar Subcategorías--' 
                        isMulti 
                        options={allSubcategorias} 
                        menuPortalTarget={document.body} 
                        closeMenuOnSelect={false}
                        components={animatedComponents} 
                        defaultValue={allSubcategorias.filter(subs => subcategorias.some(s => s.id === subs.value))}
                        onChange={(values) => setSelectedItems(values)} 
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} 
                    />                    
                    </div>
                    <div className="flex justify-end w-full mt-4 gap-4">
                        <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </button>
                        <button onClick={asociarSubcategorias} type='button' hidden={processingRequest} className="bg-accent-1 hover:bg-accent-2 text-white font-bold py-2 px-4 rounded">
                            Guardar
                        </button>
                        <button onClick={() => handleCloseModal('Asociar')} type='button' hidden={processingRequest} className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                            Cancelar
                        </button>
                    </div>
                </div>
            </ReactModal>
            <ReactModal
                closeTimeoutMS={800}
                isOpen={showRemoveModal}
                contentLabel="Default Confirmation Modal"
                className='Modal'
                overlayClassName="Overlay"
                onRequestClose={() => handleCloseModal('Remover')}
                shouldCloseOnOverlayClick={true}
            >
                <div className="flex flex-col items-start">
                    <div className="flex items-center w-full border-b-2">
                        <div className="text-gray-900 font-medium text-xl">Remover Asociación</div>
                        <button onClick={() => handleCloseModal('Remover')} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                            <XIcon className='w-5 h-5' />
                        </button>
                    </div>
                    <div className="border-b-2 w-full py-4">
                        <p>¿Estas seguro que quieres remover esta asociación entre categoría y subcategoría?</p>               
                    </div>
                    <div className="flex justify-end w-full mt-4 gap-4">
                        <button hidden={!processingRequest} type='button' disabled className='text-white bg-red-500 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </button>
                        <button onClick={handleRemove} type='button' hidden={processingRequest} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
                            Remover
                        </button>
                        <button onClick={() => handleCloseModal('Remover')} type='button' hidden={processingRequest} className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                            Cancelar
                        </button>
                    </div>
                </div>
            </ReactModal>
        </pre>
    );
}   

export default ExpandableSubcategoriasTable;