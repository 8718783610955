import { DocumentTextIcon } from '@heroicons/react/outline';
import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import EscalacionComponent from '../../components/sla/EscalacionesComponent';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
var CryptoJS = require("crypto-js");

class CrearSLAPage extends Component {

    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.state = {
            usersAccess: _usersAccess,
            // SLA States
            nombre: '', descripcion: '', condiciones: [{ condicion: '', operador: '', valor: '' }], apply: true, valorHeader: false, 
            tiempo_respuesta: {dias: 0, horas: 0, minutos: 0}, tiempo_resolucion: {dias: 0, horas: 0, minutos: 0},
            respEsc1: false, resolEsc1: false, resolEsc2: false, resolEsc3: false, resolEsc4: false,
            // Escalacion Respuesta 1
            tiempo_respEsc1: {dias: 0, horas: 0, minutos: 0}, respEsc1Users: [], respEsc1NCs: [],
            // Escalacion Resolucion 1
            tiempo_resolEsc1: {dias: 0, horas: 0, minutos: 0}, resolEsc1Users: [], resolEsc1NCs: [],
            // Escalacion Resolucion 2
            tiempo_resolEsc2: {dias: 0, horas: 0, minutos: 0}, resolEsc2Users: [], resolEsc2NCs: [],
            // Escalacion Resolucion 3
            tiempo_resolEsc3: {dias: 0, horas: 0, minutos: 0}, resolEsc3Users: [], resolEsc3NCs: [],
            // Escalacion Resolucion 4
            tiempo_resolEsc4: {dias: 0, horas: 0, minutos: 0}, resolEsc4Users: [], resolEsc4NCs: [],
            // Collections
            categorias: [], subcategorias: [], departamentos: [], estatus: [], prioridades: [],
            // Form / Request States
            statusResult: 0, processingRequest: false, errors: [{ 
            // SLA Errors
            nombre: '', descripcion: '', condiciones: '', respuesta_dias: '', respuesta_horas: '', respuesta_minutos: '', 
            resolucion_dias: '', resolucion_horas: '', resolucion_minutos: '', 
            // Escalacion Errors
            respuesta_esc1_dias: '', respuesta_esc1_horas: '', respuesta_esc1_minutos: '', respuesta_esc1_usuarios: '',
            resolucion_esc1_dias: '', resolucion_esc1_horas: '', resolucion_esc1_minutos: '', resolucion_esc1_usuarios: '',
            resolucion_esc2_dias: '', resolucion_esc2_horas: '', resolucion_esc2_minutos: '', resolucion_esc2_usuarios: '', 
            resolucion_esc3_dias: '', resolucion_esc3_horas: '', resolucion_esc3_minutos: '', resolucion_esc3_usuarios: '', 
            resolucion_esc4_dias: '', resolucion_esc4_horas: '', resolucion_esc4_minutos: '', resolucion_esc4_usuarios: ''}],

        }
    }

    renderCondiciones = (key) => {
        var header = [];
        if (key === 'header') {
            for (let index = 0; index < this.state.condiciones.length; index++) {
                header.push(<tr key={index} className='bg-accent-2 flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-0'>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Condición</th>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Operador</th>
                    <th className={`border-b-2 md:border-none p-3.5 text-left md:max-h-[54px] h-full`} hidden={!this.state.valorHeader}>Valor</th>
                    <th className="border-b-2 md:border-none p-3.5 text-left">Agregar/Remover</th>
                </tr>);
            }
            return header;
        }
        var body = [];
        if (key === 'body') {
            for (let index = 0; index < this.state.condiciones.length; index++) {
                body.push(<tr key={index} className='flex flex-col flex-no wrap md:table-row mb-2 md:mb-0'>
                    <td className='border p-1.5 max-h-[54px]'>
                        <select name="selectCondicion" onChange={(e) => this.handleCondiciones(index, 'condicion', e.target.value)} id="selectCondicion" value={this.state.condiciones[index].condicion} aria-label="Select Condition" className='w-full border p-2 bg-slate-100'>
                            <option value="" disabled>-- Selecciona Condición --</option>
                            <option value="categorias">Categoría</option>
                            <option value="subcategorias">Subcategoría</option>
                            <option value="departamentos">Departamento</option>
                            <option value="estatus">Estatus</option>
                            <option value="prioridades">Prioridad</option>
                        </select>
                    </td>
                    <td className='border p-1.5 max-h-[54px]'>
                        <select id="selectOperator" onChange={(e) => this.handleCondiciones(index, 'operador', e.target.value)} name="selectOperator" disabled={this.state.condiciones[index].condicion === ''} value={this.state.condiciones[index].operador} aria-label="Select Operator" className='disabled:bg-slate-200 w-full border p-2 bg-slate-100'>
                            <option value="" disabled>-- Selecciona Operador --</option>
                            <option value="equals">es</option>
                            <option value="not equals">no es</option>
                            <option value="empty">esta vacío</option>
                            <option value="not empty">no está vacío</option>
                        </select>
                    </td>
                    <td id={'tdValor' + index} className='border p-1.5 min-h-[54px] md:max-w-md' hidden={!this.state.valorHeader}>
                        {this.state.condiciones[index].operador !== '' && this.state.condiciones[index].operador !== 'empty' && this.state.condiciones[index].operador !== 'not empty' ?
                            <Select placeholder='--Seleccionar Valor(es)--'
                                isMulti
                                options={
                                    (this.state.condiciones[index].condicion === 'categorias' && this.state.categorias) ||
                                    (this.state.condiciones[index].condicion === 'subcategorias' && this.state.subcategorias) ||
                                    (this.state.condiciones[index].condicion === 'departamentos' && this.state.departamentos) ||
                                    (this.state.condiciones[index].condicion === 'estatus' && this.state.estatus) ||
                                    (this.state.condiciones[index].condicion === 'prioridades' && this.state.prioridades)
                                }
                                value={this.state.condiciones[index].valor}
                                menuPortalTarget={document.body}
                                className='md:p-1'
                                onChange={(valor) => this.handleCondiciones(index, 'valor', valor)}
                                isLoading={
                                    (this.state.condiciones[index].condicion === 'categorias' && this.state.categorias.length === 0) ||
                                    (this.state.condiciones[index].condicion === 'subcategorias' && this.state.subcategorias.length === 0) ||
                                    (this.state.condiciones[index].condicion === 'departamentos' && this.state.departamentos.length === 0) ||
                                    (this.state.condiciones[index].condicion === 'estatus' && this.state.estatus.length === 0) ||
                                    (this.state.condiciones[index].condicion === 'prioridades' && this.state.prioridades.length === 0)
                                }
                                styles={{
                                    option: (base, state) => ({
                                        ...base,
                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                    }),
                                }}
                            /> : <div className='p-2 text-transparent'>empty</div>}
                    </td>
                    <td className='border p-2 max-h-[54px]'>
                        <div className='flex w-full justify-center gap-4'>
                            <button type='button' onClick={() => this.setState(prevState => ({ condiciones: [...prevState.condiciones, { condicion: '', operador: '', valor: '' }] }))} className='bg-accent-2 p-2 rounded hover:bg-blue-600'>
                                <PlusIcon className='text-white h-5' />
                            </button>
                            <button type='button' disabled={this.state.condiciones.length === 1} onClick={() => this.setState(prevState => ({ condiciones: prevState.condiciones.filter((condicion, _index) => { return _index !== index }), valorHeader: prevState.condiciones.filter((condicion, _index) => { return _index !== index }).some(c => c.operador === 'equals' || c.operador === 'not equals') }))} className='disabled:opacity-70 disabled:hover:bg-accent-2 bg-accent-2 p-2 rounded hover:bg-blue-600'>
                                <MinusIcon className='text-white h-5' />
                            </button>
                        </div>
                    </td>
                </tr>);
            }
            return body;
        }
    }

    renderCondicionesMobile = () => {
        var condiciones = [];
        for (let index = 0; index < this.state.condiciones.length; index++) {
            condiciones.push(
                /* Row */
                <div key={index} className='w-full mb-2'>
                    {/* Condicion */}
                    <div className='inline-flex w-full'>
                        <div className="w-[53%]">
                            <div className='bg-accent-2 flex items-center rounded-l-lg mb-2 h-full border-b-2'>
                                <h5 className="p-3 text-left text-white font-bold">Condición</h5>
                            </div>
                        </div>
                        <div className="w-3/4 flex items-center border px-1.5">
                            <select name="selectCondicion" onChange={(e) => this.handleCondiciones(index, 'condicion', e.target.value)} id="selectCondicion" value={this.state.condiciones[index].condicion} aria-label="Select Condition" className='w-full border p-2 bg-slate-100'>
                                <option value="" disabled>-- Selecciona Condición --</option>
                                <option value="categorias">Categoría</option>
                                <option value="subcategorias">Subcategoría</option>
                                <option value="departamentos">Departamento</option>
                                <option value="estatus">Estatus</option>
                                <option value="prioridades">Prioridad</option>
                            </select>
                        </div>
                    </div>
                    {/* Operador */}
                    <div className='inline-flex w-full'>
                        <div className="w-[53%]">
                            <div className='bg-accent-2 flex items-center rounded-l-lg mb-2 h-full border-b-2'>
                                <h5 className="p-3 text-left text-white font-bold">Operador</h5>
                            </div>
                        </div>
                        <div className="w-3/4 flex items-center border px-1.5">
                            <select id="selectOperator" onChange={(e) => this.handleCondiciones(index, 'operador', e.target.value)} name="selectOperator" disabled={this.state.condiciones[index].condicion === ''} value={this.state.condiciones[index].operador} aria-label="Select Operator" className='disabled:bg-slate-200 w-full border p-2 bg-slate-100'>
                                <option value="" disabled>-- Selecciona Operador --</option>
                                <option value="equals">es</option>
                                <option value="not equals">no es</option>
                                <option value="empty">esta vacío</option>
                                <option value="not empty">no está vacío</option>
                            </select>
                        </div>
                    </div>
                    {/* Valor */}
                    <div className={`${!this.state.valorHeader ? 'hidden' : 'inline-flex'} w-full`}>
                        <div className="w-[53%]">
                            <div className='bg-accent-2 flex items-center rounded-l-lg mb-2 h-full border-b-2'>
                                <h5 className="p-3 text-left text-white font-bold">Valor</h5>
                            </div>
                        </div>
                        <div className="w-3/4 flex items-center border px-1.5">
                            <Select placeholder='--Seleccionar Valor--'
                                isMulti
                                options={this.state.condiciones[index].condicion === 'categorias' && this.state.categorias}
                                menuPortalTarget={document.body}
                                className='md:p-1'
                                //onChange={(valor) => {this.setState({ updated: !this.state.updated, tdHeight: document.getElementById('tdValor' + index).offsetHeight }); console.log(document.getElementById('tdValor' + index).offsetHeight)}}
                                isLoading={this.state.valorSelectLoading}
                                styles={{
                                    option: (base, state) => ({
                                        ...base,
                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                    }),
                                }}
                            />
                        </div>
                    </div>
                    {/* Agregar/Remover */}
                    <div className='inline-flex w-full'>
                        <div className="w-[53%]">
                            <div className='bg-accent-2 flex items-center rounded-l-lg mb-2 h-full border-b-2'>
                                <h5 className="p-3 text-left text-white font-bold">Agregar/Remover</h5>
                            </div>
                        </div>
                        <div className="w-3/4 flex items-center border px-1.5">
                            <div className='flex w-full justify-center items-center gap-4'>
                                <button type='button' onClick={() => this.setState(prevState => ({ condiciones: [...prevState.condiciones, { condicion: '', operador: '', valor: '' }] }))} className='bg-accent-2 p-2 rounded hover:bg-blue-600'>
                                    <PlusIcon className='text-white h-5' />
                                </button>
                                <button type='button' disabled={this.state.condiciones.length === 1} onClick={() => this.setState(prevState => ({ condiciones: prevState.condiciones.filter((condicion, _index) => { return _index !== index }), valorHeader: prevState.condiciones.filter((condicion, _index) => { return _index !== index }).some(c => c.operador === 'equals' || c.operador === 'not equals') }))} className='disabled:opacity-70 disabled:hover:bg-accent-2 bg-accent-2 p-2 rounded hover:bg-blue-600'>
                                    <MinusIcon className='text-white h-5' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return condiciones;
    }

    handleCondiciones = (index, type, value) => {
        const newConditions = this.state.condiciones.map((condicion, _index) => {
            if (_index === index) {
                if (type === 'condicion') {
                    return { ...condicion, [type]: value, valor: '' };
                }
                return { ...condicion, [type]: value };
            }
            else return condicion;
        });
        this.setState({ condiciones: newConditions, valorHeader: newConditions.some(c => c.operador === 'equals' || c.operador === 'not equals') });
        if (newConditions.some(c => c.operador === 'equals' || c.operador === 'not equals')) {
            this.handleValorSelect(newConditions[index].condicion);
        }
    }

    handleValorSelect = (condicion) => {
        switch (condicion) {
            case 'categorias':
                if (this.state.categorias.length === 0) {
                    RestClient.GetRequest(AppUrl.Categorias).then((result) => {
                        this.setState({ categorias: [] });
                        result.forEach(element => {
                            this.setState(prevState => ({
                                categorias: [...prevState.categorias, { value: element.id, label: element.nombre }]
                            }));
                        });
                    });
                }
                break;
            case 'subcategorias':
                if (this.state.subcategorias.length === 0) {
                    RestClient.GetRequest(AppUrl.Subcategorias).then((result) => {
                        this.setState({ subcategorias: [] });
                        result.forEach(element => {
                            this.setState(prevState => ({
                                subcategorias: [...prevState.subcategorias, { value: element.id, label: element.nombre }]
                            }));
                        });
                    });
                }
                break;
            case 'departamentos':
                if (this.state.departamentos.length === 0) {
                    RestClient.GetRequest(AppUrl.Departamentos).then((result) => {
                        this.setState({ departamentos: [] });
                        result.forEach(element => {
                            this.setState(prevState => ({
                                departamentos: [...prevState.departamentos, { value: element.id, label: element.nombre }]
                            }));
                        });
                    });
                }
                break;
            case 'estatus':
                if (this.state.estatus.length === 0) {
                    RestClient.GetRequest(AppUrl.Estatus).then((result) => {
                        var options = [];
                        var groupedOptions = [];
                        result.forEach((element, index) => {
                            var group = element.tipo;
                            if ((index + 1) !== result.length && group === result[index + 1].tipo) {
                                options.push({ value: element.id, label: element.nombre, color: element.color });
                            }
                            else {
                                options.push({ value: element.id, label: element.nombre, color: element.color });
                                groupedOptions.push({ label: 'Tipo: ' + group, options: options });
                                options = [];
                            }
                        });
                        this.setState({ estatus: groupedOptions });
                    });
                }
                break;
            case 'prioridades':
                if (this.state.prioridades.length === 0) {
                    RestClient.GetRequest(AppUrl.Prioridades).then((result) => {
                        this.setState({ prioridades: [] });
                        result.forEach(element => {
                            this.setState(prevState => ({
                                prioridades: [...prevState.prioridades, { value: element.id, label: element.nombre }]
                            }));
                        });
                    });
                }
                break;
            default:
                break;
        }
    }

    renderSelect = (value) => {
        var values = [];
        for (let index = 0; index < value; index++) {
            values.push(<option key={index} value={index}>{index}</option>);
        }
        return values;
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        //Disable controls while request is processed and clear error messages
        this.setState({ processingRequest: true, errors: [{ 
            // SLA Errors
            nombre: '', descripcion: '', condiciones: '', respuesta_dias: '', respuesta_horas: '', respuesta_minutos: '', 
            resolucion_dias: '', resolucion_horas: '', resolucion_minutos: '', 
            // Escalacion Errors
            respuesta_esc1_dias: '', respuesta_esc1_horas: '', respuesta_esc1_minutos: '', respuesta_esc1_usuarios: '',
            resolucion_esc1_dias: '', resolucion_esc1_horas: '', resolucion_esc1_minutos: '', resolucion_esc1_usuarios: '',
            resolucion_esc2_dias: '', resolucion_esc2_horas: '', resolucion_esc2_minutos: '', resolucion_esc2_usuarios: '', 
            resolucion_esc3_dias: '', resolucion_esc3_horas: '', resolucion_esc3_minutos: '', resolucion_esc3_usuarios: '', 
            resolucion_esc4_dias: '', resolucion_esc4_horas: '', resolucion_esc4_minutos: '', resolucion_esc4_usuarios: ''}] 
        });

        const data = new FormData();
        data.append('nombre', this.state.nombre);
        data.append('descripcion', this.state.descripcion);
        data.append('apply', this.state.apply);
        data.append('condiciones', JSON.stringify(this.state.condiciones));
        data.append('respuesta_dias', this.state.tiempo_respuesta.dias);
        data.append('respuesta_horas', this.state.tiempo_respuesta.horas);
        data.append('respuesta_minutos', this.state.tiempo_respuesta.minutos);
        data.append('resolucion_dias', this.state.tiempo_resolucion.dias);
        data.append('resolucion_horas', this.state.tiempo_resolucion.horas);
        data.append('resolucion_minutos', this.state.tiempo_resolucion.minutos);
        data.append('respuesta_esc1', this.state.respEsc1);
        data.append('resolucion_esc1', this.state.resolEsc1);
        data.append('resolucion_esc2', this.state.resolEsc2);
        data.append('resolucion_esc3', this.state.resolEsc3);
        data.append('resolucion_esc4', this.state.resolEsc4);
        data.append('activo', true);
        if (this.state.respEsc1) {
            data.append('respuesta_esc1_dias', this.state.tiempo_respEsc1.dias);
            data.append('respuesta_esc1_horas', this.state.tiempo_respEsc1.horas);
            data.append('respuesta_esc1_minutos', this.state.tiempo_respEsc1.minutos);
            data.append('respuesta_esc1_usuarios', this.state.respEsc1Users);
            data.append('respuesta_esc1_ncs', this.state.respEsc1NCs);
        }
        if (this.state.resolEsc1) {
            data.append('resolucion_esc1_dias', this.state.tiempo_resolEsc1.dias);
            data.append('resolucion_esc1_horas', this.state.tiempo_resolEsc1.horas);
            data.append('resolucion_esc1_minutos', this.state.tiempo_resolEsc1.minutos);
            data.append('resolucion_esc1_usuarios', this.state.resolEsc1Users);
            data.append('resolucion_esc1_ncs', this.state.resolEsc1NCs);
        }
        if (this.state.resolEsc2) {
            data.append('resolucion_esc2_dias', this.state.tiempo_resolEsc2.dias);
            data.append('resolucion_esc2_horas', this.state.tiempo_resolEsc2.horas);
            data.append('resolucion_esc2_minutos', this.state.tiempo_resolEsc2.minutos);
            data.append('resolucion_esc2_usuarios', this.state.resolEsc2Users);
            data.append('resolucion_esc2_ncs', this.state.resolEsc2NCs);
        }
        if (this.state.resolEsc3) {
            data.append('resolucion_esc3_dias', this.state.tiempo_resolEsc3.dias);
            data.append('resolucion_esc3_horas', this.state.tiempo_resolEsc3.horas);
            data.append('resolucion_esc3_minutos', this.state.tiempo_resolEsc3.minutos);
            data.append('resolucion_esc3_usuarios', this.state.resolEsc3Users);
            data.append('resolucion_esc3_ncs', this.state.resolEsc3NCs);
        }
        if (this.state.resolEsc4) {
            data.append('resolucion_esc4_dias', this.state.tiempo_resolEsc4.dias);
            data.append('resolucion_esc4_horas', this.state.tiempo_resolEsc4.horas);
            data.append('resolucion_esc4_minutos', this.state.tiempo_resolEsc4.minutos);
            data.append('resolucion_esc4_usuarios', this.state.resolEsc4Users);
            data.append('resolucion_esc4_ncs', this.state.resolEsc4NCs);
        }

        RestClient.PostRequest(AppUrl.CreateSLA, data).then((result) => {
            this.setState({ statusResult: result.status, processingRequest: false, errors: result.data });
            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
        
    }

    render() {
        if (this.state.usersAccess.find(ua => ua.modulo_name === 'SLA' && ua.permiso_name === 'create') === undefined) {
            return <Navigate to='/access-denied' />
        }
        if (this.state.statusResult === 200) {
            return <Navigate to='/slas' />
        }
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'ml-80' : 'ml-20'} duration-300 p-12 pt-24 pr-16 bg-gray-100`}>
                {/* Page Title */}
                <div className='flex w-full'>
                    <h1 className='text-xl font-medium'>Crear Acuerdo De Nivel De Servicio</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 mt-2 space-x-1'>
                    {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                    <p className='text-gray-400'>Automatización</p>
                    <span className='text-xs'>/</span>
                    <Link to='/slas' className='font-semibold hover:underline'>SLAs</Link>
                    <span className='text-xs'>/</span>
                    <p className='text-dark-purple font-semibold'>Crear SLA</p>
                </div>
                {/* Main Section */}
                <div className='w-full flex justify-center mt-6'>
                    <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                        <div className='w-11/12 mt-8 mb-8'>
                            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                <div className='bg-gray-50'>
                                    <div className='bg-white border'>
                                        <h1 className='text-accent-1 text-2xl p-4'>Registrar SLA</h1>
                                    </div>
                                    <form onSubmit={this.onFormSubmit} className='mt-4'>
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información SLA</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-2' viewBox="0 0 640 512">
                                                                <path d="M506.1 127.1c-17.97-20.17-61.46-61.65-122.7-71.1c-22.5-3.354-45.39 3.606-63.41 18.21C302 60.47 279.1 53.42 256.5 56.86C176.8 69.17 126.7 136.2 124.6 139.1c-7.844 10.69-5.531 25.72 5.125 33.57c4.281 3.157 9.281 4.657 14.19 4.657c7.406 0 14.69-3.375 19.38-9.782c.4062-.5626 40.19-53.91 100.5-63.23c7.457-.9611 14.98 .67 21.56 4.483L227.2 168.2C214.8 180.5 207.1 196.1 207.1 214.5c0 17.5 6.812 33.94 19.16 46.29C239.5 273.2 255.9 279.1 273.4 279.1s33.94-6.813 46.31-19.19l11.35-11.35l124.2 100.9c2.312 1.875 2.656 5.251 .5 7.97l-27.69 35.75c-1.844 2.25-5.25 2.594-7.156 1.063l-22.22-18.69l-26.19 27.75c-2.344 2.875-5.344 3.563-6.906 3.719c-1.656 .1562-4.562 .125-6.812-1.719l-32.41-27.66L310.7 392.3l-2.812 2.938c-5.844 7.157-14.09 11.66-23.28 12.6c-9.469 .8126-18.25-1.75-24.5-6.782L170.3 319.8H96V128.3L0 128.3v255.6l64 .0404c11.74 0 21.57-6.706 27.14-16.14h60.64l77.06 69.66C243.7 449.6 261.9 456 280.8 456c2.875 0 5.781-.125 8.656-.4376c13.62-1.406 26.41-6.063 37.47-13.5l.9062 .8126c12.03 9.876 27.28 14.41 42.69 12.78c13.19-1.375 25.28-7.032 33.91-15.35c21.09 8.188 46.09 2.344 61.25-16.47l27.69-35.75c18.47-22.82 14.97-56.48-7.844-75.01l-120.3-97.76l8.381-8.382c9.375-9.376 9.375-24.57 0-33.94c-9.375-9.376-24.56-9.376-33.94 0L285.8 226.8C279.2 233.5 267.7 233.5 261.1 226.8c-3.312-3.282-5.125-7.657-5.125-12.31c0-4.688 1.812-9.064 5.281-12.53l85.91-87.64c7.812-7.845 18.53-11.75 28.94-10.03c59.75 9.22 100.2 62.73 100.6 63.29c3.088 4.155 7.264 6.946 11.84 8.376H544v175.1c0 17.67 14.33 32.05 31.1 32.05L640 384V128.1L506.1 127.1zM48 352c-8.75 0-16-7.245-16-15.99c0-8.876 7.25-15.99 16-15.99S64 327.2 64 336.1C64 344.8 56.75 352 48 352zM592 352c-8.75 0-16-7.245-16-15.99c0-8.876 7.25-15.99 16-15.99s16 7.117 16 15.99C608 344.8 600.8 352 592 352z" />
                                                            </svg>
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => this.setState({ nombre: e.target.value })} id='txtNombre' type="text" name="nombre" maxLength={30} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtNombre" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nombre</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.nombre}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <DocumentTextIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <textarea onChange={(e) => this.setState({ descripcion: e.target.value })} id='taDescripcion' name="descripcion" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                            <label htmlFor="taDescripcion" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Descripción (Opcional)</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.descripcion}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="space-y-4 md:space-y-0 w-full p-4 text-gray-800 items-center mb-4">
                                            <h2 className="text-accent-1 font-semibold">Condiciones</h2>
                                            <fieldset className="md:inline-flex justify-around items-center w-full md:py-5 space-y-2">
                                                <span className="block text-left mb-4 md:mb-0">Cuando llegue una petición:</span>
                                                <div className="pl-4">
                                                    <div className="flex items-center gap-2">
                                                        <input type="radio" onClick={() => this.setState({ apply: true })} name="conditionRadios" id="rdApply" value="apply" defaultChecked />
                                                        <label htmlFor="rdApply">
                                                            Aplicar condiciones basado en el siguiente criterio
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="pl-4">
                                                    <div className="flex items-center gap-2">
                                                        <input type="radio" onClick={() => this.setState({ apply: false })} name="conditionRadios" id="rdDontApply" value="dontApply" />
                                                        <label htmlFor="rdDontApply">
                                                            No aplicar condición
                                                        </label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            {/* Condicones Apply */}
                                            <div className='px-5 md:container' hidden={!this.state.apply}>
                                                <table id='tblCondiciones' className='hidden md:inline-table w-full bg-white rounded-lg overflow-hidden md:shadow-md my-5'>
                                                    <thead className='text-white'>
                                                        {this.renderCondiciones('header')}
                                                    </thead>
                                                    <tbody className='flex-1 md:flex-none'>
                                                        {this.renderCondiciones('body')}
                                                    </tbody>
                                                </table>
                                                {/* Mobile */}
                                                <div className='md:hidden flex-col w-full'>
                                                    {this.renderCondicionesMobile()}
                                                </div>
                                            </div>
                                            {/* Condicones Not Apply */}
                                            <div className={`px-5 ${!this.state.apply ? 'flex' : 'hidden'} w-full`}>
                                                <div className='w-full bg-accent-2 rounded-md p-3 my-5'>
                                                    <h5 className='italic text-center text-white font-bold'>El SLA se aplicará a todas las solicitudes entrantes</h5>
                                                </div>
                                            </div>
                                            <span className='text-sm text-red-500'>{this.state.errors.condiciones}</span>
                                            {/* Tiempos */}
                                            <div className='md:p-5 px-5 py-2'>
                                                <span>Cualquier petición que cumpla las condiciones arriba debera ser</span>
                                            </div>
                                            {/* Grid */}
                                            <div className='grid md:grid-cols-4 grid-cols-3 w-full md:p-5 px-5 py-2 gap-y-6'>
                                                <div className='md:col-span-1 col-span-3'>
                                                    <div className='flex w-full md:justify-center items-center p-1'>
                                                        <span>Contestado en:</span>
                                                    </div>
                                                </div>
                                                <div className='col-span-1'>
                                                    <div className='inline-flex items-center w-full gap-4 group'>
                                                        <label htmlFor="respDias" className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Dias</label>
                                                        <input type="number" id='respDias' onChange={(e) => this.setState(prevState => {
                                                            let tiempo_respuesta = Object.assign({}, prevState.tiempo_respuesta);
                                                            tiempo_respuesta.dias = e.target.value;
                                                            return { tiempo_respuesta };
                                                        })} className='border-2 border-gray-400 rounded p-1 text-center w-20 focus:outline-none focus:ring-0 focus:border-accent-2' min={0} defaultValue={0} required />  
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.respuesta_dias}</span>
                                                </div>
                                                <div className='col-span-1'>
                                                    <div className='inline-flex items-center w-full gap-4 group'>
                                                        <label htmlFor="respHoras" className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Horas</label>
                                                        <select name="horas" id="respHoras" onChange={(e) => this.setState(prevState => {
                                                            let tiempo_respuesta = Object.assign({}, prevState.tiempo_respuesta);
                                                            tiempo_respuesta.horas = e.target.value;
                                                            return { tiempo_respuesta };
                                                        })} defaultValue={0} className='border-2 border-gray-400 rounded p-1 px-4 focus:outline-none focus:ring-0 focus:border-accent-2' required>
                                                            {this.renderSelect(24)}
                                                        </select>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.respuesta_horas}</span>
                                                </div>
                                                <div className='col-span-1'>
                                                    <div className='inline-flex items-center w-full gap-4 group'>
                                                        <label htmlFor="respMinutos" className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Minutos</label>
                                                        <select name="minutos" id="respMinutos" onChange={(e) => this.setState(prevState => {
                                                            let tiempo_respuesta = Object.assign({}, prevState.tiempo_respuesta);
                                                            tiempo_respuesta.minutos = e.target.value;
                                                            return { tiempo_respuesta };
                                                        })} defaultValue={0} className='border-2 border-gray-400 rounded p-1 px-4 focus:outline-none focus:ring-0 focus:border-accent-2' required>
                                                            {this.renderSelect(60)}
                                                        </select>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.respuesta_minutos}</span>
                                                </div>
                                                <div className='md:col-span-1 col-span-3'>
                                                    <div className='flex w-full md:justify-center items-center p-1'>
                                                        <span>Resuelto en:</span>
                                                    </div>
                                                </div>
                                                <div className='col-span-1'>
                                                    <div className='inline-flex items-center w-full gap-4 group'>
                                                        <label htmlFor="resolDias" className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Dias</label>
                                                        <input type="number" id='resolDias' onChange={(e) => this.setState(prevState => {
                                                            let tiempo_resolucion = Object.assign({}, prevState.tiempo_resolucion);
                                                            tiempo_resolucion.dias = e.target.value;
                                                            return { tiempo_resolucion };
                                                        })} className='border-2 border-gray-400 rounded p-1 text-center w-20 focus:outline-none focus:ring-0 focus:border-accent-2' min={0} defaultValue={0} required />
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.resolucion_dias}</span>
                                                </div>
                                                <div className='col-span-1'>
                                                    <div className='inline-flex items-center w-full gap-4 group'>
                                                        <label htmlFor="resolHoras" className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Horas</label>
                                                        <select name="horas" id="resolHoras" onChange={(e) => this.setState(prevState => {
                                                            let tiempo_resolucion = Object.assign({}, prevState.tiempo_resolucion);
                                                            tiempo_resolucion.horas = e.target.value;
                                                            return { tiempo_resolucion };
                                                        })} defaultValue={0} className='border-2 border-gray-400 rounded p-1 px-4 focus:outline-none focus:ring-0 focus:border-accent-2' required>
                                                            {this.renderSelect(24)}
                                                        </select>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.resolucion_horas}</span>
                                                </div>
                                                <div className='col-span-1'>
                                                    <div className='inline-flex items-center w-full gap-4 group'>
                                                        <label htmlFor="resolMinutos" className='group-focus-within:text-accent-2 group-focus-within:font-semibold'>Minutos</label>
                                                        <select name="minutos" id="resolMinutos" onChange={(e) => this.setState(prevState => {
                                                            let tiempo_resolucion = Object.assign({}, prevState.tiempo_resolucion);
                                                            tiempo_resolucion.minutos = e.target.value;
                                                            return { tiempo_resolucion };
                                                        })} defaultValue={0} className='border-2 border-gray-400 rounded p-1 px-4 focus:outline-none focus:ring-0 focus:border-accent-2' required>
                                                            {this.renderSelect(60)}
                                                        </select>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.resolucion_minutos}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="space-y-4 md:space-y-0 w-full p-4 text-gray-800 items-center">
                                            <h2 className="text-accent-1 font-semibold">Si ya pasó el tiempo de respuesta</h2>
                                            <div className='p-5 flex w-full gap-4'>
                                                <input type="checkbox" name="escalationCheck" id="resp_CheckEsc1" className='w-5' onClick={() => this.setState({ respEsc1: !this.state.respEsc1 })} />
                                                <label htmlFor="resp_CheckEsc1">Habilitar Escalación Nivel 1</label>
                                            </div>
                                            <div hidden={!this.state.respEsc1}>
                                                <EscalacionComponent name={'respEsc1'} handleTiempo={(tiempo) => this.setState({ tiempo_respEsc1: tiempo })} handleUsers={(users) => this.setState({ respEsc1Users: users })} handleNCs={(ncs) => this.setState({ respEsc1NCs: ncs })} 
                                                errors={{dias: this.state.errors.respuesta_esc1_dias, horas: this.state.errors.respuesta_esc1_horas, minutos: this.state.errors.respuesta_esc1_minutos, usuarios: this.state.errors.respuesta_esc1_usuarios}} />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="space-y-4 md:space-y-0 w-full p-4 text-gray-800 items-center mb-4">
                                            <h2 className="text-accent-1 font-semibold">Si ya pasó el tiempo de resolución</h2>
                                            <div className='px-5 py-2.5 flex w-full gap-4'>
                                                <input type="checkbox" name="escalationCheck" id="res_CheckEsc1" className='w-5' checked={this.state.resolEsc1} onChange={(e) => {this.setState({ resolEsc1: !this.state.resolEsc1 }); 
                                                !e.target.checked && this.setState({ resolEsc2: false, resolEsc3: false, resolEsc4: false })}} />
                                                <label htmlFor="res_CheckEsc1">Habilitar Escalación Nivel 1</label>
                                            </div>
                                            <div hidden={!this.state.resolEsc1}>
                                                <EscalacionComponent name={'resolEsc1'} handleTiempo={(tiempo) => this.setState({ tiempo_resolEsc1: tiempo })} handleUsers={(users) => this.setState({ resolEsc1Users: users })} handleNCs={(ncs) => this.setState({ resolEsc1NCs: ncs })}
                                                errors={{dias: this.state.errors.resolucion_esc1_dias, horas: this.state.errors.resolucion_esc1_horas, minutos: this.state.errors.resolucion_esc1_minutos, usuarios: this.state.errors.resolucion_esc1_usuarios}} />
                                            </div>
                                            <div className='px-5 py-2.5 flex w-full gap-4'>
                                                <input type="checkbox" name="escalationCheck" id="res_CheckEsc2" disabled={!this.state.resolEsc1} checked={this.state.resolEsc2} className='w-5' onChange={(e) => {this.setState({ resolEsc2: !this.state.resolEsc2 });
                                                !e.target.checked && this.setState({ resolEsc3: false, resolEsc4: false })}} />
                                                <label htmlFor="res_CheckEsc2">Habilitar Escalación Nivel 2</label>
                                            </div>
                                            <div hidden={!this.state.resolEsc2}>
                                                <EscalacionComponent name={'resolEsc2'} handleTiempo={(tiempo) => this.setState({ tiempo_resolEsc2: tiempo })} handleUsers={(users) => this.setState({ resolEsc2Users: users })} handleNCs={(ncs) => this.setState({ resolEsc2NCs: ncs })}
                                                errors={{dias: this.state.errors.resolucion_esc2_dias, horas: this.state.errors.resolucion_esc2_horas, minutos: this.state.errors.resolucion_esc2_minutos, usuarios: this.state.errors.resolucion_esc2_usuarios}} />
                                            </div>
                                            <div className='px-5 py-2.5 flex w-full gap-4'>
                                                <input type="checkbox" name="escalationCheck" id="res_CheckEsc3" disabled={!this.state.resolEsc2} checked={this.state.resolEsc3} className='w-5' onChange={(e) => {this.setState({ resolEsc3: !this.state.resolEsc3 });
                                                !e.target.checked && this.setState({ resolEsc4: false })}} />
                                                <label htmlFor="res_CheckEsc3">Habilitar Escalación Nivel 3</label>
                                            </div>
                                            <div hidden={!this.state.resolEsc3}>
                                                <EscalacionComponent name={'resolEsc3'} handleTiempo={(tiempo) => this.setState({ tiempo_resolEsc3: tiempo })} handleUsers={(users) => this.setState({ resolEsc3Users: users })} handleNCs={(ncs) => this.setState({ resolEsc3NCs: ncs })}
                                                errors={{dias: this.state.errors.resolucion_esc3_dias, horas: this.state.errors.resolucion_esc3_horas, minutos: this.state.errors.resolucion_esc3_minutos, usuarios: this.state.errors.resolucion_esc3_usuarios}} />
                                            </div>
                                            <div className='px-5 py-2.5 flex w-full gap-4'>
                                                <input type="checkbox" name="escalationCheck" id="res_CheckEsc4" disabled={!this.state.resolEsc3} checked={this.state.resolEsc4} className='w-5' onChange={() => this.setState({ resolEsc4: !this.state.resolEsc4 })} />
                                                <label htmlFor="res_CheckEsc4">Habilitar Escalación Nivel 4</label>
                                            </div>
                                            <div hidden={!this.state.resolEsc4}>
                                                <EscalacionComponent name={'resolEsc4'} handleTiempo={(tiempo) => this.setState({ tiempo_resolEsc4: tiempo })} handleUsers={(users) => this.setState({ resolEsc4Users: users })} handleNCs={(ncs) => this.setState({ resolEsc4NCs: ncs })}
                                                errors={{dias: this.state.errors.resolucion_esc4_dias, horas: this.state.errors.resolucion_esc4_horas, minutos: this.state.errors.resolucion_esc4_minutos, usuarios: this.state.errors.resolucion_esc4_usuarios}} />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='p-4 flex justify-center gap-4 bg-white'>
                                            <button hidden={!this.state.processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" hidden={this.state.processingRequest} className="text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                            <Link to='/slas' hidden={this.state.processingRequest} className={`${!this.state.processingRequest && 'flex'} items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CrearSLAPage;