import React, { useState, useEffect } from 'react';
import { Link, Navigate, useOutletContext } from 'react-router-dom';
import ConstanciaDeportiva from '../../resources/images/constancia-deportiva.png';
import ConstanciaPasaporte from '../../resources/images/constancia-pasaporte.png';
import ConstanciaVisa from '../../resources/images/constancia-visa.png';
import Ticket from '../../resources/images/ticket.png';
import ReactModal from 'react-modal';
import { XIcon } from '@heroicons/react/solid';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import ConstanciaJSON from '../../templates/ConstanciaDescripcion.json';
var CryptoJS = require("crypto-js");

// Create a constructor for functional component to get permissions
const useConstructor = (callBack = () => { }) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
}

ReactModal.setAppElement('#root');

const CrearPeticionPanelPage = (props) => {
    // Get 'SetPeticionContext' to prepare peticion context depending on user's selection
    const context = useOutletContext();
    const user = context.user;
    const setPeticionContext = context.setPeticionContext;
    const [usersAccess, setUsersAccess] = useState([]);
    const [showEstudiantesModal, setShowEstudiantesModal] = useState(false);
    const [usersEstudiantes, setUsersEstudiantes] = useState([]);
    const [selectionContext, setSelectionContext] = useState('');
    const [selected_estudiantes, setSelectedEstudiantes] = useState([]);
    const [titulo, setTitulo] = useState('');
    const [descripcion, setDescripcion] = useState(JSON.parse(JSON.stringify(ConstanciaJSON)));
    const [categoria_id, setCategoriaId] = useState(0);
    const [subcategoria_id, setSubcategoriaId] = useState(0);
    const [estatus_id, setEstatusId] = useState(0);
    const [departamento_id, setDepartamentoId] = useState(0);
    const [prioridad_id, setPrioridadId] = useState(0);
    const [loaded, setLoaded] = useState(false);

    useConstructor(() => {
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUsersAccess(_usersAccess);
    });

    function handleCloseModalEstudiantes() {
        setShowEstudiantesModal(false);
    }

    function handleOpenModalEstudiantes(seleccion) {
        switch (seleccion) {
            case 'Constancia Deportiva':
                setTitulo('Solicitud de Constancia Deportiva');
                setCategoriaId(1);
                setSubcategoriaId(1);
                setEstatusId(1);
                setDepartamentoId(8);
                setPrioridadId(3);
                break;
            case 'Constancia Pasaporte':
                setTitulo('Solicitud de Constancia Pasaporte');
                setCategoriaId(1);
                setSubcategoriaId(3);
                setEstatusId(1);
                setDepartamentoId(8);
                setPrioridadId(3);
                break;
            case 'Constancia Visa':
                setTitulo('Solicitud de Constancia Visa');
                setCategoriaId(1);
                setSubcategoriaId(6);
                setEstatusId(1);
                setDepartamentoId(8);
                setPrioridadId(3);
                break;
            default:
                break;
        }
        setSelectionContext(seleccion);
        setShowEstudiantesModal(true);
    }
    // Load tutor's estudiantes if tutor is creating peticion
    useEffect(() => {
        if (user) {
            RestClient.GetRequest(AppUrl.EstudianteByTutorId + user.user_type_id).then((result) => {
                setUsersEstudiantes(result);
                setLoaded(true);
            });
        }
    }, [user])

    function handleCheckEstudiantes(checkbox) {
        const estudiante = usersEstudiantes.find(e => e.id === parseInt(checkbox.id));
        const newDescripcion = descripcion;
        if (checkbox.checked) {
            setSelectedEstudiantes(prevState => [...prevState, checkbox.id]);
            newDescripcion.forEach((element, index) => {
                if (index === 2) {
                    element.children[1].text = titulo.replace("Solicitud de ", "");
                }
                if (element.type === 'bulleted-list') {
                    element.children.push({type: 'list-item', children: [{text: estudiante.nombre + ' ' + estudiante.apellido}]});
                }
            });
            setDescripcion(newDescripcion);
        }
        else {
            setSelectedEstudiantes(prevState => prevState.filter(id => id !== checkbox.id));
            newDescripcion.forEach((element, index) => {
                if (index === 2) {
                    element.children[1].text = titulo.replace("Solicitud de ", "");
                }
                if (element.type === 'bulleted-list') {
                    element.children = element.children.filter(e => !e.children.some(ch => ch.text === estudiante.nombre + ' ' + estudiante.apellido));
                }
            });
            setDescripcion(newDescripcion);
        }
    }

    if (usersAccess.find(ua => ua.modulo_name === 'peticiones' && ua.permiso_name === 'create') === undefined) {
        return <Navigate to='/access-denied' />
    }
    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60 ml-0' : 'sm:ml-20 ml-0'} duration-300 px-2 sm:pt-24 pt-20 pb-6 sm:px-4 md:px-8 bg-gray-100`}>
            {/* Page Title */}
            <div className='sm:px-0 px-3'>
                <div className='flex w-full'>
                    <h1 className='sm:text-xl text-lg font-medium'>¿Sobre qué quieres hacer tu solicitud?</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                    {usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                    <Link to='/peticiones' className='font-semibold hover:underline'>Solicitudes</Link>
                    <span className='text-xs'>/</span>
                    <p className='text-dark-purple font-semibold'>Tipo de Solicitud</p>
                </div>
            </div>
            <div className='w-full flex justify-center sm:mt-6 mt-3'>
                <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                    <div className='w-11/12 sm:my-8 my-4'>
                        <div className='w-full bg-gray-100 rounded-lg shadow-lg px-4 py-8 sm:px-6 lg:px-8 mx-auto'>
                            {/* Buttons */}
                            <div className='space-y-8'>
                                {/* Constancias */}
                                <div>
                                    <div className='mb-5'>
                                        <h2 className='text-xl leading-normal tracking-[-.01em] text-slate-800 font-bold'>Constancias</h2>
                                    </div>
                                    {/* Grid */}
                                    <div className='grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-6'>
                                        {/* Button */}
                                        <button type='button' onClick={() => handleOpenModalEstudiantes('Constancia Deportiva')} className='bg-white rounded text-center p-5 shadow-md'>
                                            <div className='flex flex-col h-full'>
                                                <div className='flex-grow mb-2'>
                                                    <div className='inline-flex '>
                                                        <img src={ConstanciaDeportiva} alt="Constancia Deportiva" className='w-32 h-32' />
                                                    </div>
                                                    <h3 className='text-lg leading-normal tracking-[-.01em] text-slate-800 font-semibold mb-1'>Constancia Deportiva</h3>
                                                </div>
                                            </div>
                                        </button>
                                        <button type='button' onClick={() => handleOpenModalEstudiantes('Constancia Pasaporte')} className='bg-white rounded text-center p-5 shadow-md'>
                                            <div className='flex flex-col h-full'>
                                                <div className='flex-grow mb-2'>
                                                    <div className='inline-flex '>
                                                        <img src={ConstanciaPasaporte} alt="Constancia Pasaporte" className='w-32 h-32' />
                                                    </div>
                                                    <h3 className='text-lg leading-normal tracking-[-.01em] text-slate-800 font-semibold mb-1'>Constancia Pasaporte Mexicano</h3>
                                                </div>
                                            </div>
                                        </button>
                                        <button type='button' onClick={() => handleOpenModalEstudiantes('Constancia Visa')} className='bg-white rounded text-center p-5 shadow-md'>
                                            <div className='flex flex-col h-full'>
                                                <div className='flex-grow mb-2'>
                                                    <div className='inline-flex '>
                                                        <img src={ConstanciaVisa} alt="Constancia Visa" className='w-32 h-32' />
                                                    </div>
                                                    <h3 className='text-lg leading-normal tracking-[-.01em] text-slate-800 font-semibold mb-1'>Constancia Visa</h3>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                {/* Otro */}
                                <div>
                                    <div className='mb-5'>
                                        <h2 className='text-xl leading-normal tracking-[-.01em] text-slate-800 font-bold'>General, Dudas y/o Preguntas</h2>
                                    </div>
                                    {/* Grid */}
                                    <div className='grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-6'>
                                        {/* Button */}
                                        <Link to='/peticiones/crear' onClick={() => setPeticionContext({categorias_id: 2, subcategoria_id: 4, estatus_id: 1, departamento_id: 1, asignado_id: 8})} className='bg-white rounded text-center p-5 shadow-md'>
                                            <div className='flex flex-col h-full'>
                                                <div className='flex-grow mb-2'>
                                                    <div className='inline-flex '>
                                                        <img src={Ticket} alt="Ticket General" className='w-32 h-32' />
                                                    </div>
                                                    <h3 className='text-lg leading-normal tracking-[-.01em] text-slate-800 font-semibold mb-1'>General</h3>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal Asignar Depto */}
            <ReactModal
                closeTimeoutMS={800}
                isOpen={showEstudiantesModal}
                contentLabel="Default Confirmation Modal"
                className={'Modal'}
                overlayClassName="Overlay"
                onRequestClose={handleCloseModalEstudiantes}
                shouldCloseOnOverlayClick={true}
            >
                <div className="flex flex-col items-start">
                    <div className="flex items-center w-full border-b-2">
                        <div className="text-gray-900 font-medium text-xl">Estudiantes Asociados</div>
                        <button onClick={handleCloseModalEstudiantes} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                            <XIcon className='w-5 h-5' />
                        </button>
                    </div>
                    <div className="border-b-2 w-full py-6 space-y-3">
                        <span className='text-base text-gray-600'>Elige los estudiantes para elaborar tú: <b className='text-gray-800'>{selectionContext}</b></span>
                        {!loaded ?
                        <div className='flex justify-center w-full'>
                            <svg className="animate-spin h-5 w-5 text-accent-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div> :
                        <ul className='text-gray-800 w-full flex flex-col space-y-1 max-h-72 overflow-auto'>
                            {usersEstudiantes.map(estudiante => (
                                <li key={estudiante.id} className='w-full'>
                                    <input type="checkbox" name={estudiante.id} id={estudiante.id} onChange={(e) => handleCheckEstudiantes(e.target)} className='w-1/12 accent-contrast-1' />
                                    <label htmlFor={estudiante.id}>{estudiante.nombre + ' ' + estudiante.apellido}</label>
                                </li>
                            ))}
                        </ul>}                   
                    </div>
                    <div className="flex justify-end w-full mt-4 gap-4">
                        <button type='button' disabled hidden={selected_estudiantes.length !== 0} className="disabled:bg-accent-2/60 bg-accent-1 hover:bg-accent-2 text-white font-bold py-2 px-4 rounded">
                            Continuar
                        </button>
                        <Link onClick={() => setPeticionContext({titulo: titulo, descripcion: JSON.stringify(descripcion), categoria_id: categoria_id, subcategoria_id: subcategoria_id, estatus_id: estatus_id, departamento_id: departamento_id, asignado_id: 8, prioridad_id: prioridad_id, estudiantes: selected_estudiantes})} to='/peticiones/crear' hidden={selected_estudiantes.length === 0} className="disabled:bg-accent-2/60 bg-accent-1 hover:bg-accent-2 text-white font-bold py-2 px-4 rounded">
                            Continuar
                        </Link>
                        <button onClick={handleCloseModalEstudiantes} type='button' className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                            Cancelar
                        </button>
                    </div>
                </div>
            </ReactModal>
        </div>
    );
}

export default CrearPeticionPanelPage;