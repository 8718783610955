import { BriefcaseIcon, LockClosedIcon, MailIcon, PhotographIcon, UserIcon } from '@heroicons/react/outline';
import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import Select from 'react-select';
import { PlusIcon } from '@heroicons/react/solid';
var CryptoJS = require("crypto-js");

class CrearUsuarioPage extends Component {

    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.state = {
            usersAccess: _usersAccess,
            email: '', password: '', password_confirmation: '', rol_id: 0, persona_id: 0, foto_perfil: '', 
            statusResult: 0, processingRequest: false, filter: 'tutores', selectLoading: true,
            errors: [{ email: '', password: '', rol_id: '', persona_id: '', foto_perfil: ''}], 
            roles: [], tutores: [], administrativos: [], estudiantes: []
        }
    }

    componentDidMount() {
        // Get Tutores
        RestClient.GetRequest(AppUrl.Tutores).then((result) => {
            result.forEach(element => {
                if (this.state.tutores.length < 1 && element.usuario_id === null) {
                    this.setState(prevState => ({
                        tutores: [...prevState.tutores, { value: element.persona_id, label: element.nombre + ' ' + element.apellido }]
                    }));
                }
            });
            this.setState({ selectLoading: false });
        });
        // Get Administrativos
        RestClient.GetRequest(AppUrl.Administrativos).then((result) => {
            result.forEach(element => {
                if (this.state.administrativos.length < 1 && element.usuario_id === null) {
                    this.setState(prevState => ({
                        administrativos: [...prevState.administrativos, { value: element.persona_id, label: element.nombre + ' ' + element.apellido }]
                    }));
                }
            });
            this.setState({ selectLoading: false });
        });
        // Get Estudiantes
        RestClient.GetRequest(AppUrl.Estudiantes).then((result) => {
            result.forEach(element => {
                if (this.state.estudiantes.length < 1 && element.usuario_id === null) {
                    this.setState(prevState => ({
                        estudiantes: [...prevState.estudiantes, { value: element.persona_id, label: element.nombre + ' ' + element.apellido }]
                    }));
                }
            });
            this.setState({ selectLoading: false });
        });
        // Get Roles
        RestClient.GetRequest(AppUrl.Roles).then((result) => {
            this.setState({ roles: result });
        });
    }

    handleChange = (e) => {
        this.setState({
            foto_perfil: e.target.files[0]
        })
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        //Disable controls while request is processed
        this.setState({ processingRequest: true, errors: [{ email: '', password: '', rol_id: '', persona_id: '', foto_perfil: '' }] });

        const data = new FormData();
        data.append('email', this.state.email);
        data.append('password', this.state.password);
        data.append('password_confirmation', this.state.password_confirmation);
        data.append('rol_id', this.state.rol_id);
        data.append('foto_perfil[]', this.state.foto_perfil);
        data.append('user_type', this.state.filter);
        data.append('persona_id', this.state.persona_id.value);

        RestClient.PostRequest(AppUrl.RegisterUser, data).then((result) => {
            this.setState({ statusResult: result.status, processingRequest: false, errors: result.data });
            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    render() {
        if (this.state.usersAccess.find(ua => ua.modulo_name === 'usuarios' && ua.permiso_name === 'create') === undefined) {
            return <Navigate to='/access-denied' />
        }
        if (this.state.statusResult === 200) {
            return <Navigate to='/usuarios' />
        }
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'ml-80' : 'ml-20'} duration-300 p-12 pt-24 pr-16 bg-gray-100`}>
                {/* Page Title */}
                <div className='flex w-full'>
                    <h1 className='text-xl font-medium'>Crear Usuario</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 mt-2 space-x-1'>
                    {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                    <p className='text-gray-400'>Admin</p>
                    <span className='text-xs'>/</span>
                    <Link to='/usuarios' className='font-semibold hover:underline'>Usuarios</Link>
                    <span className='text-xs'>/</span>
                    <p className='text-dark-purple font-semibold'>Crear Usuario</p>
                </div>
                {/* Main Section */}
                <div className='w-full flex justify-center mt-6'>
                    <div className='w-full flex-col justify-center bg-white rounded shadow-lg'>
                        <div className='w-11/12 mx-auto mt-8 mb-8 bg-gray-100 rounded shadow-lg'>
                            <fieldset className="flex flex-col lg:flex-row w-full lg:items-center p-5 space-y-3 lg:space-y-0">
                                <div className="w-3/5">
                                    <h1 className="font-semibold">Tipo de Usuario:</h1>
                                </div>
                                <div className='flex flex-col md:flex-row w-full justify-start md:gap-8 gap-4'>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioTutores" value="tutores" defaultChecked />
                                        <label className="font-semibold text-gray-600" htmlFor="radioTutores">
                                            Tutor
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioAdministrativos" value="administrativos" />
                                        <label className="font-semibold text-gray-600" htmlFor="radioAdministrativos">
                                            Administrativo
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioEstudiantes" value="estudiantes" />
                                        <label className="font-semibold text-gray-600" htmlFor="radioEstudiantes">
                                            Estudiante
                                        </label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div className='w-11/12 mt-8 mb-8 mx-auto'>
                            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                <div className='bg-gray-50'>
                                    <div className='bg-white border'>
                                        <h1 className='text-accent-1 text-2xl p-4'>Registrar Usuario</h1>
                                    </div>
                                    <form onSubmit={this.onFormSubmit} className='mt-4' encType="multipart/form-data">
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Persona</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex gap-4">
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                            <div className="w-1/12 pt-2 flex justify-center ml-1 mt-0.5">
                                                                <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <Select placeholder='--Seleccionar Persona--'
                                                                    options={
                                                                        this.state.filter === 'tutores' ? this.state.tutores : 
                                                                        this.state.filter === 'administrativos' ? this.state.administrativos :
                                                                        this.state.filter === 'estudiantes' ? this.state.estudiantes : []
                                                                    }
                                                                    menuPortalTarget={document.body}
                                                                    className='p-1'
                                                                    onChange={(value) => this.setState({ persona_id: value })}
                                                                    isLoading={this.state.selectLoading}
                                                                    styles={{
                                                                        option: (base, state) => ({
                                                                            ...base,
                                                                            backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                                                        }),
                                                                    }}
                                                                />
                                                                <label htmlFor="selectPersonas" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Persona</label>
                                                            </div>
                                                        </div>
                                                        {this.state.usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === this.state.filter) !== undefined &&
                                                            <Link to={`/${this.state.filter}/crear`} title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                <PlusIcon className='h-5' />
                                                            </Link>}
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.persona_id}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4 mt-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Usuario</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <MailIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => this.setState({ email: e.target.value })} id='txtEmail' type="email" name="correo" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtEmail" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Correo</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.email}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <LockClosedIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => this.setState({ password: e.target.value })} id='txtPassword' type="password" name="password" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtPassword" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Contraseña</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.password}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <LockClosedIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => this.setState({ password_confirmation: e.target.value })} id='txtConfirmPassword' type="password" name="password_confirmation" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtConfirmPassword" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Confirmar Contraseña</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.password}</span>
                                                </div>
                                                <div className="grid grid-cols-1 mt-5">
                                                    <label className="uppercase md:text-sm text-xs text-gray-400 text-light font-semibold mb-1">Foto De Perfil</label>
                                                    <div className='flex items-center justify-center w-full'>
                                                        <label className='flex flex-col border-4 border-dashed w-full h-32 hover:bg-gray-100 hover:border-accent-2 group'>
                                                            <div className='flex flex-col items-center justify-center pt-7'>
                                                                <PhotographIcon className='w-10 h-10 text-gray-400 group-hover:text-accent-2' />
                                                                <p className='lowercase text-sm text-gray-400 group-hover:text-accent-1 pt-1 tracking-wider text-center'>{this.state.foto_perfil ? this.state.foto_perfil.name : 'Selecciona una foto, Formatos soportados: JPEG, JPG, GIF, PNG, BMP, WebP.'}</p>
                                                            </div>
                                                            <input onChange={this.handleChange} name='foto_perfil' type='file' className="hidden" accept='.jpg,.jpeg,.png,.gif,.bmp,.webp' />
                                                        </label>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.foto_perfil}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4 mt-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Roles</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex gap-4">
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group my-1">
                                                            <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                <BriefcaseIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <select onChange={(e) => this.setState({ rol_id: e.target.value })} id='selectRoles' name="rol_id" defaultValue={this.state.rol_id} className={`block py-2.5 px-4 w-full text-sm bg-transparent border-l-2 border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer`} required>
                                                                    <option value="0" disabled className='text-gray-400'>-- Seleccione un Rol --</option>
                                                                    {this.state.roles.map(item => {
                                                                        return <option key={item.id} value={item.id}>{item.nombre}</option>
                                                                    })}
                                                                </select>
                                                                <label htmlFor="selectRoles" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Rol</label>
                                                            </div>
                                                        </div>
                                                        {this.state.usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'roles') !== undefined &&
                                                            <Link to='/roles/crear' title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                <PlusIcon className='h-5' />
                                                            </Link>}
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.rol_id}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='p-4 flex justify-center gap-4 bg-white'>
                                            <button hidden={!this.state.processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" hidden={this.state.processingRequest} className="text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                            <Link to='/usuarios' hidden={this.state.processingRequest} className={`${!this.state.processingRequest && 'flex'} items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CrearUsuarioPage;