import React, { Component } from 'react';
import { TagIcon } from '@heroicons/react/outline'
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
var CryptoJS = require("crypto-js");

class CrearCategoriaPage extends Component {

    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.state = {
            usersAccess: _usersAccess,
            nombre: '', statusResult: 0, processingRequest: false, errors: [{nombre: ''}]
        }
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        //Disable controls while request is processed
        this.setState({ processingRequest: true, errors: [{nombre: ''}] });

        const data = new FormData();
        data.append('nombre', this.state.nombre);

        RestClient.PostRequest(AppUrl.CreateCategoria, data).then((result) => {
            this.setState({ statusResult: result.status, processingRequest: false, errors: result.data });
            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    render() {
        if (this.state.usersAccess.find(ua => ua.modulo_name === 'categorias' && ua.permiso_name === 'create') === undefined) {
            return <Navigate to='/access-denied' />
        }
        if (this.state.statusResult === 200) {
            return <Navigate to='/categorias-subcategorias' />
        }
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'ml-80' : 'ml-20'} duration-300 p-12 pt-24 pr-16 bg-gray-100`}>
                {/* Page Title */}
                <div className='flex w-full'>
                    <h1 className='text-xl font-medium'>Crear Categoría</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 mt-2 space-x-1'>
                    {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                    <>
                        <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                        <span className='text-xs'>/</span>
                    </>}
                    <p className='text-gray-400'>Personalización</p>
                    <span className='text-xs'>/</span>
                    <Link to='/categorias-subcategorias' className='font-semibold hover:underline'>Categorías y Subcategorías</Link>
                    <span className='text-xs'>/</span>
                    <p className='text-dark-purple font-semibold'>Crear Categoría</p>
                </div>
                {/* Main Section */}
                <div className='w-full flex justify-center mt-6'>
                    <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                        <div className='w-11/12 mt-8 mb-8'>
                            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                <div className='bg-gray-50'>
                                    <div className='bg-white border'>
                                        <h1 className='text-accent-1 text-2xl p-4'>Registrar Categoría</h1>
                                    </div>
                                    <form onSubmit={this.onFormSubmit} className='mt-4'>
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Categoría</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <TagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => this.setState({ nombre: e.target.value })} id='txtNombre' type="text" name="nombre" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtNombre" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nombre</label>                                                           
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.nombre}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='p-4 flex justify-center gap-4 bg-white'>
                                            <button hidden={!this.state.processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" hidden={this.state.processingRequest} className="text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                            <Link to='/categorias-subcategorias' hidden={this.state.processingRequest} className="text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Cancelar</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CrearCategoriaPage;