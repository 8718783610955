import { BriefcaseIcon, UserGroupIcon as Group } from '@heroicons/react/outline';
import { ChartBarIcon, ChevronDownIcon, CogIcon, FolderOpenIcon, HomeIcon, InformationCircleIcon, TemplateIcon, TicketIcon, UserGroupIcon } from '@heroicons/react/solid';
import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import Select from 'react-select';
import { Tooltip as ReactTooltip } from 'react-tooltip';
var CryptoJS = require("crypto-js");

const CustomSchoolIcon = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path d="M288 0H400C408.8 0 416 7.164 416 16V64C416 72.84 408.8 80 400 80H320V95.53L410.3 160H512C547.3 160 576 188.7 576 224V448C576 483.3 547.3 512 512 512H336V400C336 373.5 314.5 352 288 352C261.5 352 240 373.5 240 400V512H64C28.65 512 0 483.3 0 448V224C0 188.7 28.65 160 64 160H165.7L256 95.53V32C256 14.33 270.3 0 288 0V0zM288 192C261.5 192 240 213.5 240 240C240 266.5 261.5 288 288 288C314.5 288 336 266.5 336 240C336 213.5 314.5 192 288 192zM80 224C71.16 224 64 231.2 64 240V304C64 312.8 71.16 320 80 320H112C120.8 320 128 312.8 128 304V240C128 231.2 120.8 224 112 224H80zM448 304C448 312.8 455.2 320 464 320H496C504.8 320 512 312.8 512 304V240C512 231.2 504.8 224 496 224H464C455.2 224 448 231.2 448 240V304zM80 352C71.16 352 64 359.2 64 368V432C64 440.8 71.16 448 80 448H112C120.8 448 128 440.8 128 432V368C128 359.2 120.8 352 112 352H80zM464 352C455.2 352 448 359.2 448 368V432C448 440.8 455.2 448 464 448H496C504.8 448 512 440.8 512 432V368C512 359.2 504.8 352 496 352H464z"/>
    </svg>
);

const CustomFinanceIcon = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 512 512">
        <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z"/>
    </svg>
);

export const Modulos = [
    { title: "Dashboard", value: 'dashboard', key: 1, icon: <HomeIcon className='h-8' />, permissions: ['read'] },
    { title: "Solicitudes", value: 'peticiones', key: 2, icon: <TicketIcon className='h-8' />, permissions: ['create', 'read', 'update', 'delete'] },
    { 
        title: "Automatización", 
        value: 'automatizacion', 
        key: 'automatizacion',
        icon: <CogIcon className='h-8' />, 
        permissions: ['create', 'read', 'update', 'delete'],
        isCollapsable: true,
        submodulos: [
            { title: "SLA", value: 'sla', key: 15, icon: <CogIcon className='h-8' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Notificaciones Correo", value: 'notificaciones correo', key: 16, icon: <CogIcon className='h-8' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Recordatorios", value: 'recordatorios', key: 28, icon: <CogIcon className='h-8' />, permissions: ['create', 'read', 'update', 'delete'] },
        ]
    },
    { 
        title: "Personalización", 
        value: 'personalizacion', 
        key: 'personalizacion',
        icon: <TemplateIcon className='h-8' />, 
        permissions: ['create', 'read', 'update', 'delete'],
        isCollapsable: true,
        submodulos: [
            { title: "Categorías", value: 'categorias', key: 3, icon: <TemplateIcon className='h-8' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Subcategorías", value: 'subcategorias', key: 10, icon: <TemplateIcon className='h-8' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Estatus", value: 'estatus', key: 4, icon: <TemplateIcon className='h-8' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Prioridades", value: 'prioridades', key: 5, icon: <TemplateIcon className='h-8' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Departamentos", value: 'departamentos', key: 6, icon: <TemplateIcon className='h-8' />, permissions: ['create', 'read', 'update', 'delete'] },
        ]
    },
    { title: "Reportes", value: 'reportes', key: 7, icon: <ChartBarIcon className='h-8' />, permissions: ['read'] },
    { 
        title: "Admin", 
        value: 'admin', 
        key: 'admin',
        icon: <FolderOpenIcon className='h-8' />, 
        permissions: ['create', 'read', 'update', 'delete'], 
        isCollapsable: true,
        submodulos: [
            { title: "Usuarios", value: 'usuarios', key: 8, icon: <FolderOpenIcon className='h-8' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Roles", value: 'roles', key: 9, icon: <FolderOpenIcon className='h-8' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Puestos", value: 'puestos', key: 13, icon: <FolderOpenIcon className='h-8' />, permissions: ['create', 'read', 'update', 'delete'] },
        ]
    }, 
    { 
        title: "Personal", 
        value: 'personal', 
        key: 'personal',
        icon: <UserGroupIcon className='h-8' />, 
        permissions: ['create', 'read', 'update', 'delete'], 
        isCollapsable: true,
        submodulos: [
            { title: "Tutores", value: 'tutores', key: 11, icon: <UserGroupIcon className='h-8' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Administrativos", value: 'administrativos', key: 12, icon: <UserGroupIcon className='h-8' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Estudiantes", value: 'estudiantes', key: 17, icon: <UserGroupIcon className='h-8' />, permissions: ['create', 'read', 'update', 'delete'] },
        ]
    },
    { 
        title: "Escuelas", 
        value: 'escuelas', 
        key: 'escuelas',
        icon: <CustomSchoolIcon className='h-7 fill-white' />, 
        permissions: ['create', 'read', 'update', 'delete'], 
        isCollapsable: true,
        submodulos: [
            { title: "Planteles", value: 'planteles', key: 14, icon: <CustomSchoolIcon className='h-7 fill-white' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Ciclos", value: 'ciclos', key: 18, icon: <CustomSchoolIcon className='h-7 fill-white' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Turnos", value: 'turnos', key: 24, icon: <CustomSchoolIcon className='h-7 fill-white' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Grupos", value: 'grupos', key: 25, icon: <CustomSchoolIcon className='h-7 fill-white' />, permissions: ['create', 'read', 'update', 'delete'] },
        ]
    },
    { 
        title: "Finanzas", 
        value: 'finanzas', 
        key: 'finanzas',
        icon: <CustomFinanceIcon className='h-7 fill-white' />, 
        permissions: ['create', 'read', 'update', 'delete'], 
        isCollapsable: true,
        submodulos: [
            { title: "Becas", value: 'becas', key: 19, icon: <CustomFinanceIcon className='h-7 fill-white' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Descuentos", value: 'descuentos', key: 20, icon: <CustomFinanceIcon className='h-7 fill-white' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Tesorería", value: 'tesoreria', key: 21, icon: <CustomFinanceIcon className='h-7 fill-white' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Inscripciones", value: 'inscripciones', key: 22, icon: <CustomFinanceIcon className='h-7 fill-white' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Colegiaturas", value: 'colegiaturas', key: 23, icon: <CustomFinanceIcon className='h-7 fill-white' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Cafetería", value: 'cafeteria', key: 26, icon: <CustomFinanceIcon className='h-7 fill-white' />, permissions: ['create', 'read', 'update', 'delete'] },
            { title: "Pagos", value: 'pagos', key: 27, icon: <CustomFinanceIcon className='h-7 fill-white' />, permissions: ['create', 'read', 'update', 'delete'] },
        ]
    },
];

class CrearRolPage extends Component {

    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.state = {
            usersAccess: _usersAccess,
            nombre: '', permisos: [], selectedDepts: [], statusResult: 0, processingRequest: false, errors: [{nombre: '', permisos: '', selected_deptos: ''}], 
            automatizacionCollsapsed: false, personalizacionCollapsed: false, adminCollapsed: false, personalCollapsed: false, escuelasCollapsed: false, finanzasCollapsed: false,
            departamentos: [],
            allDepts: true, tutor: false,
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.Departamentos).then((result) => {
            this.setState({ departamentos: [] });
            result.forEach(element => {
                this.setState(prevState => ({
                    departamentos: [...prevState.departamentos, { value: element.id, label: element.nombre }]
                }));
            });
        });
    }

    handleCollapse = (value) => {
        switch (value) {
            case 'personalizacion':
                this.setState(prevState => ({ personalizacionCollapsed: !prevState.personalizacionCollapsed }));
                break;
            case 'admin':
                this.setState(prevState => ({ adminCollapsed: !prevState.adminCollapsed }));
                break;
            case 'personal':
                this.setState(prevState => ({ personalCollapsed: !prevState.personalCollapsed }));
                break;
            case 'escuelas':
                this.setState(prevState => ({ escuelasCollapsed: !prevState.escuelasCollapsed }));
                break;
            case 'automatizacion':
                this.setState(prevState => ({automatizacionCollsapsed: !prevState.automatizacionCollsapsed}));
                break;
            case 'finanzas':
                this.setState(prevState => ({finanzasCollapsed: !prevState.finanzasCollapsed}));
                break;
            default:
                break;
        }
    }

    handleCheck = (checkbox) => {
        // Get modulo and permission from check id
        var id = checkbox.id.split('_');
        var key = id[0];
        var permission = parseInt(id[1]);
        // reason: We need to compare two literal values
        // eslint-disable-next-line
        var modulo = Modulos.find(m => m.key == key);
        // If we did not find it, search in submodulos
        var parent = undefined;
        if (modulo === undefined) {
            var filtered = Modulos.filter(m => { return m.isCollapsable });
            filtered.forEach(mod => {
                // reason: We need to compare two literal values
                // eslint-disable-next-line
                var submodulo = mod.submodulos.find(s => s.key == key);
                if (submodulo !== undefined) {
                    parent = mod;
                    modulo = submodulo;
                }
            });
        }
        // Check if checkbox is checked
        if (checkbox.checked) {
            // Check if we have checked a parent Modulo
            if (modulo.isCollapsable) {
                modulo.submodulos.forEach(sub => {
                    var permissionToCheck = this.state.permisos.find(p => p.modulo === sub.key && p.permiso === permission);
                    var readPermissionCheck = this.state.permisos.find(p => p.modulo === parseInt(sub.key) && p.permiso === 2);
                    if (!this.state.permisos.includes(permissionToCheck) && !this.state.permisos.includes(readPermissionCheck) && permission !== 2) {
                        this.setState(prevState => ({
                            permisos: [...prevState.permisos, {'modulo': parseInt(sub.key), 'permiso': 2}, {'modulo': parseInt(sub.key), 'permiso': permission}]
                        }));
                        document.getElementById(modulo.key + '_2').checked = true;
                    }
                    else if (!this.state.permisos.includes(permissionToCheck)) {
                        this.setState(prevState => ({
                            permisos: [...prevState.permisos, {'modulo': parseInt(sub.key), 'permiso': permission}]
                        }));
                    }
                });
            }
            else {   
                var permissionToCheck = this.state.permisos.find(p => p.modulo === parseInt(modulo.key) && p.permiso === permission);
                var readPermissionCheck = this.state.permisos.find(p => p.modulo === parseInt(modulo.key) && p.permiso === 2);
                if (!this.state.permisos.includes(permissionToCheck) && !this.state.permisos.includes(readPermissionCheck) && permission !== 2) {
                    this.setState(prevState => ({
                        permisos: [...prevState.permisos, {'modulo': parseInt(key), 'permiso': 2}, {'modulo': parseInt(key), 'permiso': permission}]
                    }));
                    document.getElementById(modulo.key + '_2').checked = true;
                }
                else if (!this.state.permisos.includes(permissionToCheck)) {
                    this.setState(prevState => ({
                        permisos: [...prevState.permisos, {'modulo': parseInt(key), 'permiso': permission}]
                    }));
                }
                if (parent !== undefined) {
                    var submodulosLength = parent.submodulos.length - 1;
                    var count = 0;
                    parent.submodulos.forEach(sub => {
                        if (this.state.permisos.some(p => p.modulo === sub.key && p.permiso === permission)) {
                            count++;
                        }
                    });
                    if (submodulosLength === count) {
                        document.getElementById(parent.key + '_' + permission).checked = true;
                    }
                }
            }
        }
        else {
            if (modulo.isCollapsable) {
                modulo.submodulos.forEach(sub => {
                    var permisoToRemove = this.state.permisos.find(p => p.modulo === parseInt(sub.key) && p.permiso === permission);
                    // Check if we have removed 'Read' Permission
                    if (permisoToRemove.permiso === 2) {
                        this.setState(prevState => ({
                            permisos: prevState.permisos.filter(function(permiso) { 
                                return permiso.modulo !== sub.key;
                            })
                        }));
                        for (let index = 1; index < 5; index++) {
                            document.getElementById(sub.key + '_' + index).checked = false;
                            document.getElementById(modulo.key + '_' + index).checked = false;
                        }
                    }
                    else {
                        this.setState(prevState => ({
                            permisos: prevState.permisos.filter(function(permiso) { 
                                return permiso !== permisoToRemove;
                            })
                        }));
                    }
                });
            }
            else {
                var permisoToRemove = this.state.permisos.find(p => p.modulo === parseInt(key) && p.permiso === permission);
                // Check if we have removed 'Read' Permission
                if (permisoToRemove.permiso === 2) {
                    this.setState(prevState => ({
                        permisos: prevState.permisos.filter(function(permiso) { 
                            // reason: We need to compare two literal values
                            // eslint-disable-next-line
                            return permiso.modulo != modulo.key;
                        })
                    }));
                    for (let index = 1; index < 5; index++) {
                        document.getElementById(modulo.key + '_' + index).checked = false;
                    }
                }
                else {
                    this.setState(prevState => ({
                        permisos: prevState.permisos.filter(function(permiso) { 
                            return permiso !== permisoToRemove;
                        })
                    }));
                } 
                if (parent !== undefined) {
                    document.getElementById(parent.key + '_' + permission).checked = false;
                }
            }
        }
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        //Disable controls while request is processed
        this.setState({ processingRequest: true, errors: [{nombre: '', permisos: '', selected_deptos: ''}] });

        const data = new FormData();
        data.append('nombre', this.state.nombre);
        data.append('permisos', JSON.stringify(this.state.permisos));
        data.append('selected_deptos', this.state.allDepts ? this.state.departamentos.map(d => {return d.value}) : this.state.selectedDepts);
        data.append('tutor', this.state.tutor);

        RestClient.PostRequest(AppUrl.CreateRol, data).then((result) => {
            this.setState({ statusResult: result.status, processingRequest: false, errors: result.data });
            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    render() {
        if (this.state.usersAccess.find(ua => ua.modulo_name === 'roles' && ua.permiso_name === 'create') === undefined) {
            return <Navigate to='/access-denied' />
        }
        if (this.state.statusResult === 200) {
            return <Navigate to='/roles' />
        }
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'ml-80' : 'ml-20'} duration-300 p-12 pt-24 pr-16 bg-gray-100`}>
                {/* Page Title */}
                <div className='flex w-full'>
                    <h1 className='text-xl font-medium'>Crear Rol</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 mt-2 space-x-1'>
                    {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                    <>
                        <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                        <span className='text-xs'>/</span>
                    </>}
                    <p className='text-gray-400'>Admin</p>
                    <span className='text-xs'>/</span>
                    <Link to='/roles' className='font-semibold hover:underline'>Roles</Link>
                    <span className='text-xs'>/</span>
                    <p className='text-dark-purple font-semibold'>Crear Rol</p>
                </div>
                {/* Main Section */}
                <div className='w-full flex justify-center mt-6'>
                    <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                        <div className='w-11/12 mt-8 mb-8'>
                            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                <div className='bg-gray-50'>
                                    <div className='bg-white border'>
                                        <h1 className='text-accent-1 text-2xl p-4'>Registrar Rol</h1>
                                    </div>
                                    <form onSubmit={this.onFormSubmit} className='mt-4'>
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Rol</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <BriefcaseIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => this.setState({ nombre: e.target.value })} id='txtNombre' type="text" name="nombre" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtNombre" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nombre</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.nombre}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="md:inline-flex flex-col space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mt-4 mb-4">
                                            <div className="md:w-full mx-auto space-y-8">                                                
                                                <section className="relative">
                                                    <div className="w-full mb-8 px-4">
                                                        <span className='text-sm text-red-500'>{this.state.errors.permisos}</span>
                                                        <div className="relative mt-5 flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-accent-2 text-white">
                                                            <div className="rounded-t mb-0 px-4 py-3 border-0">
                                                                <div className="flex flex-wrap items-center">
                                                                    <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                                                        <h3 className="font-semibold text-lg text-white">Información Permisos</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="block w-full overflow-x-auto ">
                                                                <table className="items-center w-full bg-transparent border-collapse">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-800 text-red-200 border-pink-700">Modulo</th>
                                                                            <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-800 text-red-200 border-pink-700">Consultar</th>
                                                                            <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-800 text-red-200 border-pink-700">Crear</th>
                                                                            <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-800 text-red-200 border-pink-700">Actualizar</th>
                                                                            <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-800 text-red-200 border-pink-700">Borrar</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {Modulos.map((modulo, index) => (
                                                                            <>
                                                                                <tr key={index}>
                                                                                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center -mr-12"> 
                                                                                        {modulo.isCollapsable === true ? 
                                                                                        <button type='button' className='inline-flex items-center' onClick={() => this.handleCollapse(modulo.value)} >
                                                                                            {modulo.icon}
                                                                                            <span className="ml-3 font-semibold text-white"> {modulo.title} </span>
                                                                                            <ChevronDownIcon className={`${(
                                                                                            modulo.title === 'Automatización' ? this.state.automatizacionCollsapsed :
                                                                                            modulo.title === 'Personalización' ? this.state.personalizacionCollapsed :
                                                                                            modulo.title === 'Admin' ? this.state.adminCollapsed : 
                                                                                            modulo.title === 'Personal' ? this.state.personalCollapsed :
                                                                                            modulo.title === 'Escuelas' ? this.state.escuelasCollapsed : 
                                                                                            modulo.title === 'Finanzas' ? this.state.finanzasCollapsed : false) && 'rotate-180'} duration-300 h-5 ml-3`} />
                                                                                        </button> :
                                                                                        <>
                                                                                            {modulo.icon}
                                                                                            <span className="ml-3 font-semibold text-white"> {modulo.title} </span>
                                                                                        </>
                                                                                        }
                                                                                    </th>
                                                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-8">
                                                                                        <input onChange={(e) => this.handleCheck(e.target)} disabled={!modulo.permissions.some(p => p === 'read')} className='ml-7 accent-pink-800' type="checkbox" name="read" id={`${modulo.key}_2`} style={{transform: 'scale(1.5)'}} />
                                                                                    </td>
                                                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-8">
                                                                                        <input onChange={(e) => this.handleCheck(e.target)} disabled={!modulo.permissions.some(p => p === 'create')} className='ml-3 accent-pink-800' type="checkbox" name="create" id={`${modulo.key}_1`} style={{transform: 'scale(1.5)'}} />
                                                                                    </td>
                                                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-8">
                                                                                        <input onChange={(e) => this.handleCheck(e.target)} disabled={!modulo.permissions.some(p => p === 'update')} className='ml-7 accent-pink-800' type="checkbox" name="update" id={`${modulo.key}_3`} style={{transform: 'scale(1.5)'}} />
                                                                                    </td>
                                                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-8">
                                                                                        <input onChange={(e) => this.handleCheck(e.target)} disabled={!modulo.permissions.some(p => p === 'delete')} className='ml-5 accent-pink-800' type="checkbox" name="delete" id={`${modulo.key}_4`} style={{transform: 'scale(1.5)'}} />
                                                                                    </td>
                                                                                </tr>
                                                                                {modulo.isCollapsable && modulo.submodulos !== undefined && modulo.submodulos.map((submodulo, index) => (
                                                                                    <tr key={index} hidden={
                                                                                    modulo.value === 'automatizacion' ? !this.state.automatizacionCollsapsed :
                                                                                    modulo.value === 'personalizacion' ? !this.state.personalizacionCollapsed : 
                                                                                    modulo.value === 'admin' ? !this.state.adminCollapsed :
                                                                                    modulo.value === 'personal' ? !this.state.personalCollapsed :
                                                                                    modulo.value === 'escuelas' ? !this.state.escuelasCollapsed :
                                                                                    modulo.value === 'finanzas' ? !this.state.finanzasCollapsed: true} >
                                                                                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center ml-8 -mr-12"> 
                                                                                            {submodulo.icon}
                                                                                            <span className="ml-3 font-semibold text-white"> {submodulo.title} </span>
                                                                                        </th>
                                                                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-8">
                                                                                            <input onChange={(e) => this.handleCheck(e.target)} disabled={!submodulo.permissions.some(p => p === 'read')} checked={this.state.permisos.some(p => (p.modulo === submodulo.key && p.permiso === 2))} className='ml-7 accent-pink-800' type="checkbox" name="read" id={`${submodulo.key}_2`} style={{transform: 'scale(1.5)'}} />
                                                                                        </td>
                                                                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-8">
                                                                                            <input onChange={(e) => this.handleCheck(e.target)} disabled={!submodulo.permissions.some(p => p === 'create')} checked={this.state.permisos.some(p => (p.modulo === submodulo.key && p.permiso === 1))} className='ml-3 accent-pink-800' type="checkbox" name="create" id={`${submodulo.key}_1`} style={{transform: 'scale(1.5)'}} />
                                                                                        </td>
                                                                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-8">
                                                                                            <input onChange={(e) => this.handleCheck(e.target)} disabled={!submodulo.permissions.some(p => p === 'update')} checked={this.state.permisos.some(p => (p.modulo === submodulo.key && p.permiso === 3))} className='ml-7 accent-pink-800' type="checkbox" name="update" id={`${submodulo.key}_3`} style={{transform: 'scale(1.5)'}} />
                                                                                        </td>
                                                                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-8">
                                                                                            <input onChange={(e) => this.handleCheck(e.target)} disabled={!submodulo.permissions.some(p => p === 'delete')} checked={this.state.permisos.some(p => (p.modulo === submodulo.key && p.permiso === 4))} className='ml-5 accent-pink-800' type="checkbox" name="delete" id={`${submodulo.key}_4`} style={{transform: 'scale(1.5)'}} />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))} 
                                                                            </>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> 
                                            </div>
                                            <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                                <div className='md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8 inline-flex items-center gap-2'>
                                                    <h2 className="">Permisos Adicionales: Solicitudes</h2>
                                                    <ReactTooltip className='tooltip-default' anchorId='tooltipPermisos-Adicionales-Peticiones' content='Selecciona los departamentos que el usuario podrá ver asociadas con las solicitudes' place="top" />
                                                    <InformationCircleIcon id='tooltipPermisos-Adicionales-Peticiones' className='h-5 text-accent-2' />
                                                </div>
                                                <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                    <div className='inline-flex gap-3'>
                                                        <input id='checkTutor' onChange={() => this.setState({ tutor: !this.state.tutor })} disabled={!this.state.permisos.some(p => p.modulo === 2)} className='accent-accent-2 peer' type="checkbox" style={{ transform: 'scale(1.5)' }} />
                                                        <label htmlFor='checkTutor' className='text-base peer-disabled:text-gray-400 text-gray-800 text-light font-normal'>El rol será usado por usuarios tipo 'Tutor' y/o 'Estudiante'</label>   
                                                    </div>
                                                    <div className={`${!this.state.tutor && 'inline-flex'} gap-3`} hidden={this.state.tutor}>
                                                        <input id='checkAllDepts' onChange={() => this.setState({ allDepts: !this.state.allDepts })} disabled={!this.state.permisos.some(p => p.modulo === 2)} className='accent-accent-2 peer' type="checkbox" defaultChecked style={{ transform: 'scale(1.5)' }} />
                                                        <label htmlFor='checkAllDepts' className='text-base peer-disabled:text-gray-400 text-gray-800 text-light font-normal'>Todos los departamentos</label>   
                                                    </div>
                                                    <div className=''>
                                                        <div className={`w-full ${!this.state.allDepts && !this.state.tutor && 'inline-flex'} border-2 rounded border-gray-400 focus-within:border-accent-2 group`} hidden={this.state.allDepts || this.state.tutor}>
                                                            <div className="w-1/12 items-center flex justify-center">
                                                                <Group className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                            <Select placeholder='--Seleccionar Departamentos--'
                                                                isMulti
                                                                options={this.state.departamentos}
                                                                menuPortalTarget={document.body}
                                                                className='md:p-1'
                                                                onChange={(valor) => this.setState({ selectedDepts: valor.map((v) => {return v.value}) })}
                                                                isLoading={this.state.departamentos.length === 0}
                                                                isDisabled={!this.state.permisos.some(p => p.modulo === 2)}
                                                                styles={{
                                                                    option: (base, state) => ({
                                                                        ...base,
                                                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                                                    }),
                                                                }}
                                                            />
                                                            </div>
                                                        </div>
                                                        <span className='text-sm text-red-500'>{this.state.errors.selected_deptos}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='p-4 flex justify-center gap-4 bg-white'>
                                            <button hidden={!this.state.processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" hidden={this.state.processingRequest} className="text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                            <Link to='/roles' hidden={this.state.processingRequest} className={`${!this.state.processingRequest && 'flex'} items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CrearRolPage;